import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AccountService } from '../../data-access-layer/account/account.service';
import { Account } from '../../model/account/account';
import { UserStateService } from '../../auth/user-state-service';
import { AuthService } from '../../auth/auth.service';
import { Constants } from '../../constants';
import { Subject } from 'rxjs';
import { Group } from '../../model/group/group';
import { NotifService } from '../../core/notification/notif.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Modules } from 'src/app/model/modules/modules';

@Component({
    selector: 'map-edit-account',
    templateUrl: './edit-account.component.html',
    styleUrls: ['./edit-account.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditAccountComponent implements OnInit {
    @Input() account: Account;
    @Input() emitAccountOnEditSubject: Subject<Group>;
    @Output() Update: EventEmitter<string> = new EventEmitter<string>();
    editAccountForm: UntypedFormGroup;
    loading = false;
    selectedValue: boolean = false;

    constructor(
        private readonly accountService: AccountService,
        private readonly userStateService: UserStateService,
        private readonly auth: AuthService, private readonly notifService: NotifService,
        private readonly changeDetector: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.editAccountForm = new UntypedFormGroup({
            id: new UntypedFormControl(null),
            name: new UntypedFormControl(null, [Validators.required, Validators.maxLength(Constants.ACCOUNT_NAME_LENGTH),  Validators.pattern('^[A-Za-z0-9]+( [A-Za-z0-9]+)*$')])
        });
        this.emitAccountOnEditSubject.subscribe(account => {
            this.selectedValue = false;
            this.editAccountForm.patchValue({
                id: account.id,
                name: account.name
            });
        });
    }

    submitAccount() {
        if (this.editAccountForm.valid) {
            let { name } = this.editAccountForm.getRawValue();
            this.account.name = name;

            this.accountService.updateAccount(this.account.id, this.account).subscribe((res) => {
                this.Update.emit('updated');
            }, err => {
                this.Update.emit('Error: Not updated;');
                this.notifService.success('Account with this username already exists.');
            });
        }
    }
}

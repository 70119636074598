import { LiveAnnouncer } from "@angular/cdk/a11y";
import { Component, ElementRef, ViewChild, inject } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { debounceTime, map, startWith, take } from "rxjs/operators";
import { UserStateService } from "src/app/auth/user-state-service";
import { NotifService } from "src/app/core/notification/notif.service";
import {
    APIMeteringDropdownItem,
    ApiMeteringService,
    FilterObject,
    SortingOrder,
} from "src/app/data-access-layer/api-metering/api-metering.service";
import { DatapointsPageStateService } from "src/app/dataset/datapoints/datapoints-page-state.service";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { AuthService } from "src/app/auth/auth.service";
import { AttachmentUtils } from "src/app/core/utils/attachment-utils";
import { Dataset } from "src/app/model/dataset/dataset";

const LIMIT = 20;
const DEFAULT_SKIP = 0;

enum FilterType {
    SERVICE = "SERVICE",
    USERNAME = "USERNAME",
}

@Component({
    selector: "map-account-api-metering",
    templateUrl: "./account-api-metering.component.html",
    styleUrls: ["./account-api-metering.component.scss"],
})
export class AccountApiMeteringComponent {
    private readonly FILE_NAME: string = "3rd-party-api-usage.xlsx";

    filterTypes = FilterType;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    serviceNameControl = new FormControl("");
    filteredServiceNames: Observable<APIMeteringDropdownItem[]>;
    serviceNames: string[] = [];
    allServiceNames: APIMeteringDropdownItem[] = [];

    usernameControl = new FormControl("");
    filteredUsernames: Observable<APIMeteringDropdownItem[]>;
    usernames: string[] = [];
    allUsernames: APIMeteringDropdownItem[] = [];

    @ViewChild("serviceNameInput")
    serviceNameInput: ElementRef<HTMLInputElement>;
    @ViewChild("usernameInput")
    usernameInput: ElementRef<HTMLInputElement>;

    announcer = inject(LiveAnnouncer);

    private readonly limit: number;

    totalResultCount: number;
    apiMeteringData: any[];
    apiMeteringDataSource: MatTableDataSource<any>;
    currentDisplayedColumns: string[] = [
        "service",
        "accountName",
        "username",
        "createdDate",
        "counter",
    ];

    accountAdminDisplayedColumns: string[] = [
        "service",
        "username",
        "createdDate",
        "counter",
    ];

    providerDisplayedColumns: string[] = [
        "service",
        "accountName",
        "createdDate",
        "counter",
    ];

    isTableDataLoading: boolean;
    breadCrumbs = ["Home", "Accounts", "Settings"];
    filterObject: FilterObject;
    paginationInfo: any;
    fetchAPIMeteringDataSubject: BehaviorSubject<boolean> = new BehaviorSubject(
        true
    );
    isAPIMeteringFetchApiCall: boolean;
    filterForm: FormGroup;
    accountId: number;

    set skip(value: number) {
        this.filterObject.skip = value;
    }

    get skip() {
        return this.filterObject.skip;
    }

    constructor(
        private readonly router: Router,
        private readonly apiMeteringService: ApiMeteringService,
        private readonly notifService: NotifService,
        private readonly datapointsPageStateService: DatapointsPageStateService,
        private fb: FormBuilder,
        public userStateService: UserStateService,
        private readonly route: ActivatedRoute,
        private readonly auth: AuthService
    ) {
        this.accountId = +this.route.snapshot.paramMap.get("accountId");
        this.isTableDataLoading = true; 
        this.limit = LIMIT;

        this.initializeFilterObject();

        this.filterForm = this.fb.group({
            startDate: [""],
            endDate: [""],
        });
    }

    add(event: MatChipInputEvent, type: FilterType): void {
        const value = (event.value || "").trim();
        if (type === FilterType.SERVICE) {
            if (value) {
                this.serviceNames.push(value);
            }
            event.chipInput!.clear();
            this.serviceNameControl.setValue(null);
        } else {
            if (value) {
                this.usernames.push(value);
            }
            event.chipInput!.clear();
            this.usernameControl.setValue(null);
        }
    }

    remove(value: string, type: FilterType): void {
        if (type === FilterType.SERVICE) {
            const index = this.serviceNames.indexOf(value);

            if (index >= 0) {
                this.serviceNames.splice(index, 1);
                this.announcer.announce(`Removed ${value}`);
            }
        } else {
            const index = this.usernames.indexOf(value);

            if (index >= 0) {
                this.usernames.splice(index, 1);
                this.announcer.announce(`Removed ${value}`);
            }
        }

        if (this.isFilterActive()) {
            this.filterAPIMeteringData();
            this.getPaginationInfo();
        }
    }

    selected(event: MatAutocompleteSelectedEvent, type: FilterType): void {
        if (type === FilterType.SERVICE) {
            this.serviceNames.push(event.option.viewValue);
            this.serviceNameInput.nativeElement.value = "";
            this.serviceNameControl.setValue(null);
        } else {
            this.usernames.push(event.option.viewValue);
            this.usernameInput.nativeElement.value = "";
            this.usernameControl.setValue(null);
        }
    }

    private _filter(
        value: string,
        type: FilterType
    ): APIMeteringDropdownItem[] {
        const filterValue = value.toLowerCase();
        if (type === FilterType.SERVICE) {
            return this.allServiceNames.filter((serviceName) =>
                serviceName.name.toLowerCase().includes(filterValue)
            );
        } else {
            return this.allUsernames.filter((username) =>
                username.name.toLowerCase().includes(filterValue)
            );
        }
    }

    ngOnInit(): void {
        if (this.userStateService.isSuperadmin) {
            this.breadCrumbs = [];
        }
        this.fetchAPIMeteringDataSubject
            .pipe(debounceTime(500))
            .subscribe((reset) => {
                if (reset === true) {
                    this.resetTable();
                }
                if (!this.isAPIMeteringFetchApiCall) {
                    this.fetchAPIMeteringData();
                    this.getPaginationInfo();
                    this.setIsAPIMeteringFetchApiCall();
                }
            });

        this.datapointsPageStateService.activeAccount = undefined;

        this.getUserForDropdown(this.accountId);
        this.getServicesForDropdown();
    }

    get componentName() {
        return this.constructor.name;
    }

    fetchAPIMeteringData() {
        this.apiMeteringService
            .getPaginatedAPIMeteringUsage(this.filterObject)
            .pipe(take(1))
            .subscribe(
                (apiMeteringData) => {
                    this.apiMeteringService.getTotalCount(this.filterObject).subscribe((count) => {
                        this.totalResultCount = count;    
                    })
                    const filterField = this.filterObject.sortBy;
                    const sortOrder = this.filterObject.sortOrder;
                    this.apiMeteringData = apiMeteringData;

                    const sortData = (fieldName, order, data) => {
                        if(order === 'ASCENDANT') {
                            return data.sort((a, b) => (a[fieldName] > b[fieldName]) ? 1 : ((b[fieldName] > a[fieldName]) ? -1 : 0));
                        } else {
                            return data.sort((a, b) => (a[fieldName] < b[fieldName]) ? 1 : ((b[fieldName] < a[fieldName]) ? -1 : 0));
                        }
                        return data;
                    }

                    this.apiMeteringDataSource = new MatTableDataSource(
                        sortData(filterField, sortOrder, apiMeteringData)
                    );
                    this.isTableDataLoading = false;
                },
                (err) => {
                    this.notifService.error(
                        "Something went wrong... Please check the FILTERS."
                    );
                }
            );
    }
    
    public setIsAPIMeteringFetchApiCall(flag: boolean = true) {
        this.isAPIMeteringFetchApiCall = flag;
    }

    onPaginationChange(paginationChangeEvent: {
        numberOfItemsPerPage: number;
        currentPage: number;
        onPageLoadCall: boolean;
    }) {
        this.filterObject.limit = paginationChangeEvent.numberOfItemsPerPage;
        this.skip = paginationChangeEvent.currentPage * this.limit;
        if (!paginationChangeEvent.onPageLoadCall) {
            this.setIsAPIMeteringFetchApiCall(false);
        }
        this.updateAPIMeteringData(false);
    }

    getPaginationInfo() {
        this.apiMeteringService
            .getAPIMeteringUsageCount(this.filterObject)
            .subscribe(
                (count) => {
                    this.paginationInfo = { count: count };
                },
                (err) => {
                    console.log(err);
                }
            );
    }

    resetTable(): void {
        this.skip = 0;
        this.apiMeteringData = [];
        this.apiMeteringDataSource = new MatTableDataSource(
            this.apiMeteringData
        );
    }

    updateAPIMeteringData(resetTable = false) {
        this.fetchAPIMeteringDataSubject.next(resetTable);
    }

    overlaySortChange($event) {
        if (SortingOrder[$event.direction.toUpperCase()]) {
            this.filterObject.sortBy = $event.active;
            this.filterObject.sortOrder =
                SortingOrder[$event.direction.toUpperCase()];
        } else {
            this.filterObject.sortOrder = SortingOrder.ASC;
        }

        this.fetchAPIMeteringData();
        this.getPaginationInfo();
    }

    filterAPIMeteringData() {
        this.filterObject.filter.end_date = this.setDateForFilterObject(
            this.filterForm.value.endDate
        );
        this.filterObject.filter.start_date = this.setDateForFilterObject(
            this.filterForm.value.startDate
        );

        this.filterObject.filter.user_id = this.mapIdsWithNames(
            this.usernames,
            FilterType.USERNAME
        );
        this.filterObject.filter.service = this.mapIdsWithNames(
            this.serviceNames,
            FilterType.SERVICE
        );
        
        this.fetchAPIMeteringData();
        this.getPaginationInfo();
    }

    setDateForFilterObject(date: any): number[] {
        if (date) {
            date = new Date(date);
            date.setHours(0, 0, 0, 0);
            return [date.valueOf()];
        }
        return [];
    }

    getUserForDropdown(accountId) {
        this.apiMeteringService.getUsersForDropdown(accountId).subscribe((users) => {
            this.allUsernames = users;
            this.filteredUsernames = this.usernameControl.valueChanges.pipe(
                startWith(null),
                map((username: string | null) =>
                    username
                        ? this._filter(username, FilterType.USERNAME)
                        : this.allUsernames.slice()
                )
            );
        });
    }

    getServicesForDropdown() {
        this.apiMeteringService
            .getServicesForDropdown()
            .subscribe((services) => {
                this.allServiceNames = services;
                this.filteredServiceNames =
                    this.serviceNameControl.valueChanges.pipe(
                        startWith(null),
                        map((serviceName: string | null) =>
                            serviceName
                                ? this._filter(serviceName, FilterType.SERVICE)
                                : this.allServiceNames.slice()
                        )
                    );
            });
    }

    mapIdsWithNames(names: string[], type: FilterType): string[] {
        const ids: string[] = [];
        if (type === FilterType.SERVICE) {
            names.forEach((name) => {
                const matchedService = this.allServiceNames.find(
                    (service) => service.name === name
                );
                if (matchedService) {
                    ids.push(matchedService.id);
                }
            });
        } else {
            names.forEach((name) => {
                const matchedUser = this.allUsernames.find(
                    (user) => user.name === name
                );
                if (matchedUser) {
                    ids.push(matchedUser.id);
                }
            });
        }

        return ids;
    }

    isFilterActive() {
        let isFilterActive = false;
        const { user_id, end_date, start_date, service } =
            this.filterObject.filter;
        if (
            user_id.length ||
            end_date.length ||
            start_date.length ||
            service.length
        ) {
            isFilterActive = true;
        }
        return isFilterActive;
    }

    clearFilter() {
        this.initializeFilterObject();
        this.filterForm.reset();
        this.usernames = [];
        this.serviceNames = [];

        this.fetchAPIMeteringData();
        this.getPaginationInfo();
    }

    clearDate(date : string) {
        this.filterForm.get(date).reset();
    }


    initializeFilterObject() {
        this.filterObject = {
            skip: DEFAULT_SKIP,
            limit: this.limit,
            sortBy: this.userStateService.principal.isSuperadmin ?  this.currentDisplayedColumns[0] : this.accountAdminDisplayedColumns[0] ,
            sortOrder: SortingOrder.ASC,
            filter: {
                client: [],
                user_id: [],
                service: [],
                end_date: [],
                start_date: [],
            },
        };

        if (
            this.auth.can("update", this.accountId) &&
            !this.userStateService.isSuperadmin
        ) {
            this.filterObject.filter.client.push(this.accountId);
        }
    }

    downloadAPIMeteringData() {
        this.apiMeteringService
            .downloadAPIMeteringData(this.filterObject, this.FILE_NAME)
            .subscribe(
                (response) => {
                    AttachmentUtils.downloadFileWithName(
                        response,
                        this.FILE_NAME
                    );
                },
                (err) => {
                    console.log(err);
                }
            );
    }
}

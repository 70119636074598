import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {RepeatPasswordValidator, settingsControlsConfig} from './settings.constants';
import {AuthService} from 'src/app/auth/auth.service';
import {Location} from '@angular/common';
import {RepeatPasswordEStateMatcher} from './validators';
import {NotifService} from '../../core/notification/notif.service';
import {DatapointsPageStateService} from '../../dataset/datapoints/datapoints-page-state.service';
import { Constants } from 'src/app/constants';

@Component({
    selector: 'map-user-settings',
    templateUrl: './user-settings.component.html',
    styleUrls: ['./user-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserSettingsComponent implements OnInit {
    userSettingsForm: UntypedFormGroup;
    passwordsMatcher = new RepeatPasswordEStateMatcher();

    constructor(
        private readonly location: Location,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly datapointsPageStateService: DatapointsPageStateService,
        formBuilder: UntypedFormBuilder,
        public auth: AuthService,
        private readonly notifService: NotifService
    ) {
        this.datapointsPageStateService.activeAccount = undefined;
        this.userSettingsForm = formBuilder.group(settingsControlsConfig, {validator: RepeatPasswordValidator});
    }

    ngOnInit() {
        this.initUserSettingsForm();
    }

    initUserSettingsForm(settings?: any) {
        if (settings) {
            this.userSettingsForm.patchValue(settings);
        } else {
            let token: any = this.auth.getPrincipal();
            this.userSettingsForm.patchValue({email: token.email, id: token.id});
        }
        this.changeDetector.detectChanges();
    }

    updateUserSettings() {
        this.auth.changeUserPassword(this.userSettingsForm.value).subscribe(
            success => {
                this.notifService.success('Password was changed successfully. Please re-login.');
                this.auth.logout();
            },
            error => {
                if(error.status === Constants.ERROR_CODES.FORBIDDEN) {
                this.notifService.error('current password is invalid.');
            }}
            );
    }

    back() {
        this.location.back();
    }
}

<mat-card>
    <form [formGroup]="createUserForm" action="">
        <h4>Create User</h4>
        <div class="col">
            <mat-form-field>
                <mat-label>User Name</mat-label>
                <input formControlName="name" matInput placeholder="Name" />
            </mat-form-field>
            <mat-form-field>
                <mat-label>Password</mat-label>
                <input
                    formControlName="password"
                    matInput
                    type="password"
                    placeholder="Password"
                />
            </mat-form-field>

            <button
                (click)="createUser()"
                type="button"
                raised-button
                mat-button
            >
                Confirm Invitation
            </button>
        </div>
    </form>
</mat-card>

<div class="edit-form-container">
    <form *ngIf="editAccountForm && !loading" [formGroup]="editAccountForm" class="form-wrapper">
        <mat-form-field>
            <mat-label>Name</mat-label>
            <input matInput autocomplete="off" name="edit-name" required formControlName="name">
            <mat-error *ngIf="editAccountForm.controls['name'].touched && editAccountForm.controls['name'].hasError('required') ">
                Account name is required
            </mat-error>
            <mat-error *ngIf="editAccountForm.controls['name'].touched && editAccountForm.controls['name'].hasError('maxlength')">
                You exceed the maximum number of characters.
            </mat-error>
            <mat-error *ngIf="editAccountForm.controls['name'].touched && editAccountForm.controls['name'].hasError('pattern')">
                You cannot use special characters. Space is allowed only between words.
            </mat-error>
        </mat-form-field>
    </form>
    <button (click)="submitAccount()" mat-flat-button type="submit"
            color="accent" class="map-button">Submit
    </button>
</div>


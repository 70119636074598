import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { MembersService } from "src/app/data-access-layer/members/members.service";

@Component({
    selector: "map-locations-edit-dialog",
    templateUrl: "./locations-edit-dialog.component.html",
    styleUrls: ["./locations-edit-dialog.component.scss"],
})
export class LocationsEditDialog {
    title: string;
    cancelButton: string;
    confirmButton: string;
    displayedColumns: string[];
    dropdownItems: any[] = [];
    selectedDropdownItems: any[];
    selectedGroupIds: any[];
    isDisabled: boolean = false;
    ulClassName = 'updateLocation';
    constructor(
        public dialogRef: MatDialogRef<LocationsEditDialog>,
        private readonly membersService: MembersService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.title = 'Update locations';
        this.cancelButton = "Cancel";
        this.confirmButton = "Save";
        this.dropdownItems = data.datasets;
        this.isDisabled = data.isAccountAdmin;
    }

    onConfirm(): void {
        this.dialogRef.close(this.selectedDropdownItems);
    }

    onDismiss(): void {
        this.dialogRef.close(false);
    }

    onSelectedItemsChanged(items: any[]): void {
        this.selectedDropdownItems = items;
        this.selectedGroupIds = this.selectedDropdownItems.map((item) => {
            return item.id;
        });
    }
}

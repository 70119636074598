import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { MapState } from '../model/map/map-state';
import { MapStateOptions } from '../model/map/map-state-options';
import { MapStateActionEnum } from '../model/map/map-state-action.enum';
import { MapDrawType } from '../model/map/map-draw-type';
import { ActiveShape } from '../model/map/active-shape';
import { MapStateForImageOverlays } from '../model/map/map-state-for-image-overlays';
import { ImageOverlay } from '../model/overlay/external/image-overlay';
import { MapLocation } from '../model/map/map-location';
import OverlayType = google.maps.drawing.OverlayType;
import { GeographicalRegion } from '../model/geographical-region/geographical-region';
import { Point } from '../model/geometry/point';
import { Crisis24Circle } from '../model/datapoint/crisis24-alert';

@Injectable({
    providedIn: 'root'
})
export class MapStateService {

    readonly activeSearchResultMarker$: BehaviorSubject<MapLocation>;
    private readonly activeOverlaysSubject: Subject<MapState>;
    private readonly activeShapesSubject: Subject<ActiveShape>;
    private readonly activeImageOverlaysSubject: Subject<MapStateForImageOverlays>;
    private readonly currentPinAddressSubject: ReplaySubject<string>;
    private readonly activeGeoRegionSubject: Subject<GeographicalRegion[]>;
    private readonly clearFilteredRegionsSubject: Subject<boolean>;
    private readonly markerIsInitialized: BehaviorSubject<boolean>;
    private readonly crisis24CirclesSubject: Subject<Crisis24Circle[]>;
    private readonly removeCrisis24Circles: Subject<boolean>;
    private readonly openWeatherAlertPolygonFlag: Subject<boolean>;

    constructor() {
        this.activeOverlaysSubject = new Subject<MapState>();
        this.activeShapesSubject = new Subject<ActiveShape>();
        this.activeSearchResultMarker$ = new BehaviorSubject<MapLocation>(null);
        this.activeImageOverlaysSubject = new Subject<MapStateForImageOverlays>();
        this.currentPinAddressSubject = new ReplaySubject<string>(1);
        this.activeGeoRegionSubject = new Subject<GeographicalRegion[]>();
        this.clearFilteredRegionsSubject = new Subject<boolean>();
        this.markerIsInitialized = new BehaviorSubject<boolean>(false);
        this.crisis24CirclesSubject = new Subject<Crisis24Circle[]>();
        this.removeCrisis24Circles = new Subject<boolean>();
        this.openWeatherAlertPolygonFlag = new Subject<boolean>();
    }

    emitUpdateStateEvent(datasetID: string, map: google.maps.ImageMapType, options: Partial<MapStateOptions> = {}, action: MapStateActionEnum = MapStateActionEnum.NONE): void {
        const mapStateEvent: MapState = {
            datasetID: datasetID,
            map: map,
            action: action,
            options: options
        };
        this.activeOverlaysSubject.next(mapStateEvent);
    }
    insertOverlay(datasetID: string, overlay: google.maps.ImageMapType, index?) {
        this.emitUpdateStateEvent(datasetID, overlay, { overlayIndex: index }, MapStateActionEnum.INSERT_OVERLAY);
    }

    updateOverlay(datasetID: string, overlay: google.maps.ImageMapType) {
        this.emitUpdateStateEvent(datasetID, overlay, {}, MapStateActionEnum.UPDATE_OVERLAY);
    }

    removeOverlay(overlayID: string) {
        this.emitUpdateStateEvent(overlayID, null, {}, MapStateActionEnum.REMOVE_OVERLAY);
    }

    clearShapes(drawingType: MapDrawType) {
        this.emitUpdateStateEvent(null, null, { drawingType }, MapStateActionEnum.CLEAR_SHAPES);
    }

    setDrawingMode(drawingMode: null | google.maps.drawing.OverlayType, drawingType: MapDrawType): void {
        this.emitUpdateStateEvent(null, null, { drawingMode, drawingType }, MapStateActionEnum.SHAPES);
    }

    insertShape(overlay, overlayType: OverlayType) {
        const activeShape: ActiveShape = {
            overlay: overlay,
            overlayType: overlayType
        };
        this.activeShapesSubject.next(activeShape);
    }

    updateCurrentPinAddress(currentPinAddress: string): void {
        this.currentPinAddressSubject.next(currentPinAddress);
    }

    getActiveShapesSubject(): Observable<ActiveShape> {
        return this.activeShapesSubject.asObservable();
    }

    getActiveOverlaysSubject(): Observable<MapState> {
        return this.activeOverlaysSubject.asObservable();
    }

    getActiveImageOverlaysSubject(): Observable<MapStateForImageOverlays> {
        return this.activeImageOverlaysSubject.asObservable();
    }

    getCurrentPinAddress(): Observable<string> {
        return this.currentPinAddressSubject.asObservable();
    }

    insertImageOverlay(overlay: ImageOverlay, map: google.maps.ImageMapType) {
        this.emitUpdateImageOverlayStateEvent(overlay, map, MapStateActionEnum.INSERT_OVERLAY);
    }

    insertInteractiveImageOverlay(overlay: ImageOverlay, opacity: number) {
        this.emitUpdateImageOverlayStateEvent(overlay, null, MapStateActionEnum.INSERT_OVERLAY, true, opacity);
    }

    updateImageOverlay(overlay: ImageOverlay, map: google.maps.ImageMapType) {
        this.emitUpdateImageOverlayStateEvent(overlay, map, MapStateActionEnum.UPDATE_OVERLAY);
    }

    removeImageOverlay(overlay: ImageOverlay) {
        this.emitUpdateImageOverlayStateEvent(overlay, null, MapStateActionEnum.REMOVE_OVERLAY);
    }

    removeInteractiveImageOverlay(overlay: ImageOverlay) {
        this.emitUpdateImageOverlayStateEvent(overlay, null, MapStateActionEnum.REMOVE_OVERLAY, true);
    }

    emitUpdateImageOverlayStateEvent(overlay: ImageOverlay, map: google.maps.ImageMapType, action: MapStateActionEnum, interactive: boolean = false, opacity: number = 0.6): void {
        const mapStateImageOverlayEvent: MapStateForImageOverlays = {
            map: map,
            overlay: overlay,
            action: action,
            opacity,
            interactive
        };
        this.activeImageOverlaysSubject.next(mapStateImageOverlayEvent);
    }

    emitSelectedGeoRegion(regions: GeographicalRegion[]): void {
        this.activeGeoRegionSubject.next(regions);
    }

    getActiveGeoRegionSubject() {
        return this.activeGeoRegionSubject.asObservable();
    }

    clearFilteredGeoRegions(value: boolean) {
        this.clearFilteredRegionsSubject.next(value);
    }

    getClearFilteredRegionsSubject() {
        return this.clearFilteredRegionsSubject.asObservable();
    }

    getMarkerIsInitializedSubject() {
        return this.markerIsInitialized.asObservable();
    }

    emitMarkerIsInitialized(value: boolean) {
        this.markerIsInitialized.next(value);
    }

    crisisCircle(crisis24: Crisis24Circle[]) {
        this.crisis24CirclesSubject.next(crisis24);
    }

    getActiveCrisis24CirclesSubject(): Observable<Crisis24Circle[]> {
        return this.crisis24CirclesSubject.asObservable();
    }

    removeCrisisCircle(flag: boolean) {
        this.removeCrisis24Circles.next(flag);
    }

    getCrisis24CirclesRemoveFlag(): Observable<boolean> {
        return this.removeCrisis24Circles.asObservable();
    }

    setRemoveOpenWeatherPolygon(flag: boolean) {
        this.openWeatherAlertPolygonFlag.next(flag);
    }
    getOpenWeatherRemovePolygonFlag(): Observable<boolean> {
        return this.openWeatherAlertPolygonFlag.asObservable();
    }
}

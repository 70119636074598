<div class="page-root">
    <div class="breadcrumbs">
        <span>Home</span>
        <span>Geographical Regions</span>
    </div>
    <div class="page">
        <div class="page-container">
            <div class="table-actions">
                <div class="toolbar-title">
                    Geographical Regions
                </div>
                <div class="toolbar-container">
                    <ng-container>
                        <map-filter-input (keyup)="applyFilter($event.target.value)"></map-filter-input>
                        <button (click)="uploadInput.click()" mat-flat-button color="accent">
                            <!-- upload-cloud-icon -->
                            <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                                style="margin-right: 4px;">
                                <g fill="none" fill-rule="evenodd">
                                    <path d="M0 0h24v24H0z" />
                                    <path
                                        d="M16 19h2a4 4 0 0 0 4-4h0a4 4 0 0 0-4-4h0a6 6 0 0 0-6-6h0a5.998 5.998 0 0 0-5.676 4.068A4.985 4.985 0 0 0 2 14h0a5 5 0 0 0 5 5h1"
                                        stroke="#ffffff" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path stroke="#ffffff" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" d="m14 16-2-2-2 2M12 19v-5" />
                                </g>
                            </svg>
                            <input #uploadInput (change)="uploadFiles($event)" onclick="this.value = null"
                                accept=".dbf, .shp" style="display: none" type="file" multiple=true>
                            <span>upload geographical regions</span>
                        </button>
                        <button (click)="deleteAllList()" mat-flat-button color="primary">
                            <!-- Trash-Icon -->
                            <svg style="margin-right: 4px;" width="16" height="16" viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg">
                                <g fill="none" fill-rule="evenodd">
                                    <path
                                        d="M18.5 6.5V19a2 2 0 0 1-2 2h-9a2 2 0 0 1-2-2V6.5M9 17h6M9 13h6M8.5 6.5v-2A1.5 1.5 0 0 1 10 3h4a1.5 1.5 0 0 1 1.5 1.5v2M20 6.5H4"
                                        stroke="#002d57" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                        class="icon-actions" />
                                    <path d="M0 0h24v24H0z" />
                                </g>
                            </svg>
                            <span>Delete All List</span>
                        </button>
                    </ng-container>
                </div>
            </div>
            <div class="table-container">
                <div class="table-content">
                    <table *ngIf="!isTableDataLoading" [dataSource]="regionsDataSource" mat-table>
                        <ng-container matColumnDef="name">
                            <th *matHeaderCellDef mat-header-cell>Name</th>
                            <td *matCellDef="let region" mat-cell>{{ region.name }}</td>
                        </ng-container>
                        <ng-container matColumnDef="points-count">
                            <th *matHeaderCellDef mat-header-cell>Points Count</th>
                            <td *matCellDef="let region" mat-cell>{{ region.pointsCount }}</td>
                        </ng-container>
                        <ng-container matColumnDef="actions">
                            <th *matHeaderCellDef mat-header-cell>Actions</th>
                            <td *matCellDef="let region" mat-cell class="table-cell-icons">
                                <span (click)="openDialogUpdateRegion(region)">
                                    <!-- Pencil-Icon -->
                                    <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <g stroke="#08080d" stroke-width="1.5" fill="none" fill-rule="evenodd"
                                            stroke-linecap="round" stroke-linejoin="round" class="icon-actions">
                                            <path
                                                d="m8.972 12.2 4.76-4.76a1.5 1.5 0 0 1 2.122 0l.707.706a1.5 1.5 0 0 1 0 2.122l-4.761 4.76a2.5 2.5 0 0 1-1.414.708l-1.815.259a.5.5 0 0 1-.566-.566l.26-1.815a2.5 2.5 0 0 1 .707-1.414zM15.209 11.62 12.38 8.791" />
                                            <rect transform="rotate(180 12 12)" x="3" y="3" width="18" height="18"
                                                rx="5" />
                                        </g>
                                    </svg>
                                </span>
                                <span (click)="deleteRegion(region)">
                                    <!-- Trash Icon -->
                                    <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <g fill="none" fill-rule="evenodd">
                                            <path
                                                d="M18.5 6.5V19a2 2 0 0 1-2 2h-9a2 2 0 0 1-2-2V6.5M9 17h6M9 13h6M8.5 6.5v-2A1.5 1.5 0 0 1 10 3h4a1.5 1.5 0 0 1 1.5 1.5v2M20 6.5H4"
                                                stroke="#08080d" stroke-width="1.5" stroke-linecap="round"
                                                stroke-linejoin="round" class="icon-actions" />
                                            <path d="M0 0h24v24H0z" />
                                        </g>
                                    </svg>
                                </span>
                            </td>
                        </ng-container>
                        <tr *matHeaderRowDef="currentDisplayedColumns" mat-header-row></tr>
                        <tr *matRowDef="let row; columns: currentDisplayedColumns" mat-row>
                        </tr>
                    </table>
                    <div *ngIf="isTableDataLoading" class="spinnder-container">
                        <mat-spinner diameter="40" color="accent"></mat-spinner>
                    </div>
                </div>
                <map-paginator [numberOfItemsPerPage]="limit" (paginationChangeEvent)="onPaginationChange($event)"
                    [totalNumberOfElements]="numberOfRegions">
                </map-paginator>
            </div>
        </div>
    </div>
</div>
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Dataset, UpdateDatasetRequest } from '../../model/dataset/dataset';
import { Point } from 'src/app/model/geometry/point';

@Injectable({
    providedIn: 'root'
})
export class DatasetService {

    constructor(private readonly http: HttpClient) {
    }

    getDataset(id: string): Observable<Dataset> {
        return this.http.get<Dataset>(`/datasets/${id}`);
    }

    getExternalDataset(datasetId: any): Observable<Dataset> {
        return this.http.get<Dataset>(`/tessadata/${datasetId}`);
    }

    getDatasets(datasetIDs: string[]): Observable<Dataset[]> {
        return this.http.get<Dataset[]>('/datasets', { params: { ids: datasetIDs } });
    }

    getThematicMapDatasets(): Observable<Dataset[]> {
        return this.http.get<Dataset[]>('/datasets/thematic-map');
    }

    updateDataset(request: UpdateDatasetRequest): Observable<Dataset> {
        return this.http.put<Dataset>('/datasets', request);
    }

    fetchTensorflight(request: any, datapointId: string): Observable<any> {
        return this.http.post<any>('/tensorflight/' + datapointId, request);
    }

}

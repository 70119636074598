import {
    Component,
    ComponentRef,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef,
} from "@angular/core";
import { MembersService } from "../../../data-access-layer/members/members.service";
import { AccountMember } from "src/app/model/member/account-member";
import { ActivatedRoute } from "@angular/router";
import { Account } from "src/app/model/account/account";
import { Group } from "src/app/model/group/group";
import { GroupService } from "../../../data-access-layer/groups/group.service";
import { PanelComponent } from "src/app/shared/panel/panel.component";
import { MatDialog } from "@angular/material/dialog";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatTableDataSource } from "@angular/material/table";
import { AccountService } from "src/app/data-access-layer/account/account.service";
import { NotifService } from "../../../core/notification/notif.service";
import { DialogComponent } from "../../../shared/dialog/dialog.component";
import { DialogModel } from "../../../model/dialog/dialog-model";
import { take } from "rxjs/operators";
import { SidePanelComponent } from "../../../core/side-panel/side-panel.component";
import { DatapointsPageStateService } from "../../../dataset/datapoints/datapoints-page-state.service";
import { MemberStatus } from "../../../model/member/member-status";
import { AccountMemberPermission } from "../../../model/member/account-member-permission";
import { TwoFactorAuthService } from "../../../auth/two-factor-auth-service";
import { SidePanelService } from "../../../shared/services/side-panel.service";
import { SidePanels } from "../../../shared/services/side-panel.helper";
import { AccountServiceState } from "../../../shared/services/account-service-state";
import { LocationsEditDialog } from "./locations-edit-dialog/locations-edit-dialog.component";
import { MaptycsApplication } from "src/app/model/account/maptycs-application";
import { TreeStructure } from "src/app/model/menu/tree-structure";
import { OverlaysService } from "src/app/data-access-layer/global-overlays/overlays.service";


type CreateMemberComponentType = {
    currentAccount: Account;
    groupModel: any;
};

@Component({
    selector: "map-members",
    templateUrl: "./account-members.component.html",
    styleUrls: ["./account-members.component.scss"],
})
export class AccountMembersComponent implements OnInit, OnDestroy {
    breadCrumbs = ["Home", "Accounts", "Settings"];
    currentAccount: Account;
    members: AccountMember[];
    membersDataSource: MatTableDataSource<AccountMember>;
    defaultDisplayedColumns: string[] = [
        "Name",
        "Email",
        "DeleteDate",
        "Status",
        "Actions",
        "Admin",
        "Write",
    ];
    currentDisplayedColumns: string[] = [
        "Name",
        "Email",
        "DeleteDate",
        "Status",
        "Actions",
        "Admin",
        "Write",
    ];
    groupModel: any = {};
    isTableDataLoading: boolean;
    groupsCountByMembers: Map<number, number>; // member id - groups count
    isTwoFactorAuthEnabled: boolean;
    accountId: number;

    // @ViewChild('createMemberPanel', { static: false }) createMemberPanel: SidePanelComponent;
    createMemberPanel: ComponentRef<SidePanelComponent>;

    get MaptycsApplication() {
        return MaptycsApplication;
    }

    constructor(
        private readonly notifService: NotifService,
        private readonly groupService: GroupService,
        private readonly route: ActivatedRoute,
        private readonly membersService: MembersService,
        private readonly twoFactorAuthService: TwoFactorAuthService,
        private readonly accountService: AccountService,
        private readonly datapointsPageStateService: DatapointsPageStateService,
        public readonly dialog: MatDialog,
        private readonly sidePanelService: SidePanelService,
        private readonly viewContainerRef: ViewContainerRef,
        private readonly accountServiceState: AccountServiceState,
        private readonly overlayService: OverlaysService
    ) {
        this.isTableDataLoading = true;
    }

    get componentName() {
        return this.constructor.name;
    }
    ngOnInit() {
        this.accountId = this.getAccountIDFromUrl();
        this.fetchAccountAndMembers();
    }

    fetchAllAccountGroups(account: Account) {
        let promises: Array<Promise<Group[]>> = [];
        account.datasets.forEach((dataset) => {
            promises.push(
                this.groupService.getGroups(dataset.id, account.id).toPromise()
            );
        });
        Promise.all(promises).then(
            (groups) => {
                groups.forEach((groupsByDataset) => {
                    let currentDataset = groupsByDataset[0].datasetID;
                    this.groupModel[currentDataset] = groupsByDataset;
                });
            },
            (err) => {
                console.log(err);
            }
        );
    }

    addMemberToGroup(memberID: number, groupID: number): void {
        this.membersService.addMemberToGroup(memberID, groupID).subscribe(
            (response) => {
                this.fetchAccountMembers();
            },
            (err) => {
                console.log(err);
            }
        );
    }

    addMembersToGroup(memberID: number, groupID: number[]): void {
        this.membersService.prepareAddMembers(memberID, groupID).then(results => {
            this.fetchAccountMembers();
        }
        );
    }

    removeMemberFromGroup(memberID: number, groupID: number): void {
        this.membersService.removeMemberFromGroup(memberID, groupID).subscribe(
            (response) => {
                this.fetchAccountMembers();
            },
            (err) => {
                console.log(err);
            }
        );
    }

    deleteMember(member: AccountMember): void {
        const dialogRef = this.dialog.open(DialogComponent, {
            data: new DialogModel(
                "Confirm Action",
                `Delete the member "${member.email}"?`
            ),
        });
        dialogRef
            .afterClosed()
            .pipe(take(1))
            .subscribe((dialogResult) => {
                if (dialogResult) {
                    this.membersService.deleteMember(member.id).subscribe(
                        (response) => {
                            this.fetchAccountMembers();
                        },
                        (err) => {
                            console.log(err);
                        }
                    );
                }
            });
    }

    generateTableColumns(account: Account): void {
        account.datasets.forEach((dataset) => {
            this.currentDisplayedColumns = this.defaultDisplayedColumns;
            this.currentDisplayedColumns.splice(
                this.currentDisplayedColumns.length - 1,
                0,
                dataset.application.toString().toUpperCase()
            );
        });
    }

    fetchAccountAndMembers(
        accountID: number = this.getAccountIDFromUrl()
    ): void {
        this.accountService.getAccount(accountID).subscribe((account) => {
            this.generateTableColumns(account);
            this.currentAccount = account;
            this.datapointsPageStateService.activeAccount = account;
            this.fetchAllAccountGroups(account);
            this.fetchAccountMembers();
        });
    }

    fetchAccountMembers(): void {
        this.groupsCountByMembers = new Map();
        this.membersService
            .findAccountMembers(this.currentAccount.id)
            .subscribe(
                (members: AccountMember[]) => {
                    members.map((member) => {
                        member.hasGroup = this.hasAtLeastOneGroup(member);
                    });
                    this.membersDataSource = new MatTableDataSource(members);
                    this.members = members;
                    this.makeMembersSearchableByGroupNameFromTableFilter(
                        members
                    );
                    // this.populateMemberModel(members);
                    this.isTableDataLoading = false;
                },
                (err) => {
                    console.log(err);
                }
            );
    }

    makeMembersSearchableByGroupNameFromTableFilter(
        members: AccountMember[]
    ): void {
        // Adding member groupNode names as a string directly into each member object,
        // so the angular mat table filter can search members by groupNode names too
        members.forEach((member: any) => {
            member.allGroups = "";
            for (const dataset in member.datasetGroups) {
                if (
                    Object.prototype.hasOwnProperty.call(
                        member.datasetGroups,
                        dataset
                    )
                ) {
                    member.datasetGroups[dataset].forEach((group) => {
                        member.allGroups = `${member.allGroups
                            } ${JSON.stringify(group.name)}`;
                    });
                }
            }
        });
    }

    addOrRemoveMemberFromGroup(
        member: AccountMember,
        groups: number[],
        group: Group
    ): void {
        let groupAdded = !(groups.indexOf(group.id) === -1);
        if (groupAdded) {
            this.addMemberToGroup(member.id, group.id);
        } else {
            this.removeMemberFromGroup(member.id, group.id);
        }
    }

    getAccountIDFromUrl(): number {
        return parseInt(this.route.snapshot.paramMap.get("accountId"), 0);
    }

    compareFn(group: any, group2: any) {
        // ANGULAR MATERIAL FILTER COMPARATOR FUNCTION
        return group && group2 ? group === group2.id : group === group2;
    }

    generateMembers(): void {
        // DEBUG FUNCTION, DELETE IN PRODUCTION
        this.membersService.generateMembers(this.currentAccount).then(
            (response) => { },
            (err) => {
                console.log(err);
            }
        );
    }

    applyFilter(filterValue: string): void {
        this.membersDataSource.filter = filterValue.trim().toLowerCase();
    }

    onMemberCreated(): void {
        this.createMemberPanel.instance.hidePanel();
        this.fetchAccountMembers();
    }

    inviteMember(member: AccountMember) {
        if (member.hasGroup) {
            this.membersService.inviteMember(member.id).subscribe(
                (res) => {
                    this.fetchAccountMembers();
                    this.notifService.success(`Successfully invited member`);
                },
                (err) => {
                    console.log(err);
                    this.notifService.success(`Couldn't invite member`);
                }
            );
        }
    }

    makeMemberAccountAdmin(
        $event: MatCheckboxChange,
        member: AccountMember
    ): void {
        member.isAccountAdmin = $event.checked;
        this.membersService.updateMember(member).subscribe((res) => {
            if (member.isAccountAdmin && (member.datasetGroups && Object.keys(member.datasetGroups).length)) {
                for (const key in member.datasetGroups) {
                    if (Object.prototype.hasOwnProperty.call(member.datasetGroups, key)) {
                        const element = member.datasetGroups[key];
                        const markedDatasets = this.markSelectedDatasetGroups(member.datasetGroups[key], this.groupModel[key]);
                        if (markedDatasets.length) {
                            const notSelectedGroupIds = markedDatasets.filter(markedDataset => !markedDataset.selected).map(group => group.id);
                            if (notSelectedGroupIds.length) {
                                this.addMembersToGroup(member.id, notSelectedGroupIds);
                            }
                        }
                    }
                }
            } else if (member.isAccountAdmin && Object.keys(member.datasetGroups).length <= 0 && (this.groupModel && Object.keys(this.groupModel).length)) {
                for (const key in this.groupModel) {
                    if (Object.prototype.hasOwnProperty.call(this.groupModel, key)) {
                        const element = this.groupModel[key];
                        if (element.length) {
                            const groupIds = element.map(group => group.id);
                            this.addMembersToGroup(member.id, groupIds);
                        }
                    }
                }
            } else {
                this.fetchAccountMembers();
            }
        });
    }


    updateMemberPermission(event: MatCheckboxChange, member: AccountMember) {
        // tslint:disable-next-line:no-bitwise
        member.permissions = event.checked
            ? member.permissions | AccountMemberPermission.WRITE
            : member.permissions & ~AccountMemberPermission.WRITE;
        this.membersService.updateMember(member).subscribe((res) => {
            this.fetchAccountMembers();
        });
    }

    hasAtLeastOneGroup(member: AccountMember) {
        let groups = [];
        for (let dataset in member.datasetGroups) {
            if (member.datasetGroups.hasOwnProperty(dataset)) {
                let g = member.datasetGroups[dataset];
                for (let group in g) {
                    if (g.hasOwnProperty(group)) {
                        groups.push(g[group]);
                    }
                }
            }
        }
        if (groups.length > 0) {
            return true;
        }
        return false;
    }

    get MemberStatus() {
        return MemberStatus;
    }

    toggleTwoFactorAuth() {
        if (this.isTwoFactorAuthEnabled) {
            const dialogRef = this.dialog.open(DialogComponent, {
                data: new DialogModel(
                    "Disable 2FA",
                    `Are you sure you want to disable 2FA? \n When you re-enable it, all your users will have to scan the barcode again.`
                ),
            });
            dialogRef
                .afterClosed()
                .pipe(take(1))
                .subscribe((dialogResult) => {
                    if (dialogResult) {
                        this.doToggleTwoFactorAuth();
                    }
                });
        } else {
            this.doToggleTwoFactorAuth();
        }
    }

    private doToggleTwoFactorAuth() {
        this.isTwoFactorAuthEnabled = !this.isTwoFactorAuthEnabled;
        this.twoFactorAuthService
            .switchTwoFactorAuthForCompany(
                this.isTwoFactorAuthEnabled,
                this.accountId
            )
            .subscribe(
                (emptyResponse) => {
                    if (this.isTwoFactorAuthEnabled) {
                        this.notifService.success(
                            "Two factor authentication was successfully enabled."
                        );
                    } else {
                        this.notifService.success(
                            "Two factor authentication was successfully disabled"
                        );
                    }
                },
                (error) => this.notifService.error(error.error.message)
            );
    }

    createMember(): void {
        if (this.createMemberPanel) {
            this.createMemberPanel.instance.showPanel();
        } else {
            this.sidePanelService.setRootViewContainerRef(
                this.viewContainerRef
            );
            this.createMemberPanel =
                this.sidePanelService.open<CreateMemberComponentType>(
                    SidePanels.CREATE_MEMBER,
                    {
                        id: "create-member-panel",
                        width: 400,
                        panelTitle: "Create Member",
                        panelIcon: "icon-user-plus",
                    },
                    {
                        currentAccount: this.currentAccount,
                        groupModel: this.groupModel,
                    }
                );

            this.accountServiceState.onCreateMemberSuccess$.subscribe(() => {
                this.onMemberCreated();
            });
        }
    }

    ngOnDestroy() {
        if (this.createMemberPanel) {
            this.createMemberPanel.instance.closePanel();
            this.createMemberPanel = null;
        }
    }

    openLocationEditDialog(memberID, selectedDatasetGroups, dataset) {
        const markedDatasets = this.markSelectedDatasetGroups(selectedDatasetGroups, this.groupModel[dataset.id]);
        const data = JSON.parse(JSON.stringify(markedDatasets));
        const transFormedDatasets = this.transformTreeStructure(data);
        const member = this.members.find(member => member.id === memberID);
        const dialogRef = this.dialog.open(LocationsEditDialog, {
            height: '300px',
            width: '500px',
            data: {
                datasets: transFormedDatasets,
                memberID: memberID,
                isAccountAdmin: member.isAccountAdmin
            },
        });
        dialogRef
            .afterClosed()
            .pipe(take(1))
            .subscribe((dialogResponse: number[]) => {
                if (dialogResponse) {
                    const selectedGroups: any = dialogResponse;
                    const removedGroups = this.getGroupsToBeRemoved(markedDatasets, selectedGroups);

                    selectedGroups.forEach((item) => {
                        this.addMemberToGroup(memberID, item.id);
                    });
                    removedGroups.forEach((item) => {
                        this.removeMemberFromGroup(memberID, item.id);
                    });
                }
        }   );
    }

    transformTreeStructure(data) {
        const nestedStructure = [];

        const idToItem = new Map();
        data.forEach(item => {
            idToItem.set(item.id, { ...item, children: [] });
        });

        data.forEach(item => {
            const { id, parentId } = item;
            if (parentId !== null) {
                const parentItem = idToItem.get(parentId);
                if (parentItem) {
                    parentItem.children.push(idToItem.get(id));
                }
            } else {
                nestedStructure.push(idToItem.get(id));
            }
        });

        return this.convertToDropdownItems(nestedStructure);
    }

    markSelectedDatasetGroups(selectedDatasetsGroups, datasets) {
        let selectedDatasetIds = new Set();
        if (selectedDatasetsGroups) {
            selectedDatasetIds = new Set(selectedDatasetsGroups.map(group => group.id));
        }

        const markedDatasets = datasets.map((dataset) => {
            return {
                ...dataset,
                selected: selectedDatasetIds.has(dataset.id),
            };
        });

        return markedDatasets;
    }

    convertToDropdownItems(
        data: any[],
        parent?: TreeStructure
    ): TreeStructure[] {
        return data.map((item) => {
            const dropdownItem: TreeStructure = {
                id: item.id,
                params: {
                    accountID: item.accountID,
                    datasetID: item.datasetID,
                    type: item.type,
                    parent: parent,
                },
                name: item.name,
                selected: parent && parent.selected ? true : item.selected,
                expanded: true,
            };

            if (item.children && item.children.length > 0) {
                dropdownItem.children = this.convertToDropdownItems(
                    item.children,
                    dropdownItem
                );
            }

            return dropdownItem;
        });
    }

    getGroupsToBeRemoved(allGroups, selectedGroups) {
        const selectedIdsSet = new Set(selectedGroups.map(item => item.id));
        const groupsNotSelected = allGroups.filter(item => !selectedIdsSet.has(item.id));

        return groupsNotSelected;
    }

    sortDatasetGroups(datasetGroupsById: any){
        return datasetGroupsById.sort((a, b) => a.id - b.id)
    }
}

import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AuthService} from '../auth.service';
import {NotifService} from '../../core/notification/notif.service';

@Component({
    selector: 'map-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {

    readonly forgotPasswordForm: UntypedFormGroup;
    isSubmitted = false;

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly authService: AuthService,
        private readonly notifService: NotifService,
        private readonly changeDetector: ChangeDetectorRef
    ) {
        this.forgotPasswordForm = this.formBuilder.group({
                email: ['', Validators.email]
        }
        );
    }

    onSubmit(): void {
        if (this.forgotPasswordForm.valid) {
            let form = this.forgotPasswordForm.value;
            this.authService.forgotPassword(form.email).subscribe(success => {
                this.isSubmitted = true;
            }, error => {
               console.log('error');
               let memssage = error ? error.error : 'The email address does not exist in our system';
               this.notifService.error(memssage);
            });

        }
    }
}

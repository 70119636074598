export enum WorkspaceItemType {
    UPLOAD_TEMPLATE = 'UPLOAD_TEMPLATE',
    FILTER = 'FILTER',
    TABLE_PROJECTION = 'TABLE_PROJECTION',
    ANALYTICS = 'ANALYTICS',
    FORMULA = 'FORMULA',
    DASHBOARD = 'DASHBOARD',
    RENEWAL_REPORT = 'RENEWAL',
    ANALYTICS_COMPARISON = 'ANALYTICS_COMPARISON',
    ANALYTICS_DASHBOARD_COMPARISON = 'ANALYTICS_DASHBOARD_COMPARISON',
}

<ng-container *ngIf="alterDatapointsEnabled">
    <div *ngIf="loading" class="spinner-container">
        <mat-spinner class="spinner" diameter="40" color="accent"></mat-spinner>
    </div>
    <div class="container" [class.hidden]="loading">
        <form class="update-datapoint-container"
              *ngIf="!loading"
              [formGroup]="updateDatapointForm">
            <div *ngFor="let field of datasetFieldsArray">
                <multi-field
                        [formControlName]="field.id"
                        [fieldType]="field.type"
                        [fieldName]="field.name"
                        [appearance]="'standard'"
                        [floatLabel]="'auto'"
                ></multi-field>

            </div>
            <div class="accounts-submit-button">
                <button [disabled]="loading" style="width:100%;" type="submit" color="primary"
                        (click)="updateDatapoint()"
                        mat-raised-button>
                    Submit
                </button>
            </div>
        </form>
        
    </div>
</ng-container>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IntegrationService } from '../../data-access-layer/integration/integration.service';
import { NotifService } from '../../core/notification/notif.service';
import { IntegrationClientInfo } from '../../model/integration/integration-client-info';

@Component({
    selector: 'map-edit-integrated-client',
    templateUrl: './edit-integrated-client.component.html',
    styleUrls: ['./edit-integrated-client.component.scss']
})
export class EditIntegratedClientComponent {


    @Output() updateSuccess = new EventEmitter();

    updateIntegrationClientForm: UntypedFormGroup;

    integrationClient: IntegrationClientInfo;

    constructor(private readonly fb: UntypedFormBuilder,
        private readonly integrationService: IntegrationService,
        private readonly notifService: NotifService) {

    }


    initForm(integratedClient: IntegrationClientInfo) {
        let form = {
            platform: new UntypedFormControl(integratedClient.platform, [Validators.required]),
            platformClient: new UntypedFormControl(integratedClient.platformClient, [Validators.required]),
            accountKey: new UntypedFormControl(integratedClient.accountKey, [Validators.required]),
            accountPassword: new UntypedFormControl(integratedClient.accountPassword, [Validators.required]),
            accountName: new UntypedFormControl({ value: integratedClient.accountName, disabled: true }),
        };
        this.integrationClient = integratedClient;
        this.updateIntegrationClientForm = this.fb.group(form);
    }


    submitIntegratedClient() {
        let request: IntegrationClientInfo = this.updateIntegrationClientForm.getRawValue();
        this.integrationService.updateIntegrationClient(this.integrationClient.id, request).subscribe(
            success => {
                this.notifService.success("Client integration was successfully updated");
                this.updateSuccess.emit();
            }, error => this.notifService.error(error.error.message));
    }
}

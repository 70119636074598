import { NgModule } from "@angular/core";
import { LoginComponent } from "./login/login.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { RegisterComponent } from "./register/register.component";
import { ForgotPasswordConfirmationComponent } from "./forgot-password/confirm/forgot-password-confirm.component";
import { SharedModule } from "../shared/shared.module";
import { MatInputModule } from "@angular/material/input";
import { TwoFactorAuthComponent } from "./two-factor-auth/two-factor-auth.component";
import { SupportCenterComponent } from "../support/support-center.component";

@NgModule({
    declarations: [
        LoginComponent,
        ForgotPasswordComponent,
        ForgotPasswordConfirmationComponent,
        RegisterComponent,
        TwoFactorAuthComponent,
        SupportCenterComponent
    ],
    imports: [SharedModule, MatInputModule],
    exports: [
        LoginComponent,
        ForgotPasswordComponent,
        ForgotPasswordConfirmationComponent,
        SupportCenterComponent
    ],
})
export class AuthModule {}

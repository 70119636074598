import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { Group, UpdateGroupRequest } from 'src/app/model/group/group';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { updateGroupControlsConfig } from './account-update-groups-panel.constants';
import { GroupService } from '../../../data-access-layer/groups/group.service';
import { Subject } from 'rxjs';
import { AccountServiceState } from '../../../shared/services/account-service-state';
import { groupTypes } from '../account-create-groups-panel/account-create-groups-panel.constants';

@Component({
    selector: 'map-account-update-groups-panel',
    templateUrl: './account-update-groups-panel.component.html',
    styleUrls: ['./account-update-groups-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountUpdateGroupsPanelComponent implements OnInit {
    // @HostBinding('class') class = 'pagePanelUpdateGroup';
    @Input() group: Group;
    @Input() groups: Group[];
    @Input() emitGroupOnClickSubject: Subject<Group>;
    updateGroupForm: UntypedFormGroup;
    parentsGroupsList: Group[] = [];
    @Output() updated = new EventEmitter<string>();
    readonly ROW_GROUP = 1;
    readonly COLUMN_GROUP = 2;
    parentGroupId: number;
    isDisabled: boolean = true;

    updateComplete(): void {
        this.accountServiceState.emitOnUpdateGroupSuccess();
        this.ngOnInit();
        this.updated.emit('group updated');
    }

    constructor(
        private readonly groupService: GroupService,
        private readonly formBuilder: UntypedFormBuilder,
        private accountServiceState: AccountServiceState,
    ) {
        this.updateGroupForm = this.formBuilder.group(updateGroupControlsConfig);
    }

    ngOnInit() {
        this.updateGroupForm.patchValue({
            id: this.group.id,
            parentId: this.group.parentId,
            name: this.group.name,
            type: this.group.type,
        });
        this.parentsGroupsList = [];
        let groups = JSON.parse(JSON.stringify(this.groups));
        this.getParentsGroupsList(this.group, groups);

        this.emitGroupOnClickSubject.subscribe(group => {
            this.parentsGroupsList = [];
            this.updateGroupForm.patchValue({
                id: group.id,
                parentId: group.parentId,
                name: group.name,
                type: group.type
            });
            groups = JSON.parse(JSON.stringify(this.groups));
            this.getParentsGroupsList(group, groups);
        });
    }

    getParentsGroupsList(editedGroup: Group, groups: Group[]) {
        for (let i = 0; i < groups.length; i++) {
            if (editedGroup.id !== groups[i].id) {
                if (editedGroup.id !== groups[i].parentId) {
                    this.parentsGroupsList.push(groups[i]);
                } else {
                    let childGroup = groups.find(x => x.parentId === groups[i].id);
                    if (childGroup !== undefined) {
                        let indexOfChildGroup = groups.indexOf(childGroup);
                        groups[indexOfChildGroup].parentId = editedGroup.id;
                        i = i - 1;
                    }
                }
            }
        }
    }

    updateGroup(): void {
        this.groupService.updateGroup(this.constructUpdateGroupRequest()).subscribe(group => {
            this.updateComplete();
        });
    }

    get groupId() {
        return this.updateGroupForm.get('id').value;
    }

    private constructUpdateGroupRequest(): UpdateGroupRequest {
        const parentGroup = this.groups.find(element => element.parentId == null);
        this.parentGroupId = parentGroup.id;
        const request: UpdateGroupRequest = {
            parentID: this.updateGroupForm.controls.type.value == groupTypes.ROW_GROUP ? this.updateGroupForm.controls.parentId.value : this.parentGroupId,
            name: this.updateGroupForm.controls.name.value.trim(),
            id: this.updateGroupForm.controls.id.value,
            type: this.updateGroupForm.controls.type.value
        };
        return request;
    }

    public compareObjects(o1: any, o2: any): boolean {
        return o1.name === o2.name && o1.id === o2.id;
    }

    showParentGroup() {
        return groupTypes.ROW_GROUP == this.updateGroupForm.controls.type.value;
    }
}

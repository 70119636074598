export class OpenWeather {
    private static readonly id = "owm_alerts_polygons";
    private static readonly fillColor = "#ffffff";
    private static readonly strokeColor = "#0000FF";

    private static readonly fillOpacity = 0.3;
    private static readonly strokeOpacity = 1;
    private static readonly strokeWeight = 0.5;
    public static getId() {
        return this.id;
    }
    public static getFillColor() {
        return this.fillColor;
    }
    public static getStrokeColor() {
        return this.strokeColor;
    }
    public static getFillOpacity() {
        return this.fillOpacity;
    }
    public static getStrokeOpacity() {
        return this.strokeOpacity;
    }
    public static getStrokeWeight() {
        return this.strokeWeight;
    }
    public static polygonConfiguration() {
        return {
            fillColor: this.getFillColor(),
            strokeColor: this.getStrokeColor(),
            fillOpacity: this.getFillOpacity(),
            strokeOpacity: this.getStrokeOpacity(),
            strokeWeight: this.getStrokeWeight(),
        };
    }
}
export interface OWMAlert {
    alert: Alert;
    msg_type: string;
    categories: string[];
    urgency: string;
    severity: string;
    certainty: string;
    start: number;
    end: number;
    sender: string;
    description: Description[];
}
export interface Description {
    language: string;
    event: string;
    headline: string;
    description: string;
    instruction: string;
}
export interface Alert {
    id: string;
    geometry: Geometry;
}
export interface Geometry {
    type: string;
    coordinates: any;
}

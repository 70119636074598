<input #inputElement (keyup)="keyup.emit($event)" autocomplete="off" placeholder="Filter">
<span class="icon-container">
    <!-- <mat-icon fontIcon="fa-filter" fontSet="fas"></mat-icon> -->
    <!-- filter-icon -->
    <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fill-rule="evenodd">
            <path d="M0 0h24v24H0z"/>
            <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2zM9 7v2M9 12v5M15 7v5M15 15v2" stroke="#002d57" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10 12H8a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1zM16 15h-2a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1z" stroke="#002d57" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
    </svg>
</span>

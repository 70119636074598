import { AfterViewInit, Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { CdkDrag, CdkDragMove, CdkDropList, CdkDropListGroup, moveItemInArray } from '@angular/cdk/drag-drop';
import { ViewportRuler } from '@angular/cdk/overlay';
import { DatasetField } from '../../model/dataset/field/dataset-field';

@Component({
    selector: 'map-field-group',
    templateUrl: './field-group.component.html',
    styleUrls: ['./field-group.component.scss']
})
export class FieldGroupComponent implements AfterViewInit {
    @ViewChild(CdkDropListGroup, {static: true}) listGroup: CdkDropListGroup<CdkDropList>;
    @ViewChild(CdkDropList, {static: true}) placeholder: CdkDropList;
    target: CdkDropList;
    targetIndex: number;
    source: CdkDropList;
    sourceIndex: number;
    activeContainer;
    localFields: DatasetField[];

    @Input() allowFloatingFields: boolean;

    get fields(): DatasetField[] {
        return this.localFields;
    }

    @Input('fields')
    set field(newField: DatasetField[]) {
        this.localFields = newField;
    }

    constructor(private readonly viewportRuler: ViewportRuler) {
        this.target = null;
        this.source = null;
    }

    ngAfterViewInit(): void {
        const phElement = this.placeholder.element.nativeElement;

        phElement.style.display = 'none';
        phElement.parentElement.removeChild(phElement);
    }

    dragMoved(e: CdkDragMove): void {
        const point = this.getPointerPositionOnPage(e.event);

        this.listGroup._items.forEach(dropList => {
            if (__isInsideDropListClientRect(dropList, point.x, point.y)) {
                this.activeContainer = dropList;
                return;
            }
        });
    }

    dropListDropped(): void {
        if (!this.target) {
            return;
        }

        const phElement = this.placeholder.element.nativeElement;
        const parent = phElement.parentElement;

        phElement.style.display = 'none';

        parent.removeChild(phElement);
        parent.appendChild(phElement);
        parent.insertBefore(this.source.element.nativeElement, parent.children[this.sourceIndex]);

        this.target = null;
        this.source = null;

        if (this.sourceIndex !== this.targetIndex) {
            moveItemInArray(this.localFields, this.sourceIndex, this.targetIndex);
        }
    }

    dropListEnterPredicate = (drag: CdkDrag, drop: CdkDropList): boolean => {
        if (drop === this.placeholder) {
            return true;
        }

        if (drop !== this.activeContainer) {
            return false;
        }

        const phElement = this.placeholder.element.nativeElement;
        const sourceElement = drag.dropContainer.element.nativeElement;
        const dropElement = drop.element.nativeElement;

        const dragIndex = __indexOf(dropElement.parentElement.children, (this.source ? phElement : sourceElement));
        const dropIndex = __indexOf(dropElement.parentElement.children, dropElement);

        if (!this.source) {
            this.sourceIndex = dragIndex;
            this.source = drag.dropContainer;

            phElement.style.width = `${sourceElement.clientWidth}px`;
            phElement.style.height = `${sourceElement.clientHeight}px`;

            sourceElement.parentElement.removeChild(sourceElement);
        }

        this.targetIndex = dropIndex;
        this.target = drop;

        phElement.style.display = '';
        dropElement.parentElement.insertBefore(phElement, (dropIndex > dragIndex
            ? dropElement.nextSibling : dropElement));

        this.placeholder._dropListRef.enter(drag._dragRef,
            drag.element.nativeElement.offsetLeft, drag.element.nativeElement.offsetTop);
        return false;
    }

    /** Determines the point of the page that was touched by the user. */
    getPointerPositionOnPage(event: MouseEvent | TouchEvent): { x: number, y: number } {
        // `touches` will be empty for start/end events so we have to fall back to `changedTouches`.
        const point = __isTouchEvent(event) ? (event.touches[0] || event.changedTouches[0]) : event;
        const scrollPosition = this.viewportRuler.getViewportScrollPosition();

        return {
            x: point.pageX - scrollPosition.left,
            y: point.pageY - scrollPosition.top
        };
    }

    removeField(field: DatasetField) {
        let index = this.fields.indexOf(field);
        this.localFields.splice(index, 1);
    }
}

function __indexOf(collection, node): number {
    return Array.prototype.indexOf.call(collection, node);
}

/** Determines whether an event is a touch event. */
function __isTouchEvent(event: MouseEvent | TouchEvent): event is TouchEvent {
    return event.type.startsWith('touch');
}

function __isInsideDropListClientRect(dropList: CdkDropList, x: number, y: number): boolean {
    const {top, bottom, left, right} = dropList.element.nativeElement.getBoundingClientRect();
    return y >= top && y <= bottom && x >= left && x <= right;
}

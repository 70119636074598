import {DatePipe} from '../pipes/date.pipe';

export class DateUtils {
    public static monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    /**
     * This method returns a string date with the format: "May 23, 2004"
     */
    public static formatDate(value: any, isTimeDisplayed?: boolean): string {
        if (value) {
            const date = isNaN(value) ? value : new Date(value); // it is either number or date
            // const hours = date.getHours();
            // const ampm = hours >= 12 ? 'PM' : 'AM';
            const monthName = DateUtils.monthNames[date.getUTCMonth()];
            const dateString = `${monthName} ${date.getUTCDate()}, ${date.getUTCFullYear()}`;
            const timeString = `${(date.getHours() < 10 ? '0' : '')}${date.getHours()}:${(date.getMinutes() < 10 ? '0' : '')}${date.getMinutes()}:${(date.getSeconds() < 10 ? '0' : '')}${date.getSeconds()}`;
            // const timeString = (date.getHours()<10?'0':'') + date.getHours() + ':' + (date.getMinutes()<10?'0':'') + date.getMinutes() + ':' + (date.getSeconds()<10?'0':'') + date.getSeconds();

            // TODO: most likely there is a better way to do this
            if (date.getMonth() === 0 && date.getFullYear() === 1970) {
                return timeString;
            }
            if (isTimeDisplayed) {
                return dateString + ', ' + timeString;
            }
            return dateString;
        } else {
            return null;
        }
    }

    public static parseDate(value: any, isTimeDisplayed?: boolean ): string {
        if(!value) {
            return null;
        }
        const date = new Date(value);
        // const hours = date.getHours();
        // const ampm = hours >= 12 ? 'PM' : 'AM';
        const monthName = DateUtils.monthNames[date.getUTCMonth()];
        const dateString = `${monthName} ${date.getUTCDate()+1}, ${date.getUTCFullYear()}`;
        const timeString = `${(date.getHours() < 10 ? '0' : '')}${date.getHours()}:${(date.getMinutes() < 10 ? '0' : '')}${date.getMinutes()}:${(date.getSeconds() < 10 ? '0' : '')}${date.getSeconds()}`;
        // const timeString = (date.getHours()<10?'0':'') + date.getHours() + ':' + (date.getMinutes()<10?'0':'') + date.getMinutes() + ':' + (date.getSeconds()<10?'0':'') + date.getSeconds();

        // TODO: most likely there is a better way to do this
        if (date.getMonth() === 0 && date.getFullYear() === 1970) {
            return timeString;
        }
        if (isTimeDisplayed) {
            return dateString + ', ' + timeString;
        }
        return dateString;
    }

     public static daysBetweenDates(first, second): number {
        // Take the difference between the dates and divide by milliseconds per day.
        // Round to nearest whole number to deal with DST.
        return Math.round((second-first)/(1000*60*60*24));
    }

    public static formatDateAndJoin(date: Date, join?: string) {
        var d = new Date(date)
        return [DateUtils.pad(d.getDate()), DateUtils.pad(d.getMonth()+1), d.getFullYear()].join(join ? join : '/');
    }

    private static pad(s: number) {
        return (s < 10) ? '0' + s : s;
    }
}

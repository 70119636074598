import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild} from '@angular/core';
import {OverlaysService} from '../../data-access-layer/global-overlays/overlays.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {CreateOverlayControlsConfig} from '../overlays.constants';
import {NotifService} from '../../core/notification/notif.service';
import {ColorScaleType} from '../../model/dataset/rendering/color-scale-type';
import {DatasetStylingOptions} from '../../model/dataset/rendering/dataset-styling-options';
import {DatasetConfigComponent} from '../../shared/dataset-config/dataset-config.component';
import {OverlayGroup} from '../../model/overlay/group/overlay-group';
import {GroupWithOverlaysTreeNode} from '../../model/overlay/group/group-with-overlays-tree-node';
import {DatapointsPageStateService} from '../../dataset/datapoints/datapoints-page-state.service';
import {AccountService} from '../../data-access-layer/account/account.service';
import {DatasetType} from '../../model/dataset/dataset-type';
import {RoutingUtils} from '../../core/utils/routing-utils';
import {RenderingOptions} from '../../model/dataset/rendering/rendering-options';
import {Dataset} from '../../model/dataset/dataset';

@Component({
    selector: 'map-create-overlay',
    templateUrl: './create-overlay.component.html',
    styleUrls: ['./create-overlay.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateOverlayComponent implements OnInit {
    @ViewChild('datasetConfig') datasetConfig: DatasetConfigComponent;
    @Output() updated = new EventEmitter<string>();
    @Output () overlayCreated =  new EventEmitter();
    @Input ()
    set accountId(id: number) {
        this.createOverlayForm.controls.accountID.setValue(id);
    }
    @Input ()
    set type(type: DatasetType) {
        this.createOverlayForm.controls.type.setValue(type);
    }
    @HostBinding('class') class = 'page';
    createOverlayForm: UntypedFormGroup;
    datasetStylingOptions: DatasetStylingOptions;
    groups: OverlayGroup[];
    dataset: Dataset;
    constructor(
        private readonly router: Router,
        private readonly overlaysService: OverlaysService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly notifService: NotifService,
        private readonly datapointsPageStateService: DatapointsPageStateService,
        private readonly accountService: AccountService,
        private readonly route: ActivatedRoute,
        private readonly cdRef: ChangeDetectorRef,
    ) {
        this.initForm();
    }
    ngOnInit() {
        this.overlaysService.getAllOverlayGroups().subscribe((groups) => {
            this.groups = this.flatten(groups).map((groupNode) => {
                return groupNode.group;
            });
        });
    }

    flatten(groupNodes: GroupWithOverlaysTreeNode[]) {
        let flatNodes = [];
        groupNodes.forEach(rootGroup => {
            this.recursiveOperation(rootGroup, group => {
                flatNodes.push(group);
            });
        });
        return flatNodes;
    }

    recursiveOperation(groupNode: GroupWithOverlaysTreeNode, operation: (node: GroupWithOverlaysTreeNode) => void) {
        operation(groupNode);
        groupNode.children.forEach(child => this.recursiveOperation(child, operation));
    }

    createOverlay() {
        if(this.createOverlayForm.controls.fields.value.length < 1) {
            this.notifService.error('Overlay must contain at least one field');
            return;
        }
        this.datasetConfig.tableForm.control.markAllAsTouched();
        this.createOverlayForm.markAllAsTouched();
        // TODO  TABLE FORM NEEDS TO BE VALIDIATED IN OTHER WAY, PREFERABLY MADE WITH REACTIVE FORMS.
        if (this.createOverlayForm.valid) {
            this.prepareDataForUpdate();
            const form = this.createOverlayForm.getRawValue();
            let geometryShardCount = Math.max(1, form.geometryShardCount / 50); // 50mb / partition
            form.geometryShardCount = geometryShardCount;
            form.geometryTileShardCount = geometryShardCount;
            const request = {
                ...form,
                geometryTileShardCount: geometryShardCount * 2,
                stylingOptions: {
                    ...this.datasetStylingOptions,
                    markerId: form.stylingOptions.markerId
                }
            };
            delete request.formBuilder;
            this.overlaysService.createOverlay(request).subscribe(overlay => {
                this.initForm();
                this.notifService.success('Overlay was successfully created');
                this.overlayCreated.emit();
            });
        }
    }

    initForm() {
        this.createOverlayForm = this.formBuilder.group(new CreateOverlayControlsConfig(this.formBuilder));
        this.datasetStylingOptions = {
            type: ColorScaleType.CONSTANT,
            colorizationFieldIndex: 0,
            defaultColor: 0xAAAAAA,
            colors: [0xAAAAAA],
            backgroundOpacity: 1,
            intervalOptions: []
        };
        const form = this.createOverlayForm.getRawValue();
        const dataset = {
            ...form,
            stylingOptions: {
                ...this.datasetStylingOptions,
                markerId: form.stylingOptions.markerId
            }
        };
        delete dataset.formBuilder;
        this.dataset = dataset;

        this.setCreateOverlayFormValidators();
    }

    private setCreateOverlayFormValidators() {
        let typeFormControl = this.createOverlayForm.get('stylingOptions').get('type');
        typeFormControl.valueChanges
            .subscribe(type => {
                if (type !== ColorScaleType.CONSTANT) {
                    let colorizationFieldFormControl = this.createOverlayForm.get('stylingOptions').get('colorizationFieldIndex');
                    colorizationFieldFormControl.setValidators(Validators.required);
                    colorizationFieldFormControl.updateValueAndValidity();

                }
            });
    }

    private prepareDataForUpdate() {
        let isThematicMap = this.createOverlayForm.get('thematicMapSettings').get('isThematicMapDataset').value;
        if (!isThematicMap) {
            this.createOverlayForm.get('thematicMapSettings').get('parentDatasetId').setValue(null);
            this.createOverlayForm.get('thematicMapSettings').get('parentLinkFieldId').setValue(null);
            this.createOverlayForm.get('thematicMapSettings').get('mainThematicFieldId').setValue(null);
        }

        let isGeoserver = this.createOverlayForm.get('isGeoserver').value;
        if (!isGeoserver) {
            this.createOverlayForm.get('geoserverDatasetId').setValue(null);
            this.createOverlayForm.get('rasterId').setValue(null);
            this.createOverlayForm.get('geoWorkspace').setValue(null);
        }
    }
}

<ng-template #dialogContent>
    <div class="page">
        <div class="dialog" [class.width-formula]="WorkspaceItemType.FORMULA === type">
            <div class="dialog-title-icon" (click)="close()">
                <!-- cross-icon -->
                <svg width="9" height="9" viewBox="0 0 9 9" xmlns="http://www.w3.org/2000/svg">
                    <g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke="#0044F1"
                        stroke-width="1.5">
                        <path d="m1 1 3.333 3.333L1 7.667" />
                        <path d="M7.333 1 4 4.333l3.333 3.334" />
                    </g>
                </svg>
            </div>
            <div class="dialog-content">
                <div class="confirm-content">
                    <div class="dialog-title">Save&nbsp;
                        <span [ngSwitch]="type">
                            <span *ngSwitchCase="WorkspaceItemType.FILTER">filter</span>
                            <span *ngSwitchCase="WorkspaceItemType.TABLE_PROJECTION">view</span>
                            <span *ngSwitchCase="WorkspaceItemType.ANALYTICS">analytics</span>
                            <span *ngSwitchCase="WorkspaceItemType.FORMULA">formula</span>
                            <span *ngSwitchCase="WorkspaceItemType.RENEWAL_REPORT">report</span>
                        </span>
                    </div>
                    <div *ngIf="(isReplace && workspaceItems.length > 0) && type !== WorkspaceItemType.FORMULA"
                        class="row">
                        <div class="dialog-label" [style.width.px]="WorkspaceItemType.ANALYTICS === type ? 165 : ''">
                            Replace&nbsp;
                            <span [ngSwitch]="type">
                                <span *ngSwitchCase="WorkspaceItemType.FILTER">filter</span>
                                <span *ngSwitchCase="WorkspaceItemType.TABLE_PROJECTION">view</span>
                                <span *ngSwitchCase="WorkspaceItemType.ANALYTICS">analytics</span>
                                <span *ngSwitchCase="WorkspaceItemType.RENEWAL_REPORT">report</span>
                            </span>
                        </div>
                        <mat-form-field color="accent" class="width-mat-input">
                            <mat-select [(ngModel)]="selectedWorkspaceItem" class="location-address-select">
                                <mat-option *ngFor="let item of workspaceItems" [value]="item"
                                    (click)="onWorkspaceItemSelected()">
                                    {{ item.name }}
                                    <span
                                        style="color: #808080eb; font-size: 14px; margin-left: 5px;">({{item.access}})</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="!isReplace" class="row">
                        <div class="dialog-label main-title">Name</div>
                        <div [formGroup]="workspaceItemsCreateForm" class="input-name-container width-mat-input">
                            <mat-form-field appearance="standard" color="accent" class="full-width">
                                <input matInput [(ngModel)]="name" name="name" autocomplete="off"
                                    formControlName="name">
                            </mat-form-field>
                            <mat-error
                                *ngIf="workspaceItemsCreateForm.controls['name'].touched && workspaceItemsCreateForm.controls['name'].hasError('maxlength')"
                                class="name-error">
                                You exceed the maximum number of characters.
                            </mat-error>
                            <!-- <small *ngIf="myField?.errors?.['alphanumeric']">Only alphanumeric characters are allowed.</small> -->

                            <mat-error
                                *ngIf="workspaceItemsCreateForm.controls['name'].touched && workspaceItemsCreateForm.controls['name'].hasError('alphanumeric')"
                                class="name-error">
                                Only alphanumeric characters are allowed.
                            </mat-error>
                        </div>
                    </div>
                    <div *ngIf="isReplace && type ===  WorkspaceItemType.FORMULA" class="row">
                        <div class="dialog-label main-title">Name</div>
                        <div class="input-name-container width-mat-input">
                            <mat-form-field appearance="standard" color="accent" class="full-width">
                                <input matInput [(ngModel)]="selectedWorkspaceItem.name" name="name" autocomplete="off"
                                    disabled>
                            </mat-form-field>
                        </div>
                    </div>
                    <div *ngIf="principalIsAdmin && !isReplace && type ===  WorkspaceItemType.DASHBOARD" class="row">
                        <div class="dialog-label main-title">Access</div>
                        <mat-form-field color="accent" class="width-mat-input">
                            <mat-select [disabled]="isView" [(ngModel)]="access" class="location-address-select">
                                <mat-option [value]="WorkspaceItemAccess.MEMBER">Only me</mat-option>
                                <mat-option [value]="WorkspaceItemAccess.GLOBAL">Account</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="principalIsAdmin && !isReplace && type !==  WorkspaceItemType.DASHBOARD" class="row">
                        <div class="dialog-label main-title">Access</div>
                        <mat-form-field color="accent" class="width-mat-input">
                            <mat-select [(ngModel)]="access" class="location-address-select">
                                <mat-option [value]="WorkspaceItemAccess.MEMBER">Only me</mat-option>
                                <mat-option [value]="WorkspaceItemAccess.GLOBAL">Account</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="isReplace && type ===  WorkspaceItemType.FORMULA && principalIsAdmin" class="row">
                        <div class="dialog-label main-title">Access</div>
                        <mat-form-field color="accent" class="width-mat-input">
                            <mat-select [disabled]="isView" [(ngModel)]="access" class="location-address-select">
                                <mat-option [value]="WorkspaceItemAccess.MEMBER">Only me</mat-option>
                                <mat-option [value]="WorkspaceItemAccess.GLOBAL">Account</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div *ngIf="isReplace && type ===  WorkspaceItemType.RENEWAL_REPORT && principalIsAdmin" class="row">
                        <div class="dialog-label main-title">Access</div>
                        <mat-form-field color="accent" class="width-mat-input">
                            <mat-select [disabled]="isView" [(ngModel)]="access" class="location-address-select">
                                <mat-option [value]="WorkspaceItemAccess.MEMBER">Only me</mat-option>
                                <mat-option [value]="WorkspaceItemAccess.GLOBAL">Account</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <ng-container *ngIf="type === WorkspaceItemType.FORMULA" class="row" style="position: relative;">
                        <div class="row">
                            <div class="dialog-label main-title">Formula</div>
                            <mat-form-field appearance="standard" color="accent" class="width-mat-input">
                                <input #formulaInput="matInput" autocomplete="off" matInput [disabled]="isView"
                                    [(ngModel)]="formula" placeholder="e.g (30/100)*[building]+[BI]/12"
                                    (keyup)="handleFormulaFieldsInput($event)">
                            </mat-form-field>
                        </div>
                        <div class="row">
                            <mat-menu #fieldsMenu="matMenu">
                                <div (click)="$event.stopPropagation()" class="nested-menu-search">
                                    <input placeholder="Search" [(ngModel)]="fieldsFilter">
                                </div>
                                <div class="dropdown-item"
                                    *ngFor="let field of numericDatasetFields | textFilter: fieldsFilter: 'name'"
                                    (click)="onFormulaFieldSelected(field, $event);">
                                    <hr/>
                                    {{ field.displayName ?? field.name }}
                                </div>
                            </mat-menu>
                            <button #fieldsMenuTrigger="matMenuTrigger" class="fields-dropdown-trigger"
                                [matMenuTriggerFor]="fieldsMenu"></button>
                        </div>
                    </ng-container>

                    <div class="buttons-container confirm-actions">
                        <button mat-flat-button color="primary" (click)="close()">
                            Cancel
                        </button>
                        <button color="accent" mat-flat-button (click)="submit()" [disabled]="disabledSubmitButton()">
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<div class="page-root">
    <div *ngIf="!isDashboardCall()">
        <map-overlay>
            <map-overlay-tabs [breadCrumbs]="breadCrumbs" [componentName]="componentName"></map-overlay-tabs>
        </map-overlay>
    </div>
    <div *ngIf="isDashboardCall()" class="breadcrumbs">
        <span>Home</span>
        <span>Accounts</span>
    </div>
    <div class="page">
        <div class="page-container grey-background">
            <div class="table-actions">
                <div class="toolbar-title">
                    Accounts
                </div>
                <div class="toolbar-container">
                    <map-filter-input (keyup)="applyFilter($event.target.value)"></map-filter-input>
                    <ng-container *ngIf="canCreate">
                        <button (click)="openCreateAccountPanel()" mat-flat-button color="accent">
                            <!-- Add User-Icon -->
                            <svg style="margin-right: 4px;" width="16" height="16" viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg">
                                <g fill="none" fill-rule="evenodd" class="icon-actions">
                                    <path d="M18 20v-.75A4.25 4.25 0 0 0 13.75 15h-7.5A4.25 4.25 0 0 0 2 19.25V20"
                                        stroke="#ffffff" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <circle stroke="#ffffff" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" cx="10" cy="7" r="4" />
                                    <path stroke="#ffffff" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" d="M19 9v4M21 11h-4" />
                                    <path d="M0 0h24v24H0z" />
                                </g>
                            </svg>
                            <span>Create Account</span>
                        </button>
                    </ng-container>
                    <ng-container *ngIf="canDelete">
                        <button (click)="deleteAccounts()" mat-flat-button color="primary">
                            <!-- Trash-Icon -->
                            <svg style="margin-right: 4px;" width="16" height="16" viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg">
                                <g fill="none" fill-rule="evenodd">
                                    <path
                                        d="M18.5 6.5V19a2 2 0 0 1-2 2h-9a2 2 0 0 1-2-2V6.5M9 17h6M9 13h6M8.5 6.5v-2A1.5 1.5 0 0 1 10 3h4a1.5 1.5 0 0 1 1.5 1.5v2M20 6.5H4"
                                        stroke="#002d57" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" class="icon-actions" />
                                    <path d="M0 0h24v24H0z" />
                                </g>
                            </svg>
                            <span>Delete Selected</span>
                        </button>
                    </ng-container>
                </div>
            </div>

            <div class="table-container">
                <div class="table-content">
                    <table [dataSource]="dataSource" mat-table matSort *ngIf="!isTableDataLoading">
                        <!-- Checkbox Column -->
                        <ng-container matColumnDef="select">
                            <th *matHeaderCellDef mat-header-cell style="width: 80px;">
                                <mat-checkbox (change)="$event ? masterToggle() : null"
                                    [checked]="selection.hasValue() && isAllSelected()"
                                    [indeterminate]="selection.hasValue() && !isAllSelected()">
                                </mat-checkbox>
                            </th>
                            <td *matCellDef="let row" mat-cell>
                                <mat-checkbox (change)="$event ? selection.toggle(row) : null"
                                    (click)="$event.stopPropagation()" [checked]="selection.isSelected(row)">
                                </mat-checkbox>
                            </td>
                        </ng-container>

                        <!-- Name Column -->
                        <ng-container matColumnDef="name">
                            <th *matHeaderCellDef mat-header-cell mat-sort-header> Name</th>
                            <td *matCellDef="let row" mat-cell class="content-ellipsis">{{row.name}}</td>
                        </ng-container>

                        <!-- Created On Column -->
                        <ng-container matColumnDef="progress">
                            <th *matHeaderCellDef mat-header-cell mat-sort-header> Created On</th>
                            <td *matCellDef="let row" mat-cell> {{row.progress}}%</td>
                        </ng-container>


                        <!-- Views Column -->
                        <ng-container *ngIf="isDashboardCall()" matColumnDef="views">
                            <th *matHeaderCellDef mat-header-cell mat-sort-header>Views</th>
                            <td *matCellDef="let accountRow" [style.color]="accountRow.color" mat-cell
                                class="table-cell-icons">
                                    <span *ngIf="accountRow.datasetMap.get('LOCATIONS')"
                                        (click)="navigateToDatapoints(accountRow, 'LOCATIONS')"
                                        style="padding: 11px 12px 4px 0; border-right: 0.8px solid #cccccc;">
                                        <!-- Building-Icon -->
                                        <svg width="16" height="16" viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg" style="cursor: pointer;">
                                            <g stroke="#08080d" stroke-width="1.5" fill="none" fill-rule="evenodd"
                                                stroke-linecap="round" stroke-linejoin="round" class="icon-actions">
                                                <path
                                                    d="M18 22.4H7M7 22.4V3.2C7 2.537 7.492 2 8.1 2h8.8c.608 0 1.1.537 1.1 1.2v19.2M10.3 6.2h4.4M10.3 9.8h4.4M10.3 13.4h4.4" />
                                                <path
                                                    d="M10.85 22.4v-4.2c0-.331.246-.6.55-.6h2.2c.304 0 .55.269.55.6v4.2" />
                                            </g>
                                        </svg>
                                    </span>

                                    <span *ngIf="accountRow.datasetMap.get('CLAIMS')"
                                        (click)="navigateToDatapoints(accountRow, 'CLAIMS')" 
                                        style="padding: 11px 12px 4px 12px; border-right: 0.8px solid #cccccc;">
                                        <!-- Dollar-Invoice-Icon -->
                                        <svg width="16" height="16" viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg" style="cursor: pointer;">
                                            <g stroke="#08080d" stroke-width="1.5" fill="none" fill-rule="evenodd"
                                                stroke-linecap="round" stroke-linejoin="round" class="icon-actions">
                                                <path
                                                    d="M20 4.575v14.85a1 1 0 0 1-.498.865l-.99.575a1 1 0 0 1-.998.003L15.994 20l-1.488.864a1 1 0 0 1-1 .002L12 20l-1.506.866a1 1 0 0 1-1-.002L8.006 20l-1.52.868a1 1 0 0 1-.998-.003l-.99-.575A1 1 0 0 1 4 19.425V4.575a1 1 0 0 1 .498-.865l.99-.575a1 1 0 0 1 .998-.003L8.006 4l1.488-.864a1 1 0 0 1 1-.002L12 4l1.506-.866a1 1 0 0 1 1 .002L15.994 4l1.52-.868a1 1 0 0 1 .998.003l.99.575a1 1 0 0 1 .498.865zM12 8v.808M12 16v-.808" />
                                                <path
                                                    d="M13.95 10.071A1.95 1.95 0 0 0 12 8.808a1.839 1.839 0 0 0-2 1.616h0c0 .684.483 1.273 1.154 1.407l1.692.338c.671.134 1.154.723 1.154 1.407h0a1.839 1.839 0 0 1-2 1.616 1.95 1.95 0 0 1-1.95-1.263" />
                                            </g>
                                        </svg>
                                    </span>

                                    <span *ngIf="accountRow.datasetMap.get('POLICIES')"
                                        (click)="navigateToDatapoints(accountRow, 'POLICIES')" 
                                        style="padding: 11px 12px 4px 12px;">
                                        <!-- Invoice-Icon -->
                                        <svg width="16" height="16" viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg" style="cursor: pointer;">
                                            <g fill="none" fill-rule="evenodd">
                                                <path d="M24 24H0V0h24z" />
                                                <circle class="icon-actions" stroke="#08080d" stroke-width="1.5"
                                                    stroke-linecap="round" stroke-linejoin="round" cx="16" cy="16.5"
                                                    r="1.5" />
                                                <path class="icon-actions"
                                                    d="M7 21H3a1 1 0 0 1-1-1V4.498a1 1 0 0 1 .551-.894l.99-.498a1 1 0 0 1 .892-.002l1.573.777 1.54-.774a1 1 0 0 1 .895-.001L10 3.882l1.559-.776a1 1 0 0 1 .894.001l1.541.774 1.573-.777a1 1 0 0 1 .892.002l.99.498a1 1 0 0 1 .551.894V9M6 9h8.004M7 15H6M6.004 12H7"
                                                    stroke="#08080d" stroke-width="1.5" stroke-linecap="round"
                                                    stroke-linejoin="round" />
                                                <rect class="icon-actions" stroke="#08080d" stroke-width="1.5"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    transform="rotate(-180 16 16.5)" x="10" y="12" width="12" height="9"
                                                    rx="2" />
                                            </g>
                                        </svg>
                                    </span>

                            </td>
                        </ng-container>

                        <!-- Actions Column -->
                        <ng-container matColumnDef="actions">
                            <th *matHeaderCellDef mat-header-cell mat-sort-header> Actions</th>
                            <td *matCellDef="let row" [style.color]="row.color" mat-cell class="table-cell-icons">
                                <ng-container *ngIf="row.canUpdate">
                                    <span (click)="navigateToSettings(row)" aria-hidden="false">
                                        <!-- Setting ICon -->
                                        <svg width="18" height="18" viewBox="0 0 27 27"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <g stroke="#08080d" stroke-width="1.5" fill="none" fill-rule="evenodd"
                                                stroke-linecap="round" stroke-linejoin="round" class="icon-actions">
                                                <path
                                                    d="m6.021 16.974 1.291.202c.953.149 1.633 1.021 1.563 2.004l-.095 1.333a.951.951 0 0 0 .521.923l.955.47a.912.912 0 0 0 1.031-.158l.96-.906a1.817 1.817 0 0 1 2.506 0l.959.906a.91.91 0 0 0 1.031.158l.957-.471a.947.947 0 0 0 .52-.92l-.096-1.335c-.07-.983.61-1.855 1.563-2.004l1.29-.202a.936.936 0 0 0 .764-.727l.236-1.055a.956.956 0 0 0-.38-.993l-1.077-.755a1.916 1.916 0 0 1-.558-2.5l.651-1.157a.966.966 0 0 0-.08-1.063l-.66-.847a.915.915 0 0 0-.996-.316l-1.248.391a1.837 1.837 0 0 1-2.26-1.112l-.478-1.24a.927.927 0 0 0-.864-.6l-1.058.003a.928.928 0 0 0-.861.604l-.466 1.226a1.836 1.836 0 0 1-2.264 1.12l-1.3-.408a.915.915 0 0 0-.999.317l-.655.848a.965.965 0 0 0-.073 1.068l.666 1.16c.49.856.25 1.954-.55 2.514l-1.064.746a.957.957 0 0 0-.38.993l.236 1.055c.084.38.387.669.762.728h0z" />
                                                <path
                                                    d="M15.268 15.268a2.5 2.5 0 1 1-3.536-3.537 2.5 2.5 0 0 1 3.536 3.537z" />
                                            </g>
                                        </svg>
                                    </span>
                                </ng-container>
                                <ng-container *ngIf="row.canUpdate">
                                    <span (click)="editAccount(row)">
                                        <!-- Pencil-Icon -->
                                        <svg width="16" height="16" viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <g stroke="#08080d" stroke-width="1.5" fill="none" fill-rule="evenodd"
                                                stroke-linecap="round" stroke-linejoin="round" class="icon-actions">
                                                <path
                                                    d="m8.972 12.2 4.76-4.76a1.5 1.5 0 0 1 2.122 0l.707.706a1.5 1.5 0 0 1 0 2.122l-4.761 4.76a2.5 2.5 0 0 1-1.414.708l-1.815.259a.5.5 0 0 1-.566-.566l.26-1.815a2.5 2.5 0 0 1 .707-1.414zM15.209 11.62 12.38 8.791" />
                                                <rect transform="rotate(180 12 12)" x="3" y="3" width="18" height="18"
                                                    rx="5" />
                                            </g>
                                        </svg>
                                    </span>
                                </ng-container>
                                <ng-container *ngIf="row.canDelete">
                                    <span (click)="deleteAccount(row)">
                                        <!-- Trash Icon -->
                                        <svg width="16" height="16" viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <g fill="none" fill-rule="evenodd">
                                                <path
                                                    d="M18.5 6.5V19a2 2 0 0 1-2 2h-9a2 2 0 0 1-2-2V6.5M9 17h6M9 13h6M8.5 6.5v-2A1.5 1.5 0 0 1 10 3h4a1.5 1.5 0 0 1 1.5 1.5v2M20 6.5H4"
                                                    stroke="#08080d" stroke-width="1.5" stroke-linecap="round"
                                                    stroke-linejoin="round" class="icon-actions" />
                                                <path d="M0 0h24v24H0z" />
                                            </g>
                                        </svg>
                                    </span>
                                </ng-container>
                                <ng-container *ngIf="!isDashboardCall()">
                                    <span (click)="openSidebar(row)">
                                        <!-- Key Icon -->
                                        <svg width="16" height="16" viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <g fill="none" fill-rule="evenodd">
                                                <g stroke-linecap="round" stroke-linejoin="round" stroke="#08080d"
                                                    stroke-width="1.5" class="icon-actions">
                                                    <path
                                                        d="M14.4 8.9c-.2 0-.4.2-.4.4s.2.4.4.4.4-.2.4-.4-.2-.4-.4-.4" />
                                                    <path
                                                        d="m9.5 11.3-.9-2c-.3-.8-.1-1.7.5-2.3L12 4.1c.6-.6 1.4-.7 2.2-.4l3.5 1.5c.5.2.9.6 1.1 1.1l1.5 3.5c.3.8.1 1.6-.4 2.2L17 14.9c-.6.6-1.4.7-2.2.4l-2.1-.9-6 6H3.5v-3.2l6-5.9h0z" />
                                                </g>
                                                <path d="M0 0h24v24H0z" />
                                            </g>
                                        </svg>
                                    </span>
                                </ng-container>
                            </td>
                        </ng-container>

                        <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
                        <tr *matRowDef="let row; columns: displayedColumns;" mat-row>
                        </tr>
                    </table>
                    <div class="spinnder-container" *ngIf="isTableDataLoading">
                        <mat-spinner diameter="40" color="accent"></mat-spinner>
                    </div>
                </div>
            </div>
        </div>
        <app-side-panel [width]="400" #editAccountPanel panelTitle="Edit Account" [panelIcon]="'icon-pencil'">
            <map-edit-account [account]="account" (Update)="fetchAccounts(); editAccountPanel.hidePanel();"
                [emitAccountOnEditSubject]="emitAccountOnEditSubject">
            </map-edit-account>
        </app-side-panel>
        <app-side-panel [width]="400" #createAccountPanel panelTitle="Create Account" [panelIcon]="'icon-user-plus'">
            <map-account-panel (updatedSuccessfully)="onCreateSuccess()"></map-account-panel>
        </app-side-panel>
    </div>
</div>
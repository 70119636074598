<div class="form-container">
	<form *ngIf="createIntegrationClientForm" [formGroup]="createIntegrationClientForm">
		<mat-form-field>
			<mat-label>Platform</mat-label>
			<input matInput formControlName="platform">
			<mat-error *ngIf="createIntegrationClientForm.controls.platform.hasError('required') ">
				Platform is required
			</mat-error>
		</mat-form-field>
		<mat-form-field>
			<mat-label>Client</mat-label>
			<input matInput formControlName="platformClient">
			<mat-error *ngIf="createIntegrationClientForm.controls.platformClient.hasError('required') ">
				Client is required
			</mat-error>
		</mat-form-field>
		<mat-form-field>
			<mat-label>Account key</mat-label>
			<input matInput formControlName="accountKey">
			<mat-error *ngIf="createIntegrationClientForm.controls.accountKey.hasError('required') ">
				Account key is required
			</mat-error>
		</mat-form-field>
		<mat-form-field>
			<mat-label>Account password</mat-label>
			<input matInput formControlName="accountPassword">
			<mat-error *ngIf="createIntegrationClientForm.controls.accountPassword.hasError('required') ">
				Account password is required
			</mat-error>
		</mat-form-field>
		<mat-form-field>
			<mat-label>Account</mat-label>
			<mat-select formControlName="accountId">
				<mat-option *ngFor="let account of accounts"
				            [value]="account.id">
					{{ account.name }}
				</mat-option>
			</mat-select>
			<mat-error *ngIf="createIntegrationClientForm.controls.accountId.hasError('required') ">
				Account is required
			</mat-error>
		</mat-form-field>
	</form>
	<button (click)="submitIntegratedClient()" [disabled]="createIntegrationClientForm.pristine" mat-flat-button type="submit"
	        color="accent" class="map-button">Submit
	</button>
</div>

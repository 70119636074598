import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { take } from 'rxjs/operators';
import { NotifService } from '../../core/notification/notif.service';
import { CreateOverlayGroupRequest, OverlaysService, UpdateOverlayGroupRequest } from '../../data-access-layer/global-overlays/overlays.service';
import { DialogModel } from '../../model/dialog/dialog-model';
import { GroupWithOverlaysTreeNode } from '../../model/overlay/group/group-with-overlays-tree-node';
import { DialogComponent } from '../../shared/dialog/dialog.component';
import { DataPointsServiceState } from '../../shared/services/datapoints-service-state';

@Component({
    selector: 'map-create-overlay-group',
    templateUrl: './create-overlay-group.component.html',
    styleUrls: ['./create-overlay-group.component.scss']
})
export class CreateOverlayGroupComponent implements OnInit {
    @Input() groupNodes: GroupWithOverlaysTreeNode[] = [];
    constructor(
        public readonly dialog: MatDialog,
        public readonly notifService: NotifService,
        private readonly overlayService: OverlaysService,
        private readonly datapointsServiceState: DataPointsServiceState
    ) { }

    ngOnInit() {
    }


    getGroupNodes() {
        return this.groupNodes;
    }

    createGroup(groupName: string, parentID?: number): void {
        let request: CreateOverlayGroupRequest = {
            name: groupName,
            parentID: parentID ? parentID : null,
            tag: '',
            id: null
        };
        this.overlayService.createOverlayGroup(request).subscribe((res) => {
            this.datapointsServiceState.emitOnCreateOverlayGroupSuccess();
            this.notifService.success('New Group Created');
        }, (err) => {
            this.notifService.error(err.error.message);
            console.log(err);
        });
    }

}

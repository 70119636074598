export enum ChartDisplayType {
    AGGREGATE = 'AGGREGATE',
    COUNT = 'COUNT',
    AVERAGE = 'AVERAGE',
    NONE = 'NONE'
}

export enum CharDisplayTypeSave {
    AGGREGATE = 'AGGREGATE',
    COUNT = 'COUNT',
    AVERAGE = 'AVERAGE',
    NONE = 'NONE'
}

export enum ChartDisplayLabel {
    AGGREGATE = 'Aggregate',
    COUNT = 'Count',
    AVERAGE = 'Average',
    NONE = 'NONE'
}

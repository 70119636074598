import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { TreeStructureUtils } from "src/app/core/utils/tree-structure-utils";
import { isUndefined } from "src/app/core/utils/util-master";
import { DatasetGeometryType } from "../../model/dataset/dataset-geometry-type";
import { Dataset } from "src/app/model/dataset/dataset";
import { DatasetField } from "src/app/model/dataset/field/dataset-field";
import { DatapointsFilterService } from "../datapoints/datapoints-filter.service";
import { TreeStructure } from "src/app/model/menu/tree-structure";

@Component({
    selector: "map-dataset-download",
    templateUrl: "./dataset-download.component.html",
    styleUrls: ["./dataset-download.component.scss"],
})
export class DatasetDownloadComponent {
    downloadDatapointsForm: UntypedFormGroup;
    advancedOptions = false;
    isClimateData = false;
    climateData;
    dropdownItems: TreeStructure[] = [];
    selectedDropdownItems: any[] = [];

    filterTreeStrcuture = new TreeStructureUtils();

    get DatasetGeometryType() {
        return DatasetGeometryType;
    }
    ulClassName = 'datasetDownload';
    constructor(
        private readonly fb: UntypedFormBuilder,
        private readonly datapointsFilterService: DatapointsFilterService,
        public dialogRef: MatDialogRef<DatasetDownloadComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        let groupObject = {
            filter: new UntypedFormControl("CURRENT_FILTER"),
            fileName: new UntypedFormControl("datapoints"),
            fileType: new UntypedFormControl("XLSX"),
            projection: new UntypedFormControl("CURRENT_FIELDS"),
            sort: new UntypedFormControl("CURRENT_SORT"),
        };
        this.downloadDatapointsForm = fb.group(groupObject);
        if (data && data !== null) {
            this.climateData = data.climateData;
            this.dropdownItems = this.convertToDropdownItems(
                this.climateData
            );
        }
    }

    onNoClick(): void {
        this.dialogRef.close(null);
    }

    submitDownloadRequest() {
        this.dialogRef.close(
            {
                result: this.downloadDatapointsForm.value,
                selectedClimateData: this.selectedDropdownItems
            }
        );
    }

    onSelectedItemsChanged(items: TreeStructure[]): void {
        this.selectedDropdownItems = items;
    }

    convertToDropdownItems(
        data: any[],
        parent?: TreeStructure
    ): TreeStructure[] {
        return data.map((item) => {
            const dropdownItem: TreeStructure = {
                id: item.id,
                params: item.params,
                name: item.name,
                selected: false,
                expanded: false,
            };

            if (item.children && item.children.length > 0) {
                dropdownItem.children = this.convertToDropdownItems(
                    item.children,
                    dropdownItem
                );
            }

            return dropdownItem;
        });
    }
}

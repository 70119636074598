import { DatapointFilter } from "src/app/model/datapoint/filter/datapoint-filter";
import { isUndefined } from "../utils/util-master";
import { Dataset } from "src/app/model/dataset/dataset";
import { Group } from "src/app/model/group/group";
import { AnayticsConstants } from "src/app/constants";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { TreeNode, TreeStructure } from "src/app/model/menu/tree-structure";

export class AnalyticsUtils {
    public generateDatapointFilter(
        datapointFilter: DatapointFilter,
        dataset: Dataset,
        isComparisonModeActivated: boolean,
        _groups: TreeNode[]
    ) {
        const currentFilter = isUndefined(datapointFilter)
            ? { datasetID: dataset.id }
            : datapointFilter;

        if (isComparisonModeActivated) {
            currentFilter.groups = _groups
                .filter((group) => group.selected            )
                .map((group) => {
                    return group.id;
                });
        }
        return currentFilter;
    }

    public sortMapDataForStringType(data: any) {
        let sortedEntries = Array.from(data.entries()).sort(
            (entry1, entry2) => {
                let key1: any = entry1;
                let key2: any = entry2;

                let [prefix1, suffix1] = key1[0].split("_");
                let [prefix2, suffix2] = key2[0].split("_");

                if (prefix1 === prefix2) {
                    return suffix1.localeCompare(suffix2);
                } else {
                    return prefix1.localeCompare(prefix2);
                }
            }
        );
        let preparedEntries = new Map<string, string>();
        sortedEntries.forEach((element) => {
            preparedEntries.set(element[0], element[1]);
        });
        return preparedEntries;
    }

    public sortMapDataForNumberType(data: any) {
        let sortedEntries = Array.from(data.entries()).sort(
            (entry1, entry2) => {
                let key1: any = entry1;
                let key2: any = entry2;

                let [prefix1, suffix1] = key1[0].split("_");
                let [prefix2, suffix2] = key2[0].split("_");

                if (prefix1 === prefix2) {
                    return suffix1.localeCompare(suffix2);
                } else {
                    return prefix1.localeCompare(prefix2);
                }
            }
        );
        let preparedEntries = new Map<string, number>();
        sortedEntries.forEach((element) => {
            preparedEntries.set(element[0], element[1]);
        });
        return preparedEntries;
    }

    public static markGroupSelected(checkedGroups: any, groups) {
        const _groups = groups.map((group) => {
            if (checkedGroups && checkedGroups.includes(group.id)) {
                group.isChecked = true;
                group.disabled = false;
            } else {
                group.disabled = true;
            }
            return group;
        });
        return _groups;
    }

    public static getSelectedGroups(data) {
        if(data?.isComparisonModeActivated) {
            return [...this.getSelectedNodes(data.report._compareGroupsStrcuture), ...this.getSelectedNodes(data.report._withGroupsStrcuture)]; 
        } else {
            return [...this.getSelectedNodes(data._compareGroupsStrcuture), ...this.getSelectedNodes(data._withGroupsStrcuture)]; 
        }
    }

    public static getSelectedGroupsForRanking(data) {
        return [...this.getSelectedNodes(data._compareGroupsStrcuture), ...this.getSelectedNodes(data._withGroupsStrcuture)]; 
    }

    public static fieldSelectLimitCount(isComparisonModeActivated) {
        return isComparisonModeActivated
            ? AnayticsConstants.MAX_FIELD_SELECT_LIMIT_COMPARISON
            : AnayticsConstants.MAX_FIELD_SELECT_LIMIT;
    }


    public static findGroupCount(groupIndex, result, activeGroups) {
        const groupId = activeGroups[groupIndex].id;
        const bucketIndex = result.buckets.findIndex(
            (bucket) => groupId === bucket.groupID
        );

        if (bucketIndex !== -1) {
            return result.values[bucketIndex].count || 0;
        }

        return 0;
    }

    public static findGroupResult(groupIndex, result, activeGroups) {
        const groupId = activeGroups[groupIndex].id;
        const bucketIndex = result.buckets.findIndex(
            (bucket) => groupId === bucket.groupID
        );

        if (bucketIndex !== -1) {
            return result.values[bucketIndex].result || 0;
        }

        return 0;
    }

    public static calculatePercentage(count: number, totalCount: number): number {
        return totalCount > 0 ? (count / totalCount) * 100 : 0;
    }

    public static getGroupName(groupIndex, activeGroups, isLengthChecked: boolean = true) {
        let name = activeGroups?.length ? activeGroups[groupIndex].name : "";
        return isLengthChecked && name.length >= 10 ? name.substring(0, 6) + "..." : name;
    }


    public static getGroupLargeName(groupIndex, activeGroups, isLengthChecked: boolean = true) {
        let name = activeGroups?.length ? activeGroups[groupIndex].name : "";
        return name;
    }

    public static calculateVariancePercentage(
        primaryGroupCount,
        secondaryGroupCount
    ): number {
        if (!primaryGroupCount) {
            return 0;
        }
        if(secondaryGroupCount === 0) {
            secondaryGroupCount = 1;
        }
        
        const difference = primaryGroupCount - secondaryGroupCount;
        const variation = (difference / secondaryGroupCount) * 100;

        return variation;
    }

    getSlectedGroupColumnName(_groups: Group[]) {
        return _groups[0].id+'_'+_groups[1].id;
    }
    
    public static calculateAverage(result, count) {
        if (count) {
            return result / count;
        }

        return 0;
    }

    public static getFormattedName(name, isLengthChecked: boolean = true) {
        return isLengthChecked && name.length >= 10 ? name.substring(0, 6) + "..." : name;
    }

    public static groupSelectionEvent(event: MatCheckboxChange, type: string, _compareGroups: any[], _withGroups: any[]) {
        if (event.checked) {
            switch(type) {
                case 'compare':
                    _withGroups.forEach(group => {
                        if(group.id === event.source.value) {
                            group.disabled = true;
                        }
                    })
                    _compareGroups.forEach(group => {
                        if(group.id !== event.source.value) {
                            group.disabled = true;
                        }
                    });
                    break;
                case 'with':
                    _compareGroups.forEach(group => {
                        if(group.id === event.source.value) {
                            group.disabled = true;
                        }
                    })
                    _withGroups.forEach(group => {
                        if(group.id !== event.source.value) {
                            group.disabled = true;
                        }
                    });
                    break;    
            }
        } else if(!event.checked){
            switch(type) {
                case 'compare':
                    const selectedWithGroup = _withGroups.filter((group) => group.isChecked);
                    _withGroups.forEach(group => {
                        if(group.id === event.source.value && selectedWithGroup.length <= 0) {
                            group.disabled = false;
                        }
                    })
                    _compareGroups.forEach(group => {
                        if(selectedWithGroup.length > 0 && group.id !== selectedWithGroup[0].id && group.id !== event.source.value) {
                            group.disabled = false;
                        } else if (selectedWithGroup.length <= 0) {
                            group.disabled = false;
                        }
                    });
                    break;
                case 'with':
                    const selectedCompareGroup = _compareGroups.filter((group) => group.isChecked);
                    _compareGroups.forEach(group => {
                        if(group.id === event.source.value && selectedCompareGroup.length <= 0) {
                            group.disabled = false;
                        }
                    });
                    _withGroups.forEach(group => {
                        if(selectedCompareGroup.length > 0 && group.id !== selectedCompareGroup[0].id && group.id !== event.source.value) {
                            group.disabled = false;
                        } else if (selectedCompareGroup.length <= 0) {
                            group.disabled = false;
                        }
                    });
            }
        }
        return {withGroups: _withGroups, compareGroups: _compareGroups};
    }

    static getSelectedNodeName(nodes, selectedId, isLengthChecked: boolean = true) {
        // let name = activeGroups?.length ? activeGroups[groupIndex].name : "";
        // return isLengthChecked && name.length >= 10 ? name.substring(0, 6) + "..." : name;
    
        for (const node of nodes) {
            if (node.selected) {
                return node.name; // Return the name of the selected node
            }
            
            if (node.children) {
                const selectedName = this.getSelectedNodeName(node.children, selectedId);
                if (selectedName) {
                    return selectedName; // Return the name if a selected node is found in the children
                }
            }
        }
        return null; // Return null if no selected node is found
    }

    static getSelectedNodes(nodes) {
        let selectedNodes = [];
        if(nodes !== undefined) {
            function traverse(nodes) {
                for (const node of nodes) {
                    if (node.selected) {
                        selectedNodes.push(node);
                    }
                    if (node.children) {
                        traverse(node.children);
                    }
                }
            }
        
            traverse(nodes);
        }
        return selectedNodes;
    }


    static toggleItem(event: MatCheckboxChange, item: TreeNode, section: 'compare' | 'with', _withGroupsStrcuture: any, _compareGroupsStrcuture: any) {
        if(event.checked && section == 'compare') {
            _withGroupsStrcuture = this.compareGroupCheckEvent(_withGroupsStrcuture, item.id, 'with');
            _compareGroupsStrcuture = this.compareGroupCheckEvent(_compareGroupsStrcuture, item.id, 'compare');
        } else if(event.checked && section == 'with') {
            _withGroupsStrcuture = this.withGroupCheckEvent(_compareGroupsStrcuture, item.id, 'compare');
            _compareGroupsStrcuture = this.withGroupCheckEvent(_withGroupsStrcuture, item.id, 'with');
        } else if(!event.checked && section == 'compare') {
            let selectedItem = AnalyticsUtils.getSelectedNodes(_withGroupsStrcuture);
            const withItem = selectedItem.length > 0 ? selectedItem[0] : undefined;
            _withGroupsStrcuture = this.compareGroupUnCheckEvent(_withGroupsStrcuture, item.id, 'with', withItem);
            _compareGroupsStrcuture = this.compareGroupUnCheckEvent(_compareGroupsStrcuture, item.id, 'compare', withItem);
        } else if(!event.checked && section == 'with') {
            let selectedItem = AnalyticsUtils.getSelectedNodes(_compareGroupsStrcuture);
            const compareItem = selectedItem.length > 0 ? selectedItem[0] : undefined;
            _compareGroupsStrcuture = this.withGroupUnCheckEvent(_compareGroupsStrcuture, item.id, 'compare', compareItem);
            _withGroupsStrcuture = this.withGroupUnCheckEvent(_withGroupsStrcuture, item.id, 'with', compareItem);
        }
        return {_compareGroupsStrcuture: _compareGroupsStrcuture, _withGroupsStrcuture: _withGroupsStrcuture};  
    }

    static compareGroupCheckEvent(nodes: TreeNode[], id: number, section: string) {
        // for (const node of nodes) {
        //     if ((section === 'compare' && node.id !== id) || (section === 'with' && node.id == id)) {
        //         node.disabled = true;
        //     } 
        //     if (node.children) {
        //         this.compareGroupCheckEvent(node.children, id, section);
        //     }
        // }
        // return nodes;

        const _group = nodes.map(node => {
            let newNode = { ...node };  // Create a shallow copy of the node to avoid mutating the original
    
            if ((section === 'compare' && newNode.id !== id) || (section === 'with' && newNode.id == id)) {
                newNode.disabled = true;
            } 
            if (newNode.children) {
                newNode.children = this.compareGroupCheckEvent(newNode.children, id, section);
            }
            return newNode;
        });
        return _group;
    }

    static withGroupCheckEvent(nodes: TreeNode[], id: number, section: string) {
        const _group = nodes.map(node => {
            let newNode = { ...node };  // Create a shallow copy of the node to avoid mutating the original
    
            if ((section === 'compare' && newNode.id === id) || (section === 'with' && newNode.id !== id)) {
                newNode.disabled = true;
            } 
            if (newNode.children) {
                newNode.children = this.withGroupCheckEvent(newNode.children, id, section);
            }
            return newNode;
        });
        return _group;

    }

    static compareGroupUnCheckEvent(nodes: TreeNode[], id: number, section: string, withItem: any) {
        // for (const node of nodes) {
        //     if ((section === 'with' && node.id == id) && withItem == undefined) {
        //         node.disabled = false;
        //     } else if (section === 'compare' && (withItem !== undefined && (node.id !== withItem.id && node.id !== id) ||  withItem == undefined)) {
        //         node.disabled = false;
        //     } else if (section === 'compare' && withItem == undefined) {
        //         node.disabled = false;
        //     }
        //     if (node.children) {
        //         this.compareGroupUnCheckEvent(node.children, id, section, withItem);
        //     }
        // }
        // return nodes;

        const _group = nodes.map(node => {
            let newNode = { ...node };  // Create a shallow copy of the node to avoid mutating the original
    
            if ((section === 'with' && newNode.id == id) && withItem == undefined) {
                newNode.disabled = false;
            } else if (section === 'compare' && (withItem !== undefined && (newNode.id !== withItem.id && newNode.id !== id) ||  withItem == undefined)) {
                newNode.disabled = false;
            } else if (section === 'compare' && withItem == undefined) {
                newNode.disabled = false;
            }
            if (newNode.children) {
                newNode.children = this.compareGroupUnCheckEvent(newNode.children, id, section, withItem);
            }
            return newNode;
        });
        return _group;
    }

    static withGroupUnCheckEvent(nodes: TreeNode[], id: number, section: string, compareItem: any) {
        // for (const node of nodes) {
        //     if ((section === 'compare' && node.id == id) && compareItem == undefined) {
        //         node.disabled = false;
        //     } else if (section === 'with' && (compareItem !== undefined && (node.id !== compareItem.id && node.id !== id) ||  compareItem == undefined)) {
        //         node.disabled = false;
        //     } else if (section === 'with' && compareItem == undefined) {
        //         node.disabled = false;
        //     }
        //     if (node.children) {
        //         this.withGroupUnCheckEvent(node.children, id, section, compareItem);
        //     }
        // }

        const _group = nodes.map(node => {
            let newNode = { ...node };  // Create a shallow copy of the node to avoid mutating the original
    
            if ((section === 'compare' && newNode.id == id) && compareItem == undefined) {
                newNode.disabled = false;
            } else if (section === 'with' && (compareItem !== undefined && (newNode.id !== compareItem.id && newNode.id !== id) ||  compareItem == undefined)) {
                newNode.disabled = false;
            } else if (section === 'with' && compareItem == undefined) {
                newNode.disabled = false;
            }
            if (newNode.children) {
                newNode.children = this.withGroupUnCheckEvent(newNode.children, id, section, compareItem);
            }
            return newNode;
        });
        return _group;
    }

    static transformTreeStructure(data) {
        const nestedStructure = [];

        const idToItem = new Map();
        data.forEach(item => {
            idToItem.set(item.id, { ...item, children: [] });
        });

        data.forEach(item => {
            const { id, parentId } = item;
            if (parentId !== null) {
                const parentItem = idToItem.get(parentId);
                if (parentItem) {
                    parentItem.children.push(idToItem.get(id));
                }
            } else {
                nestedStructure.push(idToItem.get(id));
            }
        });

        return this.convertToDropdownItems(nestedStructure);
    }

    static convertToDropdownItems(
        data: any[],
        parent?: TreeNode
    ): TreeNode[] {
        return data.map((item) => {
            const dropdownItem: TreeNode = {
                id: item.id,
                params: {
                    accountID: item.accountID,
                    datasetID: item.datasetID,
                    type: item.type,
                    parent: parent,
                },
                name: item.name,
                selected: parent && parent.selected ? true : item.selected,
                expanded: false,
                disabled: false
            };

            if (item.children && item.children.length > 0) {
                dropdownItem.children = this.convertToDropdownItems(
                    item.children,
                    dropdownItem
                );
            }

            return dropdownItem;
        });
    }

    static getSelectedStrucutureGroup(id, nodes) {
        const _group = nodes.map(node => {
            let newNode = { ...node };  // Create a shallow copy of the node to avoid mutating the original
    
            if (id.includes(newNode.id)) {  
                newNode.selected = true;
            } else {
                newNode.disabled = true;
            }
    
            if (newNode.children) {
                newNode.children = this.getSelectedStrucutureGroup(id, newNode.children);
            }
    
            return newNode;
        });
        return _group;
    }
}

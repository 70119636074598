<mat-card style="height: 100%; overflow: hidden;" class="edit-overlay-tree-card">
    <form *ngIf="map && selectedNode" class="edit-group-form" action=""  style="display:flex; flex-direction: column;">
     <mat-label class="main-label">Edit Group <span style="font-weight: bold">{{ selectedNode.group.name }} </span></mat-label>
        <mat-form-field >
            <mat-label>Choose Parent</mat-label>
            <mat-select [(value)]="selectedNode.group.parentId" #parentID>
                <mat-option *ngFor="let group of getGroupNodes()" [value]="group.group.id">
                    {{group.group.name}}
                </mat-option>
                <mat-option [value]="null">
                    None
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label>New Group Name</mat-label>
            <input  #newGroupName autocomplete="off" matInput  [value]="selectedNode.group.name">
        </mat-form-field>
        <div class="edit-form-controls">
            <button  mat-raised-button color="warn" (click)="deleteGroupModal()" >Delete</button>
            <button  mat-stroked-button (click)="editGroup(newGroupName.value,  selectedNode.group.id,parentID.value)" > Save </button>
        </div>
    </form>
    <form *ngIf="map && selectedNode" class="add-group-form" action=""  style="display:flex; flex-direction: column; margin-top: auto;">
        <mat-label class="main-label">Add child to group  <span style="font-weight: bold">{{ selectedNode.group.name }}</span> </mat-label>
        <mat-form-field class="example-full-width">
            <mat-label>New Group Name</mat-label>
            <input #newChildName autocomplete="off" matInput  value="">
        </mat-form-field>
        <button style="margin-left: auto; width: 40%; margin-right: 8px;" mat-stroked-button (click)="createGroup(newChildName.value, selectedNode.group.id); newChildName.value = ''; " > Save </button>
    </form>
</mat-card>

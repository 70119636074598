<div class="breadcrumbs mat-elevation-z1">
    <span>External Overlays</span>
    <span>External Overlay Tree</span>
    <div class="actions">
        <button (click)="navigateBackToPrivateOverlays()" mat-flat-button color="primary">
            <mat-icon fontIcon="fa-arrow-left" fontSet="fas"></mat-icon>
            <div>Back</div>
        </button>
    </div>
</div>
    <div class="container" >
            <mat-card *ngIf="map">
                <div class="controls">
                    <button (click)="showCreateGroupPanel()" mat-raised-button>Add Group</button>
                    <div>
                    <button (click)="expandAll()" mat-raised-button>Expand All</button>
                    <button (click)="collapseAll()" mat-raised-button>Collapse All</button>
                    </div>
                </div>
                <ul class="tree edit-tree" style="display: inline-block;">
                    <ng-template #treeView let-groupNodes>
                        <li (click)="$event.stopPropagation()" *ngFor="let groupNode of groupNodes" class="tree-row list-wrapper">
                            <div  class="tree-node" [ngClass]="{'selected': selectedNode === groupNode}" >
                                <div class="node-navigation-control">
                                    <button (click)="openNode(groupNode);" mat-icon-button>

                                    <mat-icon class="mat-icon-rtl-mirror"
                                    >
                                        {{ isGroupOpen(groupNode) ? 'expand_more' : 'chevron_right'}}
                                    </mat-icon>
                                    </button>
                                </div>
                                <mat-label (click)="selectNode(groupNode)" style="line-height: 40px;">
                                    {{ groupNode.group.name }}
                                </mat-label>
                                <div class="controls">
                                </div>
                            </div>
                            <ul [hidden]="(!isGroupOpen(groupNode))">
                                <ng-container
                                    *ngTemplateOutlet="treeView;  context:{ $implicit: groupNode.children }"></ng-container>
                                <li class="tree-row">
                                    <br>

                                </li>
                            </ul>
                        </li>
                    </ng-template>
                    <ng-container *ngTemplateOutlet="treeView; context:{ $implicit: groupNodes }"></ng-container>
                </ul>
            </mat-card>

        </div>

import { EventEmitter, Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { HeatmapResponse } from "src/app/dataset/clustering/heatmap-response";
import { Grouping } from "src/app/model/dataset/rendering/datapoint-converter-options";


@Injectable({
    providedIn: "root",
})
export class AccumulationsService {
    thematicMapFormula = null;
    thematicMapMetric = null;
    thematicMapGrouping = Grouping.SUM;
    thematicMapTotal = 0;

    heatmapActivationEventEmitter: EventEmitter<HeatmapResponse> = new EventEmitter<HeatmapResponse>();

    onThematicMapAnalyticsOpen$: Observable<boolean>;
    private readonly _onThematicMapAnalyticsOpen: Subject<boolean>;

    onThematicMapActivate$: Observable<boolean>;
    private readonly _onThematicMapActivate: Subject<boolean>;

    onThematicMapDeactivate$: Observable<boolean>;
    private readonly _onThematicMapDeactivate: Subject<boolean>;

    onThematicMapTotalValue$: Observable<any>;
    private readonly _onThematicMapTotalValue: Subject<any>;

    onThematicMapCount$: Observable<any>;
    private readonly _onThematicMapCount: Subject<any>;

    onThematicMapFloatingFields$: Observable<any>;
    private readonly _onThematicMapFloatingFields: Subject<any>;

    constructor() {
        this._onThematicMapAnalyticsOpen = new Subject<boolean>();
        this.onThematicMapAnalyticsOpen$ =
            this._onThematicMapAnalyticsOpen.asObservable();

        this._onThematicMapActivate = new Subject<boolean>();
        this.onThematicMapActivate$ =
            this._onThematicMapActivate.asObservable();

        this._onThematicMapDeactivate = new Subject<boolean>();
        this.onThematicMapDeactivate$ =
            this._onThematicMapDeactivate.asObservable();

        this._onThematicMapTotalValue = new Subject<boolean>();
        this.onThematicMapTotalValue$ =
            this._onThematicMapTotalValue.asObservable();

        this._onThematicMapCount = new Subject<boolean>();
        this.onThematicMapCount$ = this._onThematicMapCount.asObservable();

        this._onThematicMapFloatingFields = new Subject<boolean>();
        this.onThematicMapFloatingFields$ =
            this._onThematicMapFloatingFields.asObservable();
    }

    onHeatmapActivated(): Observable<HeatmapResponse> {
        return this.heatmapActivationEventEmitter.asObservable();
    }

    emitThematicMapAnalyticsPanel(): void {
        this._onThematicMapAnalyticsOpen.next(true);
    }

    emitThematicMapActivation(): void {
        this._onThematicMapActivate.next(true);
    }

    emitThematicMapDeactivation(): void {
        this._onThematicMapDeactivate.next(true);
    }

    emitThematicMapTotalValue(value): void {
        this._onThematicMapTotalValue.next(value);
    }

    emitThematicMapCount(value): void {
        this._onThematicMapCount.next(value);
    }

    emitThematicMapFloatingFields(value): void {
        this._onThematicMapFloatingFields.next(value);
    }
}

import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core";
import { ObjectUtils } from "src/app/core/utils/object-utils";
import { Dataset } from "src/app/model/dataset/dataset";
import { DatasetFieldType } from "src/app/model/dataset/dataset-field-type";
import { DatasetField } from "src/app/model/dataset/field/dataset-field";
import { Grouping } from "src/app/model/dataset/rendering/datapoint-converter-options";
import { WorkspaceItem } from "src/app/model/workspace/workspace-item";
import { AccumulationsService } from "src/app/shared/services/accumulations.service";

@Component({
    selector: "map-thematic-map",
    templateUrl: "./thematic-map.component.html",
    styleUrls: ["./thematic-map.component.scss"],
})
export class ThematicMapComponent {
    @Input() dataset: Dataset;
    @Input() formulas: WorkspaceItem[];
    @Input() grouping: WorkspaceItem[];
    @Input() nriFields = [];
    @Input() climateOverlays = [];

    isFormulaMenuOpen = false;
    isGroupingMenuOpen = false;
    isValueMenuOpen = false;
    isAnalyticsMenuOpen = false;
    datasetFloatingFields: DatasetField[];
    @Output() showTMAnalytics = new EventEmitter<boolean>(true);

    toggleTMAnalyticsPanel() {
        this.showTMAnalytics.emit();
    }

    thematicMapTotal: any;
    thematicMapFormula: any;
    thematicMapMetric: any;

    isThematicMapActivated: boolean = true;
    thematicMapCount: any;
    floatingFieldsValuesByFields: Map<DatasetField, number>;

    get groupingOptions() {
        return Object.keys(Grouping).filter((key) => key !== Grouping.COUNT);
    }

    get groupingEnum() {
        return Grouping;
    }

    get datasetFieldType() {
        return DatasetFieldType;
    }

    constructor(
        public readonly accumulationsSerivce: AccumulationsService,
        private readonly cd: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.thematicMapMetric = ObjectUtils.clone(this.accumulationsSerivce.thematicMapMetric);
        this.thematicMapFormula = ObjectUtils.clone(this.accumulationsSerivce.thematicMapFormula);
        this.addSuscribtions();
        this.setDatasetFloatingFields();
        this.isAnalyticsMenuOpen = true;
    }

    addSuscribtions() {
        this.accumulationsSerivce.onThematicMapTotalValue$.subscribe(
            (value) => {
                this.thematicMapTotal = value;
                this.cd.detectChanges();
            }
        );
        
        this.accumulationsSerivce.onThematicMapCount$.subscribe(
            (value) => {
                this.thematicMapCount = value;
                this.cd.detectChanges();
            }
        );

        this.accumulationsSerivce.onThematicMapFloatingFields$.subscribe(
            (value) => {
                this.floatingFieldsValuesByFields = value;
                this.cd.detectChanges();
            }
        );
    }

    setDatasetFloatingFields() {
        this.datasetFloatingFields = this.dataset.fields.filter(
            (field) => field.isFloating
        );
    }

    openThematicMapAnalyticsPanel(isAnalyticsMenuOpen) {
        if(isAnalyticsMenuOpen){
        this.accumulationsSerivce.emitThematicMapAnalyticsPanel();
        }
        this.showTMAnalytics.emit(isAnalyticsMenuOpen);
    }

    activateThematicMap() {
        this.isThematicMapActivated = true;
        this.thematicMapMetric = ObjectUtils.clone(this.accumulationsSerivce.thematicMapMetric);
        this.thematicMapFormula = ObjectUtils.clone(this.accumulationsSerivce.thematicMapFormula);
        this.accumulationsSerivce.emitThematicMapActivation();
    }

    deactivateThematicMap() {
        this.isThematicMapActivated = false;
        this.accumulationsSerivce.emitThematicMapDeactivation();
        this.showTMAnalytics.emit(false);
        this.isAnalyticsMenuOpen = false;
    }

    selectThematicMapFormula(formula, event) {
        if (!event.checked) {
            this.resetFormulas();
        } else {
            this.resetValues();
            this.formulas = this.formulas.map((_formula) => {
                if (_formula.id === formula.id) {
                    this.accumulationsSerivce.thematicMapFormula = _formula;
                    return { ..._formula, selectedForTM: true };
                }
                return { ..._formula, selectedForTM: false };
            });
        }
    }

    selectThematicMapGrouping(selectedGrouping) {
        this.accumulationsSerivce.thematicMapGrouping = selectedGrouping;
    }

    selectThematicMapMetric(selectedValue, event) {
        if (!event.checked) {
            this.resetValues();
        } else {
            this.resetFormulas();
            this.dataset.fields = this.dataset?.fields.map((value) => {
                if (value.id === selectedValue.id) {
                    this.accumulationsSerivce.thematicMapMetric = value;
                    return { ...value, selectedForTM: true };
                }
                return { ...value, selectedForTM: false };
            });
        }
    }

    resetFormulas() {
        this.accumulationsSerivce.thematicMapFormula = null;

        this.formulas = this.formulas.map((_formula) => {
            return { ..._formula, selectedForTM: false };
        });
    }

    resetValues() {
        this.accumulationsSerivce.thematicMapMetric = null;

        this.dataset.fields = this.dataset.fields.map((field) => {
            return { ...field, selectedForTM: false };
        });
    }
}

<div class="page-root">
    <div class="breadcrumbs">
        <span>Home</span>
        <span>Account</span>
        <span>Settings</span>
        <span>Edit Private Overlay</span>
        <div class="actions">
            <button (click)="navigateBackToPrivateOverlays()" mat-flat-button color="primary">
                <mat-icon fontIcon="fa-arrow-left" fontSet="fas"></mat-icon>
                <div>Back</div>
            </button>
            <button (click)="updateOverlay()" mat-flat-button color="accent">
                <!-- <mat-icon fontIcon="fa-save" fontSet="fas"></mat-icon> -->
                <div class="icon-container-wrap">
                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <g transform="translate(-.005 -.005)" fill="none" fill-rule="evenodd">
                            <path class="icon-primary"
                                d="M20.008 11.005V7.564c0-.502-.199-.983-.553-1.337l-2.672-2.672a1.89 1.89 0 0 0-1.337-.554H5.002a2 2 0 0 0-2 2.001v13.006a2 2 0 0 0 2 2h6.003"
                                stroke="#002d57" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path
                                d="M15 3.001v4.127a.876.876 0 0 1-.876.875H8.872a.876.876 0 0 1-.875-.875V3.001M7.003 20.008v-5.676a1.315 1.315 0 0 1 1.215-1.327h3.787"
                                stroke="#002d57" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <circle stroke="#002d57" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                                cx="17.507" cy="17.507" r="4.502" />
                            <path stroke="#002d57" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                                d="m18.907 16.808-1.75 1.752-1.05-1.052" />
                            <path d="M0 0h24.01v24.01H0z" />
                        </g>
                    </svg>
                </div>
                <div>Save</div>
            </button>
        </div>
    </div>
    <map-edit-overlay [type]="DatasetType.ACCOUNT_OVERLAY" #editOverlay [accountId]="accountId"
        (overlayUpdated)="navigateBackToPrivateOverlays()"></map-edit-overlay>
</div>
<div class="page">
    <div class="dialog">
        <div class="dialog-title">User settings</div>
        <div class="dialog-content user-setting-wrapper">
            <form [formGroup]="userSettingsForm">
                <mat-form-field>
                    <mat-label>Email</mat-label>
                    <input formControlName="email" readonly matInput/>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Your current password</mat-label>
                    <input formControlName="password" matInput type="password" placeholder="Type here"/>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>New password</mat-label>
                    <input formControlName="newPassword" matInput placeholder="Type here" type="password"/>
                    
                </mat-form-field>
                <mat-error *ngIf="userSettingsForm.get('newPassword').hasError('weak')" style="font-size: smaller;">
                    Your Password must contain : <br>
                    <div class="icon-setting">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="13" height="13">
                            <!-- Outer Circle -->
                            <circle cx="50" cy="50" r="45" stroke="red" stroke-width="5" fill="white" />
                            <!-- Exclamation Mark -->
                            <rect x="45" y="25" width="10" height="30" fill="red" />
                            <circle cx="50" cy="70" r="6" fill="red" />
                          </svg>
                                                  
                          &nbsp;Atleast 8 or more characters,
                    </div>
                    <div class="icon-setting">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="13" height="13">
                            <!-- Outer Circle -->
                            <circle cx="50" cy="50" r="45" stroke="red" stroke-width="5" fill="white" />
                            <!-- Exclamation Mark -->
                            <rect x="45" y="25" width="10" height="30" fill="red" />
                            <circle cx="50" cy="70" r="6" fill="red" />
                          </svg>
                                    
                      &nbsp;Atleast one number,</div>
                    <div class="icon-setting">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="13" height="13">
                            <!-- Outer Circle -->
                            <circle cx="50" cy="50" r="45" stroke="red" stroke-width="5" fill="white" />
                            <!-- Exclamation Mark -->
                            <rect x="45" y="25" width="10" height="30" fill="red" />
                            <circle cx="50" cy="70" r="6" fill="red" />
                          </svg>
                                    &nbsp;Atleast one uppercase character,</div>
                    <div class="icon-setting">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="13" height="13">
                            <!-- Outer Circle -->
                            <circle cx="50" cy="50" r="45" stroke="red" stroke-width="5" fill="white" />
                            <!-- Exclamation Mark -->
                            <rect x="45" y="25" width="10" height="30" fill="red" />
                            <circle cx="50" cy="70" r="6" fill="red" />
                          </svg>
                                    
                          &nbsp;Atleast one lowercase character</div>
                    <div class="icon-setting">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="13" height="13">
                            <!-- Outer Circle -->
                            <circle cx="50" cy="50" r="45" stroke="red" stroke-width="5" fill="white" />
                            <!-- Exclamation Mark -->
                            <rect x="45" y="25" width="10" height="30" fill="red" />
                            <circle cx="50" cy="70" r="6" fill="red" />
                          </svg>
                                    
                          &nbsp;Atleast one special character</div>
                </mat-error>

                <mat-form-field>
                    <mat-label>Repeat new password</mat-label>
                    <input [errorStateMatcher]="passwordsMatcher" formControlName="repeatNewPassword" matInput
                           type="password" placeholder="Type here"/>
                    <mat-error *ngIf="userSettingsForm.hasError('passwordsNotEqual')">
                        Passwords are different. They should be equal!
                    </mat-error>
                </mat-form-field>
                <span class="instructions">You will be automatically logged out from all devices upon submit.<br> Please re-login using the new password.</span>
                <button (click)="updateUserSettings()" mat-flat-button color="accent" [disabled]="userSettingsForm.get('newPassword').hasError('weak') || userSettingsForm.hasError('passwordsNotEqual')">
                    Save
                </button>
                <button (click)="back()" mat-flat-button color="primary">
                    Cancel
                </button>
            </form>
        </div>
    </div>
</div>

import { environment } from '../environments/environment';

export enum DatapointsViewMode {
    MAP = 'MAP',
    TABLE = 'TABLE',
    DASHBOARD = 'DASHBOARD'
}

export enum DistanceUnit {
    KM = 'KM',
    MILES = 'MILES',
    FEET = 'FEET',
    METER = 'METER',
}

export enum ClusterType {
    TOP = 'TOP',
    BOTTOM = 'BOTTOM'
}

export enum DatasetsNames {
    LOCATIONS = 'Locations',
    CLAIMS = 'Claims',
    POLICIES = 'Policies'
}

export class Constants {
    public static readonly BASE_DOMAIN = environment.baseUrl;
    public static readonly BUSINESS_ERROR_CODE = 503;
    public static readonly USERNAME_TAKEN_ERROR_CODE = Constants.BUSINESS_ERROR_CODE;
    public static readonly AUTH_ERROR_CODE = 401;
    public static readonly FORBIDDEN_ERROR_CODE = 403;
    public static readonly LOGO = environment.logo;
    public static readonly WHITE_LOGO = environment.whiteLogo;
    public static readonly ACCOUNT_NAME_LENGTH = 50;
    public static readonly TOO_MANY_REQUESTS = 429;
    public static readonly INTERNAL_SERVER_ERROR = 500;

    public static readonly ERROR = {
        COMPANY_NAME_USED: 200,
        COUNTRY_ALREADY_EXIST: 12,
        COMPANY_INVALID_ADMIN: 201
    };

    public static readonly VIEW_MODE_PARAM_NAME = 'viewMode';
    public static readonly VIEW_MODE_MAP = 'map';
    public static readonly VIEW_MODE_TABLE = 'table';

    public static readonly ONE_KM_IN_METERS = 1000;
    public static readonly ONE_MILE_IN_METERS = 1609.344;
    public static readonly DEFAULT_RADIUS = 10;
    public static readonly DEFAULT_TOP_CLUSTERS = 50;
    public static readonly IMG_SRC_BASE64_SVG_MIME_TYPE = 'data:image/svg+xml;base64,';

    public static readonly DEFAULT_TIMEZONE = 'Europe/Bucharest';
    public static readonly DEFAULT_DATE_FORMAT = 'mm/dd/yyyy';

    public static readonly DEFAULT_CURRENCY = 'USD';

    public static DATEPICKER_FORMAT = {
        parse: {
            dateInput: 'LL',
        },
        display: {
            dateInput: 'YYYY-MM-DD',
            monthYearLabel: 'YYYY',
            dateA11yLabel: 'LL',
            monthYearA11yLabel: 'YYYY',
        },
    };

    public static readonly ERROR_CODES = {
        COMPANY_NAME_USED: 200,
        COUNTRY_ALREADY_EXIST: 12,
        COMPANY_INVALID_ADMIN: 201,
        LOGIN_INVALID_EMAIL: 100,
        LOGIN_USER_INACTIVE: 101,
        LOGIN_INVALID_PASSWORD: 102,
        LOGIN_INVALID_2FA_CODE: 406,
        ACCOUNT_ALREADY_ACTIVE: 13,
        INTERNAL_SERVER_ERROR: 500,
        FORBIDDEN: 403,
        SERVICE_UNAVAILABLE: 503,
        TOO_MANY_REQUESTS: 429
    };

    public static readonly EXTENSIONS = {
        doc: 'word',
        dot: 'word',
        docx: 'word',
        docm: 'word',
        dotx: 'word',
        dotm: 'word',
        docb: 'word',
        xls: 'excel',
        xlsm: 'excel',
        xltx: 'excel',
        xltm: 'excel',
        xlt: 'excel',
        xlsx: 'excel',
        xlsb: 'excel',
        csv: 'excel',
        ppt: 'powerpoint',
        pot: 'powerpoint',
        pps: 'powerpoint',
        pptx: 'powerpoint',
        pptm: 'powerpoint',
        potx: 'powerpoint',
        potm: 'powerpoint',
        ppam: 'powerpoint',
        ppsx: 'powerpoint',
        ppsm: 'powerpoint',
        sldx: 'powerpoint',
        sldm: 'powerpoint',
        jpeg: 'image',
        jpg: 'image',
        giv: 'image',
        pdf: 'pdf',
        png: 'image',
        tiff: 'image',
        raw: 'image',
        psd: 'image',
        eps: 'image',
        ai: 'image',
        other: 'folder',
        txt: 'text'
    };

    public static readonly FLOOD_FEMA_DATASET_ID = "flood";
}

export enum AnayticsConstants {
    MAX_GROUP_SELECT_LIMIT = 2,
    MAX_FIELD_SELECT_LIMIT = 2,
    MAX_FIELD_SELECT_LIMIT_COMPARISON = 1,
    PRIMARY_GROUP_INDEX = 0,
    SECONDARY_GROUP_INDEX = 1
}

export enum AccumulationsTab {
    CLUSTER = 'CLUSTER',
    HEATMAP = 'HEATMAP',
    THEMATIC_MAP = 'THEMATIC_MAP',
}

export const ANALYTICS_PANEL_ID = "analytics";
export const ACCUMULATIONS_PANEL_ID = "accumulationsPanel";
export const COMMON_PANEL_WIDTH = 500;
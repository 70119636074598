<div class="component-container">
    <mat-expansion-panel hideToggle [(expanded)]="panelIsExpended" style="bottom:40px;right: 54px;">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <div class="header">
                    <div class="header-text">
                        {{attachments.length || ''}} Attachments
                    </div>
                    <div class="switch-button">
                        <span *ngIf="panelIsExpended"><i class="far fa-chevron-double-down"></i></span>
                        <span *ngIf="!panelIsExpended"><i class="far fa-chevron-double-up"></i></span>
                    </div>
                </div>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="attachments-container">
            <div class="attachment" *ngFor="let attachment of attachments" (click)="downloadAttachment(attachment)">
                <img class="file-icon" [src]="attachment.icon">
                <div class="file-name" [title]="attachment.name">{{attachment.name}}</div>
                <div class="delete-btn" (click)="$event.stopPropagation()" title="Delete">
                    <span class="" (click)="deleteAttachment(attachment.id)"><i class="far fa-times"></i></span>
                </div>
            </div>
            <div class="attachment add-file" title="Add attachment" (click)="attachmentInput.click()" *ngIf="hasWritePermission">
                <span *ngIf="!isUploading"><i class="fal fa-plus"></i></span>
                <mat-spinner *ngIf="isUploading" diameter="30" color="accent"></mat-spinner>
                <input #attachmentInput (change)="onAttachmentUpload($event)" style="display: none" type="file" multiple>
            </div>
        </div>
    </mat-expansion-panel>
</div>

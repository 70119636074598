import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { RandomUtils } from '../../core/utils/random-utils';
import { IntegrationService } from '../../data-access-layer/integration/integration.service';
import { IntegrationClientInfo } from '../../model/integration/integration-client-info';
import { NotifService } from '../../core/notification/notif.service';
import { Account } from '../../model/account/account';

@Component({
    selector: 'map-create-integrated-client',
    templateUrl: './create-integrated-client.component.html',
    styleUrls: ['./create-integrated-client.component.scss']
})
export class CreateIntegratedClientComponent implements OnInit {

    @Input() accounts: Account[];

    @Output() createSuccess = new EventEmitter();

    createIntegrationClientForm: UntypedFormGroup;

    constructor(private readonly fb: UntypedFormBuilder,
        private readonly integrationService: IntegrationService,
        private readonly notifService: NotifService) {

    }

    ngOnInit() {
        this.initForm();
    }

    private initForm() {
        let form = {
            platform: new UntypedFormControl(null, [Validators.required]),
            platformClient: new UntypedFormControl(null, [Validators.required]),
            accountKey: new UntypedFormControl(RandomUtils.generateUUID(), [Validators.required]),
            accountPassword: new UntypedFormControl(RandomUtils.generateRandomString(14), [Validators.required]),
            accountId: new UntypedFormControl(null, [Validators.required]),
        };
        this.createIntegrationClientForm = this.fb.group(form);
    }

    submitIntegratedClient() {
        let request: IntegrationClientInfo = this.createIntegrationClientForm.getRawValue();
        this.integrationService.createIntegrationClient(request).subscribe(
            success => {
                this.notifService.success("Client integration was successfully created");
                this.initForm();
                this.createSuccess.emit();
            }, error => this.notifService.error(error.error.message));
    }
}

import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DatapointFilter} from '../../model/datapoint/filter/datapoint-filter';
import {ClusteringRequest} from './clustering-request';
import {Cluster} from './cluster';
import {Observable} from 'rxjs';
import {ClusteringResponse} from './clustering-response';
import {NotifService} from '../../core/notification/notif.service';
import { HeatmapResponse } from './heatmap-response';

@Injectable({providedIn: 'root'})
export class ClusteringService {

    private clusterActivationEventEmitter: EventEmitter<ClusteringResponse> = new EventEmitter<ClusteringResponse>();
    private clusterDisableEventEmitter: EventEmitter<any> = new EventEmitter<any>();
    private clusterSelectedEventEmitter: EventEmitter<Cluster> = new EventEmitter<Cluster>();
    private clusterAnimationEventEmitter: EventEmitter<number> = new EventEmitter<number>();
    private isClusteringActivated: EventEmitter<boolean> = new EventEmitter<boolean>();
    private resetClustering: EventEmitter<boolean> = new EventEmitter<boolean>();
    

    private settings: ClusteringRequest;

    constructor(private http: HttpClient, private  notif: NotifService) {
    }

    enableClusteringMode(request: ClusteringRequest) {
        this.settings = request;
        this.fetchClusters(request).subscribe(response => {
            const interval = response.maxValue - response.minValue;
            let green = {red: 21, green: 237, blue: 140};
            let yellow = {red: 255, green: 255, blue: 51};
            let red = {red: 220, green: 0, blue: 0};
            response.clusters.forEach(cluster => {
                let a = cluster.value - response.minValue;
                const unit = a / interval;
                cluster.color = this.colorGradient(unit, green, yellow, red);
                cluster.opacity = 0.6;
                cluster.strokeWeight = 0.5;
            });
            this.clusterActivationEventEmitter.emit(response);
            this.isClusteringActivated.emit(true);
        }, error => {
            this.notif.error(error.error.message);
        });
    }

    getSettings(): ClusteringRequest {
        return this.settings;
    }

    animateCluster(clusterIndex): void {
        this.clusterAnimationEventEmitter.emit(clusterIndex);
    }

    clusterSelected(cluster: Cluster): void {
        this.clusterSelectedEventEmitter.emit(cluster);
    }

    onClusterSelected(): Observable<Cluster> {
        return this.clusterSelectedEventEmitter.asObservable();
    }

    onClusterAnimationNeeded(): Observable<number> {
        return this.clusterAnimationEventEmitter.asObservable();
    }

    disableClustering() {
        this.settings = null;
        this.clusterDisableEventEmitter.emit();
        this.isClusteringActivated.emit(false);
    }

    onClusteringActivated(): Observable<ClusteringResponse> {
        return this.clusterActivationEventEmitter.asObservable();
    }

    onClusteringDisabled(): Observable<any> {
        return this.clusterDisableEventEmitter.asObservable();
    }

    private fetchClusters(request: ClusteringRequest): Observable<ClusteringResponse> {
        return this.http.post<ClusteringResponse>('/clustering', request);
    }

    colorGradient(fadeFraction, rgbColor1, rgbColor2, rgbColor3?): string {
        var color1 = rgbColor1;
        var color2 = rgbColor2;
        var fade = fadeFraction;

        // Do we have 3 colors for the gradient? Need to adjust the params.
        if (rgbColor3) {
            fade = fade * 2;

            // Find which interval to use and adjust the fade percentage
            if (fade >= 1) {
                fade -= 1;
                color1 = rgbColor2;
                color2 = rgbColor3;
            }
        }

        const diffRed = color2.red - color1.red;
        const diffGreen = color2.green - color1.green;
        const diffBlue = color2.blue - color1.blue;

        const gradient = {
            red: parseInt(String(Math.floor(color1.red + (diffRed * fade))), 10),
            green: parseInt(String(Math.floor(color1.green + (diffGreen * fade))), 10),
            blue: parseInt(String(Math.floor(color1.blue + (diffBlue * fade))), 10),
        };

        return 'rgb(' + gradient.red + ',' + gradient.green + ',' + gradient.blue + ')';
    }

    isClusteringActivatedEmitter() {
        return this.isClusteringActivated.asObservable();
    }

    onResetClusterting() {
        return this.resetClustering.asObservable();
    }

    emitResetClustering() {
        this.resetClustering.emit();
    }

}

import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {IntervalOption} from '../../../../../model/dataset/rendering/interval-option';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ColorUtils} from '../../../../../core/utils/color-utils';
import {NotifService} from '../../../../../core/notification/notif.service';

const NUMBER_INPUT_MAX = 100000000000;

@Component({
    selector: 'mpt-color-intervals',
    templateUrl: './color-intervals.component.html',
    styleUrls: ['./color-intervals.component.scss']
})
export class ColorIntervalsComponent implements OnInit {

    @Input() intervals: IntervalOption[];
    @Output() Update: EventEmitter<any> = new EventEmitter<any>();
    intervalForm: UntypedFormGroup;
    color: string = '#000000';
    constructor(private readonly formBuilder: UntypedFormBuilder, private readonly notifService: NotifService) {
    }

    ngOnInit(): void {
        this.initForm();
    }

    addInterval(interval?: IntervalOption) {

        let minValue = this.intervalForm.controls.minValue.value;
        let maxValue = this.intervalForm.controls.maxValue.value;
        let color = this.intervalForm.controls.color.value;
        // this.intervalForm.controls.minValue.disable();

        if (minValue > maxValue && maxValue !== null) {
            this.notifService.error('Min cannot be greater than max.');
            return;
        }

        if (minValue > NUMBER_INPUT_MAX || maxValue > NUMBER_INPUT_MAX) {
            this.notifService.error('Exceeded the maximum allowed.');
            return;
        }

        let editedInterval = interval;
        let intervals = this.intervals;
        if (editedInterval === undefined) {
            if (this.intervalForm.valid) {
                // let lastInterval = intervals[intervals.length - 1];
                intervals.push({color: ColorUtils.colorFromBase64(color), minValue: minValue, maxValue: maxValue, total: maxValue});
            // if (maxValue) {
            //     this.intervalForm.controls.minValue.setValue(maxValue);
            //     // this.intervalForm.controls.minValue.disable();
            // } else {
            //     this.intervalForm.controls.minValue.setValue(null);
            //     this.intervalForm.controls.minValue.enable();
            // }
            //
            // if (lastInterval) { // it's not the first interval
            //     lastInterval.maxValue = minValue;
            // }
            // this.renderingOptions.datasetStylingOptions.intervalOptions = [...intervals];
            }
        } else {
            editedInterval = undefined;
            return;
        }
    }

    get ColorUtils() {
        return ColorUtils;
    }

    // ngOnChanges(): void {
    //     this.intervals.forEach((interval) => {
    //         interval.edit = false;
    //     });
    //     if (this.intervals.length === 0) {
    //         this.initForm();
    //     }
    // }

    private initForm() {
        this.intervalForm = this.formBuilder.group({
            color: ['#aaaaaa', [Validators.required]],
            minValue: [0, [Validators.required]],
            maxValue: [],
        });
    }

    toggleEdit(interval: IntervalOption) {
        if (interval.minValue > interval.maxValue && interval.maxValue !== null) {
            interval.maxValue = null;
            interval.minValue = 0;
            this.notifService.error('Min cannot be greater then max.');
            return;
        }

        if (interval.minValue > NUMBER_INPUT_MAX || interval.maxValue > NUMBER_INPUT_MAX) {
            interval.maxValue = null;
            interval.minValue = 0;
            this.notifService.error('Exceeded the maximum allowed.');
        }
        interval.edit = !interval.edit;
        if (interval.edit === false) {
            this.addInterval(interval);
        }
    }

    applyIntervals() {
        this.intervals.forEach((searchedInterval) => {
            searchedInterval.edit = false;
        });
        // this.onUpdate.emit();
    }

    saveColor($event, interval?: IntervalOption) {
        let int = this.intervals.find((searchedInterval) => {
            return searchedInterval === interval;
        });
        int.color = ColorUtils.colorFromBase64($event);
        this.color = $event;
        // this.onUpdate.emit();
    }

    removeInterval(interval) {
        if(this.intervals.length===1) {
            return;
        }
        this.intervals.splice(this.intervals.indexOf(interval), 1);
    }
}

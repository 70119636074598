import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnInit,
    Output,
} from "@angular/core";
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from "@angular/forms";
import {
    DatasetBitValues,
    datasetConfig,
    DatasetsArray,
    DirectedLinksArray,
} from "./account-panel.constants";
import { AccountService } from "../../data-access-layer/account/account.service";
import { CreateAccountRequest } from "../../model/account/account";
import { NotifService } from "../../core/notification/notif.service";
import { UserStateService } from "../../auth/user-state-service";
import { AuthService } from "../../auth/auth.service";
import { Constants } from "../../constants";
import { DirectedLinkDatasetId } from "../../model/dataset/link/directed-link-dataset-id";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { Dataset } from "../../model/dataset/dataset";

@Component({
    selector: "map-account-panel",
    templateUrl: "./account-panel.component.html",
    styleUrls: ["./account-panel.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountPanelComponent implements OnInit {
    @Output()
    updatedSuccessfully = new EventEmitter();
    datasets = datasetConfig;
    createAccountForm: UntypedFormGroup;
    datasetLinkMap = {};

    constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly accountService: AccountService,
        private readonly notifService: NotifService,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly userStateService: UserStateService,
        private readonly auth: AuthService
    ) {
        this.initializeDatasetLinkMap();
    }

    ngOnInit() {
        this.initForm();
    }

    initForm(): void {
        let name = new UntypedFormControl("", [
            Validators.required,
            Validators.maxLength(Constants.ACCOUNT_NAME_LENGTH),
            Validators.pattern("^[A-Za-z0-9]+( [A-Za-z0-9]+)*$"),
        ]);
        this.createAccountForm = new UntypedFormGroup({
            name: name,
            checkboxes: this.formBuilder.group({}),
            atLeastOneDatasetSelected: new UntypedFormControl(
                true,
                Validators.requiredTrue
            ),
        });
        const checkboxes = this.createAccountForm.get(
            "checkboxes"
        ) as UntypedFormGroup;
        let datasets = datasetConfig.map((dataset) => {
            return { title: dataset.text };
        });
        datasets.forEach((option: any) => {
            checkboxes.addControl(option.title, new UntypedFormControl(true));
        });
        this.createAccountForm.get("checkboxes").valueChanges.subscribe((v) => {
            this.createAccountForm
                .get("atLeastOneDatasetSelected")
                .setValue(this.mapItems(v));
            this.changeDetector.detectChanges();
        });
    }

    mapItems(items) {
        let values = [];
        Object.keys(items).map((index) => {
            let item = items[index];
            if (item) {
                values.push(index);
            }
        });
        return !(values.length === 0);
    }

    submitAccount(): void {
        this.accountService
            .createAccount(this.constructCreateAccountRequest())
            .subscribe(
                (account) => {
                    this.initForm();
                    this.userStateService
                        .initialize(this.auth.principal)
                        .then(() => {
                            this.notifService.success(
                                "Successfully created account"
                            );
                            this.updatedSuccessfully.emit();
                            this.changeDetector.detectChanges();
                        });
                },
                (err) => {
                    if (err && err.status === Constants.INTERNAL_SERVER_ERROR) {
                        this.createAccountForm.controls.name.setErrors({
                            used: true,
                        });
                        this.notifService.error(
                            "Account with this username already exists."
                        );
                        this.changeDetector.detectChanges();
                    }
                }
            );
    }

    private constructCreateAccountRequest(): CreateAccountRequest {
        const request: CreateAccountRequest = {
            name: this.createAccountForm.controls.name.value,
            links: [],
            datasets: [],
        };
        this.getSelectedDatasets().forEach((datasetName) => {
            let datasetRequest = DatasetsArray.find(
                (createDatasetRequest) =>
                    createDatasetRequest.application === datasetName
            );
            if (datasetRequest) {
                request.datasets.push(datasetRequest);
            }
        });
        DirectedLinksArray.forEach((DirectedLink) => {
            if (this.isLinkSelected(DirectedLink)) {
                request.links.push(DirectedLink);
            }
        });
        return request;
    }
    linkedDatasets(dataset: { key: string; text: string }) {
        return this.datasets.filter((value) => {
            return value.key !== dataset.key;
        });
    }

    private isLinkSelected(DirectedLink: DirectedLinkDatasetId): boolean {
        return this.datasetLinkMap[DirectedLink.bitValue];
    }
    getSelectedDatasets(): string[] {
        let checkboxes = this.createAccountForm.controls.checkboxes.value;
        let datasetNames = [];
        for (const dataset in checkboxes) {
            if (checkboxes.hasOwnProperty(dataset) && checkboxes[dataset]) {
                datasetNames.push(dataset.toUpperCase());
            }
        }
        return datasetNames;
    }

    private initializeDatasetLinkMap(): void {
        this.datasets.forEach((dataset1) => {
            this.datasets.forEach((dataset2) => {
                if (dataset1.key !== dataset2.key) {
                    let index = dataset1.bitValue + dataset2.bitValue;
                    this.datasetLinkMap[index] = true;
                }
            });
        });
    }

    toggleLinks(
        $event: MatCheckboxChange,
        dataset: { key: string; text: string; bitValue: DatasetBitValues }
    ) {
        let value = $event.checked;
        if (!value) {
            for (const mask in this.datasetLinkMap) {
                if (this.datasetLinkMap.hasOwnProperty(mask)) {
                    // tslint:disable-next-line:no-bitwise
                    if (Number(mask) & dataset.bitValue) {
                        this.datasetLinkMap[mask] = value;
                    }
                }
            }
        }
    }
    panelOpenState = false;

    displayedColumns: string[] = ['name', 'none', 'low'];
    dataSource = EARTHQUAKE_DATA;

}
export interface EarthquakeElement {
    name?: string;
    none?: string;
    low?: string;
    value1?: string;
    value2?: string;
    value3?: string;
    value4?: string;
    value5?: string;
    value6?: string;
    value7?: string;
    // value8?: string;
    // high?: string;
}

const EARTHQUAKE_DATA: EarthquakeElement[] = [
    {
        name: 'Earthquake',
        none: '#cccccc',
        value1: '#FFFFCC',
        value2: '##FFF34D',
        value3: '#FFAA00',
        value4: '#F00000',
        value5: '#A10000'
    },
    {
        name: 'Volcanoes',
        none: '#cccccc',
        value1: '#BABCBF',
        value2: '#FFAA00',
        value3: '#E60000',
        value4: '#730000'
    },
    {
        name: 'Tsunami',
        none: '#cccccc',
        value1: '#FFDAC9',
        value2: '#F29A77',
        value3: '#FA583C',
        value4: '#B01C05',
    },
    {
        name: 'Tropical Cyclone',
        none: '#cccccc',
        value1: '#BEE6CF',
        value2: '#8CD1A8',
        value3: '#59BA88',
        value4: '#27A16E',
        value5: '#008753',
        value6: '#006E45',
    },
    {
        name: 'Extratropical Storm',
        none: '#cccccc',
        value1: '#FFFF73',
        value2: '#C5D955',
        value3: '#92B53A',
        value4: '#609120',
        value5: '#377300',
    },
    {
        name: 'Hail',
        none: '#cccccc',
        value1: '#F0D1FF',
        value2: '#D1B3FF',
        value3: '#C36FED',
        value4: '#9446B3',
        value5: '#691273',
        value6: '#440045',
    },
    {
        name: 'Tornado',
        none: '#cccccc',
        value1: '#91E5CC',
        value2: '#47B5A1',
        value3: '#0A8A79',
        value4: '#035E5A',
    },
    {
        name: 'Lightning',
        none: '#cccccc',
        value1: '#FFFF80',
        value2: '#FCDD5D',
        value3: '#F7BA3E',
        value4: '#D68522',
        value5: '#9E4410',
        value6: '#6B0601',
    },
    {
        name: 'River Flood',
        none: '#cccccc',
        value1: '#DAE4F2',
        value2: '#B3C7E5',
        value3: '#4174BF',
        value4: '#0043A8',
    },
    {
        name: 'Flash Flood',
        none: '#cccccc',
        value1: '#DAE9EB',
        value2: '#BFD8DB',
        value3: '#99C1C7',
        value4: '#67A2AB',
        value5: '#32808C',
        value6: '#006170',
    },
    {
        name: 'Storm Surge',
        none: '#cccccc',
        value1: '#9ED6E6',
        value2: '#58B2C7',
        value3: '#1D8CA8',
    },
    {
        name: 'Wildfire',
        none: '#cccccc',
        value1: '#FFFF73',
        value2: '#FFAA00',
        value3: '#FF0000',
        value4: '#730000',
    },
];
<div class="page-root">
    <map-account-header-component>
        <settings-header-breadcrumbs [breadCrumbs]="breadCrumbs"
            [componentName]="componentName"></settings-header-breadcrumbs>
    </map-account-header-component>
    <div class="page">
        <div class="page-container">
            <div class="table-actions">
                <div class="toolbar-title">
                    Account Members Settings
                </div>
                <div class="toolbar-container">
                    <map-filter-input (keyup)="applyFilter($event.target.value)"></map-filter-input>
                    <button (click)="createMember()" mat-flat-button color="accent">
                        <!-- plus rounded icon -->
                        <svg style="margin-right: 4px;" width="16" height="16" viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg">
                            <g fill="none" fill-rule="evenodd">
                                <path d="M24 0v24H0V0z" />
                                <path
                                    d="M15 3.523a8.865 8.865 0 0 0-6 0M3.157 10.358a8.981 8.981 0 0 1 3-5.196M6.157 18.838a8.982 8.982 0 0 1-3-5.196M9 20.477c1.94.697 4.06.697 6 0M20.843 10.358a8.981 8.981 0 0 0-3-5.196M17.843 18.838a8.982 8.982 0 0 0 3-5.196M10 12h4M12 14v-4"
                                    stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </g>
                        </svg>
                        <span>Create Member</span>
                    </button>
                </div>
            </div>
            <div class="display-flex flex-end"
                style="background-color: #ffffff;    box-shadow: 0px 1.6px 3.6px rgba(0, 0, 0, 0.13), 0px 0px 2.9px rgba(0, 0, 0, 0.11);">
                <mat-slide-toggle (change)="toggleTwoFactorAuth()" [checked]="isTwoFactorAuthEnabled" color="accent"
                    class="mat-slide-toggle-badge">
                    {{ isTwoFactorAuthEnabled ? 'Disable 2FA' : 'Enable 2FA' }}
                </mat-slide-toggle>
            </div>
            <div class="table-container">
                <div class="table-content">
                    <table *ngIf="members && currentAccount && members.length && !isTableDataLoading"
                        [dataSource]="membersDataSource" mat-table class="member-table">
                        <ng-container matColumnDef="Name">
                            <th class="column-name name-column" *matHeaderCellDef mat-header-cell>Name</th>
                            <td *matCellDef="let member" mat-cell>{{ member.name }}</td>
                        </ng-container>
                        <ng-container matColumnDef="Email">
                            <th class="column-name" *matHeaderCellDef mat-header-cell>Email</th>
                            <td *matCellDef="let member" mat-cell>{{ member.email }}</td>
                        </ng-container>
                        <ng-container matColumnDef="Status">
                            <th class="column-name status-column" *matHeaderCellDef mat-header-cell>Status</th>
                            <td *matCellDef="let member" mat-cell>{{ member.status }}</td>
                        </ng-container>
                        <ng-container *ngFor="let dataset of currentAccount.datasets"
                            [matColumnDef]="dataset.application">
                            <th *matHeaderCellDef mat-header-cell>
                                <div class="dataset-name uppercase">
                                    {{ dataset.application | lowercase }}
                                </div>
                            </th>
                            <td *matCellDef="let member" mat-cell class="cell-select">
                                <ng-container
                                    *ngIf="dataset.application === MaptycsApplication.LOCATIONS; else selectDropdown">
                                    <ng-container
                                        *ngIf="member.datasetGroups[dataset.id] && member.datasetGroups[dataset.id].length">
                                        <span>
                                            {{ sortDatasetGroups(member.datasetGroups[dataset.id])[0].name
                                            }}<ng-container
                                                *ngIf="member.datasetGroups[dataset.id].length > 1">...</ng-container>
                                        </span>
                                    </ng-container>
                                    <span class="edit-locations-button"
                                        (click)="openLocationEditDialog(member.id, member.datasetGroups[dataset.id], dataset)">
                                        <!-- Edit Icon -->
                                        <svg width="14" height="14" viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <g fill="none" fill-rule="evenodd">
                                                <g stroke-linecap="round" stroke-linejoin="round" stroke="#08080d"
                                                    stroke-width="1.5" class="icon-actions">
                                                    <path
                                                        d="M21 11v8a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h7" />
                                                    <path
                                                        d="m9 15 3.15-.389c.221-.027.427-.128.585-.285l7.631-7.631a2.165 2.165 0 0 0 0-3.061h0a2.165 2.165 0 0 0-3.061 0l-7.56 7.56a1.001 1.001 0 0 0-.283.566L9 15z" />
                                                </g>
                                                <path d="M0 0h24v24H0z" />
                                            </g>
                                        </svg>
                                    </span>
                                </ng-container>

                                <ng-template #selectDropdown>
                                    <mat-select [(ngModel)]="member.datasetGroups[dataset.id]" [compareWith]="compareFn"
                                        multiple name="users">
                                        <mat-option
                                            (click)="addOrRemoveMemberFromGroup(member, member.datasetGroups[dataset.id], group)"
                                            *ngFor="let group of groupModel[dataset.id]" [value]="group.id">
                                            {{ group.name }}
                                        </mat-option>
                                    </mat-select>
                                </ng-template>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="DeleteDate">
                            <th class="column-name valid-column" *matHeaderCellDef mat-header-cell>Valid Until</th>
                            <td *matCellDef="let member" mat-cell class="">
                                {{member.deleteDate | date}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Admin">
                            <th class="column-name boolean-column" *matHeaderCellDef mat-header-cell>Account Admin</th>
                            <td *matCellDef="let member" mat-cell class="boolean-column">
                                <mat-checkbox [checked]="member.isAccountAdmin"
                                    (change)="makeMemberAccountAdmin($event, member)"></mat-checkbox>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Write">
                            <th class="column-name boolean-column" *matHeaderCellDef mat-header-cell>EMFD</th>
                            <td *matCellDef="let member" mat-cell class="boolean-column">
                                <mat-checkbox [checked]="member.permissions !== 0"
                                    (change)="updateMemberPermission($event, member)"></mat-checkbox>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Actions">
                            <th class="column-name" *matHeaderCellDef mat-header-cell>Actions</th>
                            <td *matCellDef="let member" mat-cell class="table-cell-icons">
                                <div style="display: flex;">
                                        <span (click)="deleteMember(member)" 
                                        style="margin-right: 4px;" 
                                        matTooltipPosition="above"
                                        matTooltip="Delete member">
                                            <!-- Trash Icon -->
                                            <svg width="16" height="16" viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg" style="margin-right: 2px;">
                                                <g fill="none" fill-rule="evenodd">
                                                    <path
                                                        d="M18.5 6.5V19a2 2 0 0 1-2 2h-9a2 2 0 0 1-2-2V6.5M9 17h6M9 13h6M8.5 6.5v-2A1.5 1.5 0 0 1 10 3h4a1.5 1.5 0 0 1 1.5 1.5v2M20 6.5H4"
                                                        stroke="#08080d" stroke-width="1.5" stroke-linecap="round"
                                                        stroke-linejoin="round" class="icon-actions disabled-icon" />
                                                    <path d="M0 0h24v24H0z" />
                                                </g>
                                            </svg>
                                        </span>
                                    <div class="member-invite">
                                        <span (click)="inviteMember(member)"
                                            *ngIf="member.status !== MemberStatus.ACTIVE"
                                            [class.disabled]="!member.hasGroup" style="margin-left: 8px;"
                                            [matTooltip]="!member.hasGroup || (member.status === MemberStatus.ACTIVE) ? 'Cannot
                                            invite. The user must be attached to at least one group' : 'Invite
                                            member'">
                                            <!-- envelope-icon -->
                                            <svg width="14" height="14" viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg" style="margin-right: 2px;">
                                                <g stroke="#08080d" stroke-width="1.5" fill="none" fill-rule="evenodd"
                                                    stroke-linecap="round" stroke-linejoin="round">
                                                    <path
                                                        d="M3 19V9.375a2 2 0 0 1 .94-1.696l7-4.375a2 2 0 0 1 2.12 0l7 4.375A2 2 0 0 1 21 9.375V19a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
                                                    <path d="m6.5 10.262 4.537 2.491a2 2 0 0 0 1.926 0l4.537-2.491" />
                                                </g>
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                                <!-- <span (click)="inviteMember(member)" matTooltipPosition="above"
                                    *ngIf="member.status !== MemberStatus.ACTIVE" [class.disabled]="!member.hasGroup"
                                    [matTooltip]="!member.hasGroup || (member.status === MemberStatus.ACTIVE) ? 'Cannot invite. The user  must be attached to at least one group' : 'Invite member'">
                                    <svg width="14" height="14" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <g stroke="#08080d" stroke-width="1.5" fill="none" fill-rule="evenodd"
                                            stroke-linecap="round" stroke-linejoin="round">
                                            <path
                                                d="M3 19V9.375a2 2 0 0 1 .94-1.696l7-4.375a2 2 0 0 1 2.12 0l7 4.375A2 2 0 0 1 21 9.375V19a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
                                            <path d="m6.5 10.262 4.537 2.491a2 2 0 0 0 1.926 0l4.537-2.491" />
                                        </g>
                                    </svg>
                                </span> -->
                            </td>
                        </ng-container>
                        <tr *matHeaderRowDef="currentDisplayedColumns" mat-header-row></tr>
                        <tr *matRowDef="let row; columns: currentDisplayedColumns" mat-row></tr>
                    </table>
                    <div *ngIf="isTableDataLoading" class="spinnder-container">
                        <mat-spinner diameter="40"></mat-spinner>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
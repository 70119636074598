<!--<ng-template *ngIf="readonly===true else write">-->
<!--    <mat-form-field class="full-width">-->
<!--        <input-->
<!--            #input-->
<!--            [disabled]="true"-->
<!--            matInput-->
<!--            [(ngModel)]="value"-->
<!--            [name]="fieldName"-->
<!--        />-->
<!--    </mat-form-field>-->
<!--</ng-template>-->
<!--<ng-template #write>-->
<div [ngSwitch]="fieldType">
    <div *ngSwitchCase="'DATE_FIELD'">
        <mat-form-field>
            <input
                matInput
                [matDatepicker]="picker"
                #input
                [placeholder]="fieldName"
                [(ngModel)]="dateTimeValue"
                [name]="fieldName"
                [disabled]="readonly"
                (ngModelChange)="registerDateChange()"/>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </div>
    <div *ngSwitchCase="'NUMBER_FIELD'">
        <mat-form-field [appearance]="appearance" [floatLabel]="floatLabel">
            <mat-label>{{fieldName}}</mat-label>
            <input
                [placeholder]="fieldName"
                matInput
                #input
                [(ngModel)]="value"
                (input)="registerChange(input.value)"
                [name]="fieldName"
                type="number"
                [disabled]="readonly"
                (keydown)="onKeydown($event)"
            />
        </mat-form-field>
    </div>
    <div *ngSwitchDefault>
        <mat-form-field [appearance]="appearance" [floatLabel]="floatLabel">
            <mat-label>{{fieldName}}</mat-label>
            <input
                #input
                (input)="registerChange(input.value)"
                [placeholder]="fieldName"
                matInput
                [(ngModel)]="value"
                [name]="fieldName"
                [disabled]="readonly"
            />
        </mat-form-field>
    </div>
</div>
<!--</ng-template>-->

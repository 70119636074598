<div class="form-container">
    <ag-grid-angular
      style="width: 100%; height: 100%;"
      class="ag-theme-alpine"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [autoGroupColumnDef]="autoGroupColumnDef"
      [groupDefaultExpanded]="groupDefaultExpanded"
      [rowData]="rowData"
      [enableBrowserTooltips]="true"
      (gridReady)="onGridReady($event)"
      [sideBar]="false"
      [animateRows]="true"
      [gridOptions]="gridOptions"
      [rowSelection]="rowSelection"
      [suppressRowDeselection]="true"
      (filterChanged)="onFilterChanged($event)"
      [pagination]="true"
    ></ag-grid-angular>
</div>
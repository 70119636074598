<!-- Reference -: https://stackblitz.com/edit/dynamic-nested-menus-9au7qx?file=app%2Fapp.component.ts,app%2Fapp.component.html,app%2Fapp.component.scss,app%2Fapp-routing.module.ts -->
<mat-menu #childMenu="matMenu" [overlapTrigger]="false" class="nested-menu nested-menu-leaf">
    <ng-container *ngIf="renderFor == 'filters'">
        <ng-container *ngFor="let child of inputParams.items.children">
            <!-- Handle branch node menu items -->
            <span mat-menu-item *ngIf="child.children && child.children.length > 0"
                [matMenuTriggerFor]="menu.childMenu">
                {{child.group.name}}
                <map-dynamic-filter-menu #menu (outputEvent)="dynamicFilterMenuEmitter($event)"
                [renderFor]="'filters'" [inputParams]="{items: child, overlaysFilterDistance: inputParams.overlaysFilterDistance, filterFieldSearchFilter: inputParams.filterFieldSearchFilter}"></map-dynamic-filter-menu>
            </span>
            <!-- Handle leaf node menu items -->
            <span mat-menu-item *ngIf="!child.children || child.children.length === 0"
                [matMenuTriggerFor]="overlayMenu">{{child.group.name}}
            </span>

            <mat-menu #overlayMenu="matMenu" class="nested-menu nested-menu-leaf">
                <ng-container *ngFor="let childOverlay of child.overlays">
                    <span [matMenuTriggerFor]="childOverlayMenu" mat-menu-item> {{childOverlay.name}}</span>
                    <mat-menu #childOverlayMenu="matMenu" class="nested-menu nested-menu-leaf">
                        <div *ngIf="childOverlay.geometryType === DatasetGeometryType.POINT" class="link-distance-input"
                            (click)="$event.stopPropagation()">
                            <input class="link-distance-input" #myInput placeholder="Distance"
                                [(ngModel)]="inputParams.overlaysFilterDistance && inputParams.overlaysFilterDistance[childOverlay.id]"
                                (keydown)="$event.stopPropagation()">

                            <mat-select #distanceUnit [(value)]="defaultDistanceUnit" class="dropdown-small">
                                <mat-option [value]="DistanceUnit.KM">{{DistanceUnit.KM}}</mat-option>
                                <mat-option [value]="DistanceUnit.MILES">{{DistanceUnit.MILES}}</mat-option>
                            </mat-select>

                            <div class="apply-button-wrapper">
                                <span class="apply-distance-btn"
                                    (click)="applyClick(childOverlay, myInput.value, distanceUnit.value)">Apply</span>
                            </div>
                        </div>
                        <ng-container
                            *ngFor="let field of childOverlay.fields | callback: inputParams.filterFieldSearchFilter">
                            <div *ngIf="!field.isGenerated && !field.tags?.includes('ID') && field.displayInDropdowns"
                                (click)="$event.stopPropagation()" mat-menu-item>
                                <mat-checkbox [(ngModel)]="field.selected"
                                    (ngModelChange)="filterMenuFieldClick(field.selected, childOverlay, field)">
                                    <span *ngIf="field.displayName === null || field.displayName === undefined">{{field.name}}</span>
                                    <span *ngIf="field.displayName !== null || field.displayName !== undefined">{{field.displayName}}</span>
                                </mat-checkbox>
                            </div>
                        </ng-container>
                    </mat-menu>
                </ng-container>
            </mat-menu>
        </ng-container>
        <ng-container *ngFor="let overlay of inputParams.items.overlays">
            <span [matMenuTriggerFor]="childOverlayMenu" mat-menu-item> {{overlay.name}}</span>
                    <mat-menu #childOverlayMenu="matMenu" class="nested-menu nested-menu-leaf">
                        <div *ngIf="overlay.geometryType === DatasetGeometryType.POINT" class="link-distance-input"
                            (click)="$event.stopPropagation()">
                            <input class="link-distance-input" #myInput placeholder="Distance"
                                [(ngModel)]="inputParams.overlaysFilterDistance && inputParams.overlaysFilterDistance[overlay.id]"
                                (keydown)="$event.stopPropagation()">

                            <mat-select #distanceUnit [(value)]="defaultDistanceUnit" class="dropdown-small">
                                <mat-option [value]="DistanceUnit.KM">{{DistanceUnit.KM}}</mat-option>
                                <mat-option [value]="DistanceUnit.MILES">{{DistanceUnit.MILES}}</mat-option>
                            </mat-select>

                            <div class="apply-button-wrapper">
                                <span class="apply-distance-btn"
                                    (click)="applyClick(overlay, myInput.value, distanceUnit.value)">Apply</span>
                            </div>
                        </div>
                        <ng-container
                            *ngFor="let field of overlay.fields | callback: inputParams.filterFieldSearchFilter">
                            <div *ngIf="!field.isGenerated && !field.tags?.includes('ID') && field.displayInDropdowns"
                                (click)="$event.stopPropagation()" mat-menu-item>
                                <mat-checkbox [(ngModel)]="field.selected"
                                    (ngModelChange)="filterMenuFieldClick(field.selected, overlay, field)">
                                    <span *ngIf="field.displayName === null || field.displayName === undefined">{{field.name}}</span>
                                    <span *ngIf="field.displayName !== null || field.displayName !== undefined">{{field.displayName}}</span>
                                </mat-checkbox>
                            </div>
                        </ng-container>
                    </mat-menu>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="renderFor == 'groupByAnalytics'">
        <ng-container *ngFor="let child of analyticsInputParams.items">
            <!-- Handle branch node menu items -->
            <span mat-menu-item *ngIf="child.children && child.children.length > 0"
                [matMenuTriggerFor]="menu.childMenu">
                {{child.group.name}}
                <map-dynamic-filter-menu #menu (outputEvent)="dynamicFilterMenuEmitter($event)"
                [renderFor]="'groupByAnalytics'" [analyticsInputParams]="{items: child.children, filterFieldSearchString: null, filterFieldSearchFilter: analyticsInputParams.filterFieldSearchFilter, selectedFieldsCount: analyticsInputParams.selectedFieldsCount}"></map-dynamic-filter-menu>
            </span>
            <!-- Handle leaf node menu items -->
            <span mat-menu-item *ngIf="!child.children || child.children.length === 0"
                [matMenuTriggerFor]="overlayMenu">{{child.group.name}}
            </span>

            <mat-menu #overlayMenu="matMenu" class="nested-menu nested-menu-leaf">
                <ng-container *ngFor="let childOverlay of child.overlays">
                    <ng-container *ngIf="childOverlay.geometryType === DatasetGeometryType.COMPLEX">
                        <span [matMenuTriggerFor]="childOverlayMenu" mat-menu-item> {{childOverlay.name}} </span>
                        <mat-menu #childOverlayMenu="matMenu" class="nested-menu nested-menu-leaf">
                            <ng-container
                                *ngFor="let field of childOverlay.fields | callback: analyticsInputParams.filterFieldSearchFilter">
                                <div *ngIf="!field.isHighCardinality && !field.isGenerated && field.baseType === DatasetFieldType.TEXT"
                                    (click)="$event.stopPropagation()" mat-menu-item
                                    [disabled]="!field.selected && analyticsInputParams.selectedFieldsCount == 2">
                                    <mat-checkbox [(ngModel)]="field.selected"
                                        (ngModelChange)="filterMenuFieldClick(field.selected, childOverlay, field)">
                                        <span *ngIf="field.displayName === null || field.displayName === undefined">{{field.name}}</span>
                                        <span *ngIf="field.displayName !== null || field.displayName !== undefined">{{field.displayName}}</span>
                                    </mat-checkbox>
                                </div>
                            </ng-container>
                        </mat-menu>
                    </ng-container>
                </ng-container>
            </mat-menu>
        </ng-container>
    </ng-container>


    <ng-container *ngIf="renderFor == 'viewTableColumn'">
        <ng-container *ngFor="let child of inputParams.items">
            <!-- Handle branch node menu items -->
            <span mat-menu-item *ngIf="child.children && child.children.length > 0"
                [matMenuTriggerFor]="menu.childMenu">
                {{child.group.name}}
                <map-dynamic-filter-menu #menu (outputEvent)="dynamicFilterMenuEmitter($event)"
                [renderFor]="'viewTableColumn'" [inputParams]="{items: child.children, overlaysFilterDistance: null, filterFieldSearchFilter: inputParams.filterFieldSearchFilter}"></map-dynamic-filter-menu>
            </span>
            <!-- Handle leaf node menu items -->
            <span mat-menu-item *ngIf="!child.children || child.children.length === 0"
                [matMenuTriggerFor]="overlayMenu">{{child.group.name}}
            </span>

            <mat-menu #overlayMenu="matMenu" class="nested-menu nested-menu-leaf">
                <ng-container *ngFor="let childOverlay of child.overlays">
                    <span [matMenuTriggerFor]="childOverlayMenu" mat-menu-item> {{childOverlay.name}} </span>
                    <mat-menu #childOverlayMenu="matMenu" class="nested-menu nested-menu-leaf">
                        <ng-container
                            *ngFor="let field of childOverlay.fields | callback: inputParams.filterFieldSearchFilter">
                            <div *ngIf="!field.isGenerated && !field.tags?.includes('ID') && field.displayInDropdowns"
                                (click)="$event.stopPropagation()" mat-menu-item>
                                <mat-checkbox [(ngModel)]="field.isProjected"
                                    (ngModelChange)="onProjectionChange(childOverlay, field)">
                                    <span *ngIf="field.displayName === null || field.displayName === undefined">{{field.name}}</span>
                                    <span *ngIf="field.displayName !== null || field.displayName !== undefined">{{field.displayName}}</span>
                                </mat-checkbox>
                            </div>
                        </ng-container>
                    </mat-menu>
                </ng-container>
            </mat-menu>
        </ng-container>
    </ng-container>
</mat-menu>
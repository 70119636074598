import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { NgZone } from '@angular/core';
import { CustomSnackbarComponent } from 'src/app/shared/custom-snackbar/custom-snackbar.component';
@Injectable({
    providedIn: 'root'
})
export class NotifService {

    constructor(private readonly snackBar: MatSnackBar, private zone: NgZone) {
    }

  private openCustomSnackBar(message: string, action: string, panelClass: string, icon?: string) {
    this.zone.run(() => {
      const config: MatSnackBarConfig = {
        duration: 4000,
        panelClass: panelClass // Add the appropriate CSS class
      };

      const snackBarRef = this.snackBar.openFromComponent(CustomSnackbarComponent, {
        data: { message, 
            iconType: icon ?  'image': 'svg', icon: icon, snackBarRef: null },
        ...config
      });

      (snackBarRef.instance as CustomSnackbarComponent).data.snackBarRef = snackBarRef;
      snackBarRef.afterDismissed().subscribe(() => {
      });

    });
  }

  success(message: string) {
    this.openCustomSnackBar(message, 'Close', 'success-snackbar');
  }

  error(message: string, shortMessage?: string) {
    this.openCustomSnackBar(message, 'Close', 'error-snackbar');
  }
}

<div class="slider-container">
    <div *ngIf="showControls && !scrolledToFirst" class="carousel-control-button control-left"  (click)="onClickLeft()">
        <div class="chevron-wrapper">
            <i  class=" chevron-icon fas fa-chevron-left"></i>
        </div>

    </div>
    <div class="slides" #slides>
        <ng-content select="[appSliderItem]" #sliderItem></ng-content>

    </div>
    <div class="carousel-control-button control-right" *ngIf="showControls  && !scrolledToLast"   (click)="onClickRight()">
        <div class="chevron-wrapper" >
            <i  class=" chevron-icon fas fa-chevron-right"></i>
        </div>

    </div>
</div>

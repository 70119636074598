import { ChangeDetectorRef, Component, ComponentRef, EventEmitter, HostListener, Input, OnInit, Output } from "@angular/core";
import { OverlaysService } from "../../../data-access-layer/global-overlays/overlays.service";
import { GroupWithOverlaysTreeNode } from "../../../model/overlay/group/group-with-overlays-tree-node";
import { Dataset } from "../../../model/dataset/dataset";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { RenderingOptions } from "../../../model/dataset/rendering/rendering-options";
import { DatapointConverterType } from "../../../model/dataset/rendering/datapoint-converter-options";
import { VisualizationType } from "../../../model/dataset/rendering/visualization-options";
import { ActivateOverlayEvent } from "../../../model/events/activate-overlay-event";
import { Account } from "../../../model/account/account";
import { UpdateOverlaySettingsEvent } from "../../../model/events/update-overlay-settings-event";
import { ImageOverlaysGroup } from "../../../model/overlay/external/image-overlays-group";
import { ImageOverlay } from "../../../model/overlay/external/image-overlay";
import { ActivateImageOverlayEvent } from "../../../model/events/activate-image-overlay-event";
import { forkJoin, fromEvent, ReplaySubject, Subject, Subscription } from "rxjs";
import { isEnabled } from "../../../../environments/environment";
import { DatapointsPageStateService } from "../datapoints-page-state.service";
import { DatapointsOverlayFilterService } from "../datapoints-overlay-filter/datapoints-overlay-filter.service";
import { MapInteractionMode } from "../map-interaction-mode";
import { Functionalities } from "../../../../environments/app-functionalities";
import { TessadataDatasetsByCountry } from "../../../core/tessadata/tessadata-datasets-by-country";
import { defaultMapStatus } from "../../../core/map/map.constants";
import {
    imageOverlaysGroup,
    interactiveOverlaysGroup,
} from "../../../core/aeris/AerisConsts";
import * as _ from "agile";
import { NotifService } from "../../../core/notification/notif.service";
import { UserStateService } from "../../../auth/user-state-service";
import { isUndefined } from "src/app/core/utils/util-master";
import { Crisis24Alert } from "src/app/model/datapoint/crisis24-alert";
import { MapStateService } from "src/app/map/map-state.service";
import { OpenWeather } from "src/app/model/datapoint/open-weather";
import { SidePanelComponent } from "src/app/core/side-panel/side-panel.component";
import { throttleTime } from "rxjs/operators";

const IS_PART_OF = Functionalities.OVERLAYS;

/**
 * This component stores the overlays with their rendering configurations.
 */
@Component({
    selector: "map-overlays-panel",
    templateUrl: "./datapoints-overlays.component.html",
    styleUrls: ["./datapoints-overlays.component.scss"],
})
export class DatapointsOverlaysComponent implements OnInit {
    isEnabled = isEnabled(IS_PART_OF);
    isExpanded = false;
    colorizationBoxIsExpanded = true;
    imageOverlayOpacityBoxIsExpended = false;
    colorizationBoxIsClosed = false;
    imageOverlayOpacityBoxIsClosed = false;

    @Input() account: Account;
    @Input() dataset: Dataset; // represents the location overlay in most cases
    @Input() isMapView: boolean;
    @Input() externalDatasets: TessadataDatasetsByCountry[];
    @Output() overlayToggled = new EventEmitter<ActivateOverlayEvent>();
    @Output() imageOverlayToggled =
        new EventEmitter<ActivateImageOverlayEvent>();
    @Output() overlaySettingsUpdated = new EventEmitter<ActivateOverlayEvent>();
    @Output() overlaySelected = new EventEmitter<any>();

    imageOverlayOpacity: number = 0.6;

    accountOverlaysByIds: Map<string, Dataset> = new Map();
    groupNodes: GroupWithOverlaysTreeNode[] = [];
    // tslint:disable-next-line:variable-name
    _selectedOverlay: Dataset;
    selectedImageOverlay: ImageOverlay;
    overlaysRenderingOptions: Map<string, RenderingOptions> = new Map(); // dataset id - settings

    imageOverlaysGroup: ImageOverlaysGroup;
    interactiveOverlaysGroup: ImageOverlaysGroup;
    showImageOverlays = true; // only for hiding image overlays
    showInteractiveOverlaysGroup = true;
    datasetChecked = true;

    inputHasDataSubject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
    externalDatasetsIsOpen = false;

    tessadataNodes: GroupWithOverlaysTreeNode[] = [];
    parentOverlay: any;

    @Input("accountOverlays")
    set accountOverlays(overlays: Dataset[]) {
        // where the account has access to
        if (overlays) {
            overlays.forEach((overlay) => {
                this.setRenderingOption(overlay);
            });
            this.inputHasDataSubject.next(true);
            this.inputHasDataSubject.complete();
        }
    }

    width = 296;
    grabber = false;
    dragWidth: number = this.width;
    x = 100;
    oldX = 0;
    subscription = new Subscription();
    closeModal$: Subject<boolean>;
    hideModal$: Subject<SidePanelComponent> = new Subject();
    onPanelFirst$: Subject<SidePanelComponent> = new Subject<SidePanelComponent>();
    destroy$: Subject<boolean>;
    componentRef: ComponentRef<any>;
    dropdownValueChanged$: Subject<string> = new Subject<string>();

    readonly TROPICAL_STROMS_OVERLAY_NAME: string = 'Tropical Storms (TS)';
    constructor(
        private readonly overlayService: OverlaysService,
        private readonly datapointsOverlayFilterService: DatapointsOverlayFilterService,
        private readonly datapointPageStateService: DatapointsPageStateService,
        private readonly notifService: NotifService,
        private readonly userStateService: UserStateService,
        private readonly mapStateService: MapStateService,
        private readonly changeDetectorRef: ChangeDetectorRef,
    ) {
        this.imageOverlaysGroup = imageOverlaysGroup;
        this.interactiveOverlaysGroup = interactiveOverlaysGroup;
        this.interactiveOverlaysGroup.overlays = _.orderBy(
            this.interactiveOverlaysGroup.overlays,
            "name"
        );
    }

    get selectedOverlay() {
        return this._selectedOverlay;
    }

    setRenderingOption(dataset: Dataset): void {
        // TODO gives error when colorizationFieldIndex is null, policies and claims
        this.accountOverlaysByIds.set(dataset.id, dataset);
        if (dataset.stylingOptions) {
            let colorizationField =
                dataset.fields[dataset.stylingOptions.colorizationFieldIndex];
            let colorizationFieldID = colorizationField
                ? colorizationField.id
                : null;
            this.overlaysRenderingOptions.set(dataset.id, {
                datasetStylingOptions: JSON.parse(
                    JSON.stringify(dataset.stylingOptions)
                ),
                converterOptions: {
                    type: DatapointConverterType.NONE,
                    datasetID: dataset.id,
                    fieldID: colorizationFieldID,
                },
                visualizationOptions: { type: VisualizationType.DEFAULT },
            });
        }
    }

    ngOnInit(): void {
        this.accountOverlaysByIds.set(this.dataset.id, this.dataset);
        this.setMainDatasetRenderingOptions();
        // Load External Overlay By Group
        this.loadExternalOverlayGroups();
        forkJoin(
            this.overlayService.getOverlayGroupsAsTree(this.account.id),
            this.inputHasDataSubject
        ).subscribe((res) => {
            if (res) {
                const overlayGroups = res[0];
                // this.groupNodes = overlayGroups;
                const information: GroupWithOverlaysTreeNode[] =
                    overlayGroups.filter(
                        (element) =>
                            element.group.name.toLowerCase() == "information"
                    );
                overlayGroups.forEach((element) => {
                    if (
                        information.length &&
                        element.group.name !== information[0].group.name
                    ) {
                        this.groupNodes.push(element);
                    } else {
                        this.groupNodes.push(element);
                    }
                });
                this.groupNodes.sort((a, b) =>
                    a.group.name > b.group.name ? -1 : 1
                );
                this.groupNodes = !isUndefined(information)
                    ? [...this.groupNodes, ...information]
                    : this.groupNodes;
                this.groupNodes = this.prepareFilterAcccountOverlays(
                    this.groupNodes
                );

                const tropicalStormsGroup = this.findGroupByName(this.groupNodes, this.TROPICAL_STROMS_OVERLAY_NAME);
                if(tropicalStormsGroup){
                    tropicalStormsGroup.isSelected = true;
                    this.groupWasChecked(tropicalStormsGroup, true, true)
                }


                // Hide Private Overlays for a not superadmin user until BE code for this will be implemented
                if (!this.userStateService.isSuperadmin) {
                    let index = this.groupNodes.findIndex(
                        (group) => group.group.name === "Private Overlays"
                    );
                    if (index !== -1) {
                        this.groupNodes.splice(index, 1);
                    }
                }
                //
                overlayGroups.forEach((rootGroup) => {
                    if (rootGroup.overlays.length === 0) {
                        rootGroup.disabled = true;
                    }
                    this.recursiveOperation(rootGroup, (group) => {
                        // Sort Overlays inside Group
                        group.overlays.sort((item1, item2) => {
                            if (item1 && item1.name && item2 && item2.name) {
                                return item1.name
                                    .toLowerCase()
                                    .localeCompare(item2.name.toLowerCase());
                            }
                        });
                        // Sort Children Of Group
                        if (group.children) {
                            group.children = _.orderBy(
                                group.children,
                                "group.name"
                            );
                        }
                        let counter = 0;
                        group.overlays.forEach((overlay) => {
                            overlay.isSelected =
                                this.datapointsOverlayFilterService.getOverlayFilter(
                                    overlay.id
                                ) !== undefined;
                            if (this.accountOverlaysByIds.get(overlay.id)) {
                                counter++;
                            }
                            if (counter === 0) {
                                group.disabled = true;
                            } else {
                                rootGroup.disabled = false;
                            }
                            group.count = counter;
                        });
                        //   group.isSelected = this.setParentGroupAsDefaultSelected(group);
                    });
                });
            }
        });

        this.setRenderingOption(this.dataset);
    }

    findGroupByName(nodes: GroupWithOverlaysTreeNode[], name: string): GroupWithOverlaysTreeNode | undefined {
        for (const node of nodes) {
            if (node.group.name === name) {
                return node;
            }
            const foundInChildren = this.findGroupByName(node.children, name);
            if (foundInChildren) {
                return foundInChildren;
            }
        }
        return undefined;
    }

    prepareFilterAcccountOverlays(overlays) {
        overlays.forEach((element, key1) => {
            this.parentOverlay = element;
            if (element.children.length <= 0 && element.overlays.length <= 0) {
                overlays.splice(key1, 1);
            } else if (element.children.length > 0) {
                this.recursiveFilterAccountOverlay(element);
            }
            if (element.children.length <= 0 && element.overlays.length <= 0) {
                overlays.splice(key1, 1);
            }
        });
        return overlays;
    }

    recursiveFilterAccountOverlay(element) {
        if (element.children.length) {
            let groupIds = [];
            element.children.forEach((sub_element, key) => {
                if (
                    sub_element.children.length <= 0 &&
                    sub_element.overlays.length <= 0
                ) {
                    groupIds.push(sub_element.group.id);
                } else if (sub_element.children.length > 0) {
                    element.children = element.children.filter(
                        (params) => !groupIds.includes(params.group.id)
                    );
                    this.recursiveFilterAccountOverlay(sub_element);
                    return;
                }
            });

            if (groupIds.length) {
                element.children = element.children.filter(
                    (params) => !groupIds.includes(params.group.id)
                );
                this.recursiveFilterAccountOverlay(this.parentOverlay);
            }
        }
    }

    loadExternalOverlayGroups = () => {
        forkJoin(
            this.overlayService.getExternalOverlayGroupsAsTree(this.account.id),
            this.inputHasDataSubject
        ).subscribe((res) => {
            if (res) {
                const ExternalGroups = res[0];
                if (ExternalGroups && ExternalGroups.length > 0) {
                    this.tessadataNodes = ExternalGroups;
                    ExternalGroups.forEach((rootGroup) => {
                        if (rootGroup.overlays.length === 0) {
                            rootGroup.disabled = true;
                        }
                        this.recursiveOperation(rootGroup, (group) => {
                            let counter = 0;
                            group.overlays.forEach((overlay) => {
                                // Added DatasetId as id and datasetLabel as name
                                if (overlay.datasetId) {
                                    overlay.id = overlay.datasetId;
                                    overlay.name = overlay.datasetLabel;
                                }
                                if (
                                    this.accountOverlaysByIds.get(
                                        overlay.datasetId
                                    )
                                ) {
                                    counter++;
                                }
                                if (counter === 0) {
                                    group.disabled = true;
                                } else {
                                    group.disabled = false;
                                    rootGroup.disabled = false;
                                }
                                group.count = counter;
                            });
                        });
                    });
                }
            }
        });
    };

    setMainDatasetRenderingOptions() {
        this.dataset.isSelected = true;
        if (this.dataset.stylingOptions) {
            let colorizationField =
                this.dataset.fields[
                    this.dataset.stylingOptions.colorizationFieldIndex
                ];
            let value: RenderingOptions = {
                datasetStylingOptions: JSON.parse(
                    JSON.stringify(this.dataset.stylingOptions)
                ),
                converterOptions: {
                    type: DatapointConverterType.NONE,
                    datasetID: this.dataset.id,
                    fieldID: colorizationField ? colorizationField.id : null,
                },
                visualizationOptions: { type: VisualizationType.DEFAULT },
            };
            this.overlaysRenderingOptions.set(this.dataset.id, value);
        }
    }

    getOverlaysRenderingOptions(): Map<string, RenderingOptions> {
        return this.overlaysRenderingOptions;
    }

    ExternalgroupWasChecked(node, isChecked: boolean) {
        node.isSelected = isChecked;
    }

    groupWasChecked(node, isChecked: boolean, isDefaultCall: boolean = false) {
        // TODO maybe make a delay between events if needed
        let operation: (group: GroupWithOverlaysTreeNode) => void; // will be applied recursively
        operation = (group) => {
            group.overlays.forEach((overlay) => {
                if (!this.accountOverlaysByIds.get(overlay.id)) {
                    // we ignore the overlays where there's no access
                    return;
                }
                if (isChecked && !overlay.isSelected) {
                    // we must activate it
                    overlay.isSelected = true;
                } else if (!isChecked && overlay.isSelected) {
                    // disableOverlay it
                    overlay.isSelected = false;
                }
                this.overlayToggled.next({
                    active: isChecked,
                    datasetID: overlay.id,
                    renderingOptions: this.overlaysRenderingOptions.get(
                        overlay.id
                    ),
                    isDefaultCall: isDefaultCall
                });
            });
        };
        this.recursiveOperation(node, operation);
    }

    overlayWasToggled(
        overlay: Dataset,
        groupNode: GroupWithOverlaysTreeNode,
        activate: boolean
    ) {
        if (overlay.hasOwnProperty("datasetId")) {
            //DatasetId Present in case of External Overlay
            this.externalDatasetWasToggled(overlay);
        } else {
            if (!activate && groupNode) {
                this.datapointsOverlayFilterService.onUncheckLayer.next(
                    overlay
                );
                if (overlay.id === Crisis24Alert.getId()) {
                    this.mapStateService.removeCrisisCircle(true);
                }
                if (overlay.id === OpenWeather.getId()) {
                    this.mapStateService.setRemoveOpenWeatherPolygon(true);
                }
                groupNode.isSelected = false;
            }
            this.overlayToggled.emit({
                datasetID: overlay.id,
                active: activate,
                renderingOptions: this.overlaysRenderingOptions.get(overlay.id),
            });
        }
    }

    recursiveOperation(
        groupNode: GroupWithOverlaysTreeNode,
        operation: (node: GroupWithOverlaysTreeNode) => void
    ) {
        operation(groupNode);
        groupNode.children.forEach((child) => {
            // Sort the Overlay Of children of each Parent
            if (child.overlays) {
                child.overlays.sort((item1, item2) => {
                    if (item1 && item1.name && item2 && item2.name) {
                        return item1.name
                            .toLowerCase()
                            .localeCompare(item2.name.toLowerCase());
                    } else {
                        return item1.datasetLabel
                            .toLowerCase()
                            .localeCompare(item2.datasetLabel.toLowerCase());
                    }
                });
            }
            this.recursiveOperation(child, operation);
        });
    }

    selectOverlay(overlay: Dataset, event: MouseEvent) {
        event.preventDefault(); // to cancel checkbox event
        if (this.accountOverlaysByIds.get(overlay.id)) {
            // the user has access to it
            if (overlay.stylingOptions) {
                this._selectedOverlay = overlay;
                this.colorizationBoxIsExpanded = true;
                this.colorizationBoxIsClosed = false;
                this.overlaySelected.emit(this.selectedOverlay);
            } else {
                this.notifService.error(
                    "Please check and click on the map to view the overlay"
                );
            }
        }
    }

    onOverlaySettingsUpdate(options: RenderingOptions) {
        // TODO
        let datasetID = this.selectedOverlay.id;
        this.overlaysRenderingOptions.set(datasetID, options);
        this.overlaySettingsUpdated.emit({
            datasetID: datasetID,
            renderingOptions: options,
            active: true,
        });
    }

    onExpandedClick() {
        if (this.isMapView && this.isEnabled) {
            this.isExpanded = !this.isExpanded;
        }
    }

    imageGroupWasChecked(
        imageGroup: ImageOverlaysGroup,
        checkboxEvent: MatCheckboxChange,
        interactive: boolean = false
    ) {
        imageGroup.isSelected = checkboxEvent.checked;
        imageGroup.overlays.forEach((overlay) => {
            if (checkboxEvent.checked && !overlay.isSelected) {
                // we must activate it
                overlay.isSelected = true;
            } else if (!checkboxEvent.checked && overlay.isSelected) {
                // disableOverlay it
                overlay.isSelected = false;
            }

            this.imageOverlayToggled.emit({
                active: checkboxEvent.checked,
                overlay: overlay,
                interactive,
                opacity: this.imageOverlayOpacity,
            });
        });
    }

    imageOverlayWasToggled(
        overlay: ImageOverlay,
        activate: boolean,
        interactive: boolean = false
    ) {
        this.imageOverlayToggled.emit({
            active: activate,
            overlay: overlay,
            interactive,
            opacity: this.imageOverlayOpacity,
        });
    }

    onSelectionShapeDrawn() {
        this.overlayWasToggled(this.dataset, null, true);
    }

    selectImageOverlay(overlay: ImageOverlay, event: MouseEvent) {
        event.preventDefault(); // to cancel checkbox event
        this.selectedImageOverlay = overlay;
        this.imageOverlayOpacityBoxIsExpended = true;
        this.imageOverlayOpacityBoxIsClosed = false;
    }

    resetRenderingOptions($event: {
        dataset: Dataset;
        renderingOptions: RenderingOptions;
    }) {
        this.setRenderingOption($event.dataset);
        this.onOverlaySettingsUpdate(
            this.overlaysRenderingOptions.get(this.selectedOverlay.id)
        );
    }

    get isDatasetChecked() {
        return this.dataset.isSelected;
    }

    externalDatasetWasToggled(overlay) {
        if (this.tessadataNodes.length > 0) {
            const tessaDataGrp = this.tessadataNodes[0];
            tessaDataGrp.children.forEach((children) => {
                if (children.overlays.length > 0 && !children.disabled) {
                    children.overlays.forEach((dataset) => {
                        if (this.accountOverlaysByIds.get(dataset.id)) {
                            if (overlay.datasetId !== dataset.datasetId) {
                                dataset.isSelected = false;
                            }
                        }
                    });
                }
            });
        }

        let currentState =
            this.datapointPageStateService.getMapInteractionStatus();
        if (
            overlay.isSelected &&
            (currentState.mode === MapInteractionMode.DEFAULT ||
                currentState.mode ===
                    MapInteractionMode.INTERACTION_DATASET_ACTIVE)
        ) {
            // we enable it
            this.datapointPageStateService.updateMapMode({
                mode: MapInteractionMode.INTERACTION_DATASET_ACTIVE,
                externalDatasetId: overlay.datasetId,
                externalDatasetName: overlay.datasetLabel,
            });
        }
        if (
            !overlay.isSelected &&
            currentState.mode === MapInteractionMode.INTERACTION_DATASET_ACTIVE
        ) {
            // we disable it
            this.datapointPageStateService.updateMapMode(defaultMapStatus);
        }
    }

    closeColorizationBox(): void {
        this.colorizationBoxIsClosed = true;
    }

    closeBox(): void {
        this.imageOverlayOpacityBoxIsClosed = true;
    }

    setParentGroupAsDefaultSelected(group: GroupWithOverlaysTreeNode) {
        const overlayLength = group.overlays.length;
        const selectedOverlays = group.overlays.filter(
            (element) => element.isSelected == true
        );
        return overlayLength == selectedOverlays.length;
    }

    applyChangeDetection() {
        this.changeDetectorRef.detectChanges();
    }

    onMouseMove(event: MouseEvent) {
        if (!this.grabber) {
            return;
        }

        this.resizer(event.clientX - this.oldX);
        this.oldX = event.clientX;
        this.applyChangeDetection();
    }

    @HostListener('document:mouseup', ['$event'])
    onMouseUp(event: MouseEvent) {
        if (this.width) {
            this.dragWidth = Math.min(Math.max(this.dragWidth, this.width), window.innerWidth);
        }

        this.grabber = false;
        this.subscription.unsubscribe();
    }

    resizer(offsetX: number) {
        if(this.dragWidth >= this.width){
            this.dragWidth += offsetX;
        }
    }

    onMouseDown(event: MouseEvent) {
        this.grabber = true;
        this.oldX = event.clientX;

        this.subscription.unsubscribe();
        const mousemoveGlobal = fromEvent<MouseEvent>(document, 'mousemove');
        this.subscription = mousemoveGlobal
            .pipe(throttleTime(10))
            .subscribe((e) => this.onMouseMove(e));
    }
}

import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'custom-snackbar',
  template: `
  <div style="display: flex; justify-content: space-between;">
    <div class="snackbar-content">
      <div  class="snackbar-icon">
        <img  src="../../../assets/icons/newIcons/icon-alert.svg" alt="Icon" style="width: 24px; height: 24px; color: #ffffff;" class="snackbar-alert">
      </div>
      <span class="snackbar-message">{{ data.message }}</span>
    </div>
    <button mat-icon-button class="snackbar-action" (click)="dismiss()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  `,
  styles: [`
    .snackbar-content {
      display: flex;
      align-items: center;
    }
    .snackbar-icon {
      width: 24px;
      height: 24px;
      margin-right: 12px;
    }
    .snackbar-message {
      flex-grow: 1;
    }
    .snackbar-action {
      color: black;
    }
  `]
})
export class CustomSnackbarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {}

  dismiss(): void {
    this.data.snackBarRef.dismiss();
  }
}

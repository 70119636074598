<ng-container *ngIf="datasetStylingOptions">
	<div class="row">
		<div class="text-lightdark">View Type</div>
		<div>
			<mat-form-field>
				<input value="NONE" matInput readonly/>
			</mat-form-field>
		</div>
	</div>
	<div class="row">
		<div class="text-lightdark">Scale Type</div>
		<div>
			<mat-form-field>
				<mat-select [(ngModel)]="datasetStylingOptions.type" (ngModelChange)="onScaleTypeChanged()">
					<mat-option *ngFor="let type of colorScaleTypes"
					            [value]="type"
                                [disabled]="dataset.id !== null && (type === ColorScaleType.INTERVAL && dataset.fields[datasetStylingOptions.colorizationFieldIndex].baseType !== DatasetFieldType.NUMBER)">
						{{ type | titlecase }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
	</div>
	<div class="row">
		<div class="text-lightdark">Colorized By</div>
		<div>
                <mat-form-field>
                    <mat-select [(ngModel)]="datasetStylingOptions.colorizationFieldIndex" (ngModelChange)="onColorizeByChanged()">
                        <ng-container *ngFor="let field of fields; let index = index">
                            <mat-option *ngIf="field.isHighCardinality !== true"
                                        [value]="index"
                                        [disabled]="dataset.id !== null && ((datasetStylingOptions.type === ColorScaleType.INTERVAL && field.baseType !== DatasetFieldType.NUMBER) ||
                                          (field.baseType !== DatasetFieldType.TEXT && field.baseType !== DatasetFieldType.NUMBER))">
                            {{ field.name }}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
        </div>
	</div>
	<div *ngIf="datasetGeometryType === DatasetGeometryType.POINT" class="row">
		<div class="text-lightdark">Custom Marker</div>
		<div>
			<mat-form-field>
				<mat-label style="top: -6px;">Custom Marker</mat-label>
				<mat-select [(ngModel)]="datasetStylingOptions.markerId">
					<mat-option>None</mat-option>
					<mat-option *ngFor="let marker of markers" [value]="marker.id" style="display: flex; justify-content: space-between">
						<ng-container>
							<div style="display: inline-block; max-width: 160px; overflow: hidden; text-overflow: ellipsis">{{marker.filename}}</div>
							<div style="display: inline-block; padding-left: 7px; padding-right: 7px; vertical-align: super;"><img [src]="marker.markerSrc" style="height: 0.875rem;" alt="marker"/></div>
						</ng-container>
						<div (click)="deleteMarker(marker)" style="float:right; display: inline-block;">
							<!-- Trash-Icon -->
							<svg style="margin-right: 4px;" width="16" height="16" viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg">
							<g fill="none" fill-rule="evenodd">
								<path
									d="M18.5 6.5V19a2 2 0 0 1-2 2h-9a2 2 0 0 1-2-2V6.5M9 17h6M9 13h6M8.5 6.5v-2A1.5 1.5 0 0 1 10 3h4a1.5 1.5 0 0 1 1.5 1.5v2M20 6.5H4"
									stroke="#002d57" stroke-width="1.5" stroke-linecap="round"
									stroke-linejoin="round" class="icon-actions" />
								<path d="M0 0h24v24H0z" />
							</g>
						</svg>
						</div>
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
	</div>
	<div class="row">
		<div class="text-lightdark" style="padding-top: 1rem;">Opacity</div>
		<div class="text-lightdark" style="padding-top: 1rem;">Low
			<mat-slider [(ngModel)]="datasetStylingOptions.backgroundOpacity" color=accent
			            max="1" min="0"
			            step="0.1">
			</mat-slider>
			High
		</div>
	</div>
	<div *ngIf="datasetGeometryType === DatasetGeometryType.POINT" class="row">
		<div class="text-lightdark">Add Marker</div>
		<div class="add-marker-row">
            <span (click)="markerInput.click()" class="add-color-icon">
                <mat-icon fontIcon="fa-paperclip" fontSet="fas"></mat-icon>
            </span>

			<input #markerInput (change)="onAddNewMarker($event)" accept="image/svg+xml"
			       style="display: none" type="file">

			<span (click)="saveMarker()" *ngIf="uploadedMarker" class="add-color-icon">
                <mat-icon fontIcon="fa-check" fontSet="fas"></mat-icon>
            </span>
			<span *ngIf="uploadedMarker" style="margin: 6px 0 0 8px;">{{uploadedMarker.name}} </span>
		</div>
	</div>
	<div
			*ngIf="datasetStylingOptions.type === ColorScaleType.CONSTANT || datasetStylingOptions.type === ColorScaleType.INTERVAL"
			class="row">
		<div class="text-lightdark">Color</div>
		<div class="color-gradient-container">
			<div class="color-picker-wrapper">
				<input class="color-picker-input"
					   cpColorMode="true"
					   [style.background]="color"
					   (colorPicker)="color"
					   (colorPickerChange)="onConstantColorChanged($event)"
					   [ngStyle]="{'background': ColorUtils.colorToBase64(datasetStylingOptions.defaultColor)}"
				>
			</div>
		</div>
	</div>
	<div *ngIf="datasetStylingOptions.type === ColorScaleType.GRADIENT || datasetStylingOptions.type === ColorScaleType.FIXED" class="row">
		<div style="align-self: stretch;">Colors</div>
		<div class="color-gradient-container">
				<ng-container *ngFor="let color of datasetStylingOptions.colors; let i = index;">
					<div class="color-square" style="
					position: relative;
					top: 0px;
					min-width: 16px !important;
					width: 16px !important;
					height: 17px !important;
					border-radius: 2px;"
					>
						<input class="color-picker-input"
							   cpColorMode="true"
							   (colorPicker)="color"
							   [style.background]="color"
							   (colorPickerChange)="onColorPickerChange(color, $event)"
							   [ngStyle]="{'background': ColorUtils.colorToBase64(color)}"
							   >
						<span (click)="onRemoveColor(i, $event)" class="remove-color-icon">
							<mat-icon>remove</mat-icon>
						</span>
					</div>
				</ng-container>
			<span (click)="addColors()" class="add-color-icon">
				<mat-icon style="position: relative !important;bottom: 7px !important; right: 7.5px !important;">add</mat-icon>
			</span>
		</div>
	</div>
	<div *ngIf="datasetStylingOptions.type === ColorScaleType.INTERVAL" class="row row-interval">
		<div>Custom Intervals</div>
		<div>
			<div *ngIf="datasetStylingOptions.intervalOptions?.length" class="color-interval-content">
				<div *ngFor="let interval of datasetStylingOptions.intervalOptions; let index = index" class="color-interval-row">
					<div [ngStyle]="{'background': ColorUtils.colorToBase64(interval.color)}"
					     class="color-square"></div>
					<div>[{{ (interval.minValue | number) || 'below' }}, {{ (interval.maxValue | number) || 'any' }}]</div>
                    <span *ngIf="index === datasetStylingOptions.intervalOptions.length - 1" (click)="onRemoveColorInterval(index)" class="remove-color-interval" title="remove">
                        <mat-icon>remove</mat-icon>
                    </span>
				</div>
			</div>
			<form [formGroup]="intervalForm" class="color-interval-row">
				<div class="color-square">
					<input class="color-picker-input" 
						   cpToggle="true" 
						   formControlName="color"
						   colorPicker="color"
						   (colorPickerChange)="onColorPickerIntervalChange($event)"
						   [style.background]="intervalForm.get('color').value" />
				</div>
				<input formControlName="minValue" matInput placeholder="min" type="number" autocomplete="off">
				<span>to</span>
				<input formControlName="maxValue" matInput placeholder="max" type="number" autocomplete="off">
				<span (click)="addInterval()" class="add-color-icon">
					<mat-icon style="position: relative !important;bottom: 7px !important; right: 7.5px !important;">add</mat-icon>
				</span>
			</form>			
		</div>
	</div>
</ng-container>

// Using new util master in place of util in @types@node since all the below in previously used util are now deprecated
export function isObject(value: any) {
    return value !== null && typeof value === "object";
}
export function isNumber(value: any) {
    return value !== null && typeof value === "number";
}
export function isUndefined(value: any) {
    return value === undefined;
}
export function isNullOrUndefined(value: any) {
    return value === null || value === undefined;
}
export function isNull(value: any) {
    return value === null;
}

export function sortArrayByName(list) {
    if (list[0]?.name) {
        list.sort((a, b) => a.name.localeCompare(b.name));
    }
    return list;
}

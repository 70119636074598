<div class="form-container">
	<form *ngIf="updateIntegrationClientForm" [formGroup]="updateIntegrationClientForm">
		<mat-form-field>
			<mat-label>Platform</mat-label>
			<input matInput formControlName="platform">
			<mat-error *ngIf="updateIntegrationClientForm.controls.platform.hasError('required') ">
				Platform is required
			</mat-error>
		</mat-form-field>
		<mat-form-field>
			<mat-label>Client</mat-label>
			<input matInput formControlName="platformClient">
			<mat-error *ngIf="updateIntegrationClientForm.controls.platformClient.hasError('required') ">
				Client is required
			</mat-error>
		</mat-form-field>
		<mat-form-field>
			<mat-label>Account key</mat-label>
			<input matInput formControlName="accountKey">
			<mat-error *ngIf="updateIntegrationClientForm.controls.accountKey.hasError('required') ">
				Account key is required
			</mat-error>
		</mat-form-field>
		<mat-form-field>
			<mat-label>Account password</mat-label>
			<input matInput formControlName="accountPassword">
			<mat-error *ngIf="updateIntegrationClientForm.controls.accountPassword.hasError('required') ">
				Account password is required
			</mat-error>
		</mat-form-field>
		<mat-form-field>
			<mat-label>Account</mat-label>
			<input matInput formControlName="accountName">
		</mat-form-field>
	</form>
	<button (click)="submitIntegratedClient()" [disabled]="updateIntegrationClientForm?.pristine" mat-flat-button type="submit"
	        color="accent" class="map-button">Submit
	</button>
</div>

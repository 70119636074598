<div class="form-container">
    <form *ngIf="currentAccount"
        [formGroup]="createMemberForm">
        <mat-form-field>
            <mat-label>Member Email</mat-label>
            <input matInput
                trimValue
                formControlName="email"
                placeholder="Type here" name="member-email" autocomplete="off"/>
            <mat-error class="error"
                *ngIf="createMemberForm.get('email').touched && createMemberForm.get('email').errors?.pattern">
                Please enter a valid email address.
            </mat-error>
                <mat-error *ngIf="createMemberForm.get('email').touched && createMemberForm.get('email').errors?.required" class="error">
                    Member email is required.
                </mat-error>
                <mat-error *ngIf="createMemberForm.get('email').touched && createMemberForm.get('email').errors?.maxlength" class="error">
                    Exceed the maximum length.
                </mat-error>
        </mat-form-field>
        <div>
            <mat-checkbox formControlName="isMember" class="ripple-checkbox">Temporary Member</mat-checkbox>
        </div>
        <mat-form-field *ngIf="createMemberForm.get('isMember').value === true">
            <mat-label>Removing Date</mat-label>
            <input matInput
                placeholder="Choose a date"
                [matDatepicker]="picker"
                [min]="tomorrow"
                formControlName="deleteDate"
                readonly/>
            <mat-datepicker-toggle matSuffix
                [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error class="error"
                *ngIf="createMemberForm.get('deleteDate').touched && createMemberForm.get('deleteDate').errors?.required">
                Valid Date is required.
            </mat-error>
        </mat-form-field>
        <div *ngIf="createMemberForm.get('isMember').value === true && createMemberForm.controls.deleteDate.value && createMemberForm.valid" class="fs-normal">
            (Will expire in {{DateUtils.daysBetweenDates(today, createMemberForm.controls.deleteDate.value) + 1}} days)
        </div>
    </form>

    <button (click)="createMember()"
        [disabled]="createMemberForm.invalid"
        color="accent"
        mat-flat-button>
        Create
    </button>
</div>

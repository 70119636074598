import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';
import { OverlaysService } from '../../data-access-layer/global-overlays/overlays.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DatasetService } from 'src/app/data-access-layer/dataset/dataset.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Dataset } from 'src/app/model/dataset/dataset';
import { EditOverlayControlsConfig } from '../overlays.constants';
import { ColorScaleType } from '../../model/dataset/rendering/color-scale-type';
import { NotifService } from '../../core/notification/notif.service';
import { DatasetGeometryType } from '../../model/dataset/dataset-geometry-type';
import { DatasetConfigComponent } from '../../shared/dataset-config/dataset-config.component';
import { GroupWithOverlaysTreeNode } from '../../model/overlay/group/group-with-overlays-tree-node';
import { OverlayGroup } from '../../model/overlay/group/overlay-group';
import { DatasetType } from '../../model/dataset/dataset-type';
import { AccountServiceState } from '../../shared/services/account-service-state';
import {checkIntervalsValidity} from '../../shared/validators/colorization/colorization.validator';
import {RenderingErrorCodes} from '../../model/dataset/rendering/rendering-error-codes';
import { ShowInMap } from 'src/app/model/dataset/dataset-config-filter.enum';

@Component({
    selector: 'map-edit-overlay',
    templateUrl: './edit-overlay.component.html',
    styleUrls: ['./edit-overlay.component.scss']
})
export class EditOverlayComponent implements OnInit {
    @ViewChild('datasetConfig') datasetConfig: DatasetConfigComponent;
    editOverlayForm: UntypedFormGroup;
    dataset: Dataset;
    thematicDatasets: Dataset[];
    isThematicMapOverlay: boolean;
    groups: OverlayGroup[];
    // @Input () accountId: number = null;
    // @Input () type: DatasetType;
    @HostBinding('class') class = 'page';

    @Input()
    set accountId(id: number) {
        if (this.editOverlayForm) {
            this.editOverlayForm.controls.accountID.setValue(id);
        }
    }

    @Input()
    set type(type: DatasetType) {
        if (this.editOverlayForm) {
            this.editOverlayForm.controls.type.setValue(type);
        }
    }

    constructor(
        private readonly route: ActivatedRoute,
        private readonly datasetService: DatasetService,
        private readonly accountServiceState: AccountServiceState,
        private readonly overlaysService: OverlaysService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly notifService: NotifService,
    ) {
    }

    ngOnInit(): void {
        this.accountServiceState.emitOnInitEditOverlay();
        this.fetchDatasetAndThematicOverlays();
    }

    fetchDatasetAndThematicOverlays() {
        this.datasetService.getDataset(this.getDatasetIDFromUrl()).subscribe((overlay: any) => {
            this.overlaysService.getAllOverlayGroups().subscribe((groups) => {
                this.groups = this.flatten(groups).map((groupNode) => {
                    return groupNode.group;
                });
            });
            this.dataset = overlay;
            this.isThematicMapOverlay = this.dataset.thematicMapSettings ? this.dataset.thematicMapSettings.isThematicMapDataset : false;
            this.initFormGroup(overlay);
            this.datasetService.getThematicMapDatasets().subscribe(datasets => {
                let currentDataset = datasets.find(d => d.id === this.dataset.id);
                if (currentDataset) {
                    datasets.splice(datasets.indexOf(currentDataset), 1);
                }
                this.thematicDatasets = datasets;
            });
        }, (err) => {
            console.log(err);
        });

    }

    getDatasetIDFromUrl(): string {
        return this.route.snapshot.paramMap.get('datasetId');
    }

    initFormGroup(dataset: Dataset) {
        this.editOverlayForm = this.formBuilder.group(new EditOverlayControlsConfig(this.formBuilder, this.dataset));
        this.setEditOverlayFormValidators();
    }

    get datasetType() {
        return DatasetType;
    }
    public findInvalidControls() {
        const invalid = [];
        const controls = this.editOverlayForm.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                invalid.push(name);
            }
        }
        return invalid;
    }

    updateOverlay(): void {
        this.datasetConfig.tableForm.control.markAllAsTouched();
        this.editOverlayForm.markAllAsTouched();
        if (this.isThematicSettingsInvalid()) {
            return;
        }
        let  invalidAttribute = '';
        if (this.dataset.id !== null) {
            invalidAttribute = checkIntervalsValidity(this.dataset, this.dataset.stylingOptions.intervalOptions);
            if (!invalidAttribute) {
                if (this.datasetConfig.tableForm.valid && this.editOverlayForm.valid) {
                    this.prepareDataForUpdate();
                    let form = this.editOverlayForm.value;
                    form.geometryShardCount = form.geometryShardCount / 10;
                    const request = {
                        ...form,
                        fields: this.editOverlayForm.value.dataset.fields,
                        stylingOptions: {
                            ...this.dataset.stylingOptions
                        }
                    };
                    delete request.formBuilder;
                    delete request.dataset;
                    this.datasetService.updateDataset(request).subscribe((Overlay) => {
                        this.fetchDatasetAndThematicOverlays();
                        this.notifService.success('Successfully updated overlay');
                    }, error => {
                        this.notifService.error('Not updated... You might named fields exactly the same. Please check.');
                    });
                }
            } else {
                switch (invalidAttribute) {
                    case RenderingErrorCodes.COLORS:
                        this.notifService.error('You must select some colors for the colorization');
                        return;
                    case RenderingErrorCodes.FIELD:
                        this.notifService.error('You must choose a field for the colorization');
                        return;
                    case RenderingErrorCodes.INTERVALS:
                        this.notifService.error('You must define at least one interval');
                        return;
                    case RenderingErrorCodes.INTERVALS_OVERLAP:
                        this.notifService.error('The defined intervals must not overlap');
                        return;
                    case RenderingErrorCodes.DUPLICATE_INTERVAL:
                        this.notifService.error('The defined intervals must not have duplicates');
                        return;
                }
            }
        }
    }

    get DatasetGeometryType() {
        return DatasetGeometryType;
    }

    // TODO: is there any way not to hardcode the form controls?
    private setEditOverlayFormValidators() {
        let typeFormControl = this.editOverlayForm.get('stylingOptions').get('type');
        typeFormControl.valueChanges
            .subscribe(type => {
                if (type !== ColorScaleType.CONSTANT) {
                    let colorizationFieldFormControl = this.editOverlayForm.get('stylingOptions').get('colorizationFieldIndex');
                    colorizationFieldFormControl.setValidators(Validators.required);
                    colorizationFieldFormControl.updateValueAndValidity();

                }
            });

        let thematicMapFormControl = this.editOverlayForm.get('thematicMapSettings').get('isThematicMapDataset');
        thematicMapFormControl.valueChanges
            .subscribe(isThematicMap => {
                if (isThematicMap) {
                    let parentDatasetIdControl = this.editOverlayForm.get('thematicMapSettings').get('parentDatasetId');
                    let parentLinkFieldIdControl = this.editOverlayForm.get('thematicMapSettings').get('parentLinkFieldId');
                    let mainThematicFieldIdControl = this.editOverlayForm.get('thematicMapSettings').get('mainThematicFieldId');

                    // mainThematicFieldIdControl.setValidators(Validators.required);
                    mainThematicFieldIdControl.updateValueAndValidity();
                    if (parentDatasetIdControl && parentDatasetIdControl.value) {
                        // parentLinkFieldIdControl.setValidators(Validators.required);
                        parentLinkFieldIdControl.updateValueAndValidity();
                    }
                    if (parentLinkFieldIdControl && parentLinkFieldIdControl.value) {
                        // parentDatasetIdControl.setValidators(Validators.required);
                        parentLinkFieldIdControl.updateValueAndValidity();
                    }
                }
            });
    }

    private prepareDataForUpdate() {
        let isThematicMap = this.editOverlayForm.get('thematicMapSettings').get('isThematicMapDataset').value;
        if (!isThematicMap) {
            this.editOverlayForm.get('thematicMapSettings').get('parentDatasetId').setValue(null);
            this.editOverlayForm.get('thematicMapSettings').get('parentLinkFieldId').setValue(null);
            this.editOverlayForm.get('thematicMapSettings').get('mainThematicFieldId').setValue(null);
        }
    }

    flatten(groupNodes: GroupWithOverlaysTreeNode[]) {
        let flatNodes = [];
        groupNodes.forEach(rootGroup => {
            this.recursiveOperation(rootGroup, group => {
                flatNodes.push(group);
            });
        });
        return flatNodes;
    }

    recursiveOperation(groupNode: GroupWithOverlaysTreeNode, operation: (node: GroupWithOverlaysTreeNode) => void) {
        operation(groupNode);
        groupNode.children.forEach(child => this.recursiveOperation(child, operation));
    }

    navigateBackToPrivateOverlays() {
    }

    private isThematicSettingsInvalid() {
        if (this.editOverlayForm.controls.thematicMapSettings.get('isThematicMapDataset').value === true) {
            const thematicId = this.editOverlayForm.controls.thematicMapSettings.get('mainThematicFieldId').value;
            if (thematicId !== null && thematicId !== undefined) {
                return false;
            }
            this.notifService.error('You must choose a main thematic field.');
            this.editOverlayForm.controls.thematicMapSettings.get('mainThematicFieldId').markAsTouched();
            this.editOverlayForm.controls.thematicMapSettings.get('mainThematicFieldId').setErrors({ incorrect: true });
            return true;
        }
        return false;
    }
    resetThematicDatasetSettings() {
        let defaultForm = this.formBuilder.group(new EditOverlayControlsConfig(this.formBuilder, this.dataset));
        this.editOverlayForm.controls.thematicMapSettings.reset(defaultForm.controls.thematicMapSettings.value);
    }
}

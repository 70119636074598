import { Component, Input, ViewChild } from "@angular/core";
import {
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from "@angular/forms";
import { MatMenuTrigger } from "@angular/material/menu";
import { isUndefined } from "src/app/core/utils/util-master";
import { AccumulationsDataService } from "src/app/data-access-layer/accumulations/accumulations-data.service";
import { DatapointsFilterService } from "src/app/dataset/datapoints/datapoints-filter.service";
import { DatapointFilter } from "src/app/model/datapoint/filter/datapoint-filter";
import { Dataset } from "src/app/model/dataset/dataset";
import { DatasetFieldScope } from "src/app/model/dataset/dataset-field-scope";
import { DatasetFieldType } from "src/app/model/dataset/dataset-field-type";
import { LegendInfo } from "src/app/model/legend/legend-info";
import { AccumulationsService } from "src/app/shared/services/accumulations.service";

@Component({
    selector: "map-heatmap",
    templateUrl: "./heatmap.component.html",
    styleUrls: ["./heatmap.component.scss"],
})
export class HeatmapComponent {
    filter: DatapointFilter;

    @Input() dataset: Dataset;

    @Input() dataSource: any;
    @Input() treeControl: any;
    @Input() heatMapTreeStructure: any;
    legends: LegendInfo[] = [];

    fieldId: string;
    showError: boolean = false;
    clusteringForm: UntypedFormGroup;


    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

    shouldHaveBackgroundColor = false;

    heatMap: google.maps.visualization.HeatmapLayer;
    currentZoom: number;

    removeLegend(datasetId: string) {
        let searchElement = this.legends.find(
            (legend) => legend.datasetId === datasetId
        );
        if (searchElement) {
            let index = this.legends.indexOf(searchElement);
            this.legends.splice(index, 1);
        }
        if (this.legends.length === 1 && this.legends[0].isForThematicMap) {
            this.shouldHaveBackgroundColor = false;
        }
    }

    get DatasetFieldType() {
        return DatasetFieldType;
    }

    get DatasetFieldScope() {
        return DatasetFieldScope;
    }

    constructor(
        private accumulationsDataService: AccumulationsDataService,
        private datapointFilterService: DatapointsFilterService,
        public readonly accumulationsSerivce: AccumulationsService
    ) {}

    ngOnInit(): void {
        this.clusteringForm = new UntypedFormGroup({
            datasetFieldId: new UntypedFormControl(null, Validators.required)
        });
    }

    // Method to access the form field value
    get datasetFieldIdControl(): UntypedFormControl {
        return this.clusteringForm.get('datasetFieldId') as UntypedFormControl;
    }

    getDisplayName(node) {
        let resultNode: any;
        if (!isUndefined(node?.params?.field)) {
            const field = node.params.field;
            if (field.baseType == DatasetFieldType.NUMBER) {
                return field.displayName == null ||
                    field.displayName == undefined
                    ? field.name
                    : field.displayName;
            }
            resultNode = node;
        }
        return resultNode?.name;
    }

    enableHeatmap() {
        if (!this.datasetFieldIdControl.valid) {
            this.showError = true;
            return;
        }
        this.accumulationsSerivce.heatmapActivationEventEmitter.emit(null);
        this.filter = this.datapointFilterService.getActiveFilter();
        this.accumulationsDataService
            .fetchHeatmapData(this.filter, this.datasetFieldIdControl.value)
            .subscribe(
                (response) => {
                    const selectedField = this.dataset.fields.find(fld => fld.id === this.datasetFieldIdControl.value);
                    response.fieldName = selectedField ? selectedField.name : null;
                    this.accumulationsSerivce.heatmapActivationEventEmitter.emit(
                        response
                    );
                },
                (error) => {
                    console.log(error);
                }
            );
    }

    disableHeatmap() {
        this.datasetFieldIdControl.setValue(null);
        this.showError = false;
        this.accumulationsSerivce.heatmapActivationEventEmitter.emit(null);
    }

    selectDatasetFieldId(node) {
        this.trigger.closeMenu();
        this.showError = false;
        this.datasetFieldIdControl.setValue(node);
    }
}

<mat-card style="height: 100%; overflow: hidden;" class="create-overlay-panel">
    Create Group
    <form class="add-group-form"
        action=""
        style="display:flex; flex-direction: column; margin-top: auto;">
        <mat-form-field>
            <mat-label>Choose Parent</mat-label>
            <mat-select #parentID>
                <mat-option *ngFor="let group of getGroupNodes()"
                    [value]="group.group.id">
                    {{group.group.name}}
                </mat-option>
                <mat-option [value]="null">
                    None
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label>New Group Name</mat-label>
            <input #newCreatedGroupName
                autocomplete="off"
                matInput
                value="">
        </mat-form-field>
        <button style="margin-left: auto; width: 40%;"
            mat-stroked-button
            (click)="createGroup(newCreatedGroupName.value, parentID.value); newCreatedGroupName.value = ''; ">
            Save </button>
    </form>
</mat-card>
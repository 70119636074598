import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PanelComponent } from "./panel/panel.component";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSliderModule } from "@angular/material/slider";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSortModule } from "@angular/material/sort";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatBadgeModule } from "@angular/material/badge";
import { PrivateComponent } from "./private/private.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { RouterModule } from "@angular/router";
import { PublicComponent } from "./public/public.component";
import { SidePanelComponent } from "../core/side-panel/side-panel.component";
import { ColorizationComponent } from "./colorization/colorization.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DatasetConfigComponent } from "./dataset-config/dataset-config.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { DialogComponent } from "./dialog/dialog.component";
import { HeaderComponent } from "./header/header.component";
import { FilterInputComponent } from "./filter-input/filter-input.component";
import { RestrictDialogComponent } from "./dataset-config/restrict-dialog/restrict-dialog.component";
import { AccountHeaderComponent } from "../account/account-header-component/account-header.component";
import { SettingsHeaderBreadcrumbsComponent } from "../account/account-header-component/settings-header-breadcrumbs/settings-header-breadcrumbs.component";
import { BreadcrumbsComponent } from "../account/account-header-component/breadcrumbs/breadcrumbs.component";
import { IntegratedClientsComponent } from "../integration/integrated-clients.component";
import { EditIntegratedClientComponent } from "../integration/edit-integrated-client/edit-integrated-client.component";
import { CreateIntegratedClientComponent } from "../integration/create-integrated-client/create-integrated-client.component";
import { DatePipe } from "../core/pipes/date.pipe";
import { MatInputCommifiedDirective } from "../core/directives/separatopr-directive.directive";
import { GenericPipe } from "../core/pipes/generic.pipe";
import { HasIdPipe } from "../core/pipes/has-id.pipe";
import { CommaFormatPipe } from "../core/pipes/comma-separator.pipe";
import { DraftStatusPipe } from "../core/pipes/status.pipe";
import { TextFilterPipe } from "../core/pipes/text-filter.pipe";
import { MaptycsPaginatorComponent } from "../core/maptycs-table/maptycs-paginator/maptycs-paginator.component";
import { AgmCoreModule } from "@agm/core";
import { NgbdSortableHeaderDirective } from "../core/directives/sort.directive";
import { TrimDirective } from "../core/directives/trim-directive.directive";
import { SymbolUnitNumberFormatPipe } from "../core/pipes/symbol-unit-number-format.pipe";
import { SortListPipe } from "../core/pipes/sort-list.pipe";
import { OverlayBreadcrumbsComponent } from "../overlay/overlay-breadcrumbs/overlay-breadcrumbs.component";
import { RoundUpNumberPipe } from "../core/pipes/round-up-number.pipe";
import { MatExpansionModule } from "@angular/material/expansion";
import { ColorPickerModule } from "ngx-color-picker";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { CustomSnackbarComponent } from "./custom-snackbar/custom-snackbar.component";

import { MatChipsModule } from "@angular/material/chips";
import {MatAutocompleteModule} from '@angular/material/autocomplete';
const modules = [
    CommonModule,
    MatSidenavModule,
    MatIconModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatProgressBarModule,
    RouterModule,
    MatButtonModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSliderModule,
    FormsModule,
    MatCheckboxModule,
    MatTableModule,
    DragDropModule,
    MatDialogModule,
    MatTabsModule,
    MatStepperModule,
    MatSlideToggleModule,
    MatCardModule,
    MatButtonToggleModule,
    AgmCoreModule,
    MatInputModule,
    MatSortModule,
    MatBadgeModule,
    MatExpansionModule,
    ColorPickerModule,
    MatSnackBarModule,
    MatChipsModule,
    MatAutocompleteModule
];

const pipes = [
    DatePipe,
    DraftStatusPipe,
    CommaFormatPipe,
    RoundUpNumberPipe,
    GenericPipe,
    MatInputCommifiedDirective,
    HasIdPipe,
    TextFilterPipe,
    SymbolUnitNumberFormatPipe,
    SortListPipe,
];

const directives = [NgbdSortableHeaderDirective, TrimDirective];

@NgModule({
    declarations: [
        ...pipes,
        ...directives,
        MaptycsPaginatorComponent,
        AccountHeaderComponent,
        SettingsHeaderBreadcrumbsComponent,
        BreadcrumbsComponent,
        PanelComponent,
        PrivateComponent,
        PublicComponent,
        SidePanelComponent,
        ColorizationComponent,
        DatasetConfigComponent,
        DialogComponent,
        HeaderComponent,
        FilterInputComponent,
        RestrictDialogComponent,
        HeaderComponent,
        IntegratedClientsComponent,
        EditIntegratedClientComponent,
        CreateIntegratedClientComponent,
        OverlayBreadcrumbsComponent,
        CustomSnackbarComponent
    ],
    exports: [
        ...modules,
        ...pipes,
        ...directives,
        MaptycsPaginatorComponent,
        PanelComponent,
        SidePanelComponent,
        ColorizationComponent,
        DatasetConfigComponent,
        FilterInputComponent,
        RestrictDialogComponent,
        HeaderComponent,
        AccountHeaderComponent,
        BreadcrumbsComponent,
        SettingsHeaderBreadcrumbsComponent,
        OverlayBreadcrumbsComponent,
    ],
    imports: [...modules],
    providers: [...pipes]
})
export class SharedModule {}

<div class="page-root">
    <map-overlay>
        <map-overlay-tabs [breadCrumbs]="breadCrumbs" [componentName]="componentName"></map-overlay-tabs>
    </map-overlay>
    <div class="page">
        <div class="page-container grey-background">
            <div class="table-actions">
                <div class="toolbar-title">
                    Global Overlays
                </div>
                <div class="toolbar-container">
                    <map-filter-input (keyup)="applyFilter($event.target.value)"></map-filter-input>
                    <button [routerLink]="['../overlay-tree']" mat-flat-button color="primary">
                        <!-- project-diagram-icon -->
                        <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                            style="margin-right: 4px;">
                            <g fill="none" fill-rule="evenodd">
                                <g stroke-linecap="round" stroke-linejoin="round" stroke="#002d57" stroke-width="1.5">
                                    <path
                                        d="M20 21H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2zM2 8h20" />
                                    <path
                                        d="M4.779 5.49c-.003 0-.005.002-.005.005s.002.005.005.005.005-.002.005-.005-.002-.005-.005-.005M7.322 5.49c-.003 0-.005.002-.005.005s.003.005.005.005c.003 0 .005-.002.005-.005s-.002-.005-.005-.005M9.861 5.49c-.003 0-.005.002-.005.005s.002.005.005.005.005-.002.005-.005-.002-.005-.005-.005M10.444 17.792H7.333v-3.25h3.111M10.444 11.542h3.111v6.25h-3.111zM13.556 13.542h3.111v4.25h-3.111" />
                                </g>
                                <path d="M0 0h24v24H0z" />
                            </g>
                        </svg>
                        <span>Edit Overlay Tree</span>
                    </button>
                    <button mat-flat-button color="primary" [matMenuTriggerFor]="actions">
                        <span>Actions</span>
                    </button>
                    <mat-menu #actions=matMenu>
                        <button mat-menu-item (click)="initializeThematicMap()">Initialize Thematic Map</button>
                        <button mat-menu-item (click)="initializeAerisData()">Initialize Aeris Data</button>
                        <button mat-menu-item (click)="refreshAerisData()">Refresh Aeris Data</button>
                    </mat-menu>


                    <button (click)="createOverlay()" mat-flat-button color="accent">
                        <!-- plus rounded icon -->
                        <svg style="margin-right: 4px;" width="16" height="16" viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg">
                            <g fill="none" fill-rule="evenodd">
                                <path d="M24 0v24H0V0z" />
                                <path
                                    d="M15 3.523a8.865 8.865 0 0 0-6 0M3.157 10.358a8.981 8.981 0 0 1 3-5.196M6.157 18.838a8.982 8.982 0 0 1-3-5.196M9 20.477c1.94.697 4.06.697 6 0M20.843 10.358a8.981 8.981 0 0 0-3-5.196M17.843 18.838a8.982 8.982 0 0 0 3-5.196M10 12h4M12 14v-4"
                                    stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </g>
                        </svg>
                        <span>Create Overlay</span>
                    </button>
                </div>
            </div>

            <div class="table-container">
                <div class="table-content">
                    <table *ngIf="overlaysDataSource && !isTableDataLoading" [dataSource]="overlaysDataSource"
                        mat-table>
                        <ng-container matColumnDef="Name">
                            <th *matHeaderCellDef mat-header-cell>Name</th>
                            <td *matCellDef="let overlay" mat-cell>{{ overlay.name }}</td>
                        </ng-container>
                        <ng-container matColumnDef="DatasetID">
                            <th *matHeaderCellDef mat-header-cell>DatasetID</th>
                            <td *matCellDef="let overlay" mat-cell>{{ overlay.id }}</td>
                        </ng-container>
                        <ng-container matColumnDef="RefreshDate">
                            <th *matHeaderCellDef mat-header-cell>Refreshed On</th>
                            <td *matCellDef="let overlay" mat-cell>
                                {{ overlay.lastRefreshDate | maptycsDate }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Actions">
                            <th *matHeaderCellDef mat-header-cell>Actions</th>
                            <td *matCellDef="let overlay" mat-cell class="table-cell-icons">
                                <span (click)="editOverlay(overlay)">
                                    <!-- Pencil-Icon -->
                                    <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <g stroke="#08080d" stroke-width="1.5" fill="none" fill-rule="evenodd"
                                            stroke-linecap="round" stroke-linejoin="round" class="icon-actions">
                                            <path
                                                d="m8.972 12.2 4.76-4.76a1.5 1.5 0 0 1 2.122 0l.707.706a1.5 1.5 0 0 1 0 2.122l-4.761 4.76a2.5 2.5 0 0 1-1.414.708l-1.815.259a.5.5 0 0 1-.566-.566l.26-1.815a2.5 2.5 0 0 1 .707-1.414zM15.209 11.62 12.38 8.791" />
                                            <rect transform="rotate(180 12 12)" x="3" y="3" width="18" height="18"
                                                rx="5" />
                                        </g>
                                    </svg>
                                </span>
                                <span (click)="deleteOverlay(overlay)">
                                    <!-- Trash Icon -->
                                    <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                                        style="margin-right: 2px;">
                                        <g fill="none" fill-rule="evenodd">
                                            <path
                                                d="M18.5 6.5V19a2 2 0 0 1-2 2h-9a2 2 0 0 1-2-2V6.5M9 17h6M9 13h6M8.5 6.5v-2A1.5 1.5 0 0 1 10 3h4a1.5 1.5 0 0 1 1.5 1.5v2M20 6.5H4"
                                                stroke="#08080d" stroke-width="1.5" stroke-linecap="round"
                                                stroke-linejoin="round" class="icon-actions disabled-icon" />
                                            <path d="M0 0h24v24H0z" />
                                        </g>
                                    </svg>
                                </span>
                                <span (click)="openSidebar(overlay)">
                                    <!-- Key-Icon -->
                                    <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <g fill="none" fill-rule="evenodd">
                                            <g stroke-linecap="round" stroke-linejoin="round" stroke="#08080d" stroke-width="1.5" class="icon-actions">
                                                <path d="M14.4 8.9c-.2 0-.4.2-.4.4s.2.4.4.4.4-.2.4-.4-.2-.4-.4-.4"/>
                                                <path d="m9.5 11.3-.9-2c-.3-.8-.1-1.7.5-2.3L12 4.1c.6-.6 1.4-.7 2.2-.4l3.5 1.5c.5.2.9.6 1.1 1.1l1.5 3.5c.3.8.1 1.6-.4 2.2L17 14.9c-.6.6-1.4.7-2.2.4l-2.1-.9-6 6H3.5v-3.2l6-5.9h0z"/>
                                            </g>
                                            <path d="M0 0h24v24H0z"/>
                                        </g>
                                    </svg>
                                </span>
                                <span (click)="refreshOverlay(overlay.id)" *ngIf="overlay.isRefreshable">
                                    <!-- Redo-Icon -->
                                    <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <g stroke="#08080d" stroke-width="1.5" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" class="icon-actions">
                                            <path d="M4 4v5.333a5.333 5.333 0 0 0 5.333 5.334H20"/>
                                            <path d="M14.667 20 20 14.667l-5.333-5.334"/>
                                        </g>
                                    </svg>
                                </span>
                            </td>
                        </ng-container>
                        <tr *matHeaderRowDef="currentDisplayedColumns; sticky: true" mat-header-row></tr>
                        <tr (dblclick)="handleTableDoubleClickEvent(row)"
                            *matRowDef="let row; columns: currentDisplayedColumns" mat-row class="cursor-pointer">

                        </tr>
                    </table>
                    <div *ngIf="isTableDataLoading" class="spinnder-container">
                        <mat-spinner diameter="40" color="accent"></mat-spinner>
                    </div>
                </div>
                <map-paginator [numberOfItemsPerPage]="filterObject.limit"
                    (paginationChangeEvent)="onPaginationChange($event)"
                    [totalNumberOfElements]="paginationInfo?.count">
                </map-paginator>
            </div>
        </div>
    </div>
</div>
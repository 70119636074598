<div class="mat-paginator-outer-container">
<div class="mat-paginator-container">
    <div class="count mat-paginator-page-size">
            <mat-label > {{totalNumberOfElements || '0' }} items found.</mat-label>
    </div>
    <div class="mat-paginator-range-actions">
        <div class="pagination">
            <a matTooltip="First Page" [class.disabled]="currentPage === firstPage" (click)="goToFirstPage(false)">&laquo;</a>
            <a matTooltip="Previous Page" *ngIf="currentPage !== firstPage" (click)="previousPage(false)">&lsaquo;</a>
            <a [class.active]="page === currentPage" *ngFor="let page of pageRange" (click)="goToPageNumber(page, false)">{{page + 1}}</a>
            <a matTooltip="Next Page" *ngIf="currentPage !== lastPage" (click)="nextPage(false)">&rsaquo;</a>
            <a matTooltip="Last Page" [class.disabled]="currentPage === lastPage" (click)="goToLastPage(false)">&raquo;</a>
        </div>
    </div>
</div>
</div>

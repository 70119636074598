<mat-sidenav-container>
    <mat-sidenav #sidenav mode="side" position="end" (opened)="onOpenPanel()">
        <div class="panel">
        <div class="panel-name">
            <span>{{name}}</span>
            <span class="icons">
                <mat-icon aria-hidden="false">remove</mat-icon>
                <mat-icon class="close-button" aria-hidden="false" (click)="close()">clear</mat-icon>
            </span>
        </div>
        <ng-content select="aside"></ng-content>
        </div>
    </mat-sidenav>

    <mat-sidenav-content>
        <ng-content select="main"></ng-content>
    </mat-sidenav-content>
</mat-sidenav-container>

import { ChartDataSets, ChartLegendLabelItem } from "chart.js";
import { DatapointsAggregateService } from "src/app/data-access-layer/datapoints/datapoints-aggregate.service";
import { DatapointsPageStateService } from "src/app/dataset/datapoints/datapoints-page-state.service";
import { ChartDisplayType } from "src/app/model/analytics/chart-display-type";
import { ReportDisplayType } from "src/app/model/analytics/report-display-type";
import { AggregateGroupRequest } from "src/app/model/datapoint/report/aggregate-group-request";
import { ReportRow } from "src/app/model/datapoint/report/count/report-row";
import { DatapointAggregateFieldType } from "src/app/model/datapoint/report/datapoint-aggregate-field-type";
import { ReportRequest } from "src/app/model/datapoint/report/report-request";
import { Dataset } from "src/app/model/dataset/dataset";
import { DatasetGeometryType } from "src/app/model/dataset/dataset-geometry-type";
import { DatasetField } from "src/app/model/dataset/field/dataset-field";
import { DatasetUtils } from "../utils/dataset-utils";
import { DatapointProjection } from "src/app/model/datapoint/projection/datapoint-projection";
import { DatapointFilter } from "src/app/model/datapoint/filter/datapoint-filter";
import { ReportResultResponse } from "src/app/model/datapoint/report/report-result-response";
import { ReportResultGroupResponse } from "src/app/model/datapoint/report/report-result-group-response";
import { ChartInfo } from "src/app/model/datapoint/report/chart-info";
import { ColorUtils } from "../utils/color-utils";
import { ReportType } from "src/app/model/analytics/report-type";
import { TreeStructure } from "src/app/model/menu/tree-structure";
import { NestedTreeControl } from "@angular/cdk/tree";
import { MatTreeNestedDataSource } from "@angular/material/tree";
import { Sort } from "@angular/material/sort";
import { DatasetFieldType } from "src/app/model/dataset/dataset-field-type";
import { DownloadReportItem } from "src/app/model/download/item/download-report-item";
import {
    TableColumn,
    TableColumnAlignment,
    TableColumnType,
} from "src/app/model/upload/table/table-column";
import { TableRow } from "src/app/model/upload/table/table-row";
import { TableCell } from "src/app/model/upload/table/table-cell";
import { DownloadReportTableRequest } from "src/app/model/download/download-report-table-request";
import { DownloadItemReportType } from "src/app/model/download/item/download-item-report-type";
import {
    DownloadReportChartRequest,
    ValueKey,
} from "src/app/model/download/download-report-chart-request";
import { DownloadReportChartValueRequest } from "src/app/model/download/download-report-chart-value-request";
import { ObjectUtils } from "../utils/object-utils";
import { isUndefined } from "../utils/util-master";
import { StringUtils } from "../utils/string-utils";
import { OverlaysService } from "src/app/data-access-layer/global-overlays/overlays.service";
import { GroupWithOverlaysTreeNode } from "src/app/model/overlay/group/group-with-overlays-tree-node";
import * as _ from "agile";
import { Datapoints } from "src/app/dataset/datapoints/datapoints";
import { TreeStructureUtils } from "../utils/tree-structure-utils";
import { Group } from "src/app/model/group/group";
import { AnalyticsUtils } from "./analytics-utils";
import { AnayticsConstants } from "src/app/constants";
import { RandomUtils } from "../utils/random-utils";

export class Count {
    treeControl = new NestedTreeControl<TreeStructure>((node) => node.children);
    dataSource = new MatTreeNestedDataSource<TreeStructure>();
    parentOverlay: any;
    flatOverlays: any[]= [];
    datapointObject = new Datapoints();

    constructor(
        public readonly datapointsPageStateService: DatapointsPageStateService,
        public readonly aggregateService: DatapointsAggregateService,
        private readonly overlayService: OverlaysService,
    ) {}

    readonly COUNT_COLUMN_ID = "count";
    readonly PERCENTAGE_COLUMN_ID = "percentage";
    readonly TOTAL_COLUMN_ID = "total";
    readonly BLANK_COLUMN_ID = "blank";
    sort: Sort = {
        active: null,
        direction: null
    };

    private static compare(a: any, b: any, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
    private LIMIT: number = 247;

    countData: any[] = [];
    linkedOverlays;

    columnsToDisplayForComparison: string[];

    readonly PRIMARY_GROUP_COUNT_COLUMN_ID = "primaryGroupCount";
    readonly SECONDARY_GROUP_COUNT_COLUMN_ID = "secondaryGroupCount";
    readonly COMPARE_AMOUNT_COLUMN_ID = "compareAmount";
    readonly COMPARE_COLUMN_ID = "comparePercentage";

    public process(
        dataset: Dataset,
        countIndex: number,
        analytic,
        analyticData,
        groupsIds: any,
        countFieldsData: any,
        overlays: any,
        linkedOverlays: any,
        groups: TreeStructure[],
        isComparisonModeActivated: boolean
    ) {
        this.linkedOverlays = linkedOverlays;
        this.countData[countIndex] = {
            id: analytic.id,
            reportName: analytic.name,
            reportType: ReportType.COUNT,
            sequence: analytic.sequence,
            reportSubType: analytic.reportSubType,
            selectedFieldsByDatasetCount: 0,
            selectedFieldsByDataset: new Map(),
            table: {
                dynamicColumns: new Map(), // key of the map is a string composed of datasetId_fieldId to ensure uniqueness, value is field value
                columnsToDisplay: [],
                totalCount: 0,
                totalAggregate: 0,
                reportData: [],
                downloadReportData: [],
            },
            chart: {
                chartDatasets: [],
                chartLabels: [],
                chartColors: [],
                chartOptions: {},
            },
            chartDisplayType: ChartDisplayType.COUNT,
            groupByFieldsDatasets: [],
            isTwoDimensionReport: this.isTwoDimensionReport(
                new Map(Object.entries(analyticData.selectedBreakdownFieldsByDataset || {}))
            ),
            sort: isUndefined(analyticData.sort) ? this.sort : analyticData.sort,
            filterTreeStrcuture: null,
            treeControl: null,
            dataSource: null,
        };

        if(isComparisonModeActivated){
            this.countData[countIndex].isCompareDropdownOpen = true;
            this.countData[countIndex].isCompareWithDropdownOpen = true;
            // this.countData[countIndex]._compareGroups = AnalyticsUtils.markGroupSelected(analyticData.compareGroupId, ObjectUtils.clone(groups));
            // this.countData[countIndex]._withGroups = AnalyticsUtils.markGroupSelected(analyticData.withGroupId, ObjectUtils.clone(groups));
            this.countData[countIndex]._compareGroupsStrcuture = AnalyticsUtils.getSelectedStrucutureGroup(analyticData.compareGroupId, ObjectUtils.clone(groups));
            this.countData[countIndex]._withGroupsStrcuture = AnalyticsUtils.getSelectedStrucutureGroup(analyticData.withGroupId, ObjectUtils.clone(groups));
        }
        this.countData[countIndex].filterTreeStrcuture = new TreeStructureUtils();
        this.countData[countIndex].treeControl = this.countData[countIndex].filterTreeStrcuture.getTreeControl();
        this.countData[countIndex].dataSource = this.countData[countIndex].filterTreeStrcuture.getDataSource();
        if (analyticData.selectedFieldsByDataset) {
            const selectedFieldsByDataset = new Map<string, DatasetField[]>();

            for (let datasetIdKey of Object.keys(
                analyticData.selectedFieldsByDataset
            )) {
                selectedFieldsByDataset.set(
                    datasetIdKey,
                    analyticData.selectedFieldsByDataset[datasetIdKey]
                );
            }

            this.countData[countIndex].datasetforCountReport = this.getOverlays(overlays, selectedFieldsByDataset);

            this.countData[countIndex].tessadataFields =
                countFieldsData.tessadataFields;
            this.countData[countIndex].tessadataGroupedFields =
                countFieldsData.tessadataGroupedFields;


            let selectedFieldIds = [];
            if(analyticData.selectedFieldsByDataset[dataset.id] && analyticData.selectedFieldsByDataset[dataset.id].length){
                selectedFieldIds = analyticData.selectedFieldsByDataset[dataset.id].map(function (
                    result
                ) {
                    return result["id"];
                })
            }

            this.markSelectedFields(this.countData[countIndex].tessadataFields.nriFields, selectedFieldIds);
            this.markSelectedFields(this.countData[countIndex].tessadataFields.externalFields, selectedFieldIds);
            if (this.countData[countIndex].tessadataGroupedFields !== undefined && Object.keys(this.countData[countIndex].tessadataGroupedFields).length) {
                for (const key in this.countData[countIndex].tessadataGroupedFields) {
                    if (Object.prototype.hasOwnProperty.call(this.countData[countIndex].tessadataGroupedFields, key)) {
                        const element = this.countData[countIndex].tessadataGroupedFields[key];
                        this.markSelectedFields(element, ObjectUtils.clone(selectedFieldIds));
                    }
                }
            }
            this.countData[countIndex].datasetFields = this.markDatasetFieldsAsSelected(dataset.id, this.countData[countIndex].datasetFields, this.countData[countIndex].selectedFieldsByDataset);
            let tessadataFieldsByDataset = {};
            tessadataFieldsByDataset[dataset.id] = {nriFields: []};
            tessadataFieldsByDataset[dataset.id].nriFields = this.countData[countIndex].tessadataFields.nriFields;
            this.countData[countIndex].dataSource.data = [
                ...this.datapointObject.prepareDataset([dataset], dataset),
                ...this.datapointObject.prepareTesadata(this.countData[countIndex].tessadataGroupedFields, dataset),
                ...this.datapointObject.prepareNRIFields(
                    [dataset],
                    tessadataFieldsByDataset,
                    true,
                    dataset
                ),
                ...this.datapointObject.filterAndDelete([...this.datapointObject.prepareClimateData(this.countData[countIndex].datasetforCountReport, false, false, false)], {isTextTypeCheck: true, geometryTypes: [DatasetGeometryType.COMPLEX]})
            ];
            this.countData[countIndex].dataSource.data = this.datapointObject.filterAndDelete(this.countData[countIndex].dataSource.data, {isTextTypeCheck: true});
            this.setFieldsSelected(this.countData[countIndex].datasetforCountReport, selectedFieldsByDataset, countIndex);
            selectedFieldsByDataset.forEach((fields, datasetId) => {
                this.countData[countIndex].selectedFieldsByDatasetCount +=
                    fields.length;
                let selectedFields: DatasetField[] = [];

                let selectedDataset = this.collectLinkedDatasetChooseToForm(
                    dataset,
                ).find((d) => d.id === datasetId);

                const locationDataset = this.collectLinkedDatasetChooseToForm(
                    dataset,
                )[0];

                let isDynamicMenu = true;

                if(!selectedDataset){
                    isDynamicMenu = true;
                    this.flattenOverlays(overlays);

                    selectedDataset = this.flatOverlays.find((d) => d.id === datasetId);
                }

                if (selectedDataset) {
                    selectedDataset.fields.forEach((selectionField) => {
                        let fieldIsSelected = fields.find(
                            (f) => f.id === selectionField.id
                        );
                        if (fieldIsSelected) {
                            selectionField.selected = true;
                            this.countData[countIndex].dataSource.data = this.datapointObject.marksSelected(this.countData[countIndex].dataSource.data, selectionField.id);
                            selectedFields.push(selectionField);
                        } else {
                            selectionField.selected = false;
                        }
                    });
                    this.countData[countIndex].selectedFieldsByDataset.set(
                        selectedDataset.id,
                        selectedFields
                    );
                    this.countData[countIndex].datasetFields = isDynamicMenu ? locationDataset.fields : selectedDataset.fields;
                }
            });


            const params: any = {
                report: this.countData[countIndex],
                dataset,
                selectedFieldsByDataset:
                    this.countData[countIndex].selectedFieldsByDataset,
                groupsIds,
                isComparisonModeActivated,
                comparisonGroups: isComparisonModeActivated ? [...analyticData?.compareGroupId, ...analyticData?.withGroupId] : []
            };
            return this.generateReportData(params);
        }
    }

    flattenOverlays(overlayGroups: any[]): void {
        for (const overlayGroup of overlayGroups || []) {
          if (overlayGroup.overlays && overlayGroup.overlays.length) {
            this.flatOverlays = this.flatOverlays.concat(overlayGroup.overlays);
          }

          if (overlayGroup.children && overlayGroup.children.length > 0) {
            this.flattenOverlays(overlayGroup.children);
          }
        }
    }

    getOverlays(overlays, selectedFieldsByDataset) {
        let res = overlays;
        let datasetforCountReport: GroupWithOverlaysTreeNode[];
        const information = res.filter(
            (element) =>
                element.group.name.toLowerCase() ===
                "information"
        );
        res.forEach((rootGroup) => {
            this.recursiveOperation(rootGroup, (group) => {
                // Sort Overlays inside Group
                group.overlays = this.sortFields(
                    group.overlays
                );
                // Sort Children Of Group
                if (group.children) {
                    group.children = _.orderBy(
                        group.children,
                        "group.name"
                    );
                }
            });
        });
        res.sort((a, b) =>
            a.group.name > b.group.name ? -1 : 1
        );
        let index = res.findIndex(
            (group) =>
                group.group.name.toLowerCase() === "information"
        );
        if (index !== -1) {
            res.splice(index, 1);
        }
        datasetforCountReport = res;

        datasetforCountReport = !isUndefined(information)
            ? [...datasetforCountReport, ...information]
            : datasetforCountReport;
        datasetforCountReport = ObjectUtils.clone(
            datasetforCountReport
        ).filter(
            (params) =>
                !["Risk Maps"].includes(params.group.name)
        );
        datasetforCountReport =
            this.prepareFilterAcccountOverlays(
                datasetforCountReport
            );

        // datasetforCountReport = this.setFieldsSelected(datasetforCountReport, selectedFieldsByDataset);

        return datasetforCountReport;
    }

    recursiveOperation(
        groupNode: GroupWithOverlaysTreeNode,
        operation: (node: GroupWithOverlaysTreeNode) => void
    ) {
        operation(groupNode);
        groupNode.children.forEach((child) => {
            // Sort the Overlay Of children of each Parent
            if (child.overlays) {
                child.overlays = this.sortFields(child.overlays);
            }
            this.recursiveOperation(child, operation);
        });
    }

    sortFields(fields) {
        fields.sort((item1, item2) => {
            if (item1 && item1.name && item2 && item2.name) {
                return item1.name
                    .trim()
                    .toLowerCase()
                    .localeCompare(item2.name.trim().toLowerCase());
            } else {
                return item1.datasetLabel
                    .toLowerCase()
                    .localeCompare(item2.datasetLabel.toLowerCase());
            }
        });
        return fields;
    }

    prepareFilterAcccountOverlays(overlays) {
        overlays.forEach((element, key) => {
            this.parentOverlay = element;
            if (element.children.length <= 0 && element.overlays.length <= 0) {
                overlays.splice(key, 1);
            } else if (element.children.length > 0) {
                this.recursiveFilterAccountOverlay(element);
            }
            if (element.children.length <= 0 && element.overlays.length <= 0) {
                overlays.splice(key, 1);
            }
        });
        return overlays;
    }

    recursiveFilterAccountOverlay(element) {
        if (element.children.length) {
            let groupIds = [];
            element.children.forEach((sub_element, key) => {
                if (
                    sub_element.children.length <= 0 &&
                    sub_element.overlays.length <= 0
                ) {
                    groupIds.push(sub_element.group.id);
                } else if (sub_element.children.length > 0) {
                    element.children = element.children.filter(
                        (params) => !groupIds.includes(params.group.id)
                    );
                    this.recursiveFilterAccountOverlay(sub_element);
                    return;
                }
            });

            if (groupIds.length) {
                element.children = element.children.filter(
                    (params) => !groupIds.includes(params.group.id)
                );
                this.recursiveFilterAccountOverlay(this.parentOverlay);
            }
        }
    }

    setFieldsSelected(datasetforCountReport, selectedFieldsByDataset, countIndex): GroupWithOverlaysTreeNode[] {
        for (const [key, value] of selectedFieldsByDataset) {
            datasetforCountReport.forEach((dataset) => {
                if(dataset && dataset.overlays && dataset.overlays.length){
                    let selectedOverlay = this.findSelectedOverlay(dataset.overlays, key);

                    if(!selectedOverlay) {
                        selectedOverlay = this.findSelectedOverlayFromChildren(dataset.children, key);
                    }

                    if (selectedOverlay && selectedOverlay.fields) {
                        for (const field of value) {
                            let matchingField = selectedOverlay.fields.find(
                                (overlayField) => overlayField.id === field.id
                            );
                            if (matchingField) {
                                this.countData[countIndex].dataSource.data = this.datapointObject.marksSelected(this.countData[countIndex].dataSource.data, matchingField.id);
                                matchingField.selected = true;
                            }
                        }
                    }
                }
            })
        }
        return datasetforCountReport;
    }

    findSelectedOverlayFromChildren(children: GroupWithOverlaysTreeNode[], key: string): any {
        for (const element of children || []) {
          const foundOverlay = this.findSelectedOverlay(element.overlays, key);

          if (foundOverlay) {
            return foundOverlay;
          }

          if (element.children && element.children.length > 0) {
            const overlayFromChildren = this.findSelectedOverlayFromChildren(element.children, key);
            if (overlayFromChildren) {
              return overlayFromChildren;
            }
          }
        }

        return null;
    }


    findSelectedOverlay(overlays, key){
        const selectedOverlay = overlays.find(
            (overlay) => overlay.id === key
        );

        return selectedOverlay
    }

    isCountFieldSelected(
        fieldId: string,
        countIndex: any,
        dataset: Dataset
    ): boolean {
        if (
            this.countData[countIndex].selectedFieldsByDataset
                .get(dataset.id)
                .find((field) => field.id === fieldId)
        ) {
            return true;
        }
        return false;
    }

    markDatasetFieldsAsSelected(datasetId, fields, selectedFields) {
        const slectedFields = selectedFields.get(datasetId)?.map((field) => {
            return field.id;
        });

        if(slectedFields) {
           fields.map((field) => {
                if(slectedFields.includes(field.id)){
                    field.selected = true;
                } else {
                    field.selected = false;
                }
            })
        }

        return fields;
    }

    generateReportData(params: any) {
        const { isComparisonModeActivated } = params;
        params.report.isTwoDimensionReport = this.isTwoDimensionReport(params.report.selectedFieldsByDataset);
        params.report.table.totalCount = 0;

        let reportRequest = this.createReportRequest(params);

        const datapointFilter: DatapointFilter = {
            datasetID: params.dataset.id,
            groups: !isComparisonModeActivated ? params.groupsIds : params.comparisonGroups,
            fields: [],
            links: [],
        };
        const datapointProjection = this.prepareProjection(params);

        if (!isComparisonModeActivated) {
            this.populateTableColumnsList(params.report);
            this.generateAnalytic(params, reportRequest, datapointFilter, datapointProjection);
        } else if (
            datapointFilter.groups.length ===
            AnayticsConstants.MAX_GROUP_SELECT_LIMIT
        ) {
            this.populateTableColumnsListForComparison(params.report);
            this.generateComparisonAnalytic(params, reportRequest, datapointFilter, datapointProjection);
        }

        return params.report;
    }

    generateAnalytic(params, reportRequest, datapointFilter, datapointProjection) {
        this.aggregateService
        .getDatapointsReport(
            params.dataset.id,
            datapointFilter,
            reportRequest,
            datapointProjection
        )
        .subscribe((success) => {
            this.computeTotalCount(success.groupResults, params.report);
            this.convertDataToTableFormat(
                success.groupResults,
                params.report
            );
            this.populateTableColumnsList(params.report);
            if (params.report.sort && !isUndefined(params.report.sort)) {
                params.report = this.sortData({
                    active: params.report.sort.active,
                    direction: params.report.sort.direction
                }, params.report);
            } else {
                this.populateChartData(params.report);
                params.report.tableDownloadRequest =
                    this.getTableReportDownloadRequest(params.report);
                params.report.chartDownloadRequest =
                this.getChartReportDownloadRequest(params.report);
            }
            return params.report;
        });
    }

    generateComparisonAnalytic(params, reportRequest, datapointFilter, datapointProjection) {
        this.aggregateService
            .getComparisonAnalyticsData(
                params.dataset.id,
                datapointFilter,
                reportRequest,
                datapointProjection
            )
            .subscribe((success) => {
                //params.report.activeGroups = ObjectUtils.clone(AnalyticsUtils.getSelectedGroups(params.report.groups))
                params.report.activeGroups = ObjectUtils.clone(AnalyticsUtils.getSelectedGroups(params));
                this.computeTotalCountsForComparison(success.groupResults, params.report);
                this.convertDataToTableFormatForComparison(
                    success.groupResults,
                    params.report
                );
                this.populateTableColumnsListForComparison(params.report);
                if (params.report.sort && !isUndefined(params.report.sort)) {
                    params.report = this.sortData({
                        active: params.report.sort.active,
                        direction: params.report.sort.direction
                    }, params.report, true);
                }
                this.populateChartData(params.report, true);
                params.report.tableDownloadRequest =
                    this.getTableReportDownloadRequestForComparison(params.report);
                params.report.chartDownloadRequest =
                this.getChartReportDownloadRequestForComparison(params.report);

                return params.report;
            })
    }

    collectLinkedDatasetChooseToForm(dataset: Dataset) {
        let datasetsToGroupBy = this.datapointsPageStateService
            .getLinkedAccountDatasets()
            .concat(
                this.linkedOverlays.filter(
                        (overlay) =>
                            overlay.geometryType === DatasetGeometryType.COMPLEX
                    )
            );
        datasetsToGroupBy = [...[dataset], ...datasetsToGroupBy];
        if (!datasetsToGroupBy.find((dataset) => dataset.id === dataset.id)) {
            datasetsToGroupBy.splice(0, 0, dataset);
        }
        return JSON.parse(JSON.stringify(datasetsToGroupBy));
    }

    private createReportRequest(params: any): ReportRequest {
        let groups: AggregateGroupRequest[] = [];
        params.selectedFieldsByDataset?.forEach((fields, datasetId) => {
            fields.forEach((field) => {
                groups.push({
                    datasetID: datasetId,
                    fieldID: field.id,
                });
            });
        });
        return {
            datasetID: params.dataset.id,
            aggregateFieldCodes: [{ aggregateFieldCode: "1", id: "count" }],
            groups: groups,
            aggregateFieldType: DatapointAggregateFieldType.FIELD,
        };
    }

    private populateTableColumnsList(report) {
        report.table.dynamicColumns = new Map();
        report.table.columnsToDisplay = [];

        report.selectedFieldsByDataset.forEach((fields, datasetId) => {
            fields.forEach((field) => {
                let key = DatasetUtils.createUniqueIdentifierForDatasetField(
                    datasetId,
                    field.id
                );
                report.table.dynamicColumns.set(key, field.name);
                report.table.columnsToDisplay.push(key);
            });
        });

        report.table.columnsToDisplay.push(this.PERCENTAGE_COLUMN_ID);
        report.table.columnsToDisplay.push(this.COUNT_COLUMN_ID);
    }

    private prepareProjection(params) {
        const datapointProjection = {
            datasetID: params.dataset.id,
            fields: [],
            links: [],
        };
        params.selectedFieldsByDataset.forEach((fields, datasetId) => {
            if (datasetId === params.dataset.id) {
                datapointProjection.fields = fields.map((field) => field.id);
            } else {
                let linkProjection: DatapointProjection = {
                    datasetID: datasetId,
                    fields: fields.map((field) => field.id),
                };
                datapointProjection.links.push(linkProjection);
            }
        });

        return datapointProjection;
    }

    private computeTotalCount(groupResults: ReportResultResponse[], report) {
        groupResults.forEach((groupResult) => {
            report.table.totalCount += groupResult.values[0].count;
        });
    }

    private convertDataToTableFormat(
        groupResults: ReportResultResponse[],
        report: any
    ) {
        report.table.downloadReportData = [];
        report.table.reportData = [];
        groupResults.forEach((groupResult) => {
            let percentage: number =
                report.table.totalCount > 0
                    ? (groupResult.values[0].count / report.table.totalCount) *
                      100
                    : 0;
            let tableEntry: ReportRow = {
                count: groupResult.values[0].count,
                dynamicFieldValuesByIds: this.getDynamicFieldValuesByIds(
                    groupResult.buckets
                ),
                percentage: Math.round(percentage * 100) / 100,
            };
            report.table.downloadReportData.push(tableEntry);
        });
        report.table.reportData = report.table.downloadReportData.slice(
            0,
            this.LIMIT
        );
    }

    getDynamicFieldValuesByIds(
        groupResultBuckets: ReportResultGroupResponse[]
    ): Map<string, string> {
        let tableEntries: Map<string, string> = new Map<string, string>();
        groupResultBuckets.forEach((groupResultBucket) => {
            let value = groupResultBucket.value
                ? groupResultBucket.value
                : "N/A";
            tableEntries.set(
                DatasetUtils.createUniqueIdentifierForDatasetField(
                    groupResultBucket.datasetID,
                    groupResultBucket.fieldID
                ),
                value
            );
        });
        return tableEntries;
    }

    /**
     * We need a structure like: Map<String, chartDataset[]>
     * The key if the main field value
     * The value is the chartDataset list for each of the other field's values (one chart dataset per second field value).
     * Each chart dataset array will contain the count values for the pair (mainField, secondField)
     */
    public populateChartData(report: any, isComparisonModeActivated = false) {
        if (report.selectedFieldsByDatasetCount > 2) {
            return; // cannot create chart with more than 2 group by fields
        }
        if (report.table.reportData.length === 0) {
            return;
        }

        report.chart.chartDatasets = [];
        report.chart.chartLabels = [];
        report.chart.chartColors = [];

        let chartColors: ChartInfo[] = [];
        let mainDatasetFieldValues = []; // chart labels
        let chartDatasets: ChartDataSets[] = [];

        if (report.selectedFieldsByDatasetCount <= 1) {
            if (!isComparisonModeActivated) {
                let chartDataset = {
                    data: [],
                };
                let chartColor = {
                    backgroundColor: [],
                };
                chartDatasets.push(chartDataset);
                chartColors.push(chartColor);

                report.table.reportData.forEach((entry) => {
                    mainDatasetFieldValues.push(
                        entry.dynamicFieldValuesByIds.values().next().value
                    );
                    chartDataset.data.push(entry.count);
                    chartColor.backgroundColor.push(
                        ColorUtils.generateRandomHexColor()
                    );
                });
            } else {
                const primaryGroupCounts = [];
                const secondaryGroupCounts = [];
                const primaryGroupColor = ColorUtils.generateRandomHexColor();
                const secondaryGroupColor = ColorUtils.generateRandomHexColor();

                report.table.reportData.forEach((entry, index) => {
                    primaryGroupCounts.push(entry.primaryGroupCount);
                    secondaryGroupCounts.push(entry.secondaryGroupCount);
                    mainDatasetFieldValues.push(
                        entry.dynamicFieldValuesByIds.values().next().value
                    );
                });

                chartDatasets = [
                    {
                        label: AnalyticsUtils.getGroupLargeName(
                            AnayticsConstants.PRIMARY_GROUP_INDEX,
                            report.activeGroups
                        ),
                        data: primaryGroupCounts,
                        backgroundColor: primaryGroupColor,
                    },
                    {
                        label: AnalyticsUtils.getGroupLargeName(
                            AnayticsConstants.SECONDARY_GROUP_INDEX,
                            report.activeGroups
                        ),
                        data: secondaryGroupCounts,
                        backgroundColor: secondaryGroupColor,
                    },
                ];
            }

        } else {
            let mainField = report.table.reportData[0].dynamicFieldValuesByIds
                .keys()
                .next().value; // we will use this as the main field in the chart
            report.table.reportData.forEach((row) => {
                let mainFieldValue = row.dynamicFieldValuesByIds.get(mainField);
                if (!mainDatasetFieldValues.includes(mainFieldValue)) {
                    mainDatasetFieldValues.push(mainFieldValue);
                }
            });

            report.table.reportData.forEach((row) => {

                let mainDatasetFieldValue =
                    row.dynamicFieldValuesByIds.get(mainField);
                let indexInDatasetArray = mainDatasetFieldValues.indexOf(
                    mainDatasetFieldValue
                ); // because the labels indexes must match the values indexes
                row.dynamicFieldValuesByIds.forEach((value, datasetFieldId) => {
                    if (datasetFieldId !== mainField) {
                        let chartDataset = chartDatasets.find(
                            (cd) => cd.label === value
                        );
                        let backgroundColor = chartColors.find(
                            (bg) => bg.label === value
                        );
                        if (!chartDataset) {
                            chartDataset = {
                                data: new Array(
                                    mainDatasetFieldValues.length
                                ).fill(0),
                                label: value,
                            };
                            chartDatasets.push(chartDataset);
                        }
                        if (!backgroundColor) {
                            backgroundColor = {
                                label: value,
                                backgroundColor: new Array(
                                    mainDatasetFieldValues.length
                                ).fill(ColorUtils.generateRandomHexColor()),
                            };
                            chartColors.push(backgroundColor);
                        }
                        chartDataset.data[indexInDatasetArray] = row.count;
                    }
                });
            });
        }

        report.chart.chartDatasets = chartDatasets;
        report.chart.chartLabels = mainDatasetFieldValues;
        report.chart.chartColors = chartColors;
        report.chart.chartOptions = {
            responsive: true,
            legend: {
                labels: {
                    generateLabels: (chart) => {
                        let legendItems: ChartLegendLabelItem[] = [];
                        if(chart.data.datasets.length === 1){
                            const data = chart.data.datasets[0].data;
                            if(data){
                                data.forEach((value, i) => {
                                    legendItems.push({
                                        text:  report.chart.chartLabels[i],
                                        fillStyle: report.chart.chartColors[0].backgroundColor[i], // because all values os the secondary dataset have the same color
                                    });
                                });
                            }
                        } else{
                            chart.data.datasets.forEach((dataset, i) => {
                                legendItems.push({
                                    text: dataset.label,
                                    fillStyle: dataset.backgroundColor[0], // because all values os the secondary dataset have the same color
                                });
                            });
                        }

                        if (legendItems.length <= 40) {
                            // not to overload the page
                            return legendItems;
                        } else {
                            return [];
                        }
                    },
                },
            },
            tooltips: {
                callbacks: {
                    label: (tooltipItem, data) => {
                        const value =
                            data.datasets[tooltipItem.datasetIndex].data[
                                tooltipItem.index
                            ];
                        const name = data.labels[tooltipItem.index];
                        const roundedUpValue = value;
                            // typeof value === "number" ? value.toFixed(2) : value;
                        let generatedTooltip = `${name}:${StringUtils.numberWithCommas(
                            roundedUpValue
                        )}`;

                        if (isComparisonModeActivated) {
                            generatedTooltip = `${
                                data.datasets[tooltipItem.datasetIndex].label
                            } - ${name}:${StringUtils.numberWithCommas(
                                roundedUpValue
                            )}`;
                        }

                        return generatedTooltip;
                    },
                },
            },
            scales: report.reportSubType === ReportDisplayType.BAR_CHART ? {
                yAxes: [
                    {
                        ticks: {
                            callback: (value, index, values) => {
                                return StringUtils.numberWithCommas(value);
                            },
                        },
                    },
                ],
            } : null
        };
    }

    prepareDataset(dataset: Dataset, countIndex: number): TreeStructure[] {
        let sortData = [];
        sortData.push({
            name: dataset.name,
            children: this.getDatapointFields(dataset, countIndex),
        });
        return sortData;
    }

    getDatapointFields(dataset: Dataset, countIndex: number): TreeStructure[] {
        let prepareFields = [];
        dataset.fields.forEach((field) => {
            if (!field.isGenerated && !field.tags.includes("ID")) {
                if (
                    !field.isHighCardinality &&
                    field.baseType === DatasetFieldType.TEXT
                ) {
                    prepareFields.push({
                        id: field.id,
                        name: field.name,
                        selected: this.isCountFieldSelected(
                            field.id,
                            countIndex,
                            dataset
                        ),
                        params: {
                            field: field,
                            showCheckBox: true,
                            dataset: dataset,
                            callType: "dataset",
                        },
                    });
                }
            }
        });
        return prepareFields;
    }

    isTwoDimensionReport(selectedFieldsByDataset): boolean {
        let selectedFieldNo = 0;
        selectedFieldsByDataset?.forEach(
            (fields, datasetId) => (selectedFieldNo += fields.length)
        );
        return selectedFieldNo === 2;
    }

    getTableReportDownloadRequest(report): DownloadReportItem {
        const {
            selectedFieldsByDataset,
            dynamicColumns,
            totalCount,
            downloadReportData,
            reportName,
        } = this.getDownloadRequestParams(report);

        let reportHeader = this.getTableReportHeader(selectedFieldsByDataset);
        let reportRows = this.getTableReportRows(
            downloadReportData,
            dynamicColumns
        );
        let reportFooter = this.getTableReportFooter(
            dynamicColumns,
            totalCount
        );
        let title = reportName || "Count";
        return new DownloadReportTableRequest(
            title,
            reportHeader,
            reportRows,
            reportFooter
        );
    }

    getTableReportHeader(selectedFieldsByDataset): TableColumn[] {
        let columns: TableColumn[] = [];
        selectedFieldsByDataset.forEach((fields, datasetId) => {
            fields.forEach((field) => {
                columns.push({
                    id: DatasetUtils.createUniqueIdentifierForDatasetField(
                        datasetId,
                        field.id
                    ),
                    name: field.name,
                    type: TableColumnType.TEXT, // even of  type is number, we use TEXT to cover the 'N/A' value as well
                    horizontalAlignment: TableColumnAlignment.LEFT,
                });
            });
        });
        columns.push({
            id: this.PERCENTAGE_COLUMN_ID,
            name: "Total %",
            type: TableColumnType.TEXT,
            horizontalAlignment: TableColumnAlignment.LEFT,
        });
        columns.push({
            id: this.COUNT_COLUMN_ID,
            name: "Count",
            type: TableColumnType.INTEGER,
            horizontalAlignment: TableColumnAlignment.RIGHT,
        });
        return columns;
    }

    getTableReportFooter(dynamicColumns, totalCount): TableRow {
        let cells: TableCell[] = [];
        cells.push({ id: this.TOTAL_COLUMN_ID, value: "Total" });
        dynamicColumns.forEach((key, value) =>
            cells.push({ id: this.BLANK_COLUMN_ID, value: "" })
        );
        cells.splice(cells.length - 1, 1); // we need to add only N-1 empty spaces
        cells.push({ id: this.PERCENTAGE_COLUMN_ID, value: "100%" });
        cells.push({ id: this.COUNT_COLUMN_ID, value: totalCount });
        return { cells: cells };
    }

    getTableReportRows(downloadReportData, dynamicColumns): TableRow[] {
        let rows: TableRow[] = [];
        downloadReportData.map((row) => {
            let columns: TableCell[] = [];
            dynamicColumns.forEach((value, key) => {
                columns.push({
                    id: key,
                    value: row.dynamicFieldValuesByIds.get(key),
                });
            });
            columns.push({
                id: this.PERCENTAGE_COLUMN_ID,
                value: row.percentage.toString() + "%",
            });
            columns.push({ id: this.COUNT_COLUMN_ID, value: row.count });
            rows.push({ cells: columns });
        });
        return rows;
    }

    sortData(sort: Sort, report: any, isComparisonModeActivated = false) {
        const isAsc = sort.direction === "asc";
        const fieldId = sort.active;
        report.sort.direction = sort.direction;
        report.sort.active = fieldId;
        let sortedData = report.table.reportData.sort((a, b) => {
            switch (fieldId) {
                case this.PRIMARY_GROUP_COUNT_COLUMN_ID:
                    return this.compare(
                        a.primaryGroupCount,
                        b.primaryGroupCount,
                        isAsc
                    );
                case this.SECONDARY_GROUP_COUNT_COLUMN_ID:
                    return this.compare(
                        a.secondaryGroupCount,
                        b.secondaryGroupCount,
                        isAsc
                    );
                case this.COMPARE_AMOUNT_COLUMN_ID:
                    return this.compare(
                        a.comparedGroupAmount,
                        b.comparedGroupAmount,
                        isAsc
                    );     
                case this.COMPARE_COLUMN_ID:
                    return this.compare(
                        a.comparedGroupPercentage,
                        b.comparedGroupPercentage,
                        isAsc
                    );
                default: {
                    let aValue = a.dynamicFieldValuesByIds.get(fieldId);
                    let bValue = b.dynamicFieldValuesByIds.get(fieldId);
                    return this.compare(aValue, bValue, isAsc);
                }
            }
        });
        report.table.reportData = [...sortedData];
        report.table.downloadReportData = [...sortedData];
        this.populateChartData(report, isComparisonModeActivated);

        report.tableDownloadRequest =
            !isComparisonModeActivated ? this.getTableReportDownloadRequest(report) : this.getTableReportDownloadRequestForComparison(report);
        report.chartDownloadRequest =
            !isComparisonModeActivated ? this.getChartReportDownloadRequest(report) : this.getChartReportDownloadRequestForComparison(report);
        return report;
    }

    private compare(a: any, b: any, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    getChartReportDownloadRequest(report): DownloadReportItem {
        const {
            selectedFieldsByDataset,
            chartDatasets,
            chartColors,
            chartLabels,
            reportName,
        } = this.getDownloadRequestParams(report);

        if (!chartDatasets || !chartDatasets.length) {
            return null;
        }

        let breakdownFieldsNames: string[] = [];
        selectedFieldsByDataset.forEach((fields, datasetId) => {
            fields.forEach((field) => {
                breakdownFieldsNames.push(field.name);
            });
        });
        let request: DownloadReportChartRequest = {
            title: reportName || "Count",
            valueKey: ValueKey.COUNT,
            type: undefined,
            columns: {
                value: "Count",
                categories: breakdownFieldsNames,
            },
            values: [],
        };

        chartDatasets.forEach((dataset, datasetIndex) => {
            dataset.data.forEach((value, valueIndex) => {
                let chartValueRequest: DownloadReportChartValueRequest = {
                    categories: [chartLabels[valueIndex]],
                    colors: [
                        chartColors[datasetIndex].backgroundColor[valueIndex],
                    ],
                    count: value,
                    value: value,
                };
                if (dataset.label) {
                    chartValueRequest.categories.push(dataset.label);
                }
                request.values.push(chartValueRequest);
            });
        });

        // Setting default request type as PIE CHART
        request.type = DownloadItemReportType.AGGREGATE_PIE_CHART;

        return request;
    }

    getDownloadRequestParams(report) {
        return {
            selectedFieldsByDataset: report.selectedFieldsByDataset,
            dynamicColumns: report.table.dynamicColumns,
            totalCount: report.table.totalCount,
            downloadReportData: report.table.downloadReportData,
            reportName: report.reportName,
            chartDatasets: report.chart.chartDatasets,
            chartColors: report.chart.chartColors,
            chartLabels: report.chart.chartLabels,
            reportSubType: report.reportSubType,
        };
    }

    markSelectedFields(array: any[], selectedIds: string[]): void {
        for (const item of array) {
            if (item.child && Array.isArray(item.child)) {
                this.markSelectedFields(item.child, selectedIds);
            } else if (item.child && selectedIds.includes(item.child.id)) {
                item.child.selected = true;
            } else  if (selectedIds.includes(item.id)) {
                item.selected = true;
            }
        }
    }

    private computeTotalCountsForComparison(
        groupResults: ReportResultResponse[],
        report: any
    ) {
        report.table.totalCount = 0;
        groupResults.forEach((result) => {
            report.table.totalCount +=
                AnalyticsUtils.findGroupCount(
                    AnayticsConstants.PRIMARY_GROUP_INDEX,
                    result,
                    report.activeGroups
                ) +
                AnalyticsUtils.findGroupCount(
                    AnayticsConstants.SECONDARY_GROUP_INDEX,
                    result,
                    report.activeGroups
                );
        });

        report.table.totalCountPrimaryGroup = 0;
        groupResults.forEach((result) => {
            report.table.totalCountPrimaryGroup += AnalyticsUtils.findGroupCount(
                AnayticsConstants.PRIMARY_GROUP_INDEX,
                result,
                report.activeGroups
            );
        });

        report.table.totalCountSecondaryGroup = 0;
        groupResults.forEach((result) => {
            report.table.totalCountSecondaryGroup += AnalyticsUtils.findGroupCount(
                AnayticsConstants.SECONDARY_GROUP_INDEX,
                result,
                report.activeGroups
            );
        });
    }

    private convertDataToTableFormatForComparison(
        groupResults: ReportResultResponse[],
        report: any
    ) {
        report.table.reportData = [];
        report.table.downloadReportData = [];
        groupResults.forEach((result) => {
            const percentage: number = AnalyticsUtils.calculatePercentage(
                AnalyticsUtils.findGroupCount(
                    AnayticsConstants.PRIMARY_GROUP_INDEX,
                    result,
                    report.activeGroups
                ) +
                    AnalyticsUtils.findGroupCount(
                        AnayticsConstants.SECONDARY_GROUP_INDEX,
                        result,
                        report.activeGroups
                    ),
                report.table.totalCount
            );
            const primaryGroupPercentage: number = AnalyticsUtils.calculatePercentage(
                AnalyticsUtils.findGroupCount(
                    AnayticsConstants.PRIMARY_GROUP_INDEX,
                    result,
                    report.activeGroups
                ),
                report.table.totalCountPrimaryGroup
            );
            const secondaryGroupPercentage: number = AnalyticsUtils.calculatePercentage(
                AnalyticsUtils.findGroupCount(
                    AnayticsConstants.SECONDARY_GROUP_INDEX,
                    result,
                    report.activeGroups
                ),
                report.table.totalCountSecondaryGroup
            );
            const comparedGroupPercentage: number =
                primaryGroupPercentage - secondaryGroupPercentage;

            const tableEntry: ReportRow = {
                primaryGroupCount: AnalyticsUtils.findGroupCount(
                    AnayticsConstants.PRIMARY_GROUP_INDEX,
                    result,
                    report.activeGroups
                ),
                secondaryGroupCount: AnalyticsUtils.findGroupCount(
                    AnayticsConstants.SECONDARY_GROUP_INDEX,
                    result,
                    report.activeGroups
                ),
                count:
                    AnalyticsUtils.findGroupCount(
                        AnayticsConstants.PRIMARY_GROUP_INDEX,
                        result,
                        report.activeGroups
                    ) +
                    AnalyticsUtils.findGroupCount(
                        AnayticsConstants.SECONDARY_GROUP_INDEX,
                        result,
                        report.activeGroups
                    ),
                dynamicFieldValuesByIds: this.getDynamicFieldValuesByIds(
                    result.buckets
                ),
                percentage: RandomUtils.roundUp(percentage),
                primaryGroupPercentage: RandomUtils.roundUp(
                    primaryGroupPercentage
                ),
                secondaryGroupPercentage: RandomUtils.roundUp(
                    secondaryGroupPercentage
                ),
                comparedGroupAmount:
                AnalyticsUtils.findGroupCount(
                    AnayticsConstants.PRIMARY_GROUP_INDEX,
                    result,
                    report.activeGroups
                ) -
                AnalyticsUtils.findGroupCount(
                    AnayticsConstants.SECONDARY_GROUP_INDEX,
                    result,
                    report.activeGroups
                ),
                comparedGroupPercentage: RandomUtils.roundUp(
                    comparedGroupPercentage
                ),
            };
            report.table.downloadReportData.push(tableEntry);
        });
        report.table.reportData = report.table.downloadReportData.slice(0, this.LIMIT);
    }

    private populateTableColumnsListForComparison(report) {
        report.table.dynamicColumns = new Map();
        const dynamicColumnsAsArray = [];

        report.selectedFieldsByDataset.forEach((fields, datasetId) => {
            fields.forEach((field) => {
                let key = DatasetUtils.createUniqueIdentifierForDatasetField(
                    datasetId,
                    field.id
                );
                report.table.dynamicColumns.set(key, field.name);
                dynamicColumnsAsArray.push(key);
            });
        });

        report.table.columnsToDisplay = [
            ...dynamicColumnsAsArray,
            this.PRIMARY_GROUP_COUNT_COLUMN_ID,
            this.SECONDARY_GROUP_COUNT_COLUMN_ID,
            this.COMPARE_AMOUNT_COLUMN_ID,
            this.COMPARE_COLUMN_ID
        ];
    }

    getTableReportFooterForComparison(report): TableRow {
        let cells: TableCell[] = [];
        cells.push({ id: this.TOTAL_COLUMN_ID, value: "Total" });
        report.table.dynamicColumns.forEach((key, value) =>
            cells.push({ id: this.BLANK_COLUMN_ID, value: "" })
        );
        cells.splice(cells.length - 1, 1);
        cells.push({
            id: this.PRIMARY_GROUP_COUNT_COLUMN_ID,
            value: report.table.totalCountPrimaryGroup,
        });
        cells.push({
            id: this.SECONDARY_GROUP_COUNT_COLUMN_ID,
            value: report.table.totalCountSecondaryGroup,
        });
        cells.push({ id: this.COMPARE_AMOUNT_COLUMN_ID, value: RandomUtils.roundUp((report.table.totalCountPrimaryGroup - report.table.totalCountSecondaryGroup)) });
        cells.push({ id: this.COMPARE_COLUMN_ID, value: RandomUtils.roundUp(AnalyticsUtils.calculateVariancePercentage(report.table.totalCountPrimaryGroup, report.table.totalCountSecondaryGroup)) + "%" });
        return { cells: cells };
    }

    getTableReportRowsForComparison(report): TableRow[] {
        let rows: TableRow[] = [];
        report.table.downloadReportData.map((row) => {
            let columns: TableCell[] = [];
            report.table.dynamicColumns.forEach((value, key) => {
                columns.push({
                    id: key,
                    value: row.dynamicFieldValuesByIds.get(key),
                });
            });

            columns.push({
                id: this.PRIMARY_GROUP_COUNT_COLUMN_ID,
                value: row.primaryGroupCount,
            });
            columns.push({
                id: this.SECONDARY_GROUP_COUNT_COLUMN_ID,
                value: row.secondaryGroupCount,
            });
            columns.push({
                id: this.COMPARE_AMOUNT_COLUMN_ID,
                value: row.comparedGroupAmount.toString(),
            });
            columns.push({
                id: this.COMPARE_COLUMN_ID,
                value: row.comparedGroupPercentage.toString() + "%",
            });
            rows.push({ cells: columns });
        });
        return rows;
    }

    getTableReportHeaderForComparison(report): TableColumn[] {
        let columns: TableColumn[] = [];
        report.selectedFieldsByDataset.forEach((fields, datasetId) => {
            fields.forEach((field) => {
                columns.push({
                    id: DatasetUtils.createUniqueIdentifierForDatasetField(
                        datasetId,
                        field.id
                    ),
                    name: field.name,
                    type: TableColumnType.TEXT,
                    horizontalAlignment: TableColumnAlignment.LEFT,
                });
            });
        });

        columns.push({
            id: this.PRIMARY_GROUP_COUNT_COLUMN_ID,
            name: `${AnalyticsUtils.getGroupName(
                AnayticsConstants.PRIMARY_GROUP_INDEX,
                report.activeGroups, false
            )} Count`,
            type: TableColumnType.INTEGER,
            horizontalAlignment: TableColumnAlignment.RIGHT,
        });

        columns.push({
            id: this.SECONDARY_GROUP_COUNT_COLUMN_ID,
            name: `${AnalyticsUtils.getGroupName(
                AnayticsConstants.SECONDARY_GROUP_INDEX,
                report.activeGroups, false
            )} Count`,
            type: TableColumnType.INTEGER,
            horizontalAlignment: TableColumnAlignment.RIGHT,
        });

        columns.push({
            id: this.COMPARE_AMOUNT_COLUMN_ID,
            name: `${AnalyticsUtils.getGroupName(
                AnayticsConstants.PRIMARY_GROUP_INDEX,
                report.activeGroups, false
            )} vs ${AnalyticsUtils.getGroupName(
                AnayticsConstants.SECONDARY_GROUP_INDEX,
                report.activeGroups, false
            )}`,
            type: TableColumnType.TEXT,
            horizontalAlignment: TableColumnAlignment.LEFT,
        });
    
        columns.push({
            id: this.COMPARE_COLUMN_ID,
            name: `${AnalyticsUtils.getGroupName(
                AnayticsConstants.PRIMARY_GROUP_INDEX,
                report.activeGroups, false
            )} vs ${AnalyticsUtils.getGroupName(
                AnayticsConstants.SECONDARY_GROUP_INDEX,
                report.activeGroups, false
            )} (%)`,
            type: TableColumnType.TEXT,
            horizontalAlignment: TableColumnAlignment.LEFT,
        });

        return columns;
    }

    getTableReportDownloadRequestForComparison(report) {
        let reportHeader = this.getTableReportHeaderForComparison(report);
        let reportRows = this.getTableReportRowsForComparison(report);
        let reportFooter = this.getTableReportFooterForComparison(report);
        let title = report.reportName || "Count";
        return new DownloadReportTableRequest(
            title,
            reportHeader,
            reportRows,
            reportFooter
        );
    }

    getChartReportDownloadRequestForComparison(report): DownloadReportItem {
        let breakdownFieldsNames: string[] = [];
        report.selectedFieldsByDataset.forEach((fields, _datasetId) => {
            fields.forEach((field) => {
                breakdownFieldsNames.push(field.name);
            });
        });

        let request: DownloadReportChartRequest = {
            title: report.reportName || "Count",
            valueKey: ValueKey.COUNT,
            type: DownloadItemReportType.AGGREGATE_BAR_CHART,
            columns: {
                value: "Count",
                categories: [...breakdownFieldsNames, "Count"],
            },
            values: [],
        };

        report.chart.chartDatasets.forEach((dataset, datasetIndex) => {
            dataset.data.forEach((value, valueIndex) => {
                let chartValueRequest: DownloadReportChartValueRequest = {
                    categories: [report.chart.chartLabels[valueIndex]],
                    colors: [dataset.backgroundColor],
                    count: value,
                    value: value,
                };
                if (dataset.label) {
                    chartValueRequest.categories.push(dataset.label);
                }
                request.values.push(chartValueRequest);
            });
        });

        return request;
    }
}

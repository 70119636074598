
<!-- <div class="logo">
	<img src="../../../assets/images/{{logo}}">
</div> -->
<div class="login-content">
	<div *ngIf="displayError" class="error-text">Oops, something went wrong..</div>

</div>

<!-- <div class="bubbles">
	<img src="../../../assets/images/login-bubble.svg">
</div> -->

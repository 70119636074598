import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { WorkspaceItem } from "src/app/model/workspace/workspace-item";
import { WorkspaceItemService } from "src/app/data-access-layer/workspace-item/workspace-item.service";
import { WorkspaceItemType } from "src/app/model/workspace/workspace-item-type";
import { MatDialog } from "@angular/material/dialog";
import { DialogModel } from "src/app/model/dialog/dialog-model";
import { DialogComponent } from "../dialog/dialog.component";
import { take } from "rxjs/operators";
import { NotifService } from "src/app/core/notification/notif.service";
import { DownloadFileType } from "src/app/model/download/download-file-type";
import { DownloadService } from "src/app/data-access-layer/download/download.service";
import { AttachmentUtils } from "src/app/core/utils/attachment-utils";
import { UserStateService } from "src/app/auth/user-state-service";

@Injectable({
    providedIn: "root",
})
export class ReportService {
    private reportsSubject: Subject<WorkspaceItem[]> = new Subject<
        WorkspaceItem[]
    >();
    reports$: Observable<WorkspaceItem[]> = this.reportsSubject.asObservable();

    constructor(
        private readonly http: HttpClient,
        private readonly workspaceItemsService: WorkspaceItemService,
        public readonly dialog: MatDialog,
        public readonly notifService: NotifService,
        public readonly downloadService: DownloadService,
        private readonly userStateService: UserStateService
    ) {}

    fetchReports(datasetId: string, accountId: number): void {
        this.workspaceItemsService
            .getAllWorkspaceItemsForUser(
                datasetId,
                WorkspaceItemType.RENEWAL_REPORT
            )
            .subscribe((items) => {
                const reportsWithDeletableFlag = items.map((item) => {
                    return {
                        ...item,
                        isDeletable: this.setDeletetable(
                            accountId,
                            item.userId
                        ),
                    };
                });

                this.reportsSubject.next(reportsWithDeletableFlag);
            });
    }

    setDeletetable(accountId, userId) {
        if (this.userStateService.isAccountAdmin(accountId)) {
            return true;
        }

        if (this.userStateService?.principal?.id === userId) {
            return true;
        }

        return false;
    }

    deleteRenewalReport(item, datasetId, accountId) {
        const dialogRef = this.dialog.open(DialogComponent, {
            data: new DialogModel(
                "Confirm Action",
                `Are you sure you want to delete "${item.name}"?`
            ),
        });
        dialogRef
            .afterClosed()
            .pipe(take(1))
            .subscribe((dialogResult) => {
                if (dialogResult) {
                    this.workspaceItemsService
                        .deleteWorkspaceItem(item.id)
                        .subscribe(
                            (success) => {
                                this.workspaceItemsService.setReportCall(true);
                                this.fetchReports(datasetId, accountId);
                                this.notifService.success(
                                    "Report deleted successfully"
                                );
                            },
                            (error) =>
                                this.notifService.error(error.error.message)
                        );
                }
            });
    }

    downloadRenewalReport(report) {
        const reportNameWithExtension = `${
            report.name
        }.${DownloadFileType.XLSX.toLowerCase()}`;
        this.downloadService.downloadRenewalReport(report.id).subscribe(
            (response) => {
                AttachmentUtils.downloadFileWithName(
                    response,
                    reportNameWithExtension
                );
            },
            (error) =>
                this.notifService.error(
                    "Something went wrong during report download"
                )
        );
    }

    closeRenewalReportPanel() {
        this.workspaceItemsService.setReportCall(true);
    }


    // findGroupByName(nodes: GroupWithOverlaysTreeNode[], name: string): GroupWithOverlaysTreeNode | undefined {
    //     for (const node of nodes) {
    //         if (node.group.name === name) {
    //             return node;
    //         }
    //         const foundInChildren = this.findGroupByName(node.children, name);
    //         if (foundInChildren) {
    //             return foundInChildren;
    //         }
    //     }
    //     return undefined;
    // }
}

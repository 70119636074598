<ul  class="groups-list">
    <ng-template #treeView let-groupNodes>
        <mat-radio-group
            #group
            [(ngModel)]="treeValue"
            aria-labelledby="example-radio-group-label"
            class="example-radio-group">
            <li  style="margin-left:10px;" *ngFor="let groupNode of groupNodes">
                <mat-icon
                    class="mat-icon-rtl-mirror"
                    (click)="groupNode.isOpen = !groupNode.isOpen"
                    [style.visibility] = "groupNode?.children.length < 1 ? 'hidden' : 'visible'"
                    style="cursor:pointer"
                >
                    {{ groupNode.isOpen ? "expand_more" : "chevron_right" }}
                </mat-icon>

                <mat-radio-button
                    (change)="
                        groupWasChecked(groupNode, $event);
                        changeEvent(groupNode.value.id)
                    "
                    [value]="groupNode.value.id"
                    [disabled]="checkForDisability(groupNode)"
                    >{{ groupNode.value.name }}</mat-radio-button
                >

                <ul
                    style="margin-left:34px;"
                    [hidden]="
                        !(groupNode.isOpen && groupNode?.children.length > 0)
                    "
                >
                    <ng-container
                        *ngTemplateOutlet="
                            treeView;
                            context: { $implicit: groupNode.children }
                        "
                    ></ng-container>
                </ul>
            </li>
        </mat-radio-group>
    </ng-template>
    <ng-container
        *ngTemplateOutlet="treeView; context: { $implicit: groupNodes }"
    ></ng-container>
</ul>

<ng-template #node_template> </ng-template>

<div class="page-root">
    <map-account-header-component >
        <settings-header-breadcrumbs [breadCrumbs]="breadCrumbs" [componentName]="componentName" ></settings-header-breadcrumbs>
    </map-account-header-component>
    <div class="page">
        <div class="page-container">
            <div class="table-actions">
                <div class="toolbar-title">
                    Account Tessadata Dataset Settings
                </div>
                <div class="toolbar-container">
                    <button *ngIf="isSuperadmin" style="margin-right: 10px;" (click)="applyAll()" mat-flat-button color="accent">
                        <!-- plus rounded icon -->
                        <svg style="margin-right: 4px;" width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
							<g fill="none" fill-rule="evenodd">
								<path d="M24 0v24H0V0z"/>
								<path d="M15 3.523a8.865 8.865 0 0 0-6 0M3.157 10.358a8.981 8.981 0 0 1 3-5.196M6.157 18.838a8.982 8.982 0 0 1-3-5.196M9 20.477c1.94.697 4.06.697 6 0M20.843 10.358a8.981 8.981 0 0 0-3-5.196M17.843 18.838a8.982 8.982 0 0 0 3-5.196M10 12h4M12 14v-4" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
							</g>
						</svg>
                        <span>Apply</span>
                    </button>
                    <map-filter-input (keyup)="applyFilter($event.target.value)"></map-filter-input>
                </div>

            </div>
            <div class="table-container">
                <div class="table-content">
                     <table
                        *ngIf="overlaysDataSource"
                        [dataSource]="overlaysDataSource"
                        mat-table>
                        <ng-container matColumnDef="Name">
                            <th *matHeaderCellDef mat-header-cell>Name</th>
                            <td *matCellDef="let overlay" mat-cell>{{ overlay.datasetLabel }}</td>
                        </ng-container>
                        <ng-container matColumnDef="DatasetID">
                            <th *matHeaderCellDef mat-header-cell>Dataset ID</th>
                            <td *matCellDef="let overlay" mat-cell>{{ overlay.externalDatasetKey }}</td>
                        </ng-container>

                        <ng-container matColumnDef="Actions">
                            <th *matHeaderCellDef mat-header-cell>  <span class="example-list-section">
                                        <mat-checkbox class="example-margin"
                                                      [(ngModel)]="allSelected"
                                                      (ngModelChange)="toggleAll()">
                                        Select All
                                    </mat-checkbox>
                                </span></th>
                            <td *matCellDef="let overlay" mat-cell>
                                <span class="example-list-section">
                                        <mat-checkbox
                                            class="example-margin"
                                            [(ngModel)]="overlay.hasOverlay"
                                            (ngModelChange)="toggleOverlay($event, overlay)"
                                        >
                                    </mat-checkbox>
                                </span>
                            </td>
                        </ng-container>
                        <tr *matHeaderRowDef="currentDisplayedColumns; sticky: true" mat-header-row></tr>
                        <tr *matRowDef="let row; columns: currentDisplayedColumns" mat-row></tr>
                    </table>
                    <div *ngIf="isTableDataLoading" class="spinnder-container">
                        <mat-spinner diameter="40" color="accent"></mat-spinner>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

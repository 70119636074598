    <div *ngIf="loading" class="spinner-container">
        <mat-spinner class="spinner" color="accent" diameter="40"></mat-spinner>
    </div>
    <div class="container" *ngIf="currentOverlay" [class.hidden]="loading">
        <div class="header-container">
            <h4>Assign overlay {{ currentOverlay.datasetLabel }} to accounts</h4>
            <mat-form-field color="accent">
                <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" autocomplete="off"/>
            </mat-form-field>
        </div>
        <div class="overlay-accounts-container">
            <div  class="account-row">
                 <span style="padding-right:20px">
                <mat-checkbox #selectAll color="accent" class="account-checkbox"
                              (change)="toggleSelectAll($event)"
                              ></mat-checkbox>
            </span>
                <span>
                <label
                    style="font-weight:bold; text-transform: capitalize;  display:block; text-overflow: ellipsis; white-space: nowrap;"
                    class="account-name-label">
           Account Name
                </label>
        </span>
            </div>
            <div *ngFor="let account of filteredAccounts" class="account-row">
            <span style="padding-right:20px">
                <mat-checkbox color="accent" class="account-checkbox" [checked]="account.isSelected"
                              [(ngModel)]="account.isSelected"></mat-checkbox>
            </span>
                <span>
                <label
                    style=" text-transform: capitalize;  display:block; text-overflow: ellipsis; white-space: nowrap;"
                    class="account-name-label">
            {{ account.name }}
                </label>
        </span>
            </div>
        </div>
        <div class="accounts-submit-button">
            <button [disabled]="loading"  type="submit" color="accent"
                    mat-flat-button
                    (click)="assignAccountsToExternalOverlay()">Save
            </button>
        </div>
    </div>

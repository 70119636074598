import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { OverlaysService } from "../../data-access-layer/global-overlays/overlays.service";
import { DatasetService } from "src/app/data-access-layer/dataset/dataset.service";
import { ActivatedRoute } from "@angular/router";
import { DatasetField } from "src/app/model/dataset/field/dataset-field";
import { Account } from "src/app/model/account/account";
import { AccountService } from "src/app/data-access-layer/account/account.service";
import { MatCheckbox, MatCheckboxChange } from "@angular/material/checkbox";
import { MatTableDataSource } from "@angular/material/table";
import { NotifService } from "../../core/notification/notif.service";
import { Dataset } from "../../model/dataset/dataset";
import { UserStateService } from "../../auth/user-state-service";
import { AccountServiceState } from "src/app/shared/services/account-service-state";

@Component({
    selector: "map-edit-overlay-accounts",
    templateUrl: "./edit-overlay-accounts.component.html",
    styleUrls: ["./edit-overlay-accounts.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditOverlayAccountsComponent implements OnInit {
    @ViewChild("selectAll") selectAll: MatCheckbox;

    @Input() currentOverlay;
    @Output() updatedSuccessfully = new EventEmitter<string>();

    accountsDataSource: MatTableDataSource<Account>;
    fields: DatasetField[] = [];
    filteredAccounts: Account[];
    private accounts: Account[];
    loading = false;

    constructor(
        private readonly accountService: AccountService,
        private readonly route: ActivatedRoute,
        private readonly datasetService: DatasetService,
        private readonly overlaysService: OverlaysService,
        private readonly notifService: NotifService,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly userStateService: UserStateService,
        private readonly accountServiceState: AccountServiceState
    ) {}

    ngOnInit(): void {
        this.accountServiceState.emitOnInitEditOverlay();
        this.init(this.currentOverlay);
    }

    fetchAccountsInfo(overlay: Dataset) {
        this.loading = true;
        this.userStateService.userStateSubject.subscribe((ready) => {
            this.overlaysService
                .getAccountsForGlobalOverlay(overlay.id)
                .subscribe((accountsForOverlay) => {
                    this.accountsDataSource = new MatTableDataSource(
                        accountsForOverlay
                    );
                    this.accounts = this.filteredAccounts = accountsForOverlay;
                    this.changeDetector.detectChanges();
                });
            this.loading = false;
        });
    }

    applyFilter(filterValue: string): void {
        let searchValue = filterValue.trim().toLowerCase();
        this.filteredAccounts = this.accounts.filter((account) => {
            return account.name.trim().toLowerCase().includes(searchValue);
        });
    }

    assignAccountsToGlobalOverlay(): void {
        this.loading = true;
        this.selectAll.checked = false;
        let selectedAccountsIds = this.accountsDataSource.data
            .filter((account) => account.hasOverlayAccess)
            .map((account) => account.id);
        this.overlaysService
            .assignAccountsToGlobalOverlay(
                selectedAccountsIds,
                this.currentOverlay.id
            )
            .subscribe(
                (res) => {
                    this.notifService.success(
                        `Successfully assign overlay to ${selectedAccountsIds.length} accounts`
                    );
                    this.fetchAccountsInfo(this.currentOverlay);
                    this.updatedSuccessfully.emit();
                    this.loading = false;
                },
                (err) => {
                    console.log(err);
                    this.fetchAccountsInfo(this.currentOverlay);
                }
            );
    }

    init(overlay: Dataset) {
        this.fetchAccountsInfo(overlay);
    }

    toggleSelectAll($event: MatCheckboxChange) {
        let selected = $event.checked;
        this.accounts.forEach((account) => {
            account.hasOverlayAccess = selected;
        });
    }

    refreshAccountOverlayLinkingStatus(accountID: number) {
        this.overlaysService
            .refreshOverlayLinkingStatus(accountID, this.currentOverlay.id)
            .subscribe(
                (res: any) => {
                    this.filteredAccounts = this.filteredAccounts.map(
                        (account) => {
                            if (account.id === res.id) {
                                return res;
                            }
                            return account;
                        }
                    );
                    this.accountsDataSource = new MatTableDataSource(
                        this.filteredAccounts
                    );
                    this.accounts = this.filteredAccounts;
                    this.changeDetector.detectChanges();
                    this.notifService.success(
                        `Successfully refreshed linking status`
                    );
                },
                (err) => {
                    console.log(err);
                }
            );
    }
}

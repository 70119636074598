import { NgModule, Optional, SkipSelf } from "@angular/core";
import { EncodeHttpParamsInterceptor } from "./interceptors/encode-httpparams-interceptor";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpErrorInterceptorService } from "./interceptors/http-error-interceptor.service";
import { APP_DATE_FORMATS, AppDateAdapter } from "./utils/format-datepicker";
import { LoaderInterceptor } from "./interceptors/loader-interceptor.service";
import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MomentUtcDateAdapter } from "../shared/moment-utc-date-adapter";
import { HttpDomainInterceptor } from "./interceptors/http-domain-interceptor.service";
import { LoaderStateService } from "../loader.service";
import { MAT_MOMENT_DATE_FORMATS } from "@angular/material-moment-adapter";

@NgModule({
    providers: [
        LoaderStateService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpDomainInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptorService,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoaderInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: EncodeHttpParamsInterceptor,
            multi: true,
        },
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
        { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        { provide: DateAdapter, useClass: MomentUtcDateAdapter },
    ],
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
        if (parentModule) {
            throw new Error(
                "CoreModule is already loaded. Import it in the AppModule only"
            );
        }
    }
}

// html-cell-renderer.component.ts
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'map-html-cell-renderer',
  template: `<div [innerHTML]="params.value"></div>`,
})
export class HtmlCellRendererComponent implements ICellRendererAngularComp {
  params: any;
  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }
}

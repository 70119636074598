<div class="page-root">
    <map-account-header-component>
        <settings-header-breadcrumbs [breadCrumbs]="breadCrumbs" [componentName]="componentName" ></settings-header-breadcrumbs>
    </map-account-header-component>
    <div class="page">
        <div class="page-container">
            <div class="table-actions">
                <div class="toolbar-title">
                    Account Groups Settings
                </div>
                <div class="toolbar-container">
                    <map-filter-input (keyup)="applyFilter($event.target.value)" #filterInput></map-filter-input>
                    <button (click)="createGroup()" mat-flat-button color="accent">
                        <!-- plus rounded icon -->
                        <svg style="margin-right: 4px;" width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
							<g fill="none" fill-rule="evenodd">
								<path d="M24 0v24H0V0z"/>
								<path d="M15 3.523a8.865 8.865 0 0 0-6 0M3.157 10.358a8.981 8.981 0 0 1 3-5.196M6.157 18.838a8.982 8.982 0 0 1-3-5.196M9 20.477c1.94.697 4.06.697 6 0M20.843 10.358a8.981 8.981 0 0 0-3-5.196M17.843 18.838a8.982 8.982 0 0 0 3-5.196M10 12h4M12 14v-4" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
							</g>
						</svg>
                        <span>Create Group</span>
                    </button>
                    <button (click)="bulkDeleteGroups()" mat-flat-button color="primary" [disabled]="selectedGroups.length === 0">
                        <!-- Trash Icon -->
                        <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" style="margin-right: 2px;">
                            <g fill="none" fill-rule="evenodd">
                                <path d="M18.5 6.5V19a2 2 0 0 1-2 2h-9a2 2 0 0 1-2-2V6.5M9 17h6M9 13h6M8.5 6.5v-2A1.5 1.5 0 0 1 10 3h4a1.5 1.5 0 0 1 1.5 1.5v2M20 6.5H4" stroke="#002d57" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"  class="icon-actions disabled-icon"/>
                                <path d="M0 0h24v24H0z"/>
                            </g>
                        </svg>
                        <span>Delete selected</span>
                    </button>
                </div>
            </div>

            <div class="table-container">
                <div class="table-content">
                    <table *ngIf="!isTableDataLoading" [dataSource]="groupsDataSource" mat-table>
                        <ng-container matColumnDef="Select">
                            <th *matHeaderCellDef mat-header-cell>Select items</th>
                            <td *matCellDef="let group" mat-cell>
                                <mat-checkbox
                                    [disabled]="group.isUngrouped"
                                    [(ngModel)]="group.isChecked"
                                    (change)="selectOrDeselectItem(group.id)"
                                ></mat-checkbox>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Name">
                            <th *matHeaderCellDef mat-header-cell>Name</th>
                            <td *matCellDef="let group" mat-cell>{{ group.name }}</td>
                        </ng-container>
                        <ng-container matColumnDef="Type">
                            <th *matHeaderCellDef mat-header-cell>Type</th>
                            <td *matCellDef="let group" mat-cell>{{ displayGroupType(group.type) }}</td>
                        </ng-container>
                        <ng-container matColumnDef="Parent_group">
                            <th *matHeaderCellDef mat-header-cell>Parent Group</th>
                            <td *matCellDef="let group" mat-cell>{{ groupsByIds.get(group.parentId)?.name }}</td>
                        </ng-container>
                        <ng-container matColumnDef="Set_Default">
                            <th *matHeaderCellDef mat-header-cell>Set Default</th>
                            <td *matCellDef="let group" mat-cell>
                                <mat-checkbox
                                    [(ngModel)]="group.isDefaultGroup"
                                    (change)="setOrRemoveDefaultGroup(group)"
                                ></mat-checkbox>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Application">
                            <th *matHeaderCellDef mat-header-cell>Application</th>
                            <td *matCellDef="let group" mat-cell>
                                {{ group.application || null }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Created_on">
                            <th *matHeaderCellDef mat-header-cell>Created on</th>
                            <td *matCellDef="let group" mat-cell>
                                {{ group.created_on }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Actions">
                            <th *matHeaderCellDef mat-header-cell>Actions</th>
                            <td *matCellDef="let group" mat-cell class="table-cell-icons">
                                    <span *ngIf="!group.isUngrouped" (click)="updateGroup(group)">
                                        <!-- Edit Icon -->
                                        <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <g fill="none" fill-rule="evenodd">
                                                <g stroke-linecap="round" stroke-linejoin="round" stroke="#08080d" stroke-width="1.5" class="icon-actions">
                                                    <path d="M21 11v8a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h7"/>
                                                    <path d="m9 15 3.15-.389c.221-.027.427-.128.585-.285l7.631-7.631a2.165 2.165 0 0 0 0-3.061h0a2.165 2.165 0 0 0-3.061 0l-7.56 7.56a1.001 1.001 0 0 0-.283.566L9 15z"/>
                                                </g>
                                                <path d="M0 0h24v24H0z"/>
                                            </g>
                                        </svg>
                                    </span>
                                <span *ngIf="showDeleteAction(group)" (click)="deleteGroup(group)">
                                         <!-- Trash Icon -->
                                         <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <g fill="none" fill-rule="evenodd">
                                                <path d="M18.5 6.5V19a2 2 0 0 1-2 2h-9a2 2 0 0 1-2-2V6.5M9 17h6M9 13h6M8.5 6.5v-2A1.5 1.5 0 0 1 10 3h4a1.5 1.5 0 0 1 1.5 1.5v2M20 6.5H4" stroke="#08080d" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"  class="icon-actions"/>
                                                <path d="M0 0h24v24H0z"/>
                                            </g>
                                        </svg>
                                    </span>
                            </td>
                        </ng-container>
                        <tr *matHeaderRowDef="currentDisplayedColumns" mat-header-row></tr>
                        <tr
                            *matRowDef="let row; columns: currentDisplayedColumns"
                            mat-row>
                        </tr>
                    </table>
                    <div *ngIf="isTableDataLoading" class="spinnder-container">
                        <mat-spinner diameter="40" color="accent"></mat-spinner>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-actions">
            <div class="footer-group">
                <div *ngIf="currentAccount" class="footer-group-container">
                    <span (click)="toggleDataset(dataset)" *ngFor="let dataset of currentAccount.datasets"
                          [ngClass]="{'footer-button-active': dataset.id === currentDataset.id}"
                          class="footer-button">
                        {{ dataset.name }}
                    </span>
                </div>
            </div>
        </div>

        <app-side-panel [width]="400" #createGroupPanel panelTitle="Create Group" [panelIcon]="'icon-plus-circle'">
            <map-account-create-groups-panel
                (created)="onGroupCreated()"
                [groups]="dropDownGroups">
            </map-account-create-groups-panel>
        </app-side-panel>
        <app-side-panel [width]="400" #updateGroupPanel panelTitle="Update Group" [panelIcon]="'icon-pencil'">
            <map-account-update-groups-panel
                (updated)="onGroupUpdated()"
                *ngIf="currentGroup"
                [groups]="dropDownGroups"
                [group]="currentGroup"
                [emitGroupOnClickSubject]="emitGroupOnClickSubject"
            >
            </map-account-update-groups-panel>
        </app-side-panel>
    </div>
</div>

import { TooltipComponent } from "@angular/material/tooltip";

export class ObjectUtils {
    private static hasFormattedHTMLTags = false;
    public static clone<T extends object>(object: T): T {
        return object ? this.deepClone(object) : object;
    }

    static deepClone<T extends object>(obj: T, hash = new WeakMap()): T {
        if (obj === null || typeof obj !== 'object') {
            // Primitive value
            return obj;
        }
        if (hash.has(obj)) {
            // Circular reference
            return hash.get(obj) as T;
        }
        const result = Array.isArray(obj) ? [] : Object.create(Object.getPrototypeOf(obj));
        hash.set(obj, result);
        return Object.assign(result, ...Object.keys(obj).map(
            key => ({ [key]: this.deepClone((obj as any)[key], hash) })
        ));
    }

    public formatStringWithHTMLtags() {
        if (ObjectUtils.hasFormattedHTMLTags) {
            return;
        }
        Object.defineProperty(TooltipComponent.prototype, "message", {
            set(v: any) {
                const el = document.querySelectorAll(".mat-tooltip");

                if (el) {
                    el[el.length - 1].innerHTML = v;
                }
            },
        });

        ObjectUtils.hasFormattedHTMLTags = true;
    }

    // find unique array object using id.
    public static filterUniqueObjectArray(data: any) {
        const seenIds: Record<string, boolean> = {};
        return data.filter(item => {
            if (!seenIds[item.id]) {
                seenIds[item.id] = true;
                return true;
            }
            return false;
        });
    }

}

<ng-container *ngIf="isEnabled" #analyticsComponent>
    <div class="panel-container report-scroll ">
        <div class="panel-header" style="flex-direction: column">
            <div class="analytics-button-group" style="position: sticky; top:0;z-index: 10;">
                <span (click)="addCountReport()" class="analytic-header-button">Add Count</span>
                <span (click)="addAggregateReport()" class="analytic-header-button" style="white-space: nowrap;">Add Aggregate</span>
                <span (click)="addRankingReport()" *ngIf="!isComparisonModeActivated" class="analytic-header-button">Add Ranking</span>
                <div class="comparison-toggle-button" matTooltipPosition="above" [matTooltip]="getTooltipForComparisonToggle()">
                    <mat-slide-toggle color="accent" [checked]="isComparisonModeActivated" (change)="changeComparisonMode()"></mat-slide-toggle>
                </div>
                <span *ngIf="reportItems?.length > 0 && !isDashboardCall" (click)="downloadReports()"
                    class="analytics-download-btn analytic-header-button">
                    <!-- download-arrow-icon -->
                    <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <g fill="none" fill-rule="evenodd">
                            <path stroke="#002d57" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                                d="M15.557 11.11 12 14.669l-3.557-3.557M12 3.997v10.671M20.003 16.446h0a3.557 3.557 0 0 1-3.557 3.557H7.554a3.557 3.557 0 0 1-3.557-3.557h0" />
                            <path d="M-.005-.005h24.01v24.01H-.005z" />
                        </g>
                    </svg>
                </span>

                 <!-- reset functionality -->
                <span *ngIf="reportItems?.length > 0 && !isDashboardCall" matTooltip="Reset" matTooltipPosition="above"
                    (click)="resetWidgets()" class="analytics-download-btn analytic-header-button">
                
                    <!-- reset-icon -->
                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 118.04 122.88" width="11"
                        height="11">
                        <path
                            d="M16.08,59.26A8,8,0,0,1,0,59.26a59,59,0,0,1,97.13-45V8a8,8,0,1,1,16.08,0V33.35a8,8,0,0,1-8,8L80.82,43.62a8,8,0,1,1-1.44-15.95l8-.73A43,43,0,0,0,16.08,59.26Zm22.77,19.6a8,8,0,0,1,1.44,16l-10.08.91A42.95,42.95,0,0,0,102,63.86a8,8,0,0,1,16.08,0A59,59,0,0,1,22.3,110v4.18a8,8,0,0,1-16.08,0V89.14h0a8,8,0,0,1,7.29-8l25.31-2.3Z" />
                    </svg>
                </span>

                <div *ngIf="!isDashboardCall" class="actions-button" [matMenuTriggerFor]="actionsMenu">
                    <!-- dot-menu-icon -->
                    <svg width="13" height="13" viewBox="0 0 9 9" xmlns="http://www.w3.org/2000/svg"
                        style="position: relative;top:2px;">
                        <g fill="#002d57" fill-rule="evenodd">
                            <circle cx="4.5" cy="1" r="1" />
                            <circle cx="4.5" cy="4" r="1" />
                            <circle cx="4.5" cy="7" r="1" />
                        </g>
                    </svg>
                </div>
                <mat-menu #actionsMenu="matMenu" class="nested-menu header-menu" backdropClass="mat-menu-header">
                    <hr *ngIf="workspaceItems.length" class="menu-line-separator" />
                    <div *ngIf="workspaceItems.length" mat-menu-item (click)="saveWorkspaceItem()">
                        <span>
                            <!-- save-icon -->
                            <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <g transform="translate(-.005 -.005)" fill="none" fill-rule="evenodd">
                                    <path
                                        d="M20.008 11.005V7.564c0-.502-.199-.983-.553-1.337l-2.672-2.672a1.89 1.89 0 0 0-1.337-.554H5.002a2 2 0 0 0-2 2.001v13.006a2 2 0 0 0 2 2h6.003"
                                        stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path
                                        d="M15 3.001v4.127a.876.876 0 0 1-.876.875H8.872a.876.876 0 0 1-.875-.875V3.001M7.003 20.008v-5.676a1.315 1.315 0 0 1 1.215-1.327h3.787"
                                        stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <circle stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" cx="17.507" cy="17.507" r="4.502" />
                                    <path stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" d="m18.907 16.808-1.75 1.752-1.05-1.052" />
                                    <path d="M0 0h24.01v24.01H0z" />
                                </g>
                            </svg>
                        </span>
                        Save
                    </div>
                    <hr class="menu-line-separator" />
                    <div mat-menu-item (click)="saveAsWorkspaceItem()">
                        <span>
                            <!-- save-icon -->
                            <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <g transform="translate(-.005 -.005)" fill="none" fill-rule="evenodd">
                                    <path
                                        d="M20.008 11.005V7.564c0-.502-.199-.983-.553-1.337l-2.672-2.672a1.89 1.89 0 0 0-1.337-.554H5.002a2 2 0 0 0-2 2.001v13.006a2 2 0 0 0 2 2h6.003"
                                        stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path
                                        d="M15 3.001v4.127a.876.876 0 0 1-.876.875H8.872a.876.876 0 0 1-.875-.875V3.001M7.003 20.008v-5.676a1.315 1.315 0 0 1 1.215-1.327h3.787"
                                        stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <circle stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" cx="17.507" cy="17.507" r="4.502" />
                                    <path stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" d="m18.907 16.808-1.75 1.752-1.05-1.052" />
                                    <path d="M0 0h24.01v24.01H0z" />
                                </g>
                            </svg>
                        </span>
                        Save as
                    </div>
                    <hr class="menu-line-separator" />
                    <div>
                        <div mat-menu-item class="analytics-menu" (click)="openData = !openData;$event.stopPropagation()">
                            <span>
                                <mat-icon fontIcon="fa-binoculars" fontSet="fas"></mat-icon>
                            </span>
                            Open
                            <div>
                                <mat-icon style="width: 16px;
                                    font-size: 16px;
                                    left: 60px;
                                    position: relative;
                                    margin-right: 6px !important;
                                    color: #0044f1;">
                                    {{ openData ? 'expand_less' : 'expand_more' }}
                                </mat-icon>
                            </div>
                        </div>

                    </div>
                    <!-- <hr class="menu-line-separator" /> -->
                    <div *ngIf="openData" class="nested-menu">
                        <mat-tree [dataSource]="openDatadataSource" [treeControl]="openDatatreeControl">
                            <mat-tree-node *matTreeNodeDef="let item" class="sub-menu-options-child"
                                style="min-height: 24px !important; width: 8.813rem;">
                                <li class="mat-tree-node" style="min-height: 24px !important;">
                                    <!-- <hr class="menu-line-separator"/> -->
                                    <div class="external-overlay-link external-node-overlay-link">
                                        <span mat-menu-item [matTooltip]="item.name" matTooltipPosition="left"
                                            class="workspace-item-menu-option n" (click)="applyWorkspaceItem(item)"
                                            style="justify-content: space-between; width: 8.813rem; padding-right: 2px !important;">
                                            {{ truncateName(item.name) }}
                                            <span *ngIf="item.isDeletable" class="workspace-item-menu-button"
                                                (click)="deleteWorkspaceItem(item);$event.stopPropagation()">
                                                <!-- Trash-Icon -->
                                                <svg style="margin-right: 4px;" width="16" height="16"
                                                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <g fill="none" fill-rule="evenodd">
                                                        <path
                                                            d="M18.5 6.5V19a2 2 0 0 1-2 2h-9a2 2 0 0 1-2-2V6.5M9 17h6M9 13h6M8.5 6.5v-2A1.5 1.5 0 0 1 10 3h4a1.5 1.5 0 0 1 1.5 1.5v2M20 6.5H4"
                                                            stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                                            stroke-linejoin="round" class="icon-actions" />
                                                        <path d="M0 0h24v24H0z" />
                                                    </g>
                                                </svg>
                                            </span>
                                        </span>
                                    </div>
                                </li>
                            </mat-tree-node>
                        </mat-tree>
                    </div>
                </mat-menu>
            </div>
        </div>
        <div class="reports-container" style="height: 80vh;padding-bottom: 80px;">
            <div *ngFor="let report of reportItems; let i = index">
                <div *ngIf="report.type === ReportType.COUNT" class="report">
                    <div class="report-container">
                        <map-count-report #countReportComponent [dataset]="dataset" [nriFields]="_nriFields"
                            [uuid]="report.id" [climateOverlays]="_climateOverlays"
                            [dashboardWorkspaceItems]="dashboardWorkspaceItems" (closed)="removeReportItem(i)"
                            (saveWorkspaceItemToDashboard)="
                                saveWorkspaceItemForDashboard(i)
                            " (saveAsWorkspaceItemToDashboard)="
                                saveAsWorkspaceItemForDashboard(i)
                            " (saveSorting)="saveSorting(report)" [isDashboardCall]="isDashboardCall"
                            style="width: 100%" [isComparisonModeActivated]="isComparisonModeActivated" [groups]="groups" [groupsStrcuture]="groupsStrcuture">
                        </map-count-report>
                    </div>
                </div>
                <div *ngIf="report.type === ReportType.AGGREGATE" class="report">
                    <div class="report-container">
                        <map-aggregate-report #aggregateReportComponent [dataset]="dataset" [nriFields]="_nriFields"
                            [uuid]="report.id" [formulas]="formulas" [dashboardWorkspaceItems]="dashboardWorkspaceItems"
                            style="width: 100%" (closed)="removeReportItem(i)" (saveWorkspaceItemToDashboard)="
                                saveWorkspaceItemForDashboard(i)
                            " (saveAsWorkspaceItemToDashboard)="
                                saveAsWorkspaceItemForDashboard(i)
                            " (saveSorting)="saveSorting(report)" [isDashboardCall]="isDashboardCall"
                            [climateOverlays]="_climateOverlays" [isComparisonModeActivated]="isComparisonModeActivated" [groups]="groups" [groupsStrcuture]="groupsStrcuture">
                        </map-aggregate-report>
                    </div>
                </div>
                <div class="report" *ngIf="report.type === ReportType.RANKING">
                    <div class="report-container">
                        <map-ranking-report #rankingReportComponent [dataset]="dataset" [nriFields]="_nriFields"
                            [uuid]="report.id" [formulas]="formulas" [dashboardWorkspaceItems]="dashboardWorkspaceItems"
                            style="width: 100%" (closed)="removeReportItem(i)" (saveWorkspaceItemToDashboard)="
                                saveWorkspaceItemForDashboard(i)
                            " (saveAsWorkspaceItemToDashboard)="
                                saveAsWorkspaceItemForDashboard(i)
                            " (saveSorting)="saveSorting(report)" [isDashboardCall]="isDashboardCall"
                            [isComparisonModeActivated]="isComparisonModeActivated" [groups]="groups" [groupsStrcuture]="groupsStrcuture">
                        </map-ranking-report>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<app-workspace-item-dialog #workspaceItemDialog [accountId]="dataset?.accountID" [datasetId]="dataset?.id"
    (itemSubmitted)="fetchActiveWorkspaceItems()"></app-workspace-item-dialog>

<table class="mat-table cdk-table">
    <thead>
        <tr class="mat-header-row cdk-header-row ">
            <th class="mat-header-cell cdk-header-cell cdk-column-position mat-column-position "
                [attr.colSpan]="columns && columns.length === 0 ? '100%' : null">
                <mat-checkbox [(ngModel)]="selectAll" (ngModelChange)="toggleSelectAllRows()">
                </mat-checkbox>
            </th>

            <th [matTooltip]="column.columnName" class="mat-header-cell" scope="col" *ngFor="let column of columns"
                (click)="onSort(column)" [ngClass]="sortable.column === column.columnName ? sortable.direction : '' "
                [ngStyle]="{'min-width': (column.width !== null) ? column.width : 'unset', 'max-width': (column.width !== null) ? column.width : 'unset'}">
                <span class="wrap">
                    <span class="column-title">{{ column.columnName }}</span>
                    <span class="material-icons mat-icon"></span>
                </span>
            </th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of items" class="mat-row cdk-row">
            <td class="mat-cell cdk-cell cdk-column-position mat-column-position ">
                <mat-checkbox [(ngModel)]="item.selected" (ngModelChange)="selectAll = false;"></mat-checkbox>
            </td>
            <td class="cell-value mat-cell cdk-cell cdk-column-position mat-column-position"
                *ngFor="let formulaResult of item.formulaResults">
                {{formulaResult | number}}
            </td>
            <td [matTooltip]="field.textValue || (field.numberValue | number) || (field.datetimeValue | maptycsDate)"
                (click)="onClick(item.id)" *ngFor="let field of item.fields"
                class="cell-value mat-cell cdk-cell cdk-column-position mat-column-position">
                {{field.textValue || (field.numberValue | number) || (field.datetimeValue | maptycsDate)}}
                <ng-container *ngIf="draftsTable">
                    <span *ngIf="field.unparsedValue" style="color: #ff1e1e">{{field.unparsedValue}}</span>
                    <span *ngIf="!field.valid && !field.unparsedValue" style="color: #ff1e1e"> missing </span>
                </ng-container>
            </td>
        </tr>
    </tbody>
</table>
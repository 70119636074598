<div *ngIf="(_gradientDetails && _gradientDetails.gradientMin !== undefined && _gradientDetails.gradientMax !== undefined) || intervalOptions?.length > 0" id="legend" class="legend" >
	<div class="inner-legend">
		<span style="display: flex; justify-content: space-between">
			<div *ngIf="datasetName && fieldLabel" class="legend-title">
				<span class="legend-title-dataset">{{datasetName}}</span>
				<span class="legend-title-field">{{fieldLabel}}</span>
			</div>
			<div *ngIf="datasetName && !fieldLabel" class="legend-title">
				<span class="legend-title-dataset">{{datasetName}}</span>
			</div>

			<span class="close-legend" (click)="deleteLegend()"><i class="fas fa-times"></i></span>
		</span>
		<ng-container *ngIf="intervalOptions?.length > 0">
			<div *ngFor="let option of intervalOptions; index as i" class="legend-row">
				<div class="legend-color-box">
					<div [ngStyle]="{'background-color': ColorUtils.colorToBase64(option.color)}" class="color-square"></div>
				</div>
				<span class="range">
					<ng-container *ngIf="!option.isTextInterval">
						<ng-container *ngIf="option.maxValue === option.minValue">
							<span class="range-min">{{option.minValue | symbolUnitNumberFormat}}</span>
						</ng-container>
						<ng-container *ngIf="option.maxValue !== option.minValue">
							<span class="range-min">{{(option.minValue === null || option.minValue === undefined) ? 'below' : (option.minValue | symbolUnitNumberFormat) }}</span>
							<span class="divider">-</span>
							<span class="range-max">{{(option.maxValue === null || option.maxValue === undefined) ? 'above' : (option.maxValue | symbolUnitNumberFormat) }}</span>
							<span *ngIf="intervalOptions?.length > 1 && i < 3 && datasetName === 'Thematic Map'"> {{legendLimit[i]}}%</span>
							<span *ngIf="intervalOptions?.length > 1 && i == 3 && datasetName === 'Thematic Map'"> {{legendLimit[legendLimit.length - 1]}}%+ </span>
						</ng-container>
					</ng-container>
					<ng-container *ngIf="option.isTextInterval">
						<ng-container *ngIf="option.maxValue === option.minValue">
							<span class="range-min">{{option.minValue}}</span>
						</ng-container>
						<ng-container *ngIf="option.maxValue !== option.minValue">
							<span *ngIf="option.minValue" class="range-min">{{option.minValue}}</span>
							<span *ngIf="option.minValue && option.maxValue" class="divider">-</span>
							<span *ngIf="option.maxValue" class="range-max">{{option.maxValue}}</span>
						</ng-container>
					</ng-container>
				</span>
			</div>
		</ng-container>

		<div *ngIf="_gradientDetails" style="display: flex" [style.align-items]="_gradientDetails.gradientMin === _gradientDetails.gradientMax ? 'center' : ''">
			<div *ngIf="_gradientDetails.gradientMin !== _gradientDetails.gradientMax" class="legend-color-box">
				<div class="gradient-square" [ngStyle]="{'background': linearGradientValue}"></div>
			</div>
			<div *ngIf="_gradientDetails.gradientMin === _gradientDetails.gradientMax && _gradientDetails.gradientMin !== undefined && _gradientDetails.gradientMax !== undefined" class="legend-color-box">
				<div [ngStyle]="{'background-color': _gradientDetails.gradientColors[0]}" class="color-square"></div>
			</div>
			<span class="gradient-values">
				<ng-container *ngIf="_gradientDetails.gradientMin !== _gradientDetails.gradientMax">
					<span class="gradient-max">{{_gradientDetails.gradientMax?.length ? _gradientDetails.gradientMax : (_gradientDetails.gradientMax | symbolUnitNumberFormat)}}</span>
				</ng-container>
				<span class="gradient-min">{{_gradientDetails.gradientMin?.length ? _gradientDetails.gradientMin : (_gradientDetails.gradientMin | symbolUnitNumberFormat)}}</span>
			</span>
		</div>

	</div>
</div>

<map-header>
    <div class="header-option">
        <button [matMenuTriggerFor]="actionsMenu">
            Actions
            <mat-icon fontIcon="fa-angle-down" fontSet="far"></mat-icon>
        </button>
        <mat-menu #actionsMenu="matMenu" class="custom-menu nested-menu header-menu" backdropClass="mat-menu-header">
            <div mat-menu-item (click)="openDownload()">
                <span><mat-icon fontIcon="fa-arrow-to-bottom" fontSet="fas"></mat-icon></span>
                Download
            </div>
            <div mat-menu-item *ngIf="!isMapView" (click)="deleteSelected()">
                <span>
                    <!-- Trash-Icon -->
                    <svg style="margin-right: 4px;" width="16" height="16" viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg">
                    <g fill="none" fill-rule="evenodd">
                        <path
                            d="M18.5 6.5V19a2 2 0 0 1-2 2h-9a2 2 0 0 1-2-2V6.5M9 17h6M9 13h6M8.5 6.5v-2A1.5 1.5 0 0 1 10 3h4a1.5 1.5 0 0 1 1.5 1.5v2M20 6.5H4"
                            stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                            stroke-linejoin="round" class="icon-actions" />
                        <path d="M0 0h24v24H0z" />
                    </g>
                </svg>
                </span>
                Delete
            </div>
            <div mat-menu-item [matMenuTriggerFor]="switchDatasetMenu" style="padding-left: 6px !important;">
                <div style="display: flex; align-items: center;">
                    <div>
                        <i class="fal fa-toggle-on" style="color: #0044f1; width: 20px; height: 20px;position: relative;top: 2px;"></i>
                    </div>
                    <div style="margin-left: 3px;">
                        Switch to 
                    </div>
                </div>
            </div>
        </mat-menu>
        <mat-menu #switchDatasetMenu="matMenu" class="nested-menu header-menu" color="accent">
            <button
                *ngFor="let currentDataset of availableDatasetsInAccount"
                [class.list-item-active]="currentDataset?.id === dataset?.id"
                (click)="switchApplication(currentDataset.id)"
                mat-menu-item>{{currentDataset?.name}}</button>
            <button
                (click)="navigateToDrafts()"
                mat-menu-item>Drafts
            </button>
        </mat-menu>
    </div>

    <div class="header-option">
        <button [matMenuTriggerFor]="viewMenu">
            View
            <mat-icon fontIcon="fa-angle-down" fontSet="far"></mat-icon>
        </button>
        <mat-menu #viewMenu="matMenu" class="custom-menu nested-menu header-menu">
            <ng-container *ngIf="!isMapView && dataset">
                <div mat-menu-item [matMenuTriggerFor]="projectionMenu">
                    <span><mat-icon fontIcon="fa-table" fontSet="fas"></mat-icon></span>
                    Table Columns
                </div>
                <mat-menu #projectionMenu="matMenu" class="nested-menu nested-menu-leaf">
                    <div (click)="$event.stopPropagation()" class="nested-menu-search">
                        <input placeholder="Search" [(ngModel)]="projectionFieldSearchString">
                    </div>
                    <ng-container *ngFor="let field of dataset?.fields  | callback: projectionFieldSearchFilter">
                        <div (click)="$event.stopPropagation()" mat-menu-item>
                            <mat-checkbox [(ngModel)]="field.isProjected"
                                          (ngModelChange)="onProjectionChange(field.isProjected, dataset, field)">
                                <span>{{field.name}}</span>
                            </mat-checkbox>

                        </div>
                    </ng-container>
                </mat-menu>
            </ng-container>
            <div mat-menu-item [matMenuTriggerFor]="shapeFilterMenu" *ngIf="isMapView">
                <span><mat-icon fontIcon="fa-filter" fontSet="fas"></mat-icon></span>
                Shape filters
            </div>
            <div mat-menu-item [matMenuTriggerFor]="shapeMeasurementsMenu" *ngIf="isMapView">
                <span><mat-icon fontIcon="fa-ruler-triangle" fontSet="fas"></mat-icon></span>
                Shape measurements
            </div>
        </mat-menu>
        <mat-menu #shapeFilterMenu="matMenu" class="custom-menu nested-menu header-menu">
            <div mat-menu-item (click)="drawShapes(MapShape.CIRCLE, MapDrawType.FILTER)">
                <span><mat-icon fontIcon="fa-circle" fontSet="fas"></mat-icon></span>
                Circle
            </div>
            <div mat-menu-item (click)="drawShapes(MapShape.POLYGON, MapDrawType.FILTER)">
                <span><mat-icon style="width: 15px" fontIcon="fa-hexagon" fontSet="fas"></mat-icon></span>
                Polygon
            </div>
            <div mat-menu-item (click)="drawShapes(MapShape.RECTANGLE, MapDrawType.FILTER)">
                <span><mat-icon fontIcon="fa-square" fontSet="fas"></mat-icon></span>
                Rectangle
            </div>
            <div mat-menu-item (click)="removeShapes(MapDrawType.FILTER)">
                <span><mat-icon fontIcon="fa-times-circle" fontSet="fas"></mat-icon></span>
                Cancel
            </div>
        </mat-menu>
        <mat-menu #shapeMeasurementsMenu="matMenu" class="custom-menu nested-menu header-menu">
            <div mat-menu-item (click)="drawShapes(MapShape.CIRCLE, MapDrawType.MEASURE)">
                <span><mat-icon fontIcon="fa-circle" fontSet="far"></mat-icon></span>
                Circle
            </div>
            <div mat-menu-item (click)="drawShapes(MapShape.POLYGON, MapDrawType.MEASURE)">
                <span><mat-icon style="width: 15px" fontIcon="fa-hexagon" fontSet="far"></mat-icon></span>
                Polygon
            </div>
            <div mat-menu-item (click)="drawShapes(MapShape.RECTANGLE, MapDrawType.MEASURE)">
                <span><mat-icon fontIcon="fa-square" fontSet="far"></mat-icon></span>
                Rectangle
            </div>
            <div mat-menu-item (click)="drawShapes(MapShape.POLYLINE, MapDrawType.MEASURE)">
                <span><mat-icon fontIcon="fa-ruler-triangle" fontSet="far"></mat-icon></span>
                Distance
            </div>
            <div mat-menu-item (click)="removeShapes(MapDrawType.MEASURE)">
                <span><mat-icon fontIcon="fa-times-circle" fontSet="fas"></mat-icon></span>
                Cancel
            </div>
        </mat-menu>
    </div>

    <div class="header-option">
        <button [matMenuTriggerFor]="filterMenu">Filter</button>
        <mat-menu #filterMenu="matMenu" class="custom-menu nested-menu nested-menu-leaf">
            <div (click)="$event.stopPropagation()" class="nested-menu-search">
                <input placeholder="Search..." [(ngModel)]="filterFieldSearchString">
            </div>
            <ng-container *ngFor="let field of dataset?.fields | callback: filterFieldSearchFilter">
                <div (click)="$event.stopPropagation()" mat-menu-item>
                    <mat-checkbox [(ngModel)]="field.selected"
                                  (ngModelChange)="onFilterMenuFieldClick(field.selected, dataset, field)">
                        <span>{{field.name}}</span>
                    </mat-checkbox>
                </div>
            </ng-container>
        </mat-menu>
    </div>
</map-header>

<div class="page-root">

    <div class="page" [class.isMapView]="isMapView">
        <map-group-panel #groupsPanel [dataset]="dataset" (groupsReady)="onGroupsFetched($event)"></map-group-panel>

        <map-datapoints-profile-panel class="location-profile-side-panel" #locationProfilePanel
                                      [mapDatapointInfo]="mapDatapointInfo"></map-datapoints-profile-panel>

        <div class="flex-top-bar">
            <div *ngIf="filterBarItems.length" class="  filters-container">
                <div class="filter-count">
                    <div>Filters ({{filterBarItems.length}})</div>
                </div>

                <div class="slider-outer-container">
                        <div *ngFor="let filterItem of filterBarItems; let i = index; trackBy: trackByIndexFunction" class=" filter-item">
                            <div [matTooltip]="filterItem.displayedTitle" [matMenuTriggerFor]="filterMenu" class="filter-item-content" [tabIndex]="0">
                                <div class="filter-title">
                                    {{filterItem.datasetField.name}}&nbsp;({{filterItem.dataset.name}})
                                </div>
                                <div class="filter-value">
                                    {{filterItem.displayedSearchValue || '-'}}
                                </div>
                                <div class="filter-close" (click)="$event.stopPropagation(); removeFilterBarItem(filterItem)">
                                    <mat-icon fontIcon="fa-times" fontSet="far"></mat-icon>
                                </div>
                            </div>

                            <mat-menu xPosition="before" yPosition="below" #filterMenu="matMenu"
                                      class="nested-menu nested-menu-leaf">
                                <div (click)="$event.stopPropagation()" [ngSwitch]="filterItem.datasetField.baseType">
                                    <div *ngSwitchCase="DatasetFieldType.DATE_TIME">
                                        <div class="dateMenuContent flex column">
                                            <mat-form-field class="date-form-field" appearance="outline" floatLabel="always">
                                                <mat-label>Choose a starting date</mat-label>
                                                <input [(ngModel)]="filterItem.minDateValue" [matDatepicker]="startAt"
                                                       [max]="filterItem.statistics.maxDateValue"
                                                       [min]="filterItem.statistics.minDateValue" matInput autocomplete="off">
                                                <mat-datepicker-toggle [for]="startAt"
                                                                       matSuffix></mat-datepicker-toggle>
                                                <mat-datepicker #startAt
                                                                [startAt]="filterItem.statistics.minValue"></mat-datepicker>
                                            </mat-form-field>
                                            <mat-form-field class="date-form-field" appearance="outline" floatLabel="always">
                                                <mat-label>Choose an ending date</mat-label>
                                                <input [(ngModel)]="filterItem.maxDateValue" [matDatepicker]="endAt"
                                                       [max]="filterItem.statistics.maxDateValue"
                                                       [min]="filterItem.statistics.minDateValue" matInput autocomplete="off">
                                                <mat-datepicker-toggle [for]="endAt" matSuffix></mat-datepicker-toggle>
                                                <mat-datepicker #endAt></mat-datepicker>
                                            </mat-form-field>
                                            <button (click)="applyFilters(); filterMenu.closed.emit()" mat-flat-button color="accent"
                                                    class="apply-filter-button date-apply-button small">
                                                Apply
                                            </button>
                                        </div>
                                    </div>

                                    <div class="number-field-content" (click)="$event.stopPropagation()"
                                         *ngSwitchCase="DatasetFieldType.NUMBER">
                                        <mat-form-field *ngIf="filterItem.id !== 'distance'" class="full-width" appearance="outline" floatLabel="always">
                                            <mat-label>Min</mat-label>
                                            <input [(ngModel)]="filterItem.minNumberValue"
                                                   [max]="filterItem.statistics.maxValue"
                                                   [min]="filterItem.statistics.minValue" matInput
                                                   placeholder="Min Value" type="number" autocomplete="off">
                                        </mat-form-field>
                                        <div class="display-flex" [class.distance]="filterItem.isDistanceItem">
                                            <mat-form-field appearance="outline" floatLabel="always"
                                                            [ngClass]="{'partial-width': filterItem.isDistanceItem, 'full-width': !filterItem.isDistanceItem}">
                                                <mat-label>Max</mat-label>
                                                <input [(ngModel)]="filterItem.maxNumberValue"
                                                       [max]="filterItem.statistics.maxValue"
                                                       [min]="filterItem.statistics.minValue"
                                                       matInputCommified matInput placeholder="Max Value" type="number" autocomplete="off">
                                            </mat-form-field>
                                            <mat-form-field *ngIf="filterItem.isDistanceItem"  appearance="outline" floatLabel="always">
                                                <mat-select [(ngModel)]="filterItem.distanceUnit" class="dropdown-medium">
                                                    <mat-option [value]="DistanceUnit.KM">{{DistanceUnit.KM}}</mat-option>
                                                    <mat-option
                                                        [value]="DistanceUnit.MILES">{{DistanceUnit.MILES}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <button class="apply-filter-button" mat-flat-button color="accent"
                                                (click)="applyFilters(); filterMenu.closed.emit()">Apply
                                        </button>
                                    </div>

                                    <div class="filter-bar-item-text-field ng-switch-short-text-field"
                                         [class.highCardinality]="filterItem.datasetField.isHighCardinality"
                                         (click)="$event.stopPropagation()" *ngSwitchCase="DatasetFieldType.TEXT">

                                        <!-- text field low cardinality-->
                                        <ng-container *ngIf="!filterItem.datasetField.isHighCardinality else newDeb">
                                            <div (click)="$event.stopPropagation()" class="nested-menu-search">
                                                <input matInput [(ngModel)]="filterStatisticValuesString"
                                                       placeholder="Search..." autocomplete="off">
                                            </div>
                                            <ng-container
                                                *ngFor="let value of filterItem.statistics.values | callback: filterStatisticValuesFilter">
                                                <div (click)="$event.stopPropagation()" mat-menu-item>
                                                    <mat-checkbox [(ngModel)]="filterItem.statisticValues[value]"
                                                                  (ngModelChange)="applyFilters()">
                                                        <span>{{value}}</span>
                                                    </mat-checkbox>

                                                </div>
                                            </ng-container>
                                        </ng-container>
                                        <!-- text field low cardinality-->


                                        <!-- text field high cardinality-->
                                        <ng-template #newDeb>
                                            <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                                                <mat-label>Enter value</mat-label>
                                                <input [(ngModel)]="filterItem.searchValue" matInput placeholder="Search contains..." autocomplete="off">
                                            </mat-form-field>
                                            <button class="apply-filter-button small" mat-flat-button color="accent"
                                                    (click)="applyFilters(); filterMenu.closed.emit()"
                                                    mat-raised-button>Apply
                                            </button>
                                        </ng-template>
                                        <!-- text field high cardinality-->

                                    </div>

                                    <span (click)="$event.stopPropagation()" *ngSwitchDefault>
                                      <mat-form-field class="full-width" appearance="outline" floatLabel="always">
                                    <mat-label>Search a text value</mat-label>
                                        <input matInput placeholder="Search contains..." autocomplete="off">
                                    </mat-form-field>
                            <button>default</button>
                                </span>
                                </div>
                            </mat-menu>

                        </div>
                    </div>
                <button style="display: block; margin-left: auto;" class="remove-filter-bar-button"
                        (click)="removeFilterBar()" mat-icon-button>
				<span class="material-icons">
                        clear
                    </span>
                </button>

            </div>
        </div>
        <div class="page-container">

            <map-map
                *ngIf="isMapView;"
                (mapReady)="onMapReady()"
                (DetailsOpen)="openDetails($event)"
                [isThematicMapEnabled]="isThematicMapEnabled">
            </map-map>
            <map-datapoints-table
                #tableComponent
                class="table-container"
                (Update)="openEditPanel($event)"
                *ngIf="datapointFilterObject && !isMapView"
                [dataset]="dataset"
                [filterItems]="filterBarItems"
                [filter]="datapointFilterObject"></map-datapoints-table>
        </div>
        <div class="footer-actions">
            <div class="footer-group thematic-map-section">
                <div *ngIf="account && isThematicMapEnabled" class="footer-floating-fields-container">
                    <div class="footer-label floating-field">
                        Count: {{datapointsCount | commaFormat}}
                    </div>
                    <div *ngFor="let field of datasetFloatingFields" class="footer-label floating-field">
                        {{ field.name }}: {{floatingFieldsValuesByFields?.get(field) | commaFormat }}
                    </div>
                </div>

            </div>
            <div class="footer-group footer-group-custom">
				<span (click)="switchView('table')" [ngClass]="{'footer-button-active': !isMapView}"
                      style="padding: 6px;" class="footer-button">
                      <!-- burger-icon -->
					<div class="icon-container-wrap">
						<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="dashboard-icon">
							<g fill="none" fill-rule="evenodd">
								<path class="icon-primary" stroke="#002d57" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" d="M5.498 12h13.005M5.498 16.002h13.005M5.497 7.998h13.005"/>
								<path d="M-.005-.005h24.01v24.01H-.005z"/>
							</g>
						</svg>
					</div>
                </span>
                <span *ngIf="dataset && dataset.geometryType !== 'NONE'" (click)="switchView('map')"
                      [ngClass]="{'footer-button-active': isMapView}" style="padding: 6px;" class="footer-button">
                      <!-- travel-map-icon -->
					<div class="icon-container-wrap">
						<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="dashboard-icon">
							<g fill="none" fill-rule="evenodd">
								<path class="icon-primary" stroke="#002d57" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" d="M16 18.457V13.25M8 18.463V13.25M5.083 8l-1.597.959A1 1 0 0 0 3 9.816v9.668a1 1 0 0 0 1.514.857l2.91-1.745a1 1 0 0 1 1.114.058L11.4 20.8a1 1 0 0 0 1.2 0l2.862-2.147a1 1 0 0 1 1.115-.057l2.909 1.745A1 1 0 0 0 21 19.484V9.816a1 1 0 0 0-.486-.857L18.917 8M12 15.25v5.748M8 6h0a4 4 0 0 1 4-4h0a4 4 0 0 1 4 4h0"/>
								<path class="icon-primary" d="M8 6c0 1.807 1.993 3.735 3.166 4.713.49.382 1.177.383 1.667 0C14.006 9.736 16 7.808 16 6" stroke="#002d57" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								<path class="icon-primary" d="M12.001 5.639a.25.25 0 1 1-.002.5.25.25 0 0 1 .002-.5" stroke="#002d57" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								<path d="M0 0h24v24H0z"/>
							</g>
						</svg>
					</div>
                </span>
            </div>
        </div>
    </div>
</div>

<div class="colorization-scroll">
    <div class="colorization-grid">
        <div class="colorization-cell">
            <div class="colorization-label">VIEW MODE</div>
            <mat-form-field [floatLabel]="'never'">
                <mat-select [(ngModel)]="renderingOptions.visualizationOptions.type"
                            (ngModelChange)="viewModeChanged.emit(renderingOptions.visualizationOptions.type)"
                            placeholder="Type">
                    <mat-option *ngFor="let option of getVisualizationTypes" value="{{option}}">
                        {{option | titlecase}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="colorization-cell">
            <div class="colorization-label">Grouping</div>
            <mat-form-field [floatLabel]="'never'">
                <mat-select [(value)]="renderingOptions.converterOptions.grouping" placeholder="Grouping" (valueChange)="onGroupingChange($event)">
                    <mat-option *ngFor="let grouping of groupings" [value]="grouping">
                        {{grouping | titlecase}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="colorization-cell">
            <div class="colorization-label">Scale Type</div>
            <mat-form-field [floatLabel]="'never'">
                <mat-select (valueChange)="colorScaleTypeChanged($event)"
                            [(value)]="renderingOptions.datasetStylingOptions.type" placeholder="Type">
                    <ng-container *ngIf="renderingOptions.converterOptions.grouping === Grouping.COUNT">
                        <mat-option [value]="ColorScaleType.CONSTANT">{{ColorScaleType.CONSTANT | titlecase}}</mat-option>
                    </ng-container>
                    <ng-container *ngIf="renderingOptions.converterOptions.grouping !== Grouping.COUNT">
                    <ng-container *ngFor="let scale of scaleTypes">
                        <!-- We don't display CONSTANT if SUM or AVERAGE is selected-->
                        <mat-option [value]="scale" *ngIf="!(scale === ColorScaleType.CONSTANT && (renderingOptions.converterOptions.grouping === Grouping.SUM || renderingOptions.converterOptions.grouping === Grouping.AVERAGE))">
                            {{scale | titlecase}}
                        </mat-option>
                    </ng-container>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="colorization-cell" *ngIf="renderingOptions.datasetStylingOptions.type !== ColorScaleType.CONSTANT && renderingOptions.converterOptions.grouping !== Grouping.COUNT">
            <div class="colorization-label">Colorize By</div>

            <ng-container *ngIf="dataset.type === DatasetType.ACCOUNT_APPLICATION">
                <div mat-button [matMenuTriggerFor]="filterMenu2" id="colorizeByField"
                     [matTooltip]="selectedFieldForColorization? selectedFieldForColorization.name + '(' + selectedDatasetForColorization.name + ')' : 'Select one'"
                     matTooltipClass="tooltip">
                    {{selectedFieldForColorization ? selectedFieldForColorization.name + '(' + selectedDatasetForColorization.name + ')' : 'Select one'}}
                </div>
                <mat-menu #filterMenu2="matMenu" class="nested-menu">
                    <ng-container *ngFor="let dataset of colorizeByDatasetOptions">
                        <span [matMenuTriggerFor]="datasetMenu" mat-menu-item>{{dataset.name}}</span>
                        <mat-menu #datasetMenu="matMenu" class="nested-menu">
                            <div (click)="$event.stopPropagation()" class="nested-menu-search">
                                <input placeholder="Search..."/>
                            </div>
                            <ng-container *ngFor="let field of dataset.fields">
                                <div *ngIf="field.baseType === DatasetFieldType.NUMBER && !field.isHighCardinality && !field.isGenerated && !field.tags?.includes('ID')"
                                     (click)="onFieldsMenuFieldClick(dataset, field)" mat-menu-item>
                                    <span>{{field.name}}</span>
                                </div>
                            </ng-container>
                        </mat-menu>
                    </ng-container>
                </mat-menu>
            </ng-container>

            <ng-container *ngIf="dataset.type !== DatasetType.ACCOUNT_APPLICATION ">
                <mat-select [(value)]="renderingOptions.converterOptions.fieldID" placeholder="Field">
                    <ng-container *ngFor="let field of dataset.fields">
                        <mat-option *ngIf="field.baseType === DatasetFieldType.NUMBER && !field.isHighCardinality && !field.isGenerated && !field.tags?.includes('ID')"
                                    [value]="field.id">
                            {{field.name}}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </ng-container>
        </div>

        <div class="colorization-cell">
            <div class="colorization-label">Radius</div>
            <mat-slider class="example-margin" min="0" max="200" step="0.1" color="accent" thumbLabel
                        [(ngModel)]="radius">
            </mat-slider>
        </div>
        <div class="colorization-cell">
            <div class="colorization-label">Radius Unit</div>
            <mat-form-field [floatLabel]="'never'">
                <mat-select [(ngModel)]="distanceUnit" class="distance-unit-dropdown">
                    <mat-option [value]="DistanceUnit.KM">{{DistanceUnit.KM | titlecase}}</mat-option>
                    <mat-option [value]="DistanceUnit.MILES">{{DistanceUnit.MILES | titlecase}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="colorization-cell">
            <div class="colorization-label">Opacity</div>
            <mat-slider class="example-margin" min="0" max="1" step="0.1" color="accent"
                        [(ngModel)]="renderingOptions.datasetStylingOptions.backgroundOpacity">
            </mat-slider>
        </div>

        <div class="colorization-cell" *ngIf="ColorScaleType.CONSTANT !== renderingOptions.datasetStylingOptions.type">
        </div>
    </div>

    <div class="colorization-etc">
        <div
                *ngIf="ColorScaleType.CONSTANT === renderingOptions.datasetStylingOptions.type || ColorScaleType.INTERVAL === renderingOptions.datasetStylingOptions.type"
                class="row"
                style="display: flex;align-items: center;">
            <div class="colorization-label">Color</div>
            <div class="color-picker-wrapper">
                <input class="color-picker-input" style="top:0% !important"
                       cpColorMode="true"
                       (colorPicker)="defaultColor"
                       (colorPickerChange)="onConstantColorChanged($event)"
                       [style.background]="defaultColor"
                       [ngStyle]="{'background': defaultColor}"
                >
            </div>
        </div>

        <div *ngIf="(ColorScaleType.FIXED === renderingOptions.datasetStylingOptions.type || ColorScaleType.GRADIENT === renderingOptions.datasetStylingOptions.type) && renderingOptions.converterOptions.grouping !== Grouping.COUNT"
                class="row">
            <div class="colorization-label">Colors</div>
            <div class="color-box-container">
                <ng-container *ngFor="let color of base64Colors; let i = index;">
                    <div class="color-square" [(ngModel)]="base64Colors[i]">
                            <input class="color-picker-input"
                                (colorPicker)="color"
                                (colorPickerChange)="onColorChange($event, i)"
                                cpColorMode="true"
                                [style.background]="color"
                                [ngStyle]="{'background': color}"
                                style="bottom: 1px !important; width: 16px !important; height: 16px !important; background: rgb(255, 0, 0);"
                            >
                            <span (click)="onRemoveColor(i, $event)" class="remove-color-icon">
                                <mat-icon>remove</mat-icon>
                            </span>
                    </div>
                </ng-container>
                <span (click)="base64Colors.push('#000000')" class="color-square add-color-icon">
                    <mat-icon >add</mat-icon>
                </span>
            </div>
    </div>

        <div *ngIf="ColorScaleType.INTERVAL === renderingOptions.datasetStylingOptions.type"
             class="color-interval-container">
            <mpt-color-intervals (Update)="onApplySettings()"
                                 [intervals]="renderingOptions.datasetStylingOptions.intervalOptions"></mpt-color-intervals>
        </div>
    </div>
</div>
<div class="colorization-hint" *ngIf="dataset.isSelected">Your changes will appear on the map</div>
<div class="colorization-hint" *ngIf="!dataset.isSelected">The overlay is not active on the map</div>
<div class="colorization-config-box-controls">
    <button (click)="reset()" *ngIf="dataset.isSelected" mat-button mat-flat-button color="primary">Reset</button>
    <button (click)="onApplySettings()" *ngIf="dataset.isSelected" mat-button mat-flat-button color="accent">
        Apply
    </button>
</div>

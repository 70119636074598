<div class="form-container">
    <form [formGroup]="createOverlayForm" class="external-edit-panel">
                <mat-form-field>
                    <mat-label>Dataset Id</mat-label>
                    <input formControlName="externalDatasetKey" matInput autocomplete="off"/>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Dataset Name</mat-label>
                    <input formControlName="datasetLabel" matInput autocomplete="off"/>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>External Overlay Group</mat-label>
                    <mat-select formControlName="externalGroupId">
                        <mat-option [value]="null">None</mat-option>
                        <mat-option *ngFor="let group of groups" [value]="group.id">
                            {{ group.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

        <div class="overlay-box-row">
                <mat-form-field [floatLabel]="'never'" color="accent">
                    <mat-label>Provider Email</mat-label>
                    <input formControlName="providerEmail" matInput autocomplete="off"/>
                </mat-form-field>
        </div>

        <mat-checkbox formControlName="trackable" class="track-api-checkbox">
                   <span style="font-size: 0.75rem;">Track the API</span>
        </mat-checkbox>

        <div class="overlay-box-row">
                <mat-form-field color="accent">
                    <mat-label>Show in</mat-label>
                    <mat-select formControlName="showInMap">
                        <mat-option *ngFor="let option of showInMapOptions" [value]="option.value">
                            {{option.viewValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
        </div>

        <button *ngIf="!editAction" (click)="createOverlay()" [disabled]="createOverlayForm.invalid" color="accent" mat-flat-button>
            Create
        </button>
        <button *ngIf="editAction" (click)="editOverlay()" [disabled]="createOverlayForm.invalid" color="accent" mat-flat-button>
            Edit
        </button>
    </form>
</div>

<div class="colorization-scroll">
	<div class="colorization-grid">
		<div class="colorization-cell">
			<div class="colorization-label">View Mode</div>
			<mat-form-field [floatLabel]="'never'">
				<mat-select [(ngModel)]="renderingOptions.visualizationOptions.type"
				            (ngModelChange)="viewModeChanged.emit(renderingOptions.visualizationOptions.type)"
				            placeholder="Type" class="location-address-select">
					<mat-option *ngFor="let option of getVisualizationTypes" value="{{option}}">
						{{option | titlecase}}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>


		<div class="colorization-cell">
			<div class="colorization-label">Scale Type</div>
			<mat-form-field [floatLabel]="'never'">
				<mat-label>Scale Type</mat-label>
				<mat-select [(ngModel)]="renderingOptions.datasetStylingOptions.type" (ngModelChange)="colorScaleTypeChanged($event)"
				             placeholder="Type" class="location-address-select">
					<mat-option *ngFor="let scale of scaleTypes" [value]="scale"
                                [disabled]="scale === ColorScaleType.INTERVAL && dataset.fields[renderingOptions.datasetStylingOptions.colorizationFieldIndex].baseType !== DatasetFieldType.NUMBER">
						{{scale | titlecase}}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>

		<div class="colorization-cell" *ngIf="renderingOptions.datasetStylingOptions.type !== ColorScaleType.CONSTANT">
			<!-- <div class="colorization-label">COLORIZE BY</div> -->

			<!-- <ng-container *ngIf="dataset.type === DatasetType.ACCOUNT_APPLICATION">
				<div id="colorizeByField" mat-button [matMenuTriggerFor]="filterMenu"
				     [matTooltip]="selectedFieldForColorization ? selectedFieldForColorization?.name + '(' + selectedDatasetForColorization?.name + ')' : 'Select one'"
				     matTooltipClass="tooltip">
					{{selectedFieldForColorization ? selectedFieldForColorization?.name + '(' + selectedDatasetForColorization?.name + ')' : 'Select one'}}
				</div>
				<mat-menu #filterMenu="matMenu" class="nested-menu">
                    <span *ngFor="let ds of colorizeByDatasetOptions">
                        <span [matMenuTriggerFor]="datasetMenu" mat-menu-item>{{ds.name}}</span>
                        <mat-menu #datasetMenu="matMenu" class="nested-menu">
                            <div (click)="$event.stopPropagation()" class="nested-menu-search">
                                <input placeholder="Search..."/>
                            </div>
                            <ng-container *ngIf="ds.id === dataset.id">
                                 <ng-container *ngFor="let field of ds.fields">
                                     <div *ngIf="!field.isGenerated && !field.tags?.includes('ID')"
                                          [class.disabled]="field.isHighCardinality || (renderingOptions.datasetStylingOptions.type === ColorScaleType.INTERVAL && field.baseType !== DatasetFieldType.NUMBER)"

                                          (click)="onFieldsMenuFieldClick(ds, field)" mat-menu-item>
                                         <span>{{field.name}}</span>
                                     </div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="ds.id !== dataset.id">
                                 <ng-container *ngFor="let field of ds.fields">
                                     <div *ngIf="field.displayInDropdowns && !field.isGenerated && !field.tags?.includes('ID')"
                                          [class.disabled]="field.isHighCardinality || (renderingOptions.datasetStylingOptions.type === ColorScaleType.INTERVAL && field.baseType !== DatasetFieldType.NUMBER)"
                                          (click)="onFieldsMenuFieldClick(ds, field)" mat-menu-item>
                                     <span>{{field.name}}</span>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </mat-menu>
                    </span>
				</mat-menu>
			</ng-container> -->

			<!-- <ng-container *ngIf="dataset.type !== DatasetType.ACCOUNT_APPLICATION "> -->
			<div class="colorization-label">Colorize By</div>
				<mat-form-field [floatLabel]="'never'">
					<mat-select [(value)]="renderingOptions.converterOptions.fieldID" placeholder="Field" class="location-address-select">
						<ng-container *ngFor="let field of dataset.fields">
							<mat-option
									*ngIf="!field.isHighCardinality && !field.isGenerated && !field.tags?.includes('ID')"
                                    [class.disabled]="field.isHighCardinality || (renderingOptions.datasetStylingOptions.type === ColorScaleType.INTERVAL && field.baseType !== DatasetFieldType.NUMBER)"
                                    [value]="field.id" (click)="onFieldsMenuFieldClick(dataset, field)">
								{{field.name | titlecase}}
							</mat-option>
						</ng-container>
					</mat-select>
				</mat-form-field>
<!-- </ng-container> -->
		</div>

		<div class="colorization-cell">
			<div class="colorization-label">Opacity</div>
			<mat-slider class="example-margin" min="0" max="1" step="0.1" color="accent"
			            [(ngModel)]="renderingOptions.datasetStylingOptions.backgroundOpacity">
			</mat-slider>
		</div>

		<div class="colorization-cell" *ngIf="ColorScaleType.CONSTANT === renderingOptions.datasetStylingOptions.type">
		</div>
	</div>

	<div class="colorization-etc">
		<div
				*ngIf="ColorScaleType.CONSTANT === renderingOptions.datasetStylingOptions.type || ColorScaleType.INTERVAL === renderingOptions.datasetStylingOptions.type"
				class="colorization-cell" style="display: flex;align-items: center;">
			<div class="colorization-label">Color</div>
			<div class="color-square">
				<input class="color-picker-input"
					   cpColorMode="true"
					   [style.background]="defaultColor"
					   (colorPicker)="defaultColor"
					   [ngStyle]="{'background': defaultColor}"
					   (colorPickerChange)="onConstantColorChanged($event)"
					   style="bottom: 2px !important;"
				>
            </div>
		</div>

		<div
				*ngIf="ColorScaleType.FIXED === renderingOptions.datasetStylingOptions.type || ColorScaleType.GRADIENT === renderingOptions.datasetStylingOptions.type"
				class="colorization-cell">
			<div class="colorization-label">Colors</div>
			<div class="color-box-container" style="flex-wrap: wrap;">
				<ng-container *ngFor="let color of base64Colors; let i = index;">
					<div class="color-square" style="margin: 0 6px 6px 0">
						<input class="color-picker-input"
							cpColorMode="true"
							[style.background]="color"
							(colorPicker)="color"
							[ngStyle]="{'background': color}"
							(colorPickerChange)="onColorChange($event, i)"
							style="bottom: 1px !important; width: 16px !important; height: 16px !important;"
						>
                    <span (click)="onRemoveColor(i, $event)" class="remove-color-icon">
                        <mat-icon>remove</mat-icon>
                    </span>
					</div>
				</ng-container>
				<span (click)="addColors()" class="color-square add-color-icon" style="margin: 0 6px 6px 0">
                <mat-icon style="position: relative;left: 0.5px;">add</mat-icon>
            </span>
			</div>
		</div>

		<div *ngIf="ColorScaleType.INTERVAL === renderingOptions.datasetStylingOptions.type"
		     class="color-interval-container">
			<mpt-color-intervals (Update)="onApplySettings()"
			                     [intervals]="renderingOptions.datasetStylingOptions.intervalOptions"></mpt-color-intervals>
		</div>
	</div>
</div>
<div class="colorization-hint" *ngIf="dataset.isSelected">Your changes will appear on the map</div>
<div class="colorization-hint" *ngIf="!dataset.isSelected">The overlay is not active on the map</div>
<div class="colorization-config-box-controls">
	<button (click)="reset()" *ngIf="dataset.isSelected" mat-button mat-flat-button color="primary">
		Reset
	</button>
	<button (click)="onApplySettings()" *ngIf="dataset.isSelected" mat-button mat-flat-button color="accent">
		Apply
	</button>
</div>

<!-- <div class="logo">
    <img src="../../../assets/images/{{logo}}" alt="logo">
</div> -->
<div class="login-content">
    <router-outlet></router-outlet>
</div>

<!-- <div class="bubbles">
    <img src="../../../assets/images/login-bubble.svg" class="bubble-style" alt="watermark">
</div> -->

<div class="page-container">
    <div class="page-content">
        <map-dataset-config
                            (resetThematicDatasetSettings)="resetThematicDatasetSettings()"
                            *ngIf="dataset" #datasetConfig
                            [datasetStylingOptions]="dataset.stylingOptions"
                            [form]="editOverlayForm"
                            [fields]="dataset.fields"
                            [groups]="groups"
                            [thematicDatasets]="thematicDatasets"
                            [dataset]="dataset"
                            (fileUploaded)="fetchDatasetAndThematicOverlays()">
        </map-dataset-config>
        <div *ngIf="!dataset" class="spinnder-container">
            <mat-spinner diameter="40" color="accent"></mat-spinner>
        </div>
    </div>
</div>

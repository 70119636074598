import { UntypedFormBuilder, Validators } from '@angular/forms';
import { DatasetGeometryType } from 'src/app/model/dataset/dataset-geometry-type';
import { DatasetType } from 'src/app/model/dataset/dataset-type';
import { ColorScaleType } from '../model/dataset/rendering/color-scale-type';
import { Dataset } from '../model/dataset/dataset';
import { removeSpaces } from '../shared/validators/FormControlValidators/TrimTextValue';
import { ShowInMap } from '../model/dataset/dataset-config-filter.enum';


export class OverlaysConstants {

    public static readonly  HIDE_GROUP_FROM_TABLE_COLUMNS_TAG = 'hide_from_table_columns';
    public static readonly NRI_EXTERNAL_DATASET_ID = "risk_index_tract";

}

export class CreateOverlayControlsConfig {
    readonly id = undefined;
    readonly name = ['', Validators.required];
    readonly displayName = [[]];
    readonly fields = [[], Validators.minLength(1)];
    readonly geometryDatasets = [[]];
    readonly geometryType = [DatasetGeometryType.POINT, Validators.required];
    readonly overlayGroupId = [null];
    readonly isGeocoded = [false, Validators.required];
    readonly isLinkable = [true, Validators.required];
    // id for private overlay
    readonly accountID = [null];
    readonly isRefreshable = false;
    readonly lastRefreshDate = undefined;
    readonly mainSummaryFieldIndex = [-1, Validators.required];
    readonly type = [{ value: DatasetType.GLOBAL_OVERLAY, disabled: true }, Validators.required];
    readonly geometryShardCount = [null];
    readonly stylingOptions = this.formBuilder.group({
        type: ColorScaleType.CONSTANT,
        colorizationFieldIndex: null,
        defaultColor: null,
        colors: [],
        markerId: undefined,
        backgroundOpacity: [1, [Validators.min(0), Validators.max(1)]]
    });
    readonly thematicMapSettings = this.formBuilder.group({
        isThematicMapDataset: [{value: false, disabled: true}],
        parentDatasetId: null,
        parentLinkFieldId: null,
        mainThematicFieldId: null
    });

    readonly isGeoserver = [false];
    readonly geoserverDatasetId = [null, [removeSpaces]];
    readonly rasterId = [null, [removeSpaces]];
    readonly geoWorkspace = [null, [removeSpaces]];

    constructor(private readonly formBuilder: UntypedFormBuilder) {
    }
}

export class EditOverlayControlsConfig {
    readonly id = [this.dataset.id, Validators.required];
    readonly name = [this.dataset.name, Validators.required];
    readonly displayName = [this.dataset.displayName];
    readonly fields = [this.dataset.fields, Validators.required];
    readonly geometryDatasets = [this.dataset.geometryDatasets];
    readonly geometryType = [this.dataset.geometryType, Validators.required];
    readonly isGeocoded = [this.dataset.isGeocoded, Validators.required];
    readonly isLinkable = [this.dataset.isLinkable, Validators.required];
    readonly isRefreshable = [this.dataset.isRefreshable];
    readonly accountID = [this.dataset.accountID || null];
    readonly lastRefreshDate = [this.dataset.lastRefreshDate];
    readonly mainSummaryFieldIndex = [this.dataset.mainSummaryFieldIndex, Validators.required];
    readonly type = [{ value: this.dataset.type, disabled: true }, Validators.required];
    readonly version = [this.dataset.version, Validators.required];
    readonly geometryShardCount = [{value: (this.dataset.geometryShardCount ? this.dataset.geometryShardCount * 50 : null), disabled: true}];
    readonly overlayGroupId = [this.dataset.overlayGroupId];
    readonly showInMap = [this.dataset.showInMap];
    
    readonly stylingOptions = this.formBuilder.group({
        type: this.dataset.stylingOptions.type,
        colorizationFieldIndex: this.dataset.stylingOptions.colorizationFieldIndex,
        defaultColor: this.dataset.stylingOptions.defaultColor,
        colors: [this.dataset.stylingOptions.colors],
        markerId: this.dataset.stylingOptions.markerId,
        backgroundOpacity: [this.dataset.stylingOptions.backgroundOpacity, [Validators.min(0), Validators.max(1)]]
    });
    readonly thematicMapSettings = this.formBuilder.group({
        isThematicMapDataset: this.dataset.thematicMapSettings ? this.dataset.thematicMapSettings.isThematicMapDataset : false,
        parentDatasetId: this.dataset.thematicMapSettings ? this.dataset.thematicMapSettings.parentDatasetId : null,
        parentLinkFieldId: this.dataset.thematicMapSettings ? this.dataset.thematicMapSettings.parentLinkFieldId : null,
        mainThematicFieldId: this.dataset.thematicMapSettings ? this.dataset.thematicMapSettings.mainThematicFieldId: null
    });


  constructor(
        private readonly formBuilder: UntypedFormBuilder,
        private readonly dataset: Dataset
    ) {
    }
}


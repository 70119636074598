import {DatasetField} from '../../model/dataset/field/dataset-field';
import {DatasetFieldType} from '../../model/dataset/dataset-field-type';
import {DatasetFieldSpecificType} from '../../model/dataset/dataset-field-specific.type';
import {TableColumnType} from '../../model/upload/table/table-column';

export class DatasetUtils {

    static getDatasetFieldType(field: DatasetField): DatasetFieldType {
        switch (field.type) {
            case DatasetFieldSpecificType.CHECKBOX_TEXT_FIELD:
            case DatasetFieldSpecificType.EMAIL_TEXT_FIELD:
            case DatasetFieldSpecificType.LONG_TEXT_FIELD:
            case DatasetFieldSpecificType.RADIO_TEXT_FIELD:
            case DatasetFieldSpecificType.SELECT_TEXT_FIELD:
            case DatasetFieldSpecificType.SHORT_TEXT_FIELD:
            case DatasetFieldSpecificType.TELEPHONE_TEXT_FIELD:
            case DatasetFieldSpecificType.URL_TEXT_FIELD:
                return DatasetFieldType.TEXT;
            case DatasetFieldSpecificType.DATE_FIELD:
                return DatasetFieldType.DATE_TIME;
            case DatasetFieldSpecificType.NUMBER_FIELD:
                return DatasetFieldType.NUMBER;
        }
    }

    static createUniqueIdentifierForDatasetField(datasetId: string, fieldId: string) {
        return datasetId + '_' + fieldId;
    }

    static createUniqueIdentifierForDatasetFieldForGroup(datasetId: string, fieldId: string, index:number) {
        return datasetId + '_' + fieldId + '_' + index.toString();
    }

    static getTableColumnType(field: DatasetField): TableColumnType {
        switch (field.baseType) {
            case DatasetFieldType.TEXT:
                return field.isArray ? TableColumnType.TEXT_LIST : TableColumnType.TEXT;
            case DatasetFieldType.NUMBER:
                return field.isArray ? TableColumnType.DECIMAL_LIST : TableColumnType.DECIMAL;
            case DatasetFieldType.DATE_TIME:
                return field.isArray ? TableColumnType.DATE_LIST : TableColumnType.DATE;
        }
    }

}

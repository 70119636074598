import { Component, EventEmitter, Inject, OnInit, Output } from "@angular/core";
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef,
} from "@angular/material/dialog";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { GeographicalRegionsService } from "../geographical-regions.service";
import { GeographicalRegion } from "../../model/geographical-region/geographical-region";
import { NotifService } from "../../core/notification/notif.service";

@Component({
    selector: "map-dialog-update-region",
    templateUrl: "./dialog-update-region.component.html",
    styleUrls: ["./dialog-update-region.component.scss"],
})
export class DialogUpdateRegionComponent implements OnInit {
    @Output("onUpdateSuccess") onUpdateSuccess =
        new EventEmitter<GeographicalRegion>();
    updateRegionForm: UntypedFormGroup;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: GeographicalRegion,
        private fb: UntypedFormBuilder,
        public dialogRef: MatDialogRef<DialogUpdateRegionComponent>,
        private readonly geoRegionsService: GeographicalRegionsService,
        private modalService: MatDialog,
        private readonly notifService: NotifService
    ) {}

    ngOnInit() {
        // console.log(this.data);
        this.initForm();
    }

    initForm() {
        this.updateRegionForm = this.fb.group({
            name: [this.data.name, Validators.required],
        });
    }

    updateRegion() {
        if (this.updateRegionForm.valid) {
            let request = this.updateRegionForm.value.name;
            this.geoRegionsService
                .updateRegion(this.data.id, request)
                .subscribe(
                    (response) => {
                        this.onUpdateSuccess.emit(request);
                        this.modalService.closeAll();
                        this.notifService.success("Region Updated");
                    },
                    (error) => {
                        this.notifService.error(error.error.message);
                    }
                );
        }
    }

    onDismiss() {
        this.dialogRef.close(false);
    }
}

import { Component, Input, Output, EventEmitter } from "@angular/core";
import { TreeStructure } from "src/app/model/menu/tree-structure";

@Component({
    selector: "app-multi-level-dropdown",
    templateUrl: "./multi-level-dropdown.component.html",
    styleUrls: ["./multi-level-dropdown.component.scss"],
})
export class MultiLevelDropdownComponent {
    @Input() items: TreeStructure[];
    @Input() isDisabled: boolean;
    @Input() lastItemDisable: boolean;
    @Input() ulClass: string;
    @Output() selectedItems = new EventEmitter<TreeStructure[]>();

    isLastSelectedDisabled(items: any[], currentItem: any): boolean {
        const selectedItems = items.filter(item => item.selected);
        const indeterminateItems = items.filter(item => this.isIndeterminate(item));

        if(selectedItems.length <= 1 && indeterminateItems.length === 0 && currentItem.selected){
            return true;
        }
    }

    checkIfParentIsDisabled(){

    }

    toggleItem(item: TreeStructure): void {
        this.checkInnerItems(item, item.selected);
        this.checkParentItems(item, this.items);
        this.emitSelectedItems();
    }

    checkInnerItems(item: TreeStructure, selected: boolean): void {
        if (item.children) {
            item.children.forEach((child) => {
                child.selected = selected;
                this.checkInnerItems(child, selected);
            });
        }
    }

    isIndeterminate(item: TreeStructure): boolean {
        if (item.children) {
            const selectedChildren = item.children.filter(
                (child) => child.selected
            );
            if (
                selectedChildren.length > 0 &&
                selectedChildren.length < item.children.length
            ) {
                return true;
            } else {
                return item.children.some((child) =>
                    this.isIndeterminate(child)
                );
            }
        }
        return false;
    }

    checkParentItems(item: TreeStructure, items: TreeStructure[]): void {
        const parent = this.findParentItem(item, items);
        if (parent) {
            const hasCheckedChildren = parent.children.every(
                (child) => child.selected
            );
            parent.selected = hasCheckedChildren;
            this.checkParentItems(parent, items);
        }
    }

    findParentItem(
        item: TreeStructure,
        items: TreeStructure[]
    ): TreeStructure | undefined {
        for (const parentItem of items) {
            if (parentItem.children && parentItem.children.includes(item)) {
                return parentItem;
            } else if (parentItem.children) {
                const foundParent = this.findParentItem(
                    item,
                    parentItem.children
                );
                if (foundParent) {
                    return foundParent;
                }
            }
        }
        return undefined;
    }

    emitSelectedItems(): void {
        const selectedItems = this.getSelectedItems(this.items);
        this.selectedItems.emit(selectedItems);
    }

    getSelectedItems(items: TreeStructure[]): TreeStructure[] {
        let selectedItems: TreeStructure[] = [];
        items.forEach((item) => {
            if (item.selected) {
                selectedItems.push(item);
            }
            if (item.children) {
                selectedItems = selectedItems.concat(
                    this.getSelectedItems(item.children)
                );
            }
        });
        return selectedItems;
    }

    getSelectedItemsTillGivenItem(items: TreeStructure[], stopItem: TreeStructure): TreeStructure[] {
        let selectedItems: TreeStructure[] = [];
        items.forEach((item) => {
            if(item.id !== stopItem.id) {
                if (item.selected) {
                    selectedItems.push(item);
                }
                if (item.children) {
                    selectedItems = selectedItems.concat(
                        this.getSelectedItems(item.children)
                    );
                }
            }
        });
        return selectedItems;
    }

    toggleItemExpansion(item: TreeStructure): void {
        item.expanded = !item.expanded;
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AerisType } from '../core/aeris/AerisType';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AerisService {
    baseUrl = 'https://api.aerisapi.com/';

    constructor(private readonly http: HttpClient) {
    }

    fetchLocationProfile(type: AerisType, aerisClient:string, aerisSecret:string, lat: number, long: number): Observable<any[]> {
        return this.http.get<any[]>(`${this.baseUrl}${type}/${lat},${long}?format=json&client_id=${aerisClient}&client_secret=${aerisSecret}`);
    }

    fetchAerisDataWithinCircle(type: AerisType, aerisClient:string, aerisSecret:string, lat: number, lng: number, radius: number, limit: number) {
        return this.http.get<any[]>(`${this.baseUrl}${type}/within?p=${lat},${lng}&radius=${radius}mi&format=json&limit=${limit}&client_id=${aerisClient}&client_secret=${aerisSecret}`);
    }

    fetchAerisDataWithinRectangle(type: AerisType, aerisClient:string, aerisSecret:string, north: number, west: number, south: number, east: number, limit: number) {
        return this.http.get<any[]>(`${this.baseUrl}${type}/within?p=${north},${west},${south},${east}&format=json&limit=${limit}&client_id=${aerisClient}&client_secret=${aerisSecret}`);
    }

    fetchAerisDataWithinPolygon(type: AerisType, aerisClient:string, aerisSecret:string, points: string, limit: number ) {
        return this.http.get<any[]>(`${this.baseUrl}${type}/within?p=${points}&format=json&limit=${limit}&client_id=${aerisClient}&client_secret=${aerisSecret}`);
    }

    getAerisCredentials(): Observable<any>{
        return this.http.get<any>('/key/getAerisCredentials');
  }
}

import { NestedTreeControl } from "@angular/cdk/tree";
import { MatTreeNestedDataSource } from "@angular/material/tree";
import { TreeStructure } from "src/app/model/menu/tree-structure";

export class TreeStructureUtils {

    constructor() {
    }

    private createTreeControl() {
        return new NestedTreeControl<TreeStructure>(node => node.children);
    }

    public getTreeControl() {
        return this.createTreeControl();
    }

    private createDataSource() {
        return new MatTreeNestedDataSource<TreeStructure>();
    }

    public getDataSource() {
        return this.createDataSource();
    }

    hasChild = (_: number, node: TreeStructure) =>
        !!node.children && node.children.length > 0;
    setParent(data, parent) {
        data.parent = parent;
        if (data.children) {
            data.children.forEach(x => {
                this.setParent(x, data);
            });
        }
    }

    checkAllParents(node, treeControl) {
        if (node.parent) {
            const descendants = treeControl.getDescendants(node.parent);
            node.parent.selected = descendants.every(child => child.selected);
            node.parent.indeterminate = descendants.some(child => child.selected);
            this.checkAllParents(node.parent, treeControl);
        }
    }

    todoItemSelectionToggle(checked, node, treeControl) {
        node.selected = checked;
        if (node.children) {
            node.children.forEach(x => {
                this.todoItemSelectionToggle(checked, x, treeControl);
            });
        }
        this.checkAllParents(node, treeControl);
    }
}

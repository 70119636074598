import { NativeDateAdapter } from "@angular/material/core";

export class CustomDateAdapter extends NativeDateAdapter{
    format(date: Date, displayFormat: Object): string {
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        return this._to2digit(month) + '/' + this._to2digit(day) + '/' + year;
    }

    private _to2digit(n: number) {
        return ('00' + n).slice(-2);
    }
}
import { ColDef } from "ag-grid-community";
import { DatasetFieldType } from "src/app/model/dataset/dataset-field-type";
import { DatasetField } from "src/app/model/dataset/field/dataset-field";
import { DateUtils } from "./date-utils";

export class AgGridUtils {

  public static overlayLoadingTemplate() {
    return '<span class="ag-overlay-loading-center">Please wait while your datapoints are loading</span>';
  }

  public static overlayNoRowsTemplate() {
    return '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;">There are no datapoints to show</span>';
  }

  public static defaultColumnDef(minWidth: number): ColDef {
    return {
      editable: false,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      flex: 1,
      minWidth: minWidth,
      // allow every column to be aggregated
      enableValue: false,
      // allow every column to be grouped
      enableRowGroup: false,
      // allow every column to be pivoted
      enablePivot: false,

      allowedAggFuncs: ['sum', 'avg', 'min', 'max']
    };
  }

  public static columnTypes(minWidth: number) {
    return {
      textColumn: {
        minWidth: minWidth,
        filter: 'agTextColumnFilter',
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: [
            'empty',
            'equals'],
          buttons: ['apply', 'reset']
        },
      },
      textColumnHighCardinality: {
        minWidth: minWidth,
        filter: 'agTextColumnFilter',
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: [
            'empty',
            'equals',
            'contains',
          ],
          buttons: ['apply', 'reset']
        },
      },
      numberColumn: {
        cellClass: 'ag-right-aligned-cell',
        minWidth: minWidth, filter: 'agNumberColumnFilter',
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: [
            'empty',
            'lessThanOrEqual',
            'greaterThanOrEqual',
            'inRange'
          ],
          buttons: ['apply', 'reset']
        }
      },
      nonEditableColumn: { editable: false },
      dateColumn: {
        minWidth: minWidth,
        filter: 'agDateColumnFilter',
        valueFormatter: function (params) {
          return DateUtils.formatDate(new Date(params.value));
        },
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: [
            'empty',
            'equals',
            'lessThanOrEqual',
            'greaterThanOrEqual',
            'inRange'
          ],
          buttons: ['apply', 'reset'],
          comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
            const cellDate = new Date(cellValue);
            //ignore their time
            cellDate.setHours(0, 0, 0, 0);
            filterLocalDateAtMidnight.setHours(0, 0, 0, 0);
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            } else {
              return 0;
            }
          },
        },
      },
    };
  }

  public static getGridDataTypeByColumnType(type: string): string {
      switch (type) {
        case 'numberColumn':
          return 'number';
        case 'dateColumn':
          return 'date';
        case 'textColumnHighCardinality':
          return 'text';
        case 'textColumn':
          return 'text';
        default:
          break;
      }
      return 'text';
  }

  public static getGridColumnTypeByDatasetType(datasetField: DatasetField): string {
    if(datasetField.baseType === DatasetFieldType.TEXT && datasetField.isHighCardinality){
        return 'textColumnHighCardinality';
    }
    switch (datasetField.baseType) {
      case DatasetFieldType.NUMBER:
        return 'numberColumn';
      case DatasetFieldType.DATE_TIME:
        return 'dateColumn';
      case DatasetFieldType.TEXT:
        return 'textColumn';
      default:
        break;
    }
    return 'textColumn';
  }

  public static formatCash(n): any {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
  };

  public static prepareDateFilter(filterModel: any) {
    let minDateValue: any;
    let maxDateValue: any;
    if (filterModel.type === 'inRange') {
      minDateValue = filterModel.dateFrom;
      maxDateValue = filterModel.dateTo;
    } else if (filterModel.type === 'equals') {
      minDateValue = filterModel.dateFrom;
    } else if (filterModel.type === 'lessThanOrEqual') {
      maxDateValue = filterModel.dateFrom;
    } else if (filterModel.type === 'greaterThanOrEqual') {
      minDateValue = filterModel.dateFrom;
    }

    return {minDateValue: minDateValue, maxDateValue: maxDateValue};
  }

  public static prepareNumberFilter(filterModel: any) {
    let minNumberValue: any;
    let maxNumberValue;
    if (filterModel.type === 'inRange') {
      minNumberValue = filterModel.filter;
      maxNumberValue = filterModel.filterTo;
    } else if (filterModel.type === 'lessThanOrEqual') {
      maxNumberValue = filterModel.filter;
    } else if (filterModel.type === 'greaterThanOrEqual') {
      minNumberValue = filterModel.filter;
    }

    return {minNumberValue: minNumberValue, maxNumberValue: maxNumberValue};
  }

  public static prepareTextFilter(filterModel: any, activeField: DatasetField) {
    let searchValue;
    let textValues: string[] = [];
    if (filterModel.type === 'equals') {
      if (activeField.isDisplayedInFilter) {
        filterModel.filter
        .split(/(?<!\\),/)  // This splits on commas not preceded by a backslash
        .map(item => item.replace(/\\,/g, ',').trim()) // Replace escaped commas and trim spaces
        .forEach(v => textValues.push(v));  // Push the results into textValues
            } else {
        textValues.push(filterModel.filter);
      }
    } else if (filterModel.type === 'contains') {
      searchValue = filterModel.filter;
    }

    return {searchValue: searchValue, textValues: textValues};
  }

  public static pivot(pivotColumns, rowGroupCols, valueCols, response, groupKeys) {
    let pivotData = [];
    let aggColsList = [];

    let colKeyExistsMap = {};

    let pivotResultColDefs = [];
    let pivotResultColDefsMap = {};

    let pivotColumnIds = pivotColumns.map(function(result) {return result["field"];});
    let pivotValues = [];
    response.forEach(function (item, itemkey) {
      pivotValues = [];
      if (item.buckets.length) {
        item.buckets.forEach(element => {
          if (pivotColumnIds.includes(element.fieldID)) {
            pivotValues.push(element.value.toString());
          } else if (rowGroupCols.length > pivotColumnIds.length && groupKeys.length){
            pivotValues.push(groupKeys[0]);
          } else {
            pivotValues.push('-');
          }
        });
      }
      let pivotItem = {};

      valueCols.forEach(function (valueCol, key) {
        let valField = valueCol.id;

        var colKey =  createColKey(pivotValues, valField);

        let value = item.values[key].result;
        pivotItem[colKey] = value;

        if (!colKeyExistsMap[colKey]) {
          addNewAggCol(colKey, valueCol);
          addNewPivotResultColDef(colKey, pivotValues, valueCol);
          colKeyExistsMap[colKey] = true;
        }
      });

      if (rowGroupCols.length > pivotColumnIds.length && groupKeys.length > 0) {
        rowGroupCols.forEach(function (rowGroupCol, key) {
          if (item.buckets[key] !== undefined){

          let rowGroupField = item.buckets[key].fieldID;
          pivotItem[rowGroupField] = item.buckets[key].value;}
        });
        pivotData.push(pivotItem);
      } else {
        rowGroupCols.forEach(function (rowGroupCol, key) {
          if (item.buckets[key] !== undefined){

          let rowGroupField = rowGroupCol.id;
          pivotItem[rowGroupField] = item.buckets[key].value;}
        });
        pivotData.push(pivotItem);
      }
    });

    function addNewAggCol(colKey, valueCol) {
      let newCol = {
        id: colKey,
        field: colKey,
        aggFunc: valueCol.aggFunc,
      };
      aggColsList.push(newCol);
    }

    function createColKey(pivotValues, valueField) {
      var result = pivotValues.join('|');
      if (valueField !== undefined) {
        result += '|' + valueField;
      }
      return result;
    }

    function addNewPivotResultColDef(colKey, pivotValues, valueCol) {
      let parentGroup = null;

      let keyParts = [];

      pivotValues.forEach(pivotValue => {
        keyParts.push(pivotValue);
        let colKey =  createColKey(keyParts, undefined);
        let groupColDef = pivotResultColDefsMap[colKey];
        if (!groupColDef) {
          groupColDef = {
            groupId: colKey,
            headerName: pivotValue,
            children: [],
            filter: false,
            sortable: false
          };
          pivotResultColDefsMap[colKey] = groupColDef;
          if (parentGroup) {
            parentGroup.children.push(groupColDef);
          } else {
            pivotResultColDefs.push(groupColDef);
          }
        }
        parentGroup = groupColDef;
      });

      parentGroup.children.push({
        colId: colKey,
        headerName: valueCol.aggFunc + '(' + valueCol.displayName + ')',
        field: colKey,
        filter: false,
        sortable: false
      });
    }

    return {
      data: pivotData,
      aggCols: aggColsList,
      pivotResultColDefs: pivotResultColDefs,
    };
  }

  public static buildGroupsFromData(rowData, rowGroupCols, groupKeys, valueCols) {
    let rowGroupCol = rowGroupCols[groupKeys.length];
    let field = rowGroupCol.id;
    let mappedRowData = AgGridUtils.groupBy(rowData, field);
    let groups = [];

    AgGridUtils.iterateObject(mappedRowData, function (key, rowData) {
      let groupItem = AgGridUtils.aggregateList(rowData, valueCols);
      groupItem[field] = key;
      groups.push(groupItem);
    });
    return groups;
  }

  public static iterateObject(object, callback) {
    if (!object) {
      return;
    }
    var keys = Object.keys(object);
    for (var i = 0; i < keys.length; i++) {
      var key = keys[i];
      var value = object[key];
      callback(key, value);
    }
  }

  public static groupBy(data, field) {
    var result = {};
    data.forEach(function (item) {
      var key = item[field];
      var listForThisKey = result[key];
      if (!listForThisKey) {
        listForThisKey = [];
        result[key] = listForThisKey;
      }
      listForThisKey.push(item);
    });
    return result;
  }

  public static aggregateList(rowData, valueCols) {
    var result = {};

    valueCols.forEach(function (valueCol) {
      var field = valueCol.id;

      var values = [];
      rowData.forEach(function (childItem) {
        var value = childItem[field];
        // if pivoting, value will be undefined if this row data has no value for the column
        if (value !== undefined) {
          values.push(value);
        }
      });

      // the aggregation we do depends on which agg func the user picked
      switch (valueCol.aggFunc) {
        case 'sum':
          var sum = 0;
          values.forEach(function (value) {
            sum += value;
          });
          result[field] = sum;
          break;
        case 'min':
          var min = null;
          values.forEach(function (value) {
            if (min === null || min > value) {
              min = value;
            }
          });
          result[field] = min == null ? 0 : min;
          break;
        case 'max':
          var max = null;
          values.forEach(function (value) {
            if (max === null || max < value) {
              max = value;
            }
          });
          result[field] = max == null ? 0 :  max;
          break;
        case 'avg':
          var avg = 0;
          values.forEach(function (value) {
            if (value !== undefined || value !== null) {
              avg = value;
            }
          });
          result[field] = avg;
          break;
        case 'random':
          result[field] = Math.random(); // just make up a number
          break;
        default:
          console.warn(
            'unrecognised aggregation function: ' + valueCol.aggFunc
          );
          break;
      }
    });

    return result;
  }

  public static setPivotModeFilter(request, response) {
    const pivotColumns = request.pivotCols;
    const rowGroupCols = request.rowGroupCols;
    let valueCols = request.valueCols;
    const pivotActive = request.pivotMode && pivotColumns.length > 0 && valueCols.length > 0;
    let groupKeys = request.groupKeys;
     // if pivoting, this gets set
    let pivotResultColDefs: any = null;
    // assume 1 pivot col and 1 value col for this example
    if (pivotActive) {
      var pivotResult =  AgGridUtils.pivot(pivotColumns, rowGroupCols, valueCols, response, groupKeys);
      response = pivotResult.data;
      valueCols = pivotResult.aggCols;
      pivotResultColDefs = pivotResult.pivotResultColDefs;
    }
    // if not grouping, just return the full set
    if (rowGroupCols.length > 0) {
      var showingGroupLevel = rowGroupCols.length > groupKeys.length;

      if (showingGroupLevel) {
        response = AgGridUtils.buildGroupsFromData(
          response,
          rowGroupCols,
          groupKeys,
          valueCols
        );
      } else if (request.pivotMode) {
        // if pivot mode active, but no grouping, then we aggregate everything in to one group
        var rootGroup = AgGridUtils.aggregateList(response, valueCols);
        response = [rootGroup];
      }
    }
    return {
      response: response,
      valueCols: pivotResult.aggCols,
      pivotResultColDefs: pivotResult.pivotResultColDefs
    }
  }

  public static getLastRowIndex(request, results) {
    if (!results || results.length === 0) {
      return null;
    }
    let currentLastRow = request.startRow + results.length;

    return currentLastRow <= request.endRow ? currentLastRow : -1;
  }


}

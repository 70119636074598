import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {DatapointFilter} from '../../model/datapoint/filter/datapoint-filter';
import { NotifService } from 'src/app/core/notification/notif.service';
import { HeatmapResponse } from 'src/app/dataset/clustering/heatmap-response';
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class AccumulationsDataService {

    constructor(private http: HttpClient, private  notif: NotifService) {
    }

    fetchHeatmapData(request: DatapointFilter, datasetHeatmapFieldId: string): Observable<HeatmapResponse> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append('datasetHeatmapFieldId', datasetHeatmapFieldId);
        return this.http.post<HeatmapResponse>('/heatmap', request, {params: queryParams});
    }

}

<div class="public-content">
	<div class="public-text">
		<img src="../../../assets/images/logo-icon-dark.svg" style="width: 300px; height: auto;" alt="logo">
	</div>
	<div class="public-form" style="display: flex; flex-direction: column">
		<div class="two-fa-instructions">
			<p>Step 1: Download Google Authenticator on your smartphone <br>
				Step 2: Click on the + button and scan the barcode <br>
				Step 3: Input here below the code generated by Google Authenticator <br> <br>
				Voila! Your account is secured with two factor authentication.</p>
			<div class="two-fa-qrcode">
				<img [src]="'data:image/jpg;base64,'+twoFactorAuthKey?.qrCodeBase64"/>
			</div>
		</div>
		<form [formGroup]="twoFactorAuthForm">
			<mat-form-field [floatLabel]="'never'" color="#fff">
				<mat-label>Code</mat-label>
				<input matInput trimValue autocomplete="off" [errorStateMatcher]="matcher" required formControlName="code" placeholder="__ __ __    __ __ __">
				<mat-error *ngIf="twoFactorAuthForm.controls.code.hasError('required')">
					Code is required
				</mat-error>
			</mat-form-field>
			<div class="link-button"
			     [routerLink]="['/public/login']">Go to login
			</div>
			<button mat-stroked-button
			        color="primary"
			        [disabled]="twoFactorAuthForm.invalid"
			        (click)="submit()">Submit
			</button>
		</form>
	</div>
</div>


export enum DatasetConfigFilterEnum {
    ALL,
    INTERNAL,
    EXTERNAL,
    NRI,
    TENSORFLIGHT,
    MUNICHRE
}

export enum ShowInMap {
    Filter="FILTER",
    Panel="PANEL",
    Filter_Panel="FILTER_PANEL"
}


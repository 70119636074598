<div *ngIf="loading" class="spinner-container">
    <mat-spinner class="spinner" diameter="40" color="accent"></mat-spinner>
</div>
<div class="form-container" [class.hidden]="loading">
    <form *ngIf="createDraftForm" [formGroup]="createDraftForm">
        <multi-field
            *ngFor="let field of fields"
            [formControlName]="field.name"
            [fieldType]="field.type"
            [fieldName]="field.name">
        </multi-field>
    </form>
    <button [disabled]="loading" type="submit" mat-flat-button color="accent"
            (click)="createDraft(createDraftForm.value)"
            mat-raised-button>
        Submit
    </button>
</div>

<div class="public-content">
    <div class="public-text">
        <img src="../../../assets/images/logo-icon-dark.svg" style="width: 300px; height: auto;" alt="logo">
    </div>
    <div class="public-form">
        <div *ngIf="!isTokenValid" style="color: white;">
            <div>You have already reset the password. This link is expired.</div>
            <div class="go-to-login-btn" [routerLink]="['/public/login']">GO TO LOGIN</div>
        </div>
        <form [formGroup]="forgotPasswordForm" *ngIf="isTokenValid" (ngSubmit)="onSubmit()">
            
            <div class="link-button" [routerLink]="['/public/login']">Login instead?</div>
            <mat-form-field [floatLabel]="'never'">
                <mat-label>Create your new password</mat-label>
                <input matInput autocomplete="off" required formControlName="password" type="password">
                <mat-error *ngIf="forgotPasswordForm.controls.password.hasError('required')">
                    New Password is required.
                </mat-error>
            </mat-form-field>
            <mat-form-field [floatLabel]="'never'">
                <mat-label>Enter the password to confirm</mat-label>
                <input matInput autocomplete="off" required formControlName="confirmPassword" type="password">
                <mat-error *ngIf="forgotPasswordForm.controls.password.hasError('required')">
                    confirm password is required.
                </mat-error>
            </mat-form-field>

            <mat-error *ngIf="forgotPasswordForm.hasError('passwordsNotMatching')" style="padding: 8px; border-radius: 6px; background-color: #fff; margin-bottom: 10px;">
                <div class="icon-setting">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="13" height="13">
                        <!-- Outer Circle -->
                        <circle cx="50" cy="50" r="45" stroke="red" stroke-width="5" fill="white" />
                        <!-- Exclamation Mark -->
                        <rect x="45" y="25" width="10" height="30" fill="red" />
                        <circle cx="50" cy="70" r="6" fill="red" />
                      </svg> &nbsp;
                Passwords do not match.</div>
              </mat-error>
              
            <mat-error *ngIf="forgotPasswordForm.hasError('weak')" style="padding: 8px; border-radius: 6px; background-color: #fff;">
                Your New Password must contain : <br>
                    <div class="icon-setting">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="13" height="13">
                            <!-- Outer Circle -->
                            <circle cx="50" cy="50" r="45" stroke="red" stroke-width="5" fill="white" />
                            <!-- Exclamation Mark -->
                            <rect x="45" y="25" width="10" height="30" fill="red" />
                            <circle cx="50" cy="70" r="6" fill="red" />
                          </svg>
                                                  
                          &nbsp;Atleast 8 or more characters,
                    </div>
                    <div class="icon-setting">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="13" height="13">
                            <!-- Outer Circle -->
                            <circle cx="50" cy="50" r="45" stroke="red" stroke-width="5" fill="white" />
                            <!-- Exclamation Mark -->
                            <rect x="45" y="25" width="10" height="30" fill="red" />
                            <circle cx="50" cy="70" r="6" fill="red" />
                          </svg>
                                    
                      &nbsp;Atleast one number,</div>
                    <div class="icon-setting">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="13" height="13">
                            <!-- Outer Circle -->
                            <circle cx="50" cy="50" r="45" stroke="red" stroke-width="5" fill="white" />
                            <!-- Exclamation Mark -->
                            <rect x="45" y="25" width="10" height="30" fill="red" />
                            <circle cx="50" cy="70" r="6" fill="red" />
                          </svg>
                                    &nbsp;Atleast one uppercase character,</div>
                    <div class="icon-setting">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="13" height="13">
                            <!-- Outer Circle -->
                            <circle cx="50" cy="50" r="45" stroke="red" stroke-width="5" fill="white" />
                            <!-- Exclamation Mark -->
                            <rect x="45" y="25" width="10" height="30" fill="red" />
                            <circle cx="50" cy="70" r="6" fill="red" />
                          </svg>
                                    
                          &nbsp;Atleast one lowercase character</div>
                    <div class="icon-setting">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="13" height="13">
                            <!-- Outer Circle -->
                            <circle cx="50" cy="50" r="45" stroke="red" stroke-width="5" fill="white" />
                            <!-- Exclamation Mark -->
                            <rect x="45" y="25" width="10" height="30" fill="red" />
                            <circle cx="50" cy="70" r="6" fill="red" />
                          </svg>
                                    
                          &nbsp;Atleast one special character</div>
              </mat-error>
            <!-- <div class="forgot-description" [routerLink]="['/public/login']">You will receive an email to confirm the reset of the password</div> -->
            <button mat-stroked-button color="primary" [disabled]="forgotPasswordForm.hasError('weak') || forgotPasswordForm.hasError('passwordsNotMatching')" style="margin-top: 10px;">Reset</button>
        </form>
    </div>
</div>

<mat-select style="height:0px; width:0px; visibility:hidden" #filterElement [(ngModel)]="currentValue" multiple>
    <mat-select-trigger style="display:none">
        <mat-icon>home</mat-icon>&nbsp;
    </mat-select-trigger>
    <mat-option id="search-option">
        <input
            autocomplete="off"
            (keyup)="onKey($event)"
            matInput
            placeholder="Search"
            value=""
        />
    </mat-option>
    <mat-option
        (click)="onValueChange()"
        *ngFor="let field of filteredFields"
        [value]="field.id"
        >{{ field.name }}</mat-option
    >
</mat-select>
<span  (click)="filterElement.open()">
    <mat-icon >filter_list</mat-icon>&nbsp;Filter
</span>

import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    Output
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { createGroupControlsConfig, groupTypes } from './account-create-groups-panel.constants';
import { CreateGroupRequest, Group } from '../../../model/group/group';
import { GroupService } from '../../../data-access-layer/groups/group.service';

@Component({
    selector: 'map-account-create-groups-panel',
    templateUrl: './account-create-groups-panel.component.html',
    styleUrls: ['./account-create-groups-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountCreateGroupsPanelComponent implements OnChanges {
    createGroupForm: UntypedFormGroup;
    @HostBinding('class') class = 'pagePanelCreateGroup';
    @Input() groups: Group[];
    @Output() created = new EventEmitter<string>();
    groupType: any;
    parentGroupId: number;

    readonly ROW_GROUP = 1;
    readonly COLUMN_GROUP = 2;

    constructor(
        private readonly groupService: GroupService,
        private readonly formBuilder: UntypedFormBuilder) {


    }

    ngOnChanges() {
        this.initForm();
    }

    createGroup(): void {
        this.groupService.createGroup(this.constructCreateGroupRequest()).subscribe(group => {
            this.creationComplete();
        });
    }

    creationComplete(): void {
        this.groups = [];
        this.created.emit('group created');
    }

    private constructCreateGroupRequest(): CreateGroupRequest {
        const request: CreateGroupRequest = {
            parentID: this.createGroupForm.controls.type.value == groupTypes.ROW_GROUP ? this.createGroupForm.controls.parentID.value : this.parentGroupId,
            name: this.createGroupForm.controls.name.value.trim(),
            type: this.createGroupForm.controls.type.value
        };
        return request;
    }

    initForm() {
        this.createGroupForm = this.formBuilder.group(createGroupControlsConfig);
        if (this.groups && this.groups.length > 0) {
            const parentGroup = this.groups.find(element => element.parentId == null);
            if (parentGroup !== undefined) {
            this.parentGroupId = parentGroup.id;
            this.createGroupForm.patchValue({ parentID: this.parentGroupId});
            }
        }
    }

    showParentGroup() {
        return groupTypes.ROW_GROUP == this.createGroupForm.controls.type.value;
    }
}

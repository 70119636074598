<div [class.collapsed]="!isExpanded" class="panel flex column" [style.minWidth.px]=" width && isExpanded ? width : 0"
     [style.width.px]="width && isExpanded ? dragWidth : 0"
     (mouseup)="onMouseUp($event)" style="height: calc(100% - 58px); top: 18px;right: 32px !important;">
	<div class="updating-overlay" [style.display] = "updating ? 'block' : 'none'"></div>
	<div *ngIf="!datapoint" class="spinner-container">
		<mat-spinner class="spinner" diameter="40" color="accent"></mat-spinner>
	</div>
	<div class="panel-name location-right-panel">
		<div *ngIf="!isOverlay" class="panel-title">
			<form [formGroup]="selectedGroup">
				<div>
					<span class="span-panel-title"> {{details?.dataset.name}} Profile</span>
					<mat-form-field appearance="fill" class="location-profile-dropown-new">
						<mat-select matNativeControl formControlName="defaultSelected" (selectionChange)="selectionChange($event)" class="location-address-select">
							<ng-container *ngFor="let list of menuList">
								<mat-option [value]="list[0]">&nbsp;{{ list[1] }}</mat-option >
							</ng-container>
						</mat-select>
					</mat-form-field>
				</div>
			</form>
		</div>
		<div *ngIf="isOverlay"  class="panel-title">
			{{details?.dataset.name}} Profile
		</div>
		<div class="panel-actions">
			<mat-icon (click)="onExpandedClick(false)" aria-hidden="false">close</mat-icon>
		</div>
	</div>
	<div class="panel-body details-expanded location-panel-body" *ngIf="datapoint">

		<div *ngIf="isOverlay" class="details-info">
			<mat-card>
				<div class="detail-title">
					Details
				</div>
				<div *ngIf="fields.length">
					<ng-container *ngFor="let field of fields">
						<div [class]="getClass()">
							<span [class.white-space]="isAppliedWrap">{{ field.name }}</span>
							<span *ngIf="field.type === DatasetFieldSpecificType.DATE_FIELD" [class.white-space]="isAppliedWrap" >{{ field.value | maptycsDate: true}}</span>
							<span *ngIf="field.type === DatasetFieldSpecificType.NUMBER_FIELD" [class.white-space]="isAppliedWrap">{{ field.value | number}}</span>
							<span *ngIf="field.type === DatasetFieldSpecificType.URL_TEXT_FIELD">
								<a class="hover-link" target="_blank" href="{{field.value}}">{{ field.value}}</a>
							</span>
							<span *ngIf="field.type !== DatasetFieldSpecificType.NUMBER_FIELD && field.type !== DatasetFieldSpecificType.DATE_FIELD && field.type !== DatasetFieldSpecificType.URL_TEXT_FIELD" [class.white-space]="isAppliedWrap">
								{{ field.value}}
							</span>
						</div>
					</ng-container>
				</div>
			</mat-card>
		</div>
		<ng-container *ngIf="!isOverlay">
			<div>
			<map-location-profile [datasetFields]="datasetFields" [datapoint]="datapoint"
			                      [tessadataPOIDatasets]="externalPOIDatasets"
			                      [tessadataPOIDatasetsStructures]="externalPOIDatasetsStructures"
								  [dropdownSelectedValue]="selectedValue.value"
								  (poiClosestSubmittedFilter)="poiClosestSubmittedFilter = $event">
			</map-location-profile>
		</div>
			<map-location-profile-attachments [datapoint]="datapoint"
											  [datasetId]="dataset.id"
											  (locationHasUploadedAttachments)="locationHasUploadedAttachments = $event">
			</map-location-profile-attachments>
		</ng-container>
		<span class="actions-button ellipsis-btn" [matMenuTriggerFor]="actionsMenu">
			<svg width="16" height="16" viewBox="0 0 9 9" xmlns="http://www.w3.org/2000/svg">
				<g fill="#0044f1" fill-rule="evenodd">
					<circle cx="4.5" cy="1" r="1" />
					<circle cx="4.5" cy="4" r="1" />
					<circle cx="4.5" cy="7" r="1" />
				</g>
			</svg>
		</span>
		<mat-menu #actionsMenu="matMenu" class="nested-menu header-menu" backdropClass="mat-menu-header">
			<div mat-menu-item (click)="enableEditMode()" *ngIf="hasWriteAccess">
				<span><mat-icon fontIcon="fa-pencil-alt" fontSet="fas"></mat-icon></span>
				Edit
			</div>
			<div mat-menu-item (click)="moveModeEnabled = true; onMoveModeEnabled.next(datapoint)" *ngIf="(hasWriteAccess || isAccountAdmin) && !isOverlay">
				<span><mat-icon fontIcon="fa-expand-arrows" fontSet="far"></mat-icon></span>
				Move
			</div>
			<div mat-menu-item *ngIf="hasWriteAccess" (click)="deleteDatapoint()">
				<span><mat-icon fontIcon="fa-trash-alt" fontSet="fas"></mat-icon></span>
				Delete
			</div>
			<div mat-menu-item (click)="isOverlay ? downloadOverlayProfile($event) : downloadLocationProfile($event)">
				<span><mat-icon fontIcon="fa-arrow-to-bottom" fontSet="far"></mat-icon></span>
				Download Data
			</div>
			<div mat-menu-item [disabled]="selectedValue.value !== locationProfile.CLIMATE" *ngIf="!isOverlay" (click)="downloadOverlayProfileChart()">
				<span><mat-icon fontIcon="fa-arrow-to-bottom" fontSet="far"></mat-icon></span>
				Download Chart
			</div>
		</mat-menu>
	</div>
    <div class="grabber" (mousedown)="onMouseDown($event)">
        <i class="fas fa-ellipsis-v"></i>
    </div>
</div>

export interface MunichReInterface {
    id: string,
    name: string;
    // none: {value: number, color: string, isActive: boolean};
    low?: string;
    values: [{
                none: MunichReValue, 
                value1?: MunichReValue, 
                value2?: MunichReValue, 
                value3?: MunichReValue, 
                value4?: MunichReValue, 
                value5?: MunichReValue, 
                value6?: MunichReValue, 
                value7?: MunichReValue
            }];
}

export interface MunichReValue {
    value: number; 
    color: string; 
    isActive: boolean; 
    riskIndex?: number;
    class?: string;
    style?: string;
    tooltip: string
}

export interface RiskScoresInterface {
    name: string,
    values: [{id: string, isTextValue: true, value?: number}, {id: string, isTextValue: false, value?: string}],
    imgSrc: string
}
export class MinuchRe {

    public static RiskScoresElements(): RiskScoresInterface[] {
        return [
            {
                name: 'Overall Risk',
                values: [{id: 'munichre_hazards_overall_risk_score', isTextValue: true}, {id: 'munichre_hazards_overall_risk_index', isTextValue: false}],
                imgSrc: 'assets/icons/icon-overall.svg'
            },
            {
                name: 'Earthquake Risk',
                values: [{id: 'munichre_hazards_earthquake_risk_score', isTextValue: true}, {id: 'munichre_hazards_earthquake_risk_index', isTextValue: false}],
                imgSrc: 'assets/icons/icon-earthquake.svg'
            },
            {
                name: 'Storm Risk',
                values: [{id: 'munichre_hazards_storm_risk_score', isTextValue: true}, {id: 'munichre_hazards_storm_risk_index', isTextValue: false}],
                imgSrc: 'assets/icons/icon-storm.svg'
            },
            {
                name: 'Flood Risk',
                values: [{id: 'munichre_hazards_flood_risk_score', isTextValue: true}, {id: 'munichre_hazards_flood_risk_index', isTextValue: false}],
                imgSrc: 'assets/icons/icon-flash-flood.svg'
            }
        ];
    }

    public static HazardScoresElements(): MunichReInterface[] {
        return [
            {
                id      : 'munichre_hazards_earthquake_hazard_zone',
                name    : 'Earthquake',
                values  : [{
                            none    : { value: null, color: '#cccccc', isActive: false, class: 'progress-bar', style: 'width: 100%; background-color: #cccccc', tooltip: 'Hazard Zone:  '},
                            value1  : { value: 0, color: '#FFFFCC', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #FFFFCC', tooltip: 'Hazard Zone:  ' },
                            value2  : { value: 1, color: '##FFF34D', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: ##FFF34D', tooltip: 'Hazard Zone:  ' },
                            value3  : { value: 2, color: '#FFAA00', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #FFAA00', tooltip: 'Hazard Zone:  ' } ,
                            value4  : { value: 3, color: '#F00000', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #F00000', tooltip: 'Hazard Zone:  ' },
                            value5  : { value: 4, color: '#A10000', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #A10000', tooltip: 'Hazard Zone:  ' }
                        }]
            },
            {
                id      : 'munichre_hazards_volcanoes_hazard_zone',
                name    : 'Volcanoes',
                values  : [{
                            none    : { value: null, color: '#cccccc', isActive: false, class: 'progress-bar', style: 'width: 100%; background-color: #cccccc', tooltip: 'Hazard Zone:  '},
                            value1  : { value: -1, color: '#cccccc', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #cccccc', tooltip: 'Hazard Zone:  ' },
                            value2  : { value: 0, color: '#BABCBF', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #BABCBF', tooltip: 'Hazard Zone:  ' },
                            value3  : { value: 1, color: '#FFAA00', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #FFAA00', tooltip: 'Hazard Zone:  ' },
                            value4  : { value: 2, color: '#E60000', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #E60000', tooltip: 'Hazard Zone:  ' },
                            value5  : { value: 3, color: '#730000', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #730000', tooltip: 'Hazard Zone:  ' }
                        }]
            },
            {
                id      : 'munichre_hazards_tsunami_hazard_zone',
                name    : 'Tsunami',
                values  :[{
                            none    : { value: null, color: '#cccccc', isActive: false, class: 'progress-bar', style: 'width: 100%; background-color: #cccccc', tooltip: 'Hazard Zone:  '},
                            value1  : { value: -1, color: '#cccccc', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #cccccc', tooltip: 'Hazard Zone:  ' },
                            value2  : { value: 0, color: '#FFDAC9', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #FFDAC9', tooltip: 'Hazard Zone:  ' },
                            value3  : { value: 1000, color: '#F29A77', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #F29A77', tooltip: 'Hazard Zone:  ' },
                            value4  : { value: 500, color: '#FA583C', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #FA583C', tooltip: 'Hazard Zone:  ' },
                            value5  : { value: 100, color: '#B01C05', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #B01C05', tooltip: 'Hazard Zone:  ' }
                        }]
            },
            {
                id      : 'munichre_hazards_tropical_cyclone_hazard_zone',
                name    : 'Tropical Cyclone',
                values  : [{
                            none    : { value: null, color: '#cccccc', isActive: false, class: 'progress-bar', style: 'width: 100%; background-color: #cccccc', tooltip: 'Hazard Zone:  '},
                            value1  : { value: -1, color: '#cccccc', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #cccccc', tooltip: 'Hazard Zone:  ' },
                            value2  : { value: 0, color: '#BEE6CF', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #BEE6CF', tooltip: 'Hazard Zone:  ' },
                            value3  : { value: 1, color: '#8CD1A8', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #8CD1A8', tooltip: 'Hazard Zone:  ' },
                            value4  : { value: 2, color: '#59BA88', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #59BA88', tooltip: 'Hazard Zone:  ' },
                            value5  : { value: 3, color: '#27A16E', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #27A16E', tooltip: 'Hazard Zone:  ' },
                            value6  : { value: 4, color: '#008753', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #008753', tooltip: 'Hazard Zone:  ' },
                            value7  : { value: 5, color: '#006E45', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #006E45', tooltip: 'Hazard Zone:  ' }
                        }]
            },
            {
                id      : 'munichre_hazards_extra_tropical_storm_hazard_zone',
                name    : 'Extratropical Storm',
                values  : [{
                            none    : { value: null, color: '#cccccc', isActive: false, class: 'progress-bar', style: 'width: 100%; background-color: #cccccc', tooltip: 'Hazard Zone:  '},
                            value1  : { value: -1, color: '#cccccc', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #cccccc', tooltip: 'Hazard Zone:  '},
                            value2  : { value: 0, color: '#FFFF73', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #FFFF73', tooltip: 'Hazard Zone:  ' },
                            value3  : { value: 1, color: '#C5D955', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #C5D955', tooltip: 'Hazard Zone:  ' },
                            value4  : { value: 2, color: '#92B53A', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #92B53A', tooltip: 'Hazard Zone:  ' },
                            value5  : { value: 3, color: '#609120', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #609120', tooltip: 'Hazard Zone:  ' },
                            value6  : { value: 4, color: '#377300', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #377300', tooltip: 'Hazard Zone:  ' }
                        }]
            },
            {
                id      : 'munichre_hazards_hail_hazard_zone',
                name    : 'Hail',
                values  : [{
                            none    : { value: null, color: '#cccccc', isActive: false, class: 'progress-bar', style: 'width: 100%; background-color: #cccccc', tooltip: 'Hazard Zone:  '},
                            value1  : { value: 1, color: '#F0D1FF', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #F0D1FF', tooltip: 'Hazard Zone:  '},
                            value2  : { value: 2, color: '#D1B3FF', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #D1B3FF', tooltip: 'Hazard Zone:  '},
                            value3  : { value: 3, color: '#C36FED', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #C36FED', tooltip: 'Hazard Zone:  '},
                            value4  : { value: 4, color: '#9446B3', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #9446B3', tooltip: 'Hazard Zone:  '},
                            value5  : { value: 5, color: '#691273', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #691273', tooltip: 'Hazard Zone:  '},
                            value6  : { value: 6, color: '#440045', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #440045', tooltip: 'Hazard Zone:  '}
                        }]
            },
            {
                id      : 'munichre_hazards_tornado_hazard_zone',
                name    : 'Tornado',
                values  : [{
                            none    : { value: null, color: '#cccccc', isActive: false, class: 'progress-bar', style: 'width: 100%; background-color: #cccccc', tooltip: 'Hazard Zone:  '},
                            value1  : { value: 1, color: '#91E5CC', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #91E5CC', tooltip: 'Hazard Zone:  '},
                            value2  : { value: 2, color: '#47B5A1', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #47B5A1', tooltip: 'Hazard Zone:  '},
                            value3  : { value: 3, color: '#0A8A79', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #0A8A79', tooltip: 'Hazard Zone:  '},
                            value4  : { value: 4, color: '#035E5A', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #035E5A', tooltip: 'Hazard Zone:  ' }
                        }]
            },
            {
                id      : 'munichre_hazards_lightning_hazard_zone',
                name    : 'Lightning',
                values  : [{
                            none    : { value: null, color: '#cccccc', isActive: false, class: 'progress-bar', style: 'width: 100%; background-color: #cccccc', tooltip: 'Hazard Zone:  '},
                            value1  : { value: 1, color: '#FFFF80', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #FFFF80', tooltip: 'Hazard Zone:  '},
                            value2  : { value: 2, color: '#FCDD5D', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #FCDD5D', tooltip: 'Hazard Zone:  '},
                            value3  : { value: 3, color: '#F7BA3E', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #F7BA3E', tooltip: 'Hazard Zone:  '},
                            value4  : { value: 4, color: '#D68522', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #D68522', tooltip: 'Hazard Zone:  '},
                            value5  : { value: 5, color: '#9E4410', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #9E4410', tooltip: 'Hazard Zone:  '},
                            value6  : { value: 6, color: '#6B0601', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #6B0601', tooltip: 'Hazard Zone:  ' }
                        }]
            },
            {
                id      : 'munichre_hazards_river_flood_hazard_zone',
                name    : 'River Flood',
                values  :[{
                            none    : { value: null, color: '#cccccc', isActive: false, class: 'progress-bar', style: 'width: 100%; background-color: #cccccc', tooltip: 'Hazard Zone:  '},
                            value1  : { value: 0, color: '#DAE4F2', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #DAE4F2', tooltip: 'Hazard Zone:  '},
                            value2  : { value: 500, color: '#B3C7E5', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #B3C7E5', tooltip: 'Hazard Zone:  '},
                            value3  : { value: 100, color: '#4174BF', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #4174BF', tooltip: 'Hazard Zone:  '},
                            value4  : { value: 50, color: '#0043A8', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #0043A8', tooltip: 'Hazard Zone:  ' }
                        }]
            },
            {
                id      : 'munichre_hazards_flash_flood_hazard_zone',
                name    : 'Flash Flood',
                values  :[{
                            none    : { value: null, color: '#cccccc', isActive: false, class: 'progress-bar', style: 'width: 100%; background-color: #cccccc', tooltip: 'Hazard Zone:  '},
                            value1  : { value: 1, color: '#DAE9EB', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #DAE9EB', tooltip: 'Hazard Zone:  '},
                            value2  : { value: 2, color: '#BFD8DB', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #BFD8DB', tooltip: 'Hazard Zone:  '},
                            value3  : { value: 3, color: '#99C1C7', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #99C1C7', tooltip: 'Hazard Zone:  '},
                            value4  : { value: 4, color: '#67A2AB', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #67A2AB', tooltip: 'Hazard Zone:  '},
                            value5  : { value: 5, color: '#32808C', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #32808C', tooltip: 'Hazard Zone:  '},
                            value6  : { value: 6, color: '#006170', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #006170', tooltip: 'Hazard Zone:  '}
                        }]
            },
            {
                id      : 'munichre_hazards_storm_surge_hazard_zone',
                name    : 'Storm Surge',
                values  :[{
                            none    : { value: null, color: '#cccccc', isActive: false, class: 'progress-bar', style: 'width: 100%; background-color: #cccccc', tooltip: 'Hazard Zone:  '},
                            value1  : { value: -1, color: '#cccccc', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #cccccc', tooltip: 'Hazard Zone:  '},
                            value2  : { value: 1000, color: '#9ED6E6', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #9ED6E6', tooltip: 'Hazard Zone:  '},
                            value3  : { value: 500, color: '#58B2C7', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #58B2C7', tooltip: 'Hazard Zone:  '},
                            value4  : { value: 100, color: '#1D8CA8', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #1D8CA8', tooltip: 'Hazard Zone:  '}
                        }]
           },
            {
                id      : 'munichre_hazards_wildfire_hazard_zone',
                name    : 'Wildfire',
                values  :[{
                            none    : { value: null, color: '#cccccc', isActive: false, class: 'progress-bar', style: 'width: 100%; background-color: #cccccc', tooltip: 'Hazard Zone:  '},
                            value1  : { value: -1, color: '#cccccc', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #cccccc', tooltip: 'Hazard Zone:  '},
                            value2  : { value: 1, color: '#FFFF73', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #FFFF73', tooltip: 'Hazard Zone:  '},
                            value3  : { value: 2, color: '#FFAA00', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #FFAA00', tooltip: 'Hazard Zone:  '},
                            value4  : { value: 3, color: '#FF0000', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #FF0000', tooltip: 'Hazard Zone:  '},
                            value5  : { value: 4, color: '#730000', isActive: false, class: 'progress-bar progress-bar-stripped', style: 'width: 34%; background-color: #730000', tooltip: 'Hazard Zone:  '}
                        }]
            }];
    }

    public static getTooltipText(riskIndex: number, riskScore: string): string {
        const value = riskIndex !== null ? riskIndex : 0;
        riskScore = riskScore == null ? 'Low' : riskScore;
        if (value >= 0 && value <= 5) {
          return `Risk Score: ${riskScore} - Risk Index: ${value}`;//`Low Risk: ${value}`;
        } else if (value >= 6 && value <= 15) {
          return `Risk Score: ${riskScore} - Risk Index: ${value}`;//`Medium Risk: ${value}`;
        } else if (value >= 16 && value <= 34) {
          return `Risk Score: ${riskScore} - Risk Index: ${value}`;//`High Risk: ${value}`;
        } else if (value >= 35 && value <= 450) {
          return `Risk Score: ${riskScore} - Risk Index: ${value}`;//`Extreme Risk: ${value}`;
        } else {
          return '';    }
    }
    
    public static getPointerPosition(riskIndex: number): number {
        let position = 0;
        if (riskIndex >= 0 && riskIndex <= 5) {
          position = (riskIndex / 5) * 25;
        } else if (riskIndex >= 6 && riskIndex <= 15) {
          position = 25 + ((riskIndex - 6) / 9) * 25;
        } else if (riskIndex >= 16 && riskIndex <= 34) {
          position = 50 + ((riskIndex - 16) / 19) * 25;
        } else if (riskIndex >= 35 && riskIndex <= 450) {
          position = 75 + ((riskIndex - 35) / 415) * 25;
        }
        console.log()
        return position;
      }
}


import { DatapointsClimateChartService } from "src/app/dataset/datapoints/climate-charts/datapoints-climate-chart.service";
import jsPDF from "jspdf";
import { NotifService } from "../notification/notif.service";

export class Climate {

    public static downloadOverlayProfileChart(datapointsClimateChartService: DatapointsClimateChartService, notifService: NotifService, fileName: string, datapointSummaryFields = null, addressDetails = null) {
        const graphTypes = datapointsClimateChartService.chartGraphicsConfiguration.graphType;
        const format = datapointsClimateChartService.chartGraphicsConfiguration.format;
        let options = {orientation: 'p', unit: 'mm'};
        const doc = new jsPDF(options);

        let tableHeight = 24;
        if(datapointSummaryFields){
            this.createHeading(doc, 'Location Profile', 14)
            tableHeight = 40;
            const margin = 10;
            const startY = 30;
            const cellWidth = 60;
            const cellHeight = 5;

            datapointSummaryFields.forEach((summaryField, i) => {
                const resultArray = Object.values(summaryField);
                for (let j = 0; j < resultArray.length; j++) {
                    const cell = resultArray[j];
                    this.createCell(doc, cell, {x: (margin + j * cellWidth), y: (startY + i * cellHeight), width: cellWidth, height: cellHeight})
                }

                tableHeight = tableHeight + cellHeight;
            });
        }

        if(addressDetails){
            const startY = 29;
            const cellHeight = 5;
            this.createHeading(doc, 'Address Profile', 14)
            this.createHeading(doc, 'Address', 10, {x: 10, y: 27})
            this.createCell(doc, addressDetails.address || "", {x: 10, y: startY + cellHeight, width: 120, height: cellHeight});
            this.createHeading(doc, 'Coordinates (latitude, longitude)', 10, {x: 10, y: 42});
            this.createCell(doc, addressDetails.yAxis || "", {x: 10, y: 49, width: 60, height: cellHeight});
            this.createCell(doc, addressDetails.xAxis || "", {x: 70, y: 49, width: 60, height: cellHeight});
        }

        doc.setFont("arial", "bold");
        doc.setFontSize(14);
        let counter = 1;
        let isAddPage: boolean = false;
        let isFirstPage = true;


        for (const key in graphTypes) {
            if (Object.prototype.hasOwnProperty.call(graphTypes, key)) {
                const element = graphTypes[key];

                if (element.instance) {
                    const img = new Image();
                    img.src = element.instance.getDataURL({
                        pixelRatio: 2,
                        backgroundColor: '#fff'
                    });

                    if(isFirstPage){
                        if(datapointSummaryFields){
                            this.createChart(doc, counter, img, format, element, {x: 10, y: tableHeight + 30})
                        }
                        if(addressDetails){
                            this.createChart(doc, counter, img, format, element, {x: 10, y: 100})
                        }

                    } else {
                        if(counter === 1){
                            doc.addPage("landscape");
                        }
                        if (counter % 2 !== 0) {
                            if (isAddPage) {
                                doc.addPage("landscape");
                                isAddPage = false;
                            }
                            this.createChart(doc, counter, img, format, element, {x: 10, y: 24})
                        } else {
                            this.createChart(doc, counter, img, format, element, {x: 10, y: 144})
                            isAddPage = true;
                        }

                    }
                    if(!isFirstPage){
                        counter++;
                    }
                    isFirstPage = false;
                }
            }
        }
        if (counter > 1 || !isFirstPage) {
            doc.save(fileName + ".pdf");
        } else {
            notifService.success('No data found for chart.');
        }
    }

    public static resetGraphTypes(datapointsClimateChartService: DatapointsClimateChartService) {
        const graphTypes = datapointsClimateChartService.chartGraphicsConfiguration.graphType;
        for (const key in graphTypes) {
            if (Object.prototype.hasOwnProperty.call(graphTypes, key)) {
                const element = graphTypes[key];
                element.instance = null;
            }
        }
    }

    public static createHeading(doc, heading, fontSize, dimensions = {x: 10, y: 20}){
        doc.setFont("arial", "bold");
        doc.setFontSize(fontSize);
        doc.text(dimensions.x, dimensions.y, heading);
        doc.setFont("Helvetica", "normal");
        doc.setFontSize(10);
    }

    public static createCell(doc, text, dimensions = {x: 10, y: 20, width: 60, height: 5}){
        doc.rect(dimensions.x,  (dimensions.y) - 4, dimensions.width, dimensions.height);
        doc.text((dimensions.x) + 2, dimensions.y, text);
    }

    public static createChart(doc, counter, img, format, element, dimensions = {x: 10, y: 20}){
        doc.rect(dimensions.x, dimensions.y , 170, 100);
        doc.text(dimensions.x, dimensions.y - 4, element.title);
        doc.addImage(img, format, 15, dimensions.y + 5, 150, 90, "alias"+counter, 'SLOW');
    }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RenderingOptions} from '../../../../../model/dataset/rendering/rendering-options';
import {Dataset} from '../../../../../model/dataset/dataset';
import {ColorScaleType} from '../../../../../model/dataset/rendering/color-scale-type';
import {Grouping} from '../../../../../model/dataset/rendering/datapoint-converter-options';
import {ColorUtils} from '../../../../../core/utils/color-utils';
import {DatasetType} from '../../../../../model/dataset/dataset-type';
import {RenderingUtils} from '../../../../../core/utils/rendering-utils';
import {NotifService} from '../../../../../core/notification/notif.service';
import {DatasetField} from '../../../../../model/dataset/field/dataset-field';
import {RenderingErrorCodes} from '../../../../../model/dataset/rendering/rendering-error-codes';
import {DatasetGeometryType} from '../../../../../model/dataset/dataset-geometry-type';
import {Account} from '../../../../../model/account/account';
import {DatapointsPageStateService} from '../../../datapoints-page-state.service';
import {DistanceUnit} from '../../../../../constants';
import {ComputationUtils} from '../../../../../core/utils/computation-utils';
import {VisualizationType} from '../../../../../model/dataset/rendering/visualization-options';
import {DatasetFieldType} from 'src/app/model/dataset/dataset-field-type';

@Component({
    selector: 'mpt-cluster-config-box',
    templateUrl: './cluster-config-box.component.html',
    styleUrls: ['./cluster-config-box.component.scss']
})
export class ClusterConfigBoxComponent implements OnInit {

    @Output() submitChanges: EventEmitter<RenderingOptions> = new EventEmitter();
    @Output() Reset: EventEmitter<void> = new EventEmitter<void>();
    @Output() viewModeChanged = new EventEmitter<VisualizationType>();

    @Input() renderingOptions: RenderingOptions; // the component manipulate directly this object
    @Input() dataset: Dataset; // the dataset that is colorized

    base64Colors: string[] = [];
    colorizeByDatasetOptions: Dataset[] = [];

    scaleTypes: string[] = Object.keys(ColorScaleType);
    groupings: string[] = Object.keys(Grouping);

    account: Account;
    selectedDatasetForColorization: Dataset; // this is populated when colorization is done by a link dataset. need to think more on this
    selectedFieldForColorization: DatasetField;

    radius: number;

    defaultColor = '#ed6a22';
    distanceUnit: DistanceUnit = DistanceUnit.KM;
    colorPickerOpen: any;

    constructor(
        private readonly notifService: NotifService,
        private readonly datapointsPageStateService: DatapointsPageStateService
    ) {
    }

    ngOnInit(): void {
        this.account = this.datapointsPageStateService.activeAccount;
        let privateComplexOverlays = this.datapointsPageStateService.getLinkedAccountDatasetsNotCloned().filter(overlay => overlay.geometryType === DatasetGeometryType.COMPLEX);
        this.colorizeByDatasetOptions = this.datapointsPageStateService.getLinkedAccountDatasetsNotCloned().concat(privateComplexOverlays);
        this.colorizeByDatasetOptions.unshift(this.dataset);
        this.populateBase64Colors();
         this.colorPickerOpen = new Array(this.base64Colors.length).fill(false);
    }

    get Grouping() {
        return Grouping;
    }

    get DatasetFieldType() {
        return DatasetFieldType;
    }

    get getVisualizationTypes() {
        let visualizationTypes = [VisualizationType.DEFAULT];
        switch (this.dataset.type) {
            case DatasetType.GLOBAL_OVERLAY:
            case DatasetType.ACCOUNT_OVERLAY:
                if (this.dataset.geometryType === DatasetGeometryType.COMPLEX) {
                    visualizationTypes.push(VisualizationType.THEMATIC_MAP);
                }
                break;
            case DatasetType.ACCOUNT_APPLICATION:
                visualizationTypes.push(VisualizationType.CLUSTER);
                // visualizationTypes.push(VisualizationType.HEATMAP);
                break;
            default:
                break;

        }
        return visualizationTypes;
    }

    onApplySettings() {
        this.renderingOptions.datasetStylingOptions.colors = this.base64Colors.map(color => ColorUtils.colorFromBase64(color));
        this.renderingOptions.visualizationOptions.radius = ComputationUtils.getDistanceInMeters(this.radius, this.distanceUnit);
        let invalidAttribute = RenderingUtils.validate(this.renderingOptions);
        if (!invalidAttribute) {
            this.submitChanges.next(this.renderingOptions);
        } else {
            switch (invalidAttribute) {
                case RenderingErrorCodes.FIELD:
                    this.notifService.error('You must choose a field for the colorization');
                    return;
                case RenderingErrorCodes.INTERVALS:
                    this.notifService.error('You must define at least one interval');
                    return;
                case RenderingErrorCodes.INTERVALS_OVERLAP:
                    this.notifService.error('The defined intervals must not overlap');
                    return;
            }
        }
    }

    colorScaleTypeChanged(newValue: ColorScaleType) {
        if (newValue === ColorScaleType.CONSTANT) {

        } else { // it has a scale
            this.populateBase64Colors();
        }
    }

    onGroupingChange(event) {
        if (event === Grouping.COUNT) {
            this.renderingOptions.datasetStylingOptions.type = ColorScaleType.CONSTANT;
        } else {
            this.renderingOptions.datasetStylingOptions.type = ColorScaleType.GRADIENT;
        }
    }

    private populateBase64Colors() {
        if (!this.renderingOptions.datasetStylingOptions.colors || this.renderingOptions.datasetStylingOptions.colors.length === 0) {
            this.base64Colors = ColorUtils.defaultColorScale();
        } else { // it already has an interval
            this.base64Colors = this.renderingOptions.datasetStylingOptions.colors.map(color => ColorUtils.colorToBase64(color));
        }
        if (this.renderingOptions.datasetStylingOptions.defaultColor) {
            this.defaultColor = ColorUtils.colorToBase64(this.renderingOptions.datasetStylingOptions.defaultColor);
        }
    }

    onFieldsMenuFieldClick(dataset: Dataset, field: DatasetField) {
        this.selectedDatasetForColorization = dataset;
        this.selectedFieldForColorization = field;
        this.renderingOptions.converterOptions.fieldID = field.id;
        this.renderingOptions.converterOptions.datasetID = dataset.id;
    }

    onColorChange(event: any, index: number) {
        this.base64Colors[index] = event;
        this.colorPickerOpen[index] = false;
    }

    onRemoveColor(index: number, event: Event) {
        event.stopPropagation();
        if (this.base64Colors.length > 1) { // don't delete last element
            this.base64Colors.splice(index, 1);
        }
    }

    onConstantColorChanged(event) {
        this.defaultColor = event;       
        this.renderingOptions.datasetStylingOptions.defaultColor = ColorUtils.colorFromBase64(event);
        this.defaultColor = ColorUtils.colorToBase64(this.renderingOptions.datasetStylingOptions.defaultColor);
    }

    get DatasetType() {
        return DatasetType;
    }

    get ColorScaleType() {
        return ColorScaleType;
    }

    get ColorUtils() {
        return ColorUtils;
    }

    get DistanceUnit() {
        return DistanceUnit;
    }

    reset(): void {
        this.Reset.emit();
    }
}

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { MatCheckbox, MatCheckboxChange } from "@angular/material/checkbox";
import { MatTableDataSource } from "@angular/material/table";
import { forkJoin } from "rxjs";
import { UserStateService } from "src/app/auth/user-state-service";
import { NotifService } from "src/app/core/notification/notif.service";
import { OverlaysService } from "src/app/data-access-layer/global-overlays/overlays.service";
import { Dataset } from "src/app/model/dataset/dataset";
import { DatasetType } from "src/app/model/dataset/dataset-type";
import { DatasetField } from "src/app/model/dataset/field/dataset-field";

@Component({
    selector: "map-edit-account-overlays",
    templateUrl: "./edit-account-overlays.component.html",
    styleUrls: ["./edit-account-overlays.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditAccountOverlaysComponent implements OnInit {
    @ViewChild("selectAll") selectAll: MatCheckbox;

    @Input() currentAccount;
    @Output() updatedSuccessfully = new EventEmitter<string>();

    fields: DatasetField[] = [];
    filteredOverlays: Dataset[];
    private overlay: Dataset[];
    loading = false;
    overlays: Dataset[];
    allSelected: boolean;
    accountOverlays: Dataset[];
    accountExternalOverlays: Dataset[];

    constructor(
        private readonly notifService: NotifService,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly userStateService: UserStateService,
        private readonly overlayService: OverlaysService
    ) {}

    ngOnInit() {
        this.fetchOverlays();
    }

    applyFilter(filterValue: string): void {
        let searchValue = filterValue.trim().toLowerCase();
        this.filteredOverlays = this.overlays.filter((overlay) => {
            return overlay.name.trim().toLowerCase().includes(searchValue);
        });
    }

    fetchOverlays(): void {
        this.loading = true;
        forkJoin(
            this.overlayService.getAllTessadataOverlays(),
            this.overlayService.getAccountTessadataOverlays(
                this.currentAccount.id
            ),
            this.overlayService.getAllGlobalOverlays(),
            this.overlayService.getAccountGlobalOverlays(this.currentAccount.id)
        ).subscribe(
            ([
                externalOverlyas,
                accountExternalOverlays,
                globalOverlays,
                accountOverlays,
            ]: [Dataset[], Dataset[], Dataset[], Dataset[]]) => {
                this.accountOverlays = accountOverlays;
                this.accountExternalOverlays = accountExternalOverlays;
                if (this.userStateService.isSuperadmin) {
                    externalOverlyas.forEach((overlay) => {
                        overlay.hasOverlay =
                            accountExternalOverlays.find(
                                (accountExternalOverlay) =>
                                    accountExternalOverlay.datasetId ===
                                    overlay.datasetId
                            ) !== undefined;
                        overlay.name = overlay.datasetLabel;
                        overlay.type = DatasetType.EXTERNAL_OVERLAY;
                    });
                    globalOverlays.forEach((overlay) => {
                        overlay.hasOverlay = accountOverlays.find(
                            (acoountOverlay) => acoountOverlay.id === overlay.id
                        );
                        overlay.type = DatasetType.GLOBAL_OVERLAY;
                    });
                    this.overlays = globalOverlays.concat(externalOverlyas);
                }
                this.filteredOverlays = this.overlays;
                this.loading = false;
                this.changeDetector.detectChanges();
            }
        );
    }

    accountHasOverlay(overlayId: string): boolean {
        const overlay = this.accountOverlays.find(
            (accountOverlay) => accountOverlay.id === overlayId
        );
        return overlay ? true : false;
    }

    toggleSelectAll($event: MatCheckboxChange) {
        let selected = $event.checked;
        this.overlays.forEach((overlay) => {
            overlay.hasOverlay = selected;
        });
    }

    assignOverlaysToAccounts() {
        this.loading = true;
        let globaldatasetIDs = [];
        let externaldatasetIDs = [];
        this.overlays.forEach((overlay) => {
            if (
                overlay.hasOverlay &&
                overlay.type == DatasetType.GLOBAL_OVERLAY
            ) {
                globaldatasetIDs.push(overlay.id);
            } else if (
                overlay.hasOverlay &&
                overlay.type == DatasetType.EXTERNAL_OVERLAY
            ) {
                externaldatasetIDs.push(overlay.datasetId);
            }
        });
        forkJoin(
            this.overlayService.assignGlobalOverlaysToAccount({
                accountID: this.currentAccount.id,
                datasetIDs: globaldatasetIDs,
            }),
            this.overlayService.assignExternalOverlaysToAccount({
                accountID: this.currentAccount.id,
                datasetIDs: externaldatasetIDs,
            })
        ).subscribe(
            ([globalOverlayResponse, ExternalOverlayResponse]: [
                null,
                null
            ]) => {
                this.loading = false;
                this.notifService.success("Changes applied.");
                this.changeDetector.detectChanges();
            }
        );
    }
}

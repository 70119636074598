<agm-map
    [latitude]="lat"
    [longitude]="lng"
    [zoom]="zoom"
    [disableDefaultUI]="true"
    [zoomControl]="false">


    <agm-circle [latitude]="lat" [longitude]="lng"
                [radius]="5000"
                [fillColor]="'red'"
                [circleDraggable]="false"
                [editable]="false"
                (circleClick)="clicking()"
                (mouseOver)="hovering()"
                (mouseOut)="leaving()">
    </agm-circle>

</agm-map>

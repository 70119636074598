import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from "@angular/core";
import { Dataset } from "../../../model/dataset/dataset";
import { MatDialog } from "@angular/material/dialog";
import { NotifService } from "../../../core/notification/notif.service";
import { DatasetGeometryType } from "../../../model/dataset/dataset-geometry-type";
import { UploadService } from "../../../data-access-layer/upload/upload.service";
import { Upload } from "../../../model/upload/upload";
import { flatMap } from "rxjs/operators";
import { Observable } from "rxjs";
import { TableUploadSheetOptions } from "../../../model/upload/table/table-upload-sheet-options";
import { TableRow } from "../../../model/upload/table/table-row";
import {
    TableUploadColumnMapping,
    TableUploadColumnMappingType,
} from "../../../model/upload/table/table-upload-column-mapping";
import { TableCell } from "../../../model/upload/table/table-cell";
import { StringUtils } from "../../../core/utils/string-utils";
import { UploadType } from "../../../model/upload/upload-type";
import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";

/**
 * TODO Design suggestion: https://cdn.dribbble.com/users/1228768/screenshots/5665870/63.png
 */
@Component({
    selector: "map-drafts-upload",
    templateUrl: "./drafts-upload.component.html",
    styleUrls: ["./drafts-upload.component.scss"],
    providers: [
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { displayDefaultIndicatorType: false },
        },
    ],
})
export class DraftsUploadComponent implements OnInit {
    @Input() dataset: Dataset;
    @Input() IsInDraft: boolean;

    @Output() uploadCompleted = new EventEmitter();

    datasetFieldsById = {};

    currentStepIndex = 0; // starts from 0
    isLoading = false;

    // ------------------- For step 1
    acceptedFileTypes: string;
    isComplexGeometry = false; // is COMPLEX or LINE_STRING
    uploadInProgress: Upload;
    uploadColumns: TableCell[] = [];
    uploadSheetOptions: TableUploadSheetOptions;
    files: any[] = [];

    @ViewChild("uploadModal", { static: true }) modalTemplate: TemplateRef<any>;

    constructor(
        private readonly dialog: MatDialog,
        private readonly notifService: NotifService,
        private readonly uploadService: UploadService
    ) {}

    ngOnInit(): void {
        this.acceptedFileTypes = this.getAcceptedFileTypesForDataset();
        for (let field of this.dataset.fields) {
            this.datasetFieldsById[field.id] = field;
        }
    }

    openComponent(): void {
        this.dialog.open(this.modalTemplate, { disableClose: true });
        this.isComplexGeometry =
            this.dataset.geometryType === DatasetGeometryType.COMPLEX ||
            this.dataset.geometryType === DatasetGeometryType.LINE_STRING;
    }

    closeModal(): void {
        this.dialog.closeAll();
    }

    onFilesSelected(event): void {
        const files = event.target.files;
        if (this.filesAreValid(files)) {
            if (!this.isComplexGeometry) {
                // already have some files
                if (files.length > 1) {
                    this.notifService.error("You can add only one file");
                    return;
                }
            }
            if (this.isComplexGeometry && this.files.length > 2) {
                this.notifService.error("You can add maximum 2 files");
                return;
            }

            this.files = files;
        }
    }

    submitFiles() {
        const fileTypesMap = {}; // key = extension, value = file
        for (let file of this.files) {
            let fileExtension = file.name.split(".").pop();
            fileTypesMap[fileExtension] = file;
        }
        switch (this.dataset.geometryType) {
            case DatasetGeometryType.COMPLEX: // allowed are DBF+SHP, or JSON
            case DatasetGeometryType.LINE_STRING: // allowed are DBF+SHP or JSON
                if (
                    !(
                        fileTypesMap[FileExtensions.json] ||
                        (fileTypesMap[FileExtensions.shp] &&
                            fileTypesMap[FileExtensions.dbf])
                    )
                ) {
                    this.notifService.error(
                        "Please select one JSON file, or one SHP + DBF"
                    );
                    return;
                }
                break;
            case DatasetGeometryType.NONE: // allowed are CSV, XLS, XLSX, DBF, JSON
            case DatasetGeometryType.POINT: // allowed are CSV, XLS, XLSX, DBF, JSON
                break;
        }
        this.isLoading = true;
        this.uploadService.createUpload(this.dataset.id).subscribe(
            (upload) => {
                this.uploadInProgress = upload;
                let uploadObservable: Observable<Upload> = null;
                if (
                    this.isComplexGeometry &&
                    fileTypesMap[FileExtensions.dbf] &&
                    fileTypesMap[FileExtensions.shp]
                ) {
                    uploadObservable = this.uploadFile(
                        fileTypesMap[FileExtensions.dbf]
                    ).pipe(
                        flatMap((v) =>
                            this.uploadFile(fileTypesMap[FileExtensions.shp])
                        )
                    );
                } else {
                    uploadObservable = this.uploadFile(this.files[0]);
                }
                uploadObservable.subscribe(
                    (onUploadSuccess) => {
                        // TODO display the upload.rowsCount somewhere.
                        this.uploadService
                            .getUploadEntries(this.uploadInProgress.id, 0, 0, 1) // fetch header
                            .subscribe((entries) => {
                                if (entries.length) {
                                    let headerRow: TableRow = entries[0].row;
                                    if (
                                        onUploadSuccess.type ===
                                        UploadType.TABLE_AND_GEOMETRY
                                    ) {
                                        this.uploadColumns =
                                            onUploadSuccess.tableSheets[0].columns.map(
                                                (column) => {
                                                    const tableCell: TableCell =
                                                        {
                                                            id: column.id,
                                                            value: column.name,
                                                        };
                                                    return tableCell;
                                                }
                                            );
                                    } else {
                                        this.uploadColumns = headerRow.cells;
                                    }
                                    this.currentStepIndex = 3;
                                    this.isLoading = false;
                                    let mappingsRequest: TableUploadColumnMapping[] =
                                        [];
                                    // TODO here we can make like in app2, where the system pre-match columns with fields if the names are similar
                                    if (
                                        this.dataset.geometryType ===
                                        DatasetGeometryType.POINT
                                    ) {
                                        mappingsRequest.push({
                                            type: TableUploadColumnMappingType.LATITUDE,
                                            columnID: null,
                                        });
                                        mappingsRequest.push({
                                            type: TableUploadColumnMappingType.LONGITUDE,
                                            columnID: null,
                                        });
                                    }
                                    mappingsRequest = mappingsRequest.concat(
                                        this.dataset.fields
                                            .filter(
                                                (field) => !field.isGenerated
                                            )
                                            .map((field) => {
                                                return {
                                                    type: TableUploadColumnMappingType.FIELD,
                                                    fieldID: field.id,
                                                    columnID:
                                                        field.columnID ||
                                                        "none",
                                                } as TableUploadColumnMapping;
                                            })
                                    );
                                    this.uploadSheetOptions = {
                                        headerIndex: 1,
                                        footerIndex: 0,
                                        sheetIndex: 0,
                                        mappings: mappingsRequest,
                                    };
                                }
                                this.isLoading = false;
                                this.currentStepIndex = 1;
                                this.uploadInProgress = onUploadSuccess; // it contains sheets data
                            });
                    },
                    (error1) => {
                        this.isLoading = false;
                        this.uploadInProgress = null;
                        this.notifService.error("We can't upload the file");
                    }
                );
            },
            (error1) => {
                this.isLoading = false;
                this.uploadInProgress = null;
            }
        );
    }

    proceedToFieldMappings() {
        this.currentStepIndex = 2;
        let uploadColumnsValues = this.uploadColumns.map(
            (column) => column.value
        );
        this.uploadSheetOptions.mappings.forEach((mapping) => {
            let uploadColumnBestMatch;
            switch (mapping.type) {
                case TableUploadColumnMappingType.LONGITUDE:
                    uploadColumnBestMatch = StringUtils.findBestMatch(
                        "longitude",
                        uploadColumnsValues
                    );
                    break;
                case TableUploadColumnMappingType.LATITUDE:
                    uploadColumnBestMatch = StringUtils.findBestMatch(
                        "latitude",
                        uploadColumnsValues
                    );
                    break;
                case TableUploadColumnMappingType.FIELD:
                    let datasetField = this.datasetFieldsById[mapping.fieldID];
                    uploadColumnBestMatch = StringUtils.findBestMatch(
                        datasetField.name,
                        uploadColumnsValues
                    );
                    break;
            }
            if (uploadColumnBestMatch) {
                let matchedColumn = this.uploadColumns.find(
                    (f) => f.value === uploadColumnBestMatch
                );
                mapping.columnID = matchedColumn.id;
            }
        });
    }

    submitUploadSheetOptions() {
        this.isLoading = true;
        this.uploadSheetOptions.mappings.forEach((mapping) => {
            if (mapping.columnID === "none") {
                delete mapping.columnID;
            }
        });
        this.uploadSheetOptions.mappings =
            this.uploadSheetOptions.mappings.filter((mapping) => {
                // remove incomplete mappings
                return mapping.columnID;
            });
        this.uploadService
            .setTableUploadSheetOptions(this.uploadInProgress.id, {
                sheets: [this.uploadSheetOptions],
            })
            .pipe(
                flatMap((v) =>
                    this.uploadService.completeUpload(
                        this.uploadInProgress.id,
                        this.IsInDraft
                    )
                )
            )
            .subscribe(
                (v) => {
                    this.currentStepIndex = 3;
                    this.onUploadSuccess();
                },
                (error) => {
                    this.onUploadError();
                }
            );

        // TODO here we need an iterator if multiple sheet are allowed, foreach sheet.
    }

    onUploadSuccess() {
        this.isLoading = false;
        setTimeout(() => {
            this.completeUploadProcess();
        }, 1500);
    }

    onUploadError() {
        this.notifService.error("Cannot upload file");
        this.isLoading = false;
        this.resetUploadProcess();
    }

    uploadFile(file: any): Observable<Upload> {
        return this.uploadService.uploadFile(this.uploadInProgress.id, file);
    }

    get DatasetGeometryType() {
        return DatasetGeometryType;
    }

    get Number() {
        return Number;
    }

    getAcceptedFileTypesForDataset(): string {
        let forSimpleGeometries =
            ".csv, .xls, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .dbf";
        let forComplexGeometries = ".dbf, .shp, .json";
        switch (this.dataset.geometryType) {
            case DatasetGeometryType.COMPLEX: // allowed are DBF+SHP, or JSON
            case DatasetGeometryType.LINE_STRING: // allowed are DBF+SHP or JSON
                return forComplexGeometries;
            case DatasetGeometryType.NONE: // allowed are CSV, XLS, XLSX, DBF
            case DatasetGeometryType.POINT: // allowed are CSV, XLS, XLSX, DBF
                return forSimpleGeometries;
        }
    }

    resetUploadProcess() {
        this.closeModal();
        // set default values
        this.files = [];
        this.uploadInProgress = null;
        this.currentStepIndex = 0;
        this.uploadSheetOptions = null;
        this.uploadColumns = [];
    }

    completeUploadProcess() {
        this.uploadCompleted.emit();
        this.resetUploadProcess();
    }

    private filesAreValid(files: any[]): boolean {
        let fileTypesAreValid = true;
        const fileTypesMap = {}; // key = extension, value = file
        let acceptedFileTypesForDataset = this.getAcceptedFileTypesForDataset()
            .replace(/\s/g, "")
            .split(",");
        for (let file of files) {
            let fileExtension: string = file.name.split(".").pop();
            if (!acceptedFileTypesForDataset.includes("." + fileExtension)) {
                fileTypesAreValid = false;
            }
            fileTypesMap[fileExtension] = file;
        }

        if (Object.keys(fileTypesMap).length !== files.length) {
            // if 2 files with the same type were updated
            this.notifService.error("Invalid files. The types must be unique");
            return false;
        }

        if (!fileTypesAreValid) {
            this.notifService.error("File type is not valid");
            return false;
        }

        return true;
    }

    get TableUploadColumnMappingType() {
        return TableUploadColumnMappingType;
    }
}

enum FileExtensions {
    csv = "csv",
    xlsx = "xlsx",
    xls = "xls",
    json = "json",
    shp = "shp",
    dbf = "dbf",
}

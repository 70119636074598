<div *ngIf="filteredGeoRegion.length > 0" class="flex searched-labels-section" style="flex-direction: column !important;max-width: 270px !important;">
    <div class="flex container-filters" style="flex-direction: column !important;">
        <div *ngFor="let selectedGeoRegion of filteredGeoRegion" class="region-label deleteIcon" [matTooltip]="selectedGeoRegion.name" matTooltipPosition="right">
            <span>{{selectedGeoRegion.name}}</span>
            <div (click)="$event.stopPropagation(); removeSingleGeoRegionsFilter(selectedGeoRegion)" class="delete-filter"  matTooltip="Delete Filter">
                <mat-icon fontIcon="fa-times" fontSet="far"></mat-icon>
            </div>
        </div>
    </div>
    <div class="container-delete-btn" [class.hide-close-btn] = "filteredGeoRegion.length === 0">
        <div (click)="$event.stopPropagation(); removeGeoRegionsFilter()" class="delete-filter"  matTooltip="Delete Filter">
            <mat-icon fontIcon="fa-times" fontSet="far"></mat-icon>
        </div>
    </div>
</div>

<div [class.container-input-btn] = "filteredGeoRegion.length > 0">
    <div (click)="$event.stopPropagation()" class="nested-menu-search zoom-input" [ngClass]="{'padding-zoom-input remove-border' : filteredGeoRegion.length > 0}">
        <input placeholder="Search Region..." (keyup)="searchGeoRegions($event)" #input>
    </div>
    <ng-container *ngIf="filteredGeoRegion.length > 0">
        <span class="submit-btn" (click)="submitSelectedGeoRegions()">Submit</span>
    </ng-container>
</div>

<ng-container *ngIf="geographicalRegions.length > 0">
    <div (click)="$event.stopPropagation()" mat-menu-item
         *ngFor="let geoRegion of geographicalRegions" [matTooltip]="geoRegion.name" matTooltipPosition="right">
        <mat-checkbox [(ngModel)]="geoRegion.isSelected"
                      (ngModelChange)="onGeoRegionSelection(geoRegion, $event)" [disabled]="geoRegion.isSelected">
            <span>{{ geoRegion.name }}</span>
        </mat-checkbox>
    </div>
</ng-container>



<div class="dialog">
    <div class="form-title">
        <!-- cross-icon -->
        <span  (click)="onDismiss()" style="cursor: pointer;">
            <svg width="9" height="9" viewBox="0 0 9 9" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke="#0044F1" stroke-width="1.5">
                    <path d="m1 1 3.333 3.333L1 7.667"/>
                    <path d="M7.333 1 4 4.333l3.333 3.334"/>
                </g>
            </svg>
        </span>
    </div>
    <div class="confirm-content">
        <div class="form-content">
            <div class="update-title">
                <span class="title">Update Region</span>
            </div>
            <div class="form-container-wrapper">
                <form [formGroup]="updateRegionForm">
                    <mat-form-field class="name-input">
                        <mat-label>Name</mat-label>
                        <input formControlName="name" matInput placeholder="Name"/>
                        <mat-error *ngIf="updateRegionForm.controls.name.hasError('required') ">
                            Region name is required
                        </mat-error>
                    </mat-form-field>
                </form>
            </div>
        </div>
    </div>
    <div class="actions">
        <button (click)= "updateRegion()" class="small edit-btn" mat-flat-button color="accent">Edit</button>
        <button (click)="onDismiss()" class="small" mat-flat-button color="primary">Cancel</button>
    </div>
</div>

<div *ngIf="loading" class="spinner-container">
    <mat-spinner class="spinner" diameter="40" color="accent"></mat-spinner>
</div>
<div class="form-container" [class.hidden]="loading">
    <form class="update-draft-form" *ngIf="updateDraftForm && !loading" [formGroup]="updateDraftForm">
        <div *ngFor="let field of datasetToEdit.fields">
            <multi-field [formControlName]="field.id" [fieldType]="field.type" [fieldName]="field.name"
                [appearance]="'standard'" [floatLabel]="'auto'">
            </multi-field>
        </div>
        <button [disabled]="loading" type="submit" mat-flat-button color="accent" (click)="updateDraft()"
            mat-raised-button>
            Submit
        </button>
    </form>
</div>
export const REPORT_TABS = {
    GLOBAL_SOV: { value: "Global SOV", isComparison: false },
    GLOBAL_SUMMARY: { value: "Global Summary", isComparison: false },
    COUNTRY_COMPARISON: { value: "Country Comparison", isComparison: true },
    USA_SOV: { value: "USA SOV", isComparison: false },
    USA_SUMMARY: { value: "USA Summary", isComparison: false },
    USA_COMPARISON: { value: "USA Comparison", isComparison: true },
    CANADA_SOV: { value: "Canada SOV", isComparison: false },
    CANADA_SUMMARY: { value: "Canada Summary", isComparison: false },
    CANADA_COMPARISON: { value: "Canada Comparison", isComparison: true },
    CALIFORNIA_SOV: { value: "California SOV", isComparison: false },
    CALIFORNIA_SUMMARY: { value: "California Summary", isComparison: false },
    FLORIDA_SOV: { value: "Florida SOV", isComparison: false },
    FLORIDA_SUMMARY: { value: "Florida Summary", isComparison: false },
    FLOOD_FEMA: { value: "FEMA Flood Zone", isComparison: false },
};

export enum REPORT_TYPES {
    RENEWWAL = "RENEWAL",
}

export const META_INTERNAL_FIELDS = ["id", "created_on", "updated_on"];

export const REPORT_PANEL_ID = "renewalReportPanel";
export const EXCESS_VALUE_LIMIT = 5;

export const CONDITIONAL_REPORT_TABS = ["FLOOD_FEMA"];

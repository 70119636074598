import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {GeographicalRegion} from '../../model/geographical-region/geographical-region';
import {GeographicalRegionsService} from '../geographical-regions.service';
import {MapStateService} from '../../map/map-state.service';
import {BehaviorSubject} from 'rxjs';
import {debounceTime, switchMap} from 'rxjs/operators';
import {MapDrawType} from '../../model/map/map-draw-type';
import {DatapointsFilterService} from '../../dataset/datapoints/datapoints-filter.service';

@Component({
    selector: 'map-geographical-filter',
    templateUrl: './geographical-filter.component.html',
    styleUrls: ['./geographical-filter.component.scss']
})
export class GeographicalFilterComponent implements OnInit {

    searchedGeoFilterValue: string;
    geographicalRegions: GeographicalRegion[];
    filteredGeoRegion: GeographicalRegion[];
    @ViewChild('input', {static: true}) inputElement: ElementRef;

    fetchRegionsSubject: BehaviorSubject<string> = new BehaviorSubject('');

    constructor(
        private readonly datapointsFilterService: DatapointsFilterService,private readonly geoRegionsService: GeographicalRegionsService, private readonly mapStateService: MapStateService) {
        this.searchedGeoFilterValue = '';
        this.geographicalRegions = [];
        this.filteredGeoRegion = [];
    }

    ngOnInit() {
        this.mapStateService.getClearFilteredRegionsSubject().subscribe((value) => {
            if (value) {
                this.clearGeoFilter();
            }
        });
        this.fetchRegionsSubject.pipe(
            debounceTime(200),
            switchMap(searchValue => this.geoRegionsService.findRegions(searchValue, 0, 225))
        ).subscribe((geoRegions) => {
                this.geographicalRegions = geoRegions;
                this.geographicalRegions.forEach(region => {
                    let regionFounded = this.filteredGeoRegion.filter(filteredRegion => filteredRegion.id === region.id);
                    if (regionFounded.length > 0) {
                        region.isSelected = true;
                    }
                });
            });
        this.datapointsFilterService.onzoomRegionChange().subscribe((updatedzoomRegion) => {
            if(updatedzoomRegion.length){
                this.filteredGeoRegion = updatedzoomRegion;
            }
        });
    }

    searchGeoRegions(event) {
        if (this.inputElement.nativeElement.value !== '') {
            this.fetchRegionsSubject.next(event.target.value);
        }
    }

    onGeoRegionSelection(geoRegion, event) {
        if (event) {
            geoRegion.isSelected = true;
            this.geoRegionsService.getRegion(geoRegion.id).subscribe((region) => {
                this.filteredGeoRegion.push(region);
            });
            this.inputElement.nativeElement.value = '';
        }
    }

    removeGeoRegionsFilter() {
        this.mapStateService.clearFilteredGeoRegions(true);
        this.clearGeoFilter();
    }

    removeSingleGeoRegionsFilter = (geoLocation) => {
        if(this.filteredGeoRegion.length > 0){
            if(this.geographicalRegions.length){
                //Uncheck the checkbox if exist in panel
              let objIndex = this.geographicalRegions.findIndex((obj => obj.id === geoLocation.id));
                if(objIndex >= 0){
                    this.geographicalRegions[objIndex].isSelected = false;
                }
            }
            this.filteredGeoRegion = this.filteredGeoRegion.filter(region => region.id !== geoLocation.id)
            this.submitSelectedGeoRegions();
        }
    }

    clearGeoFilter() {
        this.filteredGeoRegion = [];
        this.geographicalRegions = [];
        this.inputElement.nativeElement.value = '';
    }

    submitSelectedGeoRegions() {
        this.mapStateService.emitSelectedGeoRegion(this.filteredGeoRegion);
    }

}

<div class="ag-page-root">
    <div class="ag-page">
        <div class="ag-page-container">
            <div class="ag-table-actions">
                <div class="ag-toolbar-container">
                    <ng-container>
                        <span>
                            <mat-slide-toggle (change)="selectAll = !selectAll" [matBadge]="totalRecordsSimplified"
                            matBadgeColor="accent" matBadgeOverlap="false" matBadgePosition="after" class="mat-slide-toggle-badge">Select all
                            </mat-slide-toggle>
                        </span>
                    </ng-container>
                </div>
            </div>
            <div class="ag-table-container">
                <div class="ag-table-content">
                    <ag-grid-angular #agTableComponent style="width: 100%; height:100%;" class="ag-theme-alpine"
                        [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [suppressRowClickSelection]="true"
                        [groupSelectsChildren]="true" [rowSelection]="rowSelection" [enableRangeSelection]="true"
                        [enableCharts]="enableCharts" [animateRows]="true" [rowData]="rowData"
                        [gridOptions]="gridOptions" [overlayLoadingTemplate]="overlayLoadingTemplate"
                        [overlayNoRowsTemplate]="overlayNoRowsTemplate" [isRowSelectable]="isRowSelectable"
                        [sideBar]="true" (gridReady)="onGridReady($event)">
                    </ag-grid-angular>
                </div>
            </div>
        </div>
    </div>
</div>

export const MAPTYCS_CHART_COLOR = "#1855F1";
export const MaptycsTheme = {
    color: [
        "#D3E1FF",
        "#A1BAFF",
        "#6A98FB",
        "#3C76F6",
        MAPTYCS_CHART_COLOR,
    ],

    title: {
        fontWeight: "normal",
        color: MAPTYCS_CHART_COLOR,
    },

    visualMap: {
        color: ["#D3E1FF", MAPTYCS_CHART_COLOR],
    },

    toolbox: {
        color: [
            MAPTYCS_CHART_COLOR,
            MAPTYCS_CHART_COLOR,
            MAPTYCS_CHART_COLOR,
            MAPTYCS_CHART_COLOR,
        ],
    },

    tooltip: {
        backgroundColor: "rgba(0,0,0,0.5)",
        axisPointer: {
            type: "line",
            lineStyle: {
                color: MAPTYCS_CHART_COLOR,
                type: "dashed",
            },
            crossStyle: {
                color: MAPTYCS_CHART_COLOR,
            },
            shadowStyle: {
                color: "rgba(200,200,200,0.3)",
            },
        },
    },

    dataZoom: {
        dataBackgroundColor: "#eee",
        fillerColor: "rgba(24, 85, 241, 0.2)",
        handleColor: MAPTYCS_CHART_COLOR,
    },

    timeline: {
        lineStyle: {
            color: MAPTYCS_CHART_COLOR,
        },
        controlStyle: {
            color: MAPTYCS_CHART_COLOR,
            borderColor: MAPTYCS_CHART_COLOR,
        },
    },

    candlestick: {
        itemStyle: {
            color: MAPTYCS_CHART_COLOR,
            color0: "#6A98FB",
        },
        lineStyle: {
            width: 1,
            color: MAPTYCS_CHART_COLOR,
            color0: "#6A98FB",
        },
        areaStyle: {
            color: MAPTYCS_CHART_COLOR,
            color0: "#3C76F6",
        },
    },

    chord: {
        padding: 4,
        itemStyle: {
            color: MAPTYCS_CHART_COLOR,
            borderWidth: 1,
            borderColor: "rgba(128, 128, 128, 0.5)",
        },
        lineStyle: {
            color: "rgba(128, 128, 128, 0.5)",
        },
        areaStyle: {
            color: "#3C76F6",
        },
    },

    graph: {
        itemStyle: {
            color: MAPTYCS_CHART_COLOR,
        },
        linkStyle: {
            color: "#6A98FB",
        },
    },

    map: {
        itemStyle: {
            color: MAPTYCS_CHART_COLOR,
        },
        areaStyle: {
            color: "#D3E1FF",
        },
        label: {
            color: MAPTYCS_CHART_COLOR,
        },
    },

    gauge: {
        axisLine: {
            lineStyle: {
                color: [
                    [0.2, "#dddddd"],
                    [0.8, MAPTYCS_CHART_COLOR],
                    [1, "#D3E1FF"],
                ],
                width: 8,
            },
        },
    },
};

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output
} from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Dataset } from 'src/app/model/dataset/dataset';
import { DatasetField } from 'src/app/model/dataset/field/dataset-field';
import { DraftService } from 'src/app/data-access-layer/draft/draft.service';
import { take } from 'rxjs/operators';
import { DatasetFieldSpecificType } from '../../../model/dataset/dataset-field-specific.type';

@Component({
    selector: 'update-drafts',
    templateUrl: './drafts-update.component.html',
    styleUrls: ['./drafts-update.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DraftsUpdateComponent implements OnInit, OnChanges {

    @Output() Updated = new EventEmitter<string>();
    @Input() draftId: any;
    @Input() dataset: Dataset;
    datasetToEdit: Dataset;

    private draft;
    loading = true;
    datasetFields: Map<string, DatasetField>;
    updateDraftForm: UntypedFormGroup;

    constructor(
        private readonly router: Router,
        private readonly draftService: DraftService,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly changeDetector: ChangeDetectorRef
    ) {
        this.datasetFields = new Map<string, DatasetField>();
    }

    ngOnInit() {
    }

    updatingComplete(): void {
        this.Updated.emit('draft created');
    }

    ngOnChanges() {
        this.loading = true;
        this.datasetToEdit = JSON.parse(JSON.stringify(this.dataset));
        // console.log(this.dataset);
        // console.log(this.datasetToEdit);
        if (this.dataset) {
            this.datasetToEdit.fields = this.datasetToEdit.fields.filter(field => !field.isGenerated);
            this.dataset.fields.forEach(field => {
                if (field.isGenerated) {
                    return;
                }
                this.datasetFields.set(field.id, field);
            });
            this.fetchDraft();
        }
        this.changeDetector.detectChanges();
    }

    fetchDraft(): void {
        this.loading = true;
        this.draftService.findDraft(this.draftId, this.dataset.id).pipe(take(1)).subscribe((draft) => {
            this.updateDraftForm = this.formBuilder.group(this.constructFormObject());
            this.draft = draft;
            let fields = draft.fields.filter(field => this.datasetFields.get(field.id) !== undefined);
            fields.forEach((field) => {
                this.updateDraftForm.get(field.id).setValue(field.textValue || field.datetimeValue || field.numberValue);
            });
            this.loading = false;
            this.changeDetector.detectChanges();
        }, (err) => {
            console.log(err);
        });
    }

    constructFormObject() {
        let formObject = {};
        this.datasetFields.forEach((field) => {
            formObject[field.id] = new UntypedFormControl(null);
        });
        return formObject;
    }

    updateDraft() {
        this.draftService.updateDraft(this.dataset.id, this.constructCreateDraftRequest()).subscribe((draft) => {
            this.updatingComplete();
        }, error => {
            console.log(error);
        });
    }

    constructCreateDraftRequest() {
        let draft = this.draft;
        draft.fields = this.getParsedFieldsFromUpdateForm();
        return draft;
    }

    getParsedFieldsFromUpdateForm(): Array<any> {
        let fields = [];
        this.datasetFields.forEach((datasetField) => {
            let field: any = { id: datasetField.id };
            if (datasetField.type === DatasetFieldSpecificType.NUMBER_FIELD) {
                field.numberValue = this.updateDraftForm.get(datasetField.id).value;
            } else if (datasetField.type === DatasetFieldSpecificType.DATE_FIELD) {
                field.datetimeValue = this.updateDraftForm.get(datasetField.id).value;
            } else {
                field.textValue = this.updateDraftForm.get(datasetField.id).value;
            }
            fields.push(field);
        });
        return fields;
    }
}

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output
} from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DatasetField } from 'src/app/model/dataset/field/dataset-field';
import { DraftService } from 'src/app/data-access-layer/draft/draft.service';
import { DataPointField } from './field.class';

@Component({
    selector: 'create-drafts',
    templateUrl: './drafts-create.component.html',
    styleUrls: ['./drafts-create.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DraftsCreateComponent implements OnInit, OnChanges {

    @Input() fields: DatasetField[] = [];
    @Input() dataset: any;
    @Output() draftCreated = new EventEmitter<string>();

    createDraftForm: UntypedFormGroup;
    draft: any;
    loading = true;

    constructor(
        private readonly router: Router,
        private readonly draftService: DraftService,
        private readonly formBuilder: UntypedFormBuilder
    ) {
        this.createDraftForm = this.formBuilder.group({});
    }

    ngOnInit() {
    }

    creationComplete(): void {
        this.initForm();
        this.draftCreated.emit('draft created');
    }

    // addNewField(): void {
    //   let currentFields = this.createOverlayForm.controls.fields.value;
    //   currentFields.push(new DefaultField); //TODO daca adaugi asa si modific filterBarItem-ul din frontend, se modifica direct in constants valorile, ceea ce nu ii bine.
    // }
    createOverlay() {

    }

    ngOnChanges() {
        this.fields = this.fields.filter(field => !field.isGenerated);
        this.initForm();
    }

    initForm() {
        this.createDraftForm = this.formBuilder.group({});
        this.fields.forEach(element => {

            this.createDraftForm.addControl(element.name, new UntypedFormControl('', Validators.required));
        });
        this.loading = false;
        // this.createDraftForm = this.formBuilder.group(fields);
    }

    createDraft(value) {
        this.loading = true;
        this.draftService.createDraft(this.dataset.id, this.constructCreateDraftRequest(value)).subscribe((draft) => {
            this.creationComplete();
            this.loading = false;
        }, error => {
            console.log(error);
        });
    }

    constructCreateDraftRequest(value) {
        let fields = this.objectToArray(value);

        let createDraftRequest = {
            fields: fields
        };
        return createDraftRequest;
    }

    objectToArray(value) {
        let arrayOfValues = [];
        let map = {};
        this.fields.forEach((field) => {
            map[field.name] = field;
        });
        for (let prop in value) {
            if (Object.prototype.hasOwnProperty.call(value, prop)) {
                let field = map[prop];
                field.value = value[prop];
                let datapointField = new DataPointField(field);
                // arrayOfValues.push({ name: prop, value: value[prop], id: map[prop].id })
                arrayOfValues.push(datapointField.fieldValue);
            }
        }
        return arrayOfValues;
    }
}


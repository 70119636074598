<div *ngIf="loading" class="spinner-container">
    <mat-spinner class="spinner" color="accent" diameter="40"></mat-spinner>
</div>
<div class="container" *ngIf="currentOverlay" [class.hidden]="loading">
    <div class="header-container">
        <h4 class="create-title">Assign overlay {{ currentOverlay.name }} to accounts</h4>
        <mat-form-field color="accent">
            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" autocomplete="off" />
        </mat-form-field>
    </div>
    <div class="overlay-accounts-container">
        <div class="account-row">
            <span style="padding-right:20px; width: 24px;">
                <mat-checkbox #selectAll color="accent" class="account-checkbox"
                    (change)="toggleSelectAll($event)"></mat-checkbox>
            </span>
            <span>
                <label style="font-weight:bold; text-transform: capitalize;  display:block; text-overflow: ellipsis; white-space: nowrap;" class="account-name-label">
                    Account Name
                </label>
            </span>
        </div>
        <div *ngFor="let account of filteredAccounts" class="account-row status-account-row">
            <span style="padding-right:20px; width: 24px;">
                <mat-checkbox color="accent" class="account-checkbox" [checked]="account.hasOverlayAccess"
                    [(ngModel)]="account.hasOverlayAccess"></mat-checkbox>
            </span>
            <span class="overlay-wrap" style="text-overflow: ellipsis; white-space: nowrap; width: 54px; overflow: hidden;">
                <label style=" text-transform: capitalize;"
                    class="account-name-label" [matTooltip]="account.name" matTooltipPosition="below">
                    {{ account.name }}
                </label>
            </span>
            <div class="custom-status-set">
                <div class="custom-status error">
                    <p class="label fs-small">Error</p>
                    <p class="fs-small">{{ account.status.linkError | number:'1.0':'en-US' }}</p>
                </div>
                <div class="custom-status ready">
                    <p class="label fs-small">Ready</p>
                    <p class="fs-small">{{ account.status.linkReady | number:'1.0':'en-US' }}</p>
                </div>
                <div class="custom-status flight">
                    <p class="label fs-small">In Flight</p>
                    <p class="fs-small">{{ account.status.linkInFlight | number:'1.0':'en-US' }}</p>
                </div>
                <div class="custom-status linked">
                    <p class="label fs-small">Linked</p>
                    <p class="fs-small">{{ account.status.linked | number:'1.0':'en-US' }}</p>
                </div>
                <div class="custom-status not-found">
                    <p class="label fs-small">Not Found</p>
                    <p class="fs-small">{{ account.status.notFound | number:'1.0':'en-US' }}</p>
                </div>
            </div>
            <button class="refresh-button" mat-icon-button (click)="refreshAccountOverlayLinkingStatus(account.id)">
                <mat-icon aria-hidden="false" fontIcon="fa-redo" fontSet="fas"></mat-icon>
            </button>
        </div>
    </div>
    <div class="accounts-submit-button">
        <button [disabled]="loading" type="submit" color="accent" mat-flat-button
            (click)="assignAccountsToGlobalOverlay()">Save
        </button>
    </div>
</div>

import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    Output,
} from "@angular/core";
import { GroupService } from "src/app/data-access-layer/groups/group.service";
import { Dataset } from "src/app/model/dataset/dataset";
import { GroupTreeNode } from "src/app/model/overlay/group/group-with-overlays-tree-node";
import { DatapointsPageStateService } from "../datapoints/datapoints-page-state.service";
import { DatapointsFilterService } from "../datapoints/datapoints-filter.service";
import { isEnabled } from "../../../environments/environment";
import { NotifService } from "../../core/notification/notif.service";
import { Functionalities } from "../../../environments/app-functionalities";

const IS_PART_OF = Functionalities.GROUPS;

@Component({
    selector: "map-group-panel",
    templateUrl: "./group-panel.component.html",
    styleUrls: ["./group-panel.component.scss"],
})
export class GroupPanelComponent implements OnChanges {
    isExpanded = false;
    groupNodes: GroupTreeNode[] = [];
    isEnabled = isEnabled(IS_PART_OF);
    @Input() disabled = false;
    private ignoreClickOutside: boolean;

    @Input() dataset: Dataset;
    @Output() groupsReady = new EventEmitter<number[]>();

    constructor(
        private readonly groupService: GroupService,
        private readonly datapointsFilterService: DatapointsFilterService,
        private readonly datapointsPageState: DatapointsPageStateService,
        private readonly notifService: NotifService,
        private readonly eRef: ElementRef,
        private readonly changeDetector: ChangeDetectorRef
    ) {}

    @HostListener("document:click", ["$event"])
    clickOut(event) {
        if (
            !this.eRef.nativeElement.contains(event.target) &&
            this.isExpanded &&
            !this.ignoreClickOutside
        ) {
            this.isExpanded = false;
            event.stopPropagation();
        }
        this.ignoreClickOutside = false;
    }

    ngOnChanges() {
        if (this.dataset) {
            this.groupService
                .getGroupsAsTree(this.dataset.id, this.dataset.accountID)
                .subscribe((groups) => {
                    this.groupNodes = groups;
                    this.selectGroups(this.groupNodes);
                });
        }
    }

    selectGroups(groups: GroupTreeNode[]) {
        if (this.isDefaultGroupFunctionalityActive(groups)) {
            groups.forEach((group) => {
                this.selectGroupByDefault(group);
            });
        } else {
            groups.forEach((group) => {
                this.legacyGroupFunctionality(group);
            });
        }
    }

    isDefaultGroupFunctionalityActive(groups: GroupTreeNode[]): boolean {
        for (const group of groups) {
            if (group.value.isDefaultGroup === true) {
                return true;
            }
            if (group.children?.length) {
                if (this.isDefaultGroupFunctionalityActive(group.children)) {
                    return true;
                }
            }
        }
        return false;
    }

    selectGroupByDefault(node: GroupTreeNode) {
        let operation: (group: GroupTreeNode) => void;
        operation = (groupNode) => {
            const isGroupSelectedByDefault =
                groupNode.isSelected || groupNode.value.isDefaultGroup === true;

            groupNode.isSelected = isGroupSelectedByDefault;
            groupNode.children.forEach((child) => {
                child.isSelected = isGroupSelectedByDefault;
            });
        };
        this.recursiveOperation(node, operation);
        this.publishSelectedGroups();
    }

    legacyGroupFunctionality(node: GroupTreeNode) {
        let operation: (group: GroupTreeNode) => void;
        operation = (groupNode) => {
            groupNode.isSelected = true;
            groupNode.children.forEach((child) => {
                child.isSelected = true;
            });
        };
        this.recursiveOperation(node, operation);
        this.publishSelectedGroups();
    }

    open(): void {
        this.isExpanded = true;
        this.ignoreClickOutside = true;
    }

    emitGroupsUpdatedEvent(groups: number[]): void {
        this.groupsReady.emit(groups);
    }

    groupWasClicked(node: GroupTreeNode, event: any) {
        // TODO make a delay between events
        let operation: (group: GroupTreeNode) => void; // will be applied recursively
        operation = (groupNode) => {
            groupNode.isSelected = event.checked;
            groupNode.children.forEach((child) => {
                child.isSelected = event.checked;
            });
        };
        this.recursiveOperation(node, operation);
        this.publishSelectedGroups();
    }

    publishSelectedGroups() {
        let newGroups = this.extractSelectedGroupsIds();
        if (newGroups.length > 0) {
            this.datapointsFilterService.updateGroups(newGroups);
            this.datapointsPageState.activeGroups = newGroups;
            this.emitGroupsUpdatedEvent(newGroups);
        } else {
            this.notifService.error("Please select at least one group");
        }
    }

    private extractSelectedGroupsIds(): number[] {
        const selectedGroups = [];
        this.groupNodes.forEach((rootGroupNode) => {
            this.recursiveOperation(rootGroupNode, (groupNode) => {
                if (
                    (groupNode.children && this.isAllSelected(groupNode)) ||
                    groupNode.isSelected
                ) {
                    selectedGroups.push(groupNode.value.id);
                }
            });
        });
        return selectedGroups;
    }

    public get groupsIds() {
        const groupIds = [];
        this.groupNodes.forEach((rootGroupNode) => {
            this.recursiveOperation(rootGroupNode, (groupNode) => {
                groupIds.push(groupNode.value.id);
            });
        });
        return groupIds;
    }

    public get selectedGroupsIds() {
        return this.extractSelectedGroupsIds();
    }

    recursiveOperation(
        groupNode: GroupTreeNode,
        operation: (node: GroupTreeNode) => void
    ) {
        operation(groupNode);
        groupNode.children.forEach((child) =>
            this.recursiveOperation(child, operation)
        );
        // this.changeEvent();
    }

    markGroupsAsSelected(selectedGroups: number[]) {
        this.groupNodes.forEach((rootGroupNode) => {
            this.recursiveOperation(
                rootGroupNode,
                (gr) => (gr.isSelected = selectedGroups.includes(gr.value.id))
            );
        });
        // this.emitGroupsUpdatedEvent(selectedGroups);
        this.datapointsPageState.activeGroups = selectedGroups;
    }

    isIndeterminate(item: any): boolean {
        if (item.children) {
            const selectedChildren = item.children.filter(
                (child) => child.isSelected
            );
            if (
                selectedChildren.length > 0 &&
                selectedChildren.length < item.children.length
            ) {
                return true;
            } else {
                return item.children.some((child) =>
                    this.isIndeterminate(child)
                );
            }
        }
        return false;
    }

    isAllSelected(groupNode): boolean {
        if (!groupNode.children?.length) {
            return false;
        }
        if (groupNode.children?.every((group) => group.isSelected)) {
            groupNode.isSelected = true;
            return true;
        }
        groupNode.isSelected = false;
        return false;
    }
}

import { Component, Input, OnInit } from "@angular/core";
import { VisualizationType } from "../../model/dataset/rendering/visualization-options";
import { ColorUtils } from "../../core/utils/color-utils";
import { ColorScaleType } from "../../model/dataset/rendering/color-scale-type";
import { DatasetStylingOptions } from "../../model/dataset/rendering/dataset-styling-options";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Constants } from "../../constants";
import { Marker } from "../../model/overlay/marker";
import { MarkersService } from "../../data-access-layer/global-overlays/markers.service";
import { NotifService } from "../../core/notification/notif.service";
import { DatasetGeometryType } from "../../model/dataset/dataset-geometry-type";
import { DomSanitizer } from "@angular/platform-browser";
import { take } from "rxjs/operators";
import { DialogModel } from "../../model/dialog/dialog-model";
import { DialogComponent } from "../dialog/dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { Dataset } from "../../model/dataset/dataset";
import { DatasetFieldType } from "../../model/dataset/dataset-field-type";
import { DatapointsAggregateService } from "../../data-access-layer/datapoints/datapoints-aggregate.service";
import { RenderingErrorCodes } from "../../model/dataset/rendering/rendering-error-codes";
import { checkIntervalsValidity } from "../validators/colorization/colorization.validator";
import { DatapointsFilterService } from "src/app/dataset/datapoints/datapoints-filter.service";
import { DatapointFilter } from "src/app/model/datapoint/filter/datapoint-filter";
import { Group } from "src/app/model/group/group";
import { isNull, isUndefined } from "src/app/core/utils/util-master";

const NUMBER_INPUT_MAX = 100000000000;

@Component({
    selector: "map-colorization",
    templateUrl: "./colorization.component.html",
    styleUrls: ["./colorization.component.scss"],
})
export class ColorizationComponent implements OnInit {
    @Input() fields: any[] = [];
    @Input() datasetStylingOptions: DatasetStylingOptions; // the component manipulate directly this object
    @Input() datasetGeometryType: DatasetGeometryType;
    @Input() dataset: Dataset;
    @Input() rowGroups: any[];

    intervalForm: UntypedFormGroup;
    colorScaleTypes = Object.values(ColorScaleType);
    colorizationFieldIndex: number | null = null;
    ColorScaleType = ColorScaleType;
    markers: Marker[] = [];
    uploadedMarker: any;
    color: string = '#ff0000';
    colorInterval : string = '#aaaaaa';

    constructor(
        public readonly dialog: MatDialog,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly markerService: MarkersService,
        private readonly notifService: NotifService,
        private readonly sanitizer: DomSanitizer,
        private readonly datapointsFilterService: DatapointsFilterService,
        private readonly datapointAggregateService: DatapointsAggregateService
    ) {
        this.intervalForm = this.formBuilder.group({
            color: ["#aaaaaa", [Validators.required]],
            minValue: [0, [Validators.required]],
            maxValue: [],
        });
    }

    ngOnInit() {
        this.fetchMarkers();
    }

    get VisualizationTypes() {
        return [
            VisualizationType.DEFAULT,
            VisualizationType.CLUSTER,
            VisualizationType.HEATMAP,
        ];
    }

    get ColorUtils() {
        return ColorUtils;
    }

    get DatasetFieldType() {
        return DatasetFieldType;
    }

    get DatasetGeometryType() {
        return DatasetGeometryType;
    }

    // onColorChange(event, index: number) {
    //     this.datasetStylingOptions.colors[index] = ColorUtils.colorFromBase64(
    //         event.target.value
    //     );
    // }
    onColorPickerChange(oldColor: any, newColor: any) {
        const index = this.datasetStylingOptions.colors.indexOf(oldColor);
        if (index !== -1) {
            this.datasetStylingOptions.colors[index] = ColorUtils.colorFromBase64(newColor);
        }
    }
    addColors() {
        this.datasetStylingOptions.colors.push(0);
        this.checkScaleTypeByStatisticalValues();
    }

    defaultColors() {
        let colors = ColorUtils.defaultColorScale();
        let base64Colors = [];
        colors.forEach((c) => {
            base64Colors.push(ColorUtils.colorFromBase64(c));
        });
        return base64Colors;
    }

    checkScaleTypeByStatisticalValues() {
        if (this.dataset.id !== null) {
            let colorizationField =
                this.dataset.fields[
                    this.dataset.stylingOptions.colorizationFieldIndex
                ];
            let gradientScaleType =
                this.datasetStylingOptions.type === ColorScaleType.GRADIENT;
            let fixedScaleType =
                this.datasetStylingOptions.type === ColorScaleType.FIXED;
            let constantScaleType =
                this.datasetStylingOptions.type === ColorScaleType.CONSTANT;
            if (!constantScaleType) {
                let filter: DatapointFilter = { datasetID: this.dataset.id };
                if (!isUndefined(this.rowGroups) && !isNull(this.rowGroups)) {
                    const groupIds = this.rowGroups.map(function (result) {
                        return result["id"];
                    });
                    filter = {
                        datasetID: this.dataset.id,
                        groups: groupIds,
                    };
                }
                this.datapointAggregateService
                    .getDatapointsFieldStatistics(
                        this.dataset.id,
                        colorizationField.id,
                        filter
                    )
                    .subscribe((statistics) => {
                        if (statistics.values) {
                            // not allowing falsy/null values
                            let values = statistics.values.filter(Boolean);
                            if (values.length === 0) {
                                this.notifService.error(
                                    "Not available Field values... CONSTANT Color will apply."
                                );
                                this.datasetStylingOptions.type =
                                    ColorScaleType.CONSTANT;
                                return;
                            }
                            if (
                                this.datasetStylingOptions.colors.length >
                                    values.length &&
                                fixedScaleType
                            ) {
                                let indexToSplice = values.length;
                                this.notifService.error(
                                    "Not allowing so many COLORS because of the available Field values"
                                );
                                this.datasetStylingOptions.colors.splice(
                                    indexToSplice,
                                    this.datasetStylingOptions.colors.length
                                );
                            }
                            if (values.length === 1 && gradientScaleType) {
                                this.notifService.error(
                                    "No GRADIENT could be generated with only one value."
                                );
                            }
                            if (
                                values.length > 1 &&
                                this.datasetStylingOptions.colors.length === 1
                            ) {
                                this.datasetStylingOptions.colors.push(0);
                                this.notifService.error(
                                    "More COLOR is required to visualize this."
                                );
                            }
                        } else {
                            if (
                                statistics.minValue &&
                                statistics.maxValue &&
                                statistics.minValue !== statistics.maxValue
                            ) {
                                let numberOfColorsAllowed =
                                    statistics.maxValue -
                                    statistics.minValue +
                                    1;
                                if (
                                    this.datasetStylingOptions.colors.length >
                                        numberOfColorsAllowed &&
                                    fixedScaleType
                                ) {
                                    this.datasetStylingOptions.colors.splice(
                                        numberOfColorsAllowed,
                                        this.datasetStylingOptions.colors.length
                                    );
                                    this.notifService.error(
                                        `Exceeded maximum number of COLORS allowed.`
                                    );
                                }
                                if (
                                    this.datasetStylingOptions.colors.length ===
                                        1 &&
                                    (fixedScaleType || gradientScaleType)
                                ) {
                                    this.datasetStylingOptions.colors.push(0);
                                    this.notifService.error(
                                        "More COLOR is required to visualize this."
                                    );
                                }
                            }
                            if (statistics.minValue === statistics.maxValue) {
                                this.datasetStylingOptions.colors = [
                                    this.datasetStylingOptions.colors[0],
                                ];
                                this.notifService.error(
                                    "Only one value available on this numeric field."
                                );
                            }
                        }
                    });
            }
        }
    }

    onColorizeByChanged() {
        this.checkScaleTypeByStatisticalValues();
    }

    onScaleTypeChanged() {
        this.checkScaleTypeByStatisticalValues();
    }

    onConstantColorChanged(event) {
        this.datasetStylingOptions.defaultColor = ColorUtils.colorFromBase64(
            event
        );
    }

    onRemoveColor(index: number, event: Event) {
        event.stopPropagation();
        if (this.datasetStylingOptions.colors.length > 1) {
            // don't delete last element
            this.datasetStylingOptions.colors.splice(index, 1);
        }
        this.checkScaleTypeByStatisticalValues();
    }

    onRemoveColorInterval(index: number) {
        this.datasetStylingOptions.intervalOptions.splice(index, 1);
        if (
            this.datasetStylingOptions.intervalOptions[index - 1] !== undefined
        ) {
            this.intervalForm.controls.minValue.setValue(
                this.datasetStylingOptions.intervalOptions[index - 1].maxValue
            );
        } else {
            this.intervalForm.controls.minValue.setValue(null);
            this.intervalForm.controls.minValue.enable();
        }
        this.intervalForm.controls.maxValue.setValue(null);
    }

    addInterval() {
        let minValue = this.intervalForm.controls.minValue.value;
        let maxValue = this.intervalForm.controls.maxValue.value;
        let color = this.intervalForm.controls.color.value;

        if (minValue > maxValue && maxValue !== null) {
            this.notifService.error("Min cannot be greater then max.");
            return;
        }

        if (minValue > NUMBER_INPUT_MAX || maxValue > NUMBER_INPUT_MAX) {
            this.notifService.error("Exceeded the maximum allowed.");
            return;
        }

        if (minValue === null && maxValue === null) {
            this.notifService.error("Please fill a min or a max value.");
            return;
        }

        let intervals = this.datasetStylingOptions.intervalOptions;
        this.intervalForm.controls.minValue.disable();
        if (this.intervalForm.valid) {
            let lastInterval = intervals[intervals.length - 1];
            intervals.push({
                color: ColorUtils.colorFromBase64(color),
                minValue: minValue,
                maxValue: maxValue,
                total: maxValue,
            });

            if (maxValue) {
                this.intervalForm.controls.minValue.setValue(maxValue);
                this.intervalForm.controls.minValue.disable();
            } else {
                this.intervalForm.controls.minValue.setValue(null);
                this.intervalForm.controls.minValue.enable();
            }

            if (lastInterval) {
                // it's not the first interval
                lastInterval.maxValue = minValue;
            }
        }
        if (this.dataset.id !== null) {
            let invalidAttribute = checkIntervalsValidity(
                this.dataset,
                intervals
            );
            switch (invalidAttribute) {
                case RenderingErrorCodes.COLORS:
                    this.notifService.error(
                        "You must select some colors for the colorization"
                    );
                    return;
                case RenderingErrorCodes.FIELD:
                    this.notifService.error(
                        "You must choose a field for the colorization"
                    );
                    return;
                case RenderingErrorCodes.INTERVALS:
                    this.notifService.error(
                        "You must define at least one interval"
                    );
                    return;
                case RenderingErrorCodes.INTERVALS_OVERLAP:
                    this.notifService.error(
                        "The defined intervals must not overlap"
                    );
                    return;
                case RenderingErrorCodes.DUPLICATE_INTERVAL:
                    this.notifService.error(
                        "The defined intervals must not have duplicates"
                    );
                    return;
            }
        }
    }

    private fetchMarkers() {
        this.markerService.getMarkers().subscribe((markers) => {
            markers.forEach(
                (marker) =>
                    (marker.markerSrc =
                        this.sanitizer.bypassSecurityTrustResourceUrl(
                            Constants.IMG_SRC_BASE64_SVG_MIME_TYPE +
                                marker.marker
                        ))
            );
            this.markers = markers;
        });
    }

    onAddNewMarker(event) {
        this.uploadedMarker = event.target.files[0];
    }

    saveMarker() {
        this.markerService.createMarker(this.uploadedMarker).subscribe(
            (success) => {
                this.notifService.success("Successfully added marker");
                this.uploadedMarker = null;
                this.fetchMarkers();
            },
            (error) => this.notifService.error(error.error.message)
        );
    }

    deleteMarker(marker: Marker) {
        const dialogRef = this.dialog.open(DialogComponent, {
            data: new DialogModel(
                "Confirm Action",

                `Are you sure you want to delete marker ${marker.filename} ?`
            ),
        });
        dialogRef
            .afterClosed()
            .pipe(take(1))
            .subscribe((dialogResult) => {
                if (dialogResult) {
                    this.markerService.deleteMarker(marker.id).subscribe(
                        () => {
                            this.notifService.success(
                                `Successfully deleted marker ${marker.filename}`
                            );
                            this.fetchMarkers();
                        },
                        (err) => {
                            this.notifService.error(err.error.message);
                        }
                    );
                }
            });
    }
    onColorPickerIntervalChange(newColor: string) {
        this.intervalForm.get('color').setValue(newColor);
    }
    
}

<div class="page-root">
    <map-account-header-component >
        <settings-header-breadcrumbs [breadCrumbs]="breadCrumbs" [componentName]="componentName"></settings-header-breadcrumbs>
        <div class="actions">
            <button (click)="saveDataset()" mat-flat-button color="primary">
                <div class="icon-container-wrap">
                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <g transform="translate(-.005 -.005)" fill="none" fill-rule="evenodd">
                            <path class="icon-primary"
                                d="M20.008 11.005V7.564c0-.502-.199-.983-.553-1.337l-2.672-2.672a1.89 1.89 0 0 0-1.337-.554H5.002a2 2 0 0 0-2 2.001v13.006a2 2 0 0 0 2 2h6.003"
                                stroke="#002d57" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path
                                d="M15 3.001v4.127a.876.876 0 0 1-.876.875H8.872a.876.876 0 0 1-.875-.875V3.001M7.003 20.008v-5.676a1.315 1.315 0 0 1 1.215-1.327h3.787"
                                stroke="#002d57" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <circle stroke="#002d57" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                                cx="17.507" cy="17.507" r="4.502" />
                            <path stroke="#002d57" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                                d="m18.907 16.808-1.75 1.752-1.05-1.052" />
                            <path d="M0 0h24.01v24.01H0z" />
                        </g>
                    </svg>
                </div>
                <div>Save</div>
            </button>
        </div>
    </map-account-header-component>
    <div class="page" *ngIf="groupes">
        <div class="page-container">
            <div class="page-content">
                <map-dataset-config #datasetConfig (fileUploaded)="getCurrentDataset(dataset.id.toString())"
                                    *ngIf="dataset.fields.length"
                                    [datasetStylingOptions]="dataset.stylingOptions"
                                    [fields]="dataset.fields"
                                    [form]="editOverlayForm"
                                    [isAccountApplicationDataset]="true"
                                    [thematicDatasets]="[]"
                                    [displayColorizationBox]="displayColorizationBox"
                                    [dataset]="dataset"
                                    [groupes]="groupes"></map-dataset-config>

                <div *ngIf="!dataset.fields.length" class="spinnder-container">
                    <mat-spinner diameter="40" color="accent"></mat-spinner>
                </div>
            </div>
        </div>


        <div class="footer-actions">
            <div class="footer-group">
                <div class="footer-group-container">
            <span (click)="getCurrentDataset(dataset.id)" *ngFor="let dataset of applications"
                  [ngClass]="{'footer-button-active': dataset.id === applicationId}"
                  class="footer-button">
                {{ dataset.name }}
            </span>
                </div>
            </div>
        </div>

    </div>
</div>

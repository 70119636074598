import { ChangeDetectorRef, Component, Input, OnInit, ViewChild, ViewChildren } from "@angular/core";
import { AccumulationsTab, Constants } from "../constants";
import { ClusteringRequest } from "../dataset/clustering/clustering-request";
import { Dataset } from "../model/dataset/dataset";
import { DatapointFilter } from "../model/datapoint/filter/datapoint-filter";
import { WorkspaceItem } from "../model/workspace/workspace-item";
import { AccumulationsService } from "../shared/services/accumulations.service";
import { ClusteringService } from "../dataset/clustering/clustering.service";
import { AggregateReportComponent } from "../dataset/datapoints/datapoints-analytics/aggregate-report/aggregate-report.component";
import { CountReportComponent } from "../dataset/datapoints/datapoints-analytics/count-report/count-report.component";
import { RankingReportComponent } from "../dataset/datapoints/datapoints-analytics/ranking-report/ranking-report.component";
import { WorkspaceItemDialogComponent } from "../dataset/workspace-item/projection/workspace-item-dialog.component";
import { ReportItem } from "../model/analytics/report-item";
import { TreeStructureUtils } from "../core/utils/tree-structure-utils";
import { WorkspaceItemService } from "../data-access-layer/workspace-item/workspace-item.service";
import { WorkspaceItemType } from "../model/workspace/workspace-item-type";
import { UserStateService } from "../auth/user-state-service";
import { GroupService } from "../data-access-layer/groups/group.service";
import { WorkspaceItemAccess } from "../model/workspace/workspace-item-access";
import { Group } from "../model/group/group";
import { GroupTreeNode } from "../model/overlay/group/group-with-overlays-tree-node";
import { AnalyticsUtils } from "../core/analytics/analytics-utils";
import { TreeStructure } from "../model/menu/tree-structure";
import { RandomUtils } from "../core/utils/random-utils";
import { ReportType } from "../model/analytics/report-type";
import { UpdateWorkspaceItemRequest } from "../model/workspace/update-workspace-item-request";
import { NotifService } from "../core/notification/notif.service";
import { isEnabled } from "src/environments/environment";
import { Functionalities } from "src/environments/app-functionalities";
import { DatapointsPageStateService } from "../dataset/datapoints/datapoints-page-state.service";
import { MapInteractionStatus } from "../dataset/datapoints/map-interaction-status";
import { MapInteractionMode } from "../dataset/datapoints/map-interaction-mode";
import { DialogModel } from "../model/dialog/dialog-model";
import { DownloadReportItem } from "../model/download/item/download-report-item";
import { ReportComponent } from "../dataset/datapoints/datapoints-analytics/report.component";
import { ReportDisplayType } from "../model/analytics/report-display-type";
import { DownloadReportRequest } from "../model/download/download-report-request";
import { DownloadDatapointRequest } from "../model/download/download-datapoint-request";
import { DownloadFileType } from "../model/download/download-file-type";
import { DialogComponent } from "../shared/dialog/dialog.component";
import { AttachmentUtils } from "../core/utils/attachment-utils";
import { MatDialog } from "@angular/material/dialog";
import { DataPointsServiceState } from "../shared/services/datapoints-service-state";
import { DownloadService } from "../data-access-layer/download/download.service";
import { DatapointsFilterService } from "../dataset/datapoints/datapoints-filter.service";
import { isUndefined } from "../core/utils/util-master";
import { ChartDisplayType } from "../model/analytics/chart-display-type";
import { DatasetField } from "../model/dataset/field/dataset-field";
import { take } from "rxjs/operators";

const IS_PART_OF = Functionalities.ANALYTICS;

@Component({
    selector: "map-accumulations",
    templateUrl: "./accumulations.component.html",
    styleUrls: ["./accumulations.component.scss"],
})
export class AccumulationsComponent implements OnInit {
    isEnabled = isEnabled(IS_PART_OF);

    @Input() dataset: Dataset;
    @Input() initSettings: ClusteringRequest;
    @Input() filter: DatapointFilter;
    @Input() dataSource: any;
    @Input() treeControl: any;
    @Input() heatMapTreeStructure: any;

    showTMAnalytics: boolean = false;

    @Input() formulas: WorkspaceItem[];

    @Input()  isComparisonModeActivated: boolean = false;

    @Input() isDashboardCall: boolean = false;

    onShowTMAnalyticsChange(value: boolean) {
        this.showTMAnalytics = value;
      }

    _nriFields: any;
    _climateOverlays: any;
    isThematicMapActive : boolean = false;

    get nriFields(): any {
        return this._nriFields;
    }
    @Input() set nriFields(nriFields: any) {
        this._nriFields = JSON.parse(JSON.stringify(nriFields));
    }
    get climateOverlays(): any {
        return this._climateOverlays;
    }
    @Input() set climateOverlays(climateOverlays: any) {
        this._climateOverlays = JSON.parse(JSON.stringify(climateOverlays));
    }

    @ViewChildren(AggregateReportComponent)
    aggregateReportComponents: AggregateReportComponent[];
    @ViewChildren(CountReportComponent)
    countReportComponents: CountReportComponent[];
    @ViewChildren(RankingReportComponent)
    rankingReportComponents: RankingReportComponent[];
    @ViewChild("workspaceItemDialog", { static: true })
    workspaceIemDialog: WorkspaceItemDialogComponent;

    reportItems: ReportItem[] = [];
    workspaceItems: WorkspaceItem[] = [];
    comparisonWorkspaceItems: WorkspaceItem[] = [];
    activeWorkspaceItems: WorkspaceItem[] = [];
    openDataTreeStrcuture = new TreeStructureUtils();
    openDatatreeControl = this.openDataTreeStrcuture.getTreeControl();
    openDatadataSource = this.openDataTreeStrcuture.getDataSource();
    openData: boolean = false;
    groups: Group[];
    dashboardWorkspaceItems: WorkspaceItem[] = [];
    selectedReportId: number;
    groupsStrcuture: TreeStructure[];

    get accumulationsTab() {
        return AccumulationsTab;
    }

    get ReportType() {
        return ReportType;
    }

    activeTab: AccumulationsTab = AccumulationsTab.CLUSTER;

    constructor(
        private readonly accumulationsSerivce: AccumulationsService,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly datapointsFilterService: DatapointsFilterService,
        private readonly downloadService: DownloadService,
        private readonly notifService: NotifService,
        private readonly workspaceItemService: WorkspaceItemService,
        private readonly datapointsStateService: DataPointsServiceState,
        private readonly userStateService: UserStateService,
        public readonly dialog: MatDialog,
        private readonly groupService: GroupService,
        private readonly datapointsPageStateService: DatapointsPageStateService,
        private readonly clusteringService: ClusteringService

    ) {}

    ngOnInit() {
        this.initialize();
    }


    initialize(): void {
        this.fetchWorkspaceItems();
        this.fetchComparisonWorkspaceItems();
        this.fetchDashboardWorkspaceItems();
        this.fetchGroups();
    }

    getThematicMapStatus() : boolean {
        this.datapointsPageStateService
                .onMapInteractionChange()
                .subscribe((newStatus) => {
                  this.isThematicMapActive = newStatus.mode === MapInteractionMode.THEMATIC_MAP_ACTIVE;
                })
                // if(this.isThematicMapActive && (this.showTMAnalytics === true)){
                //     return false;
                // }
                // return true;
                return !(this.isThematicMapActive && this.showTMAnalytics);
    }

    saveSorting(report) {
        if (this.selectedReportId !== 0) {
            this.updateWorkspaceItemName(report);
        }
    }

    removeReportItem(position: number) {
        this.reportItems.splice(position, 1);
    }

    updateWorkspaceItemName(reportData) {
        let report = this.workspaceItems.find(
            (element) => element.id === this.selectedReportId
        );
        if (report !== undefined) {
            let data = JSON.parse(report.data);
            if (data.length) {
                data.forEach((element) => {
                    if (element.id == reportData.id) {
                        element.sort = reportData.sort;
                    }
                });
            }
            let request: UpdateWorkspaceItemRequest = {
                id: report.id,
                data: JSON.stringify(data),
                access: report.access,
                name: report.name,
            };
            this.workspaceItemService.updateWorkspaceItem(request).subscribe(
                (success) => {},
                (error) => this.notifService.error(error.error.message)
            );
        } else {
            console.log("report data not found.");
        }
    }

    truncateName(name: string): string {
        if (name.length >= 18) {
            return name.substring(0, 13) + "...";
        }
        return name;
    }

    
    updateOpenDatadataSource(items) {
        this.openDatadataSource.data = items.map((item) => ({
            data: item.data,
            name: item.name,
            isDeletable: item.isDeletable,
            id: item.id,
        }));
        this.changeDetector.detectChanges();
    }

    addCountReport() {
        this.selectedReportId = 0;
        this.reportItems.push({
            id: RandomUtils.generateUUID(),
            type: ReportType.COUNT,
        });
        this.changeDetector.detectChanges();
    }

    addAggregateReport() {
        this.selectedReportId = 0;
        this.reportItems.push({
            id: RandomUtils.generateUUID(),
            type: ReportType.AGGREGATE,
        });
        this.changeDetector.detectChanges();
    }

    addRankingReport() {
        this.selectedReportId = 0;
        this.reportItems.push({
            id: RandomUtils.generateUUID(),
            type: ReportType.RANKING,
        });
        this.changeDetector.detectChanges();
    }

    removeAllReports() {
        this.reportItems = [];
        this.isComparisonModeActivated = false;
        this.changeDetector.detectChanges();
    }

    fetchWorkspaceItems() {
        this.workspaceItemService
            .getAllWorkspaceItemsForUser(
                this.dataset.id,
                WorkspaceItemType.ANALYTICS
            )
            .subscribe((items) => {
                this.workspaceItems = this.addDeletableKey(items);
                this.updateOpenDatadataSource(this.workspaceItems);
            });

        this.workspaceItemService
            .getAllWorkspaceItemsForUser(
                this.dataset.id,
                WorkspaceItemType.FORMULA
            )
            .subscribe((items) => (this.formulas = items));
    }

    addDeletableKey(items){
        items.forEach((item) => {
            if (
                this.userStateService.isSuperadmin ||
                this.userStateService.isAccountAdmin(
                    this.dataset.accountID
                )
            ) {
                item.isDeletable = true;
            } else {
                if (item.access === WorkspaceItemAccess.MEMBER) {
                    item.isDeletable = true;
                }
            }
        });

        return items;
    }

    fetchComparisonWorkspaceItems() {
        this.workspaceItemService
            .getAllWorkspaceItemsForUser(
                this.dataset.id,
                WorkspaceItemType.ANALYTICS_COMPARISON
            )
            .subscribe((items) => {
                this.comparisonWorkspaceItems = this.addDeletableKey(items);
                if(this.isComparisonModeActivated){
                    this.updateOpenDatadataSource(this.comparisonWorkspaceItems);
                }
            });
    }

    fetchDashboardWorkspaceItems() {
        this.workspaceItemService
            .getAllWorkspaceItemsForUser(
                this.dataset.id,
                WorkspaceItemType.DASHBOARD
            )
            .subscribe((items) => {
                this.dashboardWorkspaceItems = items;
                this.dashboardWorkspaceItems.forEach((item) => {
                    if (
                        this.userStateService.isSuperadmin ||
                        this.userStateService.isAccountAdmin(
                            this.dataset.accountID
                        )
                    ) {
                        item.isDeletable = true;
                    } else {
                        if (item.access === WorkspaceItemAccess.MEMBER) {
                            item.isDeletable = true;
                        }
                    }
                });
            });
    }

    extractChildrenOnly(nodes: GroupTreeNode[]): Group[] {
        let groups: Group[] = [];

        const extract = (nodes: GroupTreeNode[]) => {
            for (const node of nodes) {
                if (!node?.children?.length) {
                    groups.push({ ...node.value, isChecked: false });
                } else {
                    extract(node.children);
                }
            }
        };

        extract(nodes);

        return groups;
    }

    fetchGroups() {
        this.groupService
            .getGroupsAsTree(this.dataset.id, this.dataset.accountID)
            .subscribe((groups) => {
                this.groups = this.extractChildrenOnly(groups);
            });
        this.groupService.getGroups(this.dataset.id, this.dataset.accountID).subscribe(response => {
            const data = JSON.parse(JSON.stringify(response));
            this.groupsStrcuture = AnalyticsUtils.transformTreeStructure(data);
        })
    }


    changeActiveTab(newTab: AccumulationsTab) {
        this.activeTab = newTab;

        switch (this.activeTab) {
            case AccumulationsTab.CLUSTER:
                this.resetHeatMap();
                this.resetThematicMap();
                this.showTMAnalytics = false;
                break;
            case AccumulationsTab.HEATMAP:
                this.resetClustering();
                this.resetThematicMap();
                this.showTMAnalytics = false;
                break;
            case AccumulationsTab.THEMATIC_MAP:
                this.activateThematicMap();
                this.resetHeatMap();
                this.resetClustering();
                // below flags are used to show the analytics on the thematic map when the tab is switched to thematic map
                this.showTMAnalytics = true;
                break;

            default:
                break;
        }
    }

    activateThematicMap() {
        this.accumulationsSerivce.emitThematicMapActivation();
    }

    resetThematicMap() {
        this.accumulationsSerivce.emitThematicMapDeactivation();
    }

    resetHeatMap() {
        this.accumulationsSerivce.heatmapActivationEventEmitter.emit(null);
    }

    resetClustering() {
        this.clusteringService.emitResetClustering();
    }

    getActiveDasboardType(){
        return !this.isComparisonModeActivated ? WorkspaceItemType.DASHBOARD : WorkspaceItemType.ANALYTICS_DASHBOARD_COMPARISON;
    }


    saveWorkspaceItemForDashboard(i: number) {
        const dashboardReportItem = [];
        dashboardReportItem.push(
            this.prepareSpecificWorkspaceItemInfo(this.reportItems[i])
        );

        this.workspaceIemDialog.open(
            dashboardReportItem,
            this.getActiveDasboardType(),
            true,
            true
        );
    }

    saveAsWorkspaceItemForDashboard(i: number) {
        const dashboardReportItem = [];
        dashboardReportItem.push(
            this.prepareSpecificWorkspaceItemInfo(this.reportItems[i])
        );

        this.workspaceIemDialog.open(
            dashboardReportItem,
            this.getActiveDasboardType(),
            false,
            true
        );
    }

    private prepareSpecificWorkspaceItemInfo(report) {
        switch (report.type) {
            case ReportType.AGGREGATE:
                let aggregateReportComponent =
                    this.aggregateReportComponents.find(
                        (component) => component.uuid === report.id
                    );
                if (aggregateReportComponent.getSelectedFormula()) {
                    report.selectedFormula =
                        aggregateReportComponent.getSelectedFormula();
                } else {
                    report.selectedAggregateField =
                        aggregateReportComponent.selectedAggregateField;
                }

                report.selectedAggregateField =
                    aggregateReportComponent.selectedAggregateField;
                let jsonObject = {};
                aggregateReportComponent.selectedBreakdownFieldsByDataset.forEach(
                    (value, key) => {
                        jsonObject[key] = value;
                    }
                );
                report.sort = aggregateReportComponent.getSort();
                report.selectedBreakdownFieldsByDataset = jsonObject;
                report.name = aggregateReportComponent.reportName;
                report.reportType = aggregateReportComponent.reportType;
                report.reportSubType = aggregateReportComponent.reportSubType;
                report.chartDisplayType =
                    aggregateReportComponent.chartDisplayType;
                if(this.isComparisonModeActivated) {
                    report.compareGroupId = [AnalyticsUtils.getSelectedNodes(aggregateReportComponent._compareGroupsStrcuture)[0].id];
                    report.withGroupId = [AnalyticsUtils.getSelectedNodes(aggregateReportComponent._withGroupsStrcuture)[0].id];
                }
                break;
            case ReportType.RANKING:
                let rankingReportComponent = this.rankingReportComponents.find(
                    (component) => component.uuid === report.id
                );
                if (rankingReportComponent.getSelectedFormula()) {
                    report.selectedFormula =
                        rankingReportComponent.getSelectedFormula();
                } else {
                    report.selectedRankingField =
                        rankingReportComponent.selectedRankingField;
                }
                report.selectedFields = rankingReportComponent.selectedFields;
                report.sort = rankingReportComponent.getSort();
                report.noOfItems = rankingReportComponent.noOfItems;
                report.name = rankingReportComponent.reportName;
                report.reportType = rankingReportComponent.reportType;
                report.reportSubType = rankingReportComponent.reportSubType;
                report.chartDisplayType =
                    rankingReportComponent.chartDisplayType;
                if(this.isComparisonModeActivated) {
                    report.compareGroupId = [AnalyticsUtils.getSelectedNodes(rankingReportComponent._compareGroupsStrcuture)[0].id];
                    report.withGroupId = [AnalyticsUtils.getSelectedNodes(rankingReportComponent._withGroupsStrcuture)[0].id];
                }
                break;
            case ReportType.COUNT:
                let countReportComponent = this.countReportComponents.find(
                    (component) => component.uuid === report.id
                );
                let countJsonObject = {};
                countReportComponent.selectedFieldsByDataset.forEach(
                    (value, key) => {
                        countJsonObject[key] = value;
                    }
                );
                report.sort = countReportComponent.getSort();
                report.selectedFieldsByDataset = countJsonObject;
                report.name = countReportComponent.reportName;
                report.reportType = countReportComponent.reportType;
                report.reportSubType = countReportComponent.reportSubType;
                report.chartDisplayType = countReportComponent.chartDisplayType;
                if(this.isComparisonModeActivated) {
                    report.compareGroupId = [AnalyticsUtils.getSelectedNodes(countReportComponent._compareGroupsStrcuture)[0].id];
                    report.withGroupId = [AnalyticsUtils.getSelectedNodes(countReportComponent._withGroupsStrcuture)[0].id];
                }
                break;
        }

        return report;
    }


    downloadReports() {
        let downloadItems: DownloadReportItem[] = [];
        let reportHasTwoDimensionPieChart = false;

        this.reportItems.forEach((reportItem) => {
            let aggregateReportComponent = this.aggregateReportComponents.find(
                (aggregateComp) => aggregateComp.uuid === reportItem.id
            );
            let countReportComponent = this.countReportComponents.find(
                (countComp) => countComp.uuid === reportItem.id
            );
            let rankingReportComponent = this.rankingReportComponents.find(
                (rankingComp) => rankingComp.uuid === reportItem.id
            );

            let component: ReportComponent =
                aggregateReportComponent ||
                countReportComponent ||
                rankingReportComponent;
            if (component) {
                if (
                    component.getDisplayType() ===
                        ReportDisplayType.PIE_CHART &&
                    component.isTwoDimensionReport()
                ) {
                    reportHasTwoDimensionPieChart = true;
                }
                let reportDownloadRequest: DownloadReportItem =
                    component.getReportDownloadRequest();
                if (reportDownloadRequest) {
                    downloadItems.push(reportDownloadRequest);
                }
            }
        });

        let reportRequest: DownloadReportRequest = {
            items: downloadItems,
        };

        let downloadRequest: DownloadDatapointRequest = {
            reportRequest: reportRequest,
            filter: this.datapointsFilterService.getActiveFilter(),
            timezone: Constants.DEFAULT_TIMEZONE,
            dateFormat: Constants.DEFAULT_DATE_FORMAT,
            outputFileType: DownloadFileType.XLSX,
        };

        if (reportHasTwoDimensionPieChart) {
            const dialogRef = this.dialog.open(DialogComponent, {
                data: new DialogModel(
                    "Confirm Download",
                    `Your pie chart has multiple criteria, but the spreadsheet download will only display one. We recommend downloading the bar charts instead.`,
                    "Cancel",
                    "Download"
                ),
            });
            dialogRef
                .afterClosed()
                .pipe(take(1))
                .subscribe((dialogResult) => {
                    if (dialogResult) {
                        this.downloadReportFile(downloadRequest);
                    }
                });
        } else {
            this.downloadReportFile(downloadRequest);
        }
    }

    private downloadReportFile(downloadRequest: DownloadDatapointRequest) {
        this.downloadService
            .downloadDatapoints(
                this.dataset.id,
                downloadRequest,
                "Analytics.xlsx"
            )
            .subscribe(
                (response) => {
                    AttachmentUtils.downloadFileWithName(
                        response,
                        "Analytics.xlsx"
                    );
                },
                (error) =>
                    this.notifService.error(
                        "Something went wrong during download"
                    )
            );
    }

    get WorkspaceItemType() {
        return WorkspaceItemType;
    }

    fetchActiveWorkspaceItems(){
        if(!this.isComparisonModeActivated){
            this.fetchWorkspaceItems();
        } else {
            this.fetchComparisonWorkspaceItems();
        }
    }

    applyWorkspaceItem(item: WorkspaceItem) {
        this.selectedReportId = item.id;
        this.reportItems = JSON.parse(item.data);
        this.changeDetector.detectChanges();
        this.reportItems.forEach((report) => {
            switch (report.type) {
                case ReportType.AGGREGATE: {
                    let aggregateReportComponent =
                        this.aggregateReportComponents.find(
                            (component) =>
                                !component.getSelectedAggregateField() &&
                                !component.getSelectedFormula()
                        );
                    if (aggregateReportComponent) {
                        if (report.selectedAggregateField) {
                            aggregateReportComponent.setSelectedAggregateField(
                                report.selectedAggregateField
                            );
                        }
                        if (report.selectedFormula) {
                            aggregateReportComponent.setSelectedFormula(
                                report.selectedFormula
                            );
                        }
                        if (report.selectedBreakdownFieldsByDataset) {
                            let selectedBreakdownFieldsByDataset = new Map<
                                string,
                                DatasetField[]
                            >();
                            for (let k of Object.keys(
                                report.selectedBreakdownFieldsByDataset
                            )) {
                                selectedBreakdownFieldsByDataset.set(
                                    k,
                                    report.selectedBreakdownFieldsByDataset[k]
                                );
                            }
                            aggregateReportComponent.setSelectedBreakdownFieldsByDataset(
                                selectedBreakdownFieldsByDataset
                            );
                        }
                        aggregateReportComponent.sort = report.sort;
                        aggregateReportComponent.reportName = report.name;
                        aggregateReportComponent.reportSubType = isUndefined(
                            report.reportSubType
                        )
                            ? ReportDisplayType.TABLE
                            : report.reportSubType;
                        aggregateReportComponent.chartDisplayType = isUndefined(
                            report.chartDisplayType
                        )
                            ? ChartDisplayType.NONE
                            : report.chartDisplayType;
                        if (
                            (report.selectedAggregateField ||
                                report.selectedFormula) &&
                            report.selectedBreakdownFieldsByDataset
                        ) {
                            aggregateReportComponent.generateReport();
                        }

                        if(this.isComparisonModeActivated) {
                            // aggregateReportComponent._compareGroups = this.getSelectedGroup(report.compareGroupId);
                            // aggregateReportComponent._withGroups = this.getSelectedGroup(report.withGroupId);
                            aggregateReportComponent._compareGroupsStrcuture = AnalyticsUtils.getSelectedStrucutureGroup(report.compareGroupId, this.groupsStrcuture);
                            aggregateReportComponent._withGroupsStrcuture = AnalyticsUtils.getSelectedStrucutureGroup(report.withGroupId, this.groupsStrcuture);
                        }
                        aggregateReportComponent.generateReport();
                    }

                    break;
                }
                case ReportType.RANKING: {
                    let rankingReportComponent =
                        this.rankingReportComponents.find(
                            (component) =>
                                !component.getSelectedRankingField() &&
                                !component.getSelectedFormula()
                        );

                    if (rankingReportComponent) {
                        if (report.selectedRankingField) {
                            rankingReportComponent.setSelectedRankingField(
                                report.selectedRankingField
                            );
                        }
                        if (report.selectedFormula) {
                            rankingReportComponent.setSelectedFormula(
                                report.selectedFormula
                            );
                        }
                        if (report.selectedFields) {
                            rankingReportComponent.setSelectedFields(
                                report.selectedFields
                            );
                        }
                        if (report.noOfItems) {
                            rankingReportComponent.noOfItems = report.noOfItems;
                        }
                        rankingReportComponent.sort = report.sort;
                        rankingReportComponent.reportName = report.name;
                        rankingReportComponent.reportSubType = isUndefined(
                            report.reportSubType
                        )
                            ? ReportDisplayType.TABLE
                            : report.reportSubType;
                        rankingReportComponent.chartDisplayType = isUndefined(
                            report.chartDisplayType
                        )
                            ? ChartDisplayType.NONE
                            : report.chartDisplayType;
                        if (
                            (report.selectedRankingField ||
                                report.selectedFormula) &&
                            report.selectedFields
                        ) {
                            if(this.isComparisonModeActivated) {
                                // rankingReportComponent._compareGroups = this.getSelectedGroup(report.compareGroupId);
                                // rankingReportComponent._withGroups = this.getSelectedGroup(report.withGroupId);
                                rankingReportComponent._compareGroupsStrcuture = AnalyticsUtils.getSelectedStrucutureGroup(report.compareGroupId, this.groupsStrcuture);
                                rankingReportComponent._withGroupsStrcuture = AnalyticsUtils.getSelectedStrucutureGroup(report.withGroupId, this.groupsStrcuture);
                            }
                            rankingReportComponent.generateReport();
                        }
                    }

                    break;
                }
                case ReportType.COUNT: {
                    let countReportComponent = this.countReportComponents.find(
                        (component) =>
                            !component.getSelectedFieldsByDataset().size
                    );

                    if (countReportComponent) {
                        if (report.selectedFieldsByDataset) {
                            let selectedFieldsByDataset = new Map<
                                string,
                                DatasetField[]
                            >();
                            for (let k of Object.keys(
                                report.selectedFieldsByDataset
                            )) {
                                selectedFieldsByDataset.set(
                                    k,
                                    report.selectedFieldsByDataset[k]
                                );
                            }
                            countReportComponent.setSelectedFieldsByDataset(
                                selectedFieldsByDataset
                            );
                            countReportComponent.sort = report.sort;
                            countReportComponent.reportName = report.name;
                            countReportComponent.reportSubType = isUndefined(
                                report.reportSubType
                            )
                                ? ReportDisplayType.TABLE
                                : report.reportSubType;
                            countReportComponent.chartDisplayType = isUndefined(
                                report.chartDisplayType
                            )
                                ? ChartDisplayType.NONE
                                : report.chartDisplayType;

                            if(this.isComparisonModeActivated) {
                                // countReportComponent._compareGroups = this.getSelectedGroup(report.compareGroupId);
                                // countReportComponent._withGroups = this.getSelectedGroup(report.withGroupId);
                                countReportComponent._compareGroupsStrcuture = AnalyticsUtils.getSelectedStrucutureGroup(report.compareGroupId, this.groupsStrcuture);
                                countReportComponent._withGroupsStrcuture = AnalyticsUtils.getSelectedStrucutureGroup(report.withGroupId, this.groupsStrcuture);
                            }
                            countReportComponent.generateReport();
                        }
                    }

                    break;
                }
            }
        });
    }

    getSelectedGroup(checkedGroups) {
        const _groups = JSON.parse(JSON.stringify(this.groups)).map((group) => {
            if(checkedGroups.includes(group.id)){
                group.isChecked = true;
            } else {
                group.disabled = true;
            }
            return group;
        });
        return _groups;
    }

    deleteWorkspaceItem(item: WorkspaceItem) {
        const dialogRef = this.dialog.open(DialogComponent, {
            data: new DialogModel(
                "Confirm Action",
                `Are you sure you want to delete "${item.name}"?`
            ),
        });
        dialogRef
            .afterClosed()
            .pipe(take(1))
            .subscribe((dialogResult) => {
                if (dialogResult) {
                    this.workspaceItemService
                        .deleteWorkspaceItem(item.id)
                        .subscribe(
                            (success) => {
                                this.fetchActiveWorkspaceItems();
                                this.notifService.success(
                                    "Item was deleted successfully"
                                );
                            },
                            (error) =>
                                this.notifService.error(error.error.message)
                        );
                }
            });
    }

    saveWorkspaceItem() {
        this.prepareWorkspaceItemInfo();
        this.workspaceIemDialog.open(
            this.reportItems,
            this.getActiveAnalyticType(),
            true
        );
    }

    saveAsWorkspaceItem() {
        this.prepareWorkspaceItemInfo();
        this.workspaceIemDialog.open(
            this.reportItems,
            this.getActiveAnalyticType(),
            false
        );
    }

    getActiveAnalyticType(){
        return !this.isComparisonModeActivated ? WorkspaceItemType.ANALYTICS : WorkspaceItemType.ANALYTICS_COMPARISON;
    }

    private prepareWorkspaceItemInfo() {
        this.reportItems.forEach((report) => {
            switch (report.type) {
                case ReportType.AGGREGATE:
                    let aggregateReportComponent =
                        this.aggregateReportComponents.find(
                            (component) => component.uuid === report.id
                        );
                    if (aggregateReportComponent.getSelectedFormula()) {
                        report.selectedFormula =
                            aggregateReportComponent.getSelectedFormula();
                    } else {
                        report.selectedAggregateField =
                            aggregateReportComponent.selectedAggregateField;
                    }

                    report.selectedAggregateField =
                        aggregateReportComponent.selectedAggregateField;
                    let jsonObject = {};
                    aggregateReportComponent.selectedBreakdownFieldsByDataset.forEach(
                        (value, key) => {
                            jsonObject[key] = value;
                        }
                    );
                    report.selectedBreakdownFieldsByDataset = jsonObject;
                    report.sort = aggregateReportComponent.getSort();
                    report.name = aggregateReportComponent.reportName;
                    report.reportType = aggregateReportComponent.reportType;
                    report.reportSubType =
                        aggregateReportComponent.reportSubType;
                    report.chartDisplayType =
                        aggregateReportComponent.chartDisplayType;
                    if(this.isComparisonModeActivated) {
                        report.compareGroupId = [AnalyticsUtils.getSelectedNodes(aggregateReportComponent._compareGroupsStrcuture)[0].id];
                        report.withGroupId = [AnalyticsUtils.getSelectedNodes(aggregateReportComponent._withGroupsStrcuture)[0].id];
                    }
                    break;
                case ReportType.RANKING:
                    let rankingReportComponent =
                        this.rankingReportComponents.find(
                            (component) => component.uuid === report.id
                        );
                    if (rankingReportComponent.getSelectedFormula()) {
                        report.selectedFormula =
                            rankingReportComponent.getSelectedFormula();
                    } else {
                        report.selectedRankingField =
                            rankingReportComponent.selectedRankingField;
                    }
                    report.selectedFields =
                        rankingReportComponent.selectedFields;
                    report.sort = rankingReportComponent.getSort();
                    report.noOfItems = rankingReportComponent.noOfItems;
                    report.name = rankingReportComponent.reportName;
                    report.reportType = rankingReportComponent.reportType;
                    report.reportSubType = rankingReportComponent.reportSubType;
                    report.chartDisplayType =
                        rankingReportComponent.chartDisplayType;
                    if(this.isComparisonModeActivated) {
                    //     report.compareGroupId = rankingReportComponent._compareGroups.filter((group) => group.isChecked).map((group) => {
                    //         return group.id;
                    //     })
                    //     report.withGroupId = rankingReportComponent._withGroups.filter((group) => group.isChecked).map((group) => {
                    //         return group.id;
                    //     });
                    report.compareGroupId = [AnalyticsUtils.getSelectedNodes(rankingReportComponent._compareGroupsStrcuture)[0].id];
                        report.withGroupId = [AnalyticsUtils.getSelectedNodes(rankingReportComponent._withGroupsStrcuture)[0].id];
                    }
                    break;
                case ReportType.COUNT:
                    let countReportComponent = this.countReportComponents.find(
                        (component) => component.uuid === report.id
                    );
                    let countJsonObject = {};
                    countReportComponent.selectedFieldsByDataset.forEach(
                        (value, key) => {
                            countJsonObject[key] = value;
                        }
                    );
                    report.selectedFieldsByDataset = countJsonObject;
                    report.sort = countReportComponent.getSort();
                    report.name = countReportComponent.reportName;
                    report.reportType = countReportComponent.reportType;
                    report.reportSubType = countReportComponent.reportSubType;
                    report.chartDisplayType =
                        countReportComponent.chartDisplayType;
                    if(this.isComparisonModeActivated) {
                        // report.compareGroupId = countReportComponent._compareGroups.filter((group) => group.isChecked).map((group) => {
                        //     return group.id;
                        // })
                        // report.withGroupId = countReportComponent._withGroups.filter((group) => group.isChecked).map((group) => {
                        //     return group.id;
                        // });
                        report.compareGroupId = [AnalyticsUtils.getSelectedNodes(countReportComponent._compareGroupsStrcuture)[0].id];
                        report.withGroupId = [AnalyticsUtils.getSelectedNodes(countReportComponent._withGroupsStrcuture)[0].id];
                    }
                    break;
            }
        });
    }
   
    changeComparisonMode() {
        this.isComparisonModeActivated = !this.isComparisonModeActivated;
        this.reportItems = [];

        if(!this.isComparisonModeActivated){
            this.updateOpenDatadataSource(this.workspaceItems);
        } else {
            this.updateOpenDatadataSource(this.comparisonWorkspaceItems);
        }
    }

    getTooltipForComparisonToggle() {
        return !this.isComparisonModeActivated
            ? "Activate comparison mode"
            : "Deactivate comparison mode";
    }

}

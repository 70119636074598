import { NativeDateAdapter } from '@angular/material/core';
import { MatDateFormats } from '@angular/material/core';
import {DatePipe} from '../pipes/date.pipe';
import {DateUtils} from './date-utils';
import { Injectable } from "@angular/core";

/**
 * This class is used to override the default formatting of the Angular Material DatePicker
 */
@Injectable()
export class AppDateAdapter extends NativeDateAdapter {
    format(date: Date, displayFormat: any): string {
        if (displayFormat === 'custom') {
            return DateUtils.formatDate(date);
        }
        return date.toDateString();
    }
}
export const APP_DATE_FORMATS: MatDateFormats = {
    parse: {
        dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
},
display: {
    dateInput: 'custom',
    monthYearLabel: 'YYYY',
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric'
    },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
}
};

<div #content class="table-content">
    <map-maptycs-table
        #maptycsTable
        [items]="datapoints"
        [columns]="columns"
        (rowClickEvent)="updateDatapointById($event)"
        (sort)="onSort($event)">
    </map-maptycs-table>
</div>
<map-paginator
    #paginator
        [numberOfItemsPerPage]="filter.limit"
    (paginationChangeEvent)="onPaginationChange($event)"
    [totalNumberOfElements]="paginationInfo?.count">
</map-paginator>

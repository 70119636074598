import { Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NotifService } from 'src/app/core/notification/notif.service';
import { OverlaysService } from 'src/app/data-access-layer/global-overlays/overlays.service';
import { DatasetType } from 'src/app/model/dataset/dataset-type';
import { ColorScaleType } from 'src/app/model/dataset/rendering/color-scale-type';
import { DatasetStylingOptions } from 'src/app/model/dataset/rendering/dataset-styling-options';
import { GroupWithOverlaysTreeNode } from 'src/app/model/overlay/group/group-with-overlays-tree-node';
import { OverlayGroup } from 'src/app/model/overlay/group/overlay-group';
import { CreateExternalOverlayControlsConfig } from 'src/app/external-overlays/externaloverlays.constants'
import { DatasetConfigComponent } from 'src/app/shared/dataset-config/dataset-config.component';
import { AccountServiceState } from 'src/app/shared/services/account-service-state';
import { AccountService } from 'src/app/data-access-layer/account/account.service';
import { Modules } from 'src/app/model/modules/modules';
import { isUndefined } from 'src/app/core/utils/util-master';
import { ShowInMap } from 'src/app/model/dataset/dataset-config-filter.enum';

@Component({
  selector: 'map-external-overlay-create',
  templateUrl: './external-overlay-create.component.html',
  styleUrls: ['./external-overlay-create.component.scss']
})
export class ExternalOverlayCreateComponent implements OnInit {

  createOverlayForm: UntypedFormGroup;
  @Input() currentExternaloverlay;
  @Input() groups;
  @Input() editAction: boolean;
  @Input() showInMap: ShowInMap;

  @HostBinding('class') class = 'page';
  constructor(
      private readonly overlaysService: OverlaysService,
      private readonly formBuilder: UntypedFormBuilder,
      private readonly notifService: NotifService,
      private readonly accountServiceState: AccountServiceState,
      private readonly accountService: AccountService
  ) {
      this.initForm();
      this.editAction = false;
  }
  ngOnInit() {
    if(this.currentExternaloverlay) {
        this.createOverlayForm.patchValue(this.currentExternaloverlay);
    }
  }

  createOverlay() {
    this.createOverlayForm. markAllAsTouched();
    if (this.createOverlayForm.valid) {
        const form = this.createOverlayForm.getRawValue();
        const request = {...form};
        delete request.formBuilder;
        this.overlaysService.createExternalOverlay(request).subscribe(overlay => {
            this.initForm();
            this.notifService.success('Overlay was successfully created');
            this.accountServiceState.emitOnExternalOverlayCreate();
        });
    } else {
        this.notifService.error('All fields are mandatory*');
        return;
    }
  }

  showInMapOptions = Object.keys(ShowInMap)
  .filter(key => isNaN(Number(key)))
  .map(key => ({ value: ShowInMap[key], viewValue: key }));

  editOverlay() {
    this.createOverlayForm.markAllAsTouched();
    if(this.createOverlayForm.valid) {
            let form = this.createOverlayForm.value;
            form.datasetId = this.currentExternaloverlay.datasetId;
            const request = { ...form };
            delete request.formBuilder;
            this.overlaysService.updateExternalDataset(request).subscribe((Overlay) => {
                this.initForm();
                this.accountServiceState.emitOnExternalOverlayCreate();
                this.notifService.success('Successfully updated overlay');
            });
    } else {
        this.notifService.error('All fields are mandatory*');
        return;
    }
  }

  initForm() {
      this.createOverlayForm = this.formBuilder.group(new CreateExternalOverlayControlsConfig(this.formBuilder));
  }
}

import { UntypedFormGroup, UntypedFormControl, Validators, FormBuilder, FormGroupDirective, NgForm, ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export const EmailValidation = [Validators.required, Validators.email];
export const PasswordValidation = [
    Validators.required,
    Validators.minLength(6),
    Validators.maxLength(24),
];

export class RepeatPasswordEStateMatcher implements ErrorStateMatcher {
    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        return (control && control.parent.get('newPassword').value !== control.parent.get('repeatNewPassword').value && control.dirty);
    }
}

export function RepeatPasswordValidator(group: UntypedFormGroup) {
    const password = group.controls.newPassword.value;
    const passwordConfirmation = group.controls.repeatNewPassword.value;

    return password === passwordConfirmation ? null : {passwordsNotEqual: true};
}

export function passwordValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value || '';
  
      // Regular expression to check for at least one uppercase letter, one lowercase letter, and one digit and special character
      const hasUpperCase = /[A-Z]/.test(value);
      const hasLowerCase = /[a-z]/.test(value);
      const hasNumber = /\d/.test(value);
      const hasSpecial = /[!@#$%^&*(),.?":{}|<>]/.test(value);
  
      const passwordValid = hasUpperCase && hasLowerCase && hasNumber && hasSpecial && value.length >= 8;
  
      return !passwordValid ? { weak: true } : null;
    };
  }
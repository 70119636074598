import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IndicatorsData } from 'src/app/model/dashboard/indicators-data';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {

    constructor(private readonly http: HttpClient) {
    }

    getDashboardIndicators(accountId: number): Observable<IndicatorsData> {
        return this.http.get<IndicatorsData>(`/dashboard-indicators/${accountId}`);
    }

    updateDashboardIndicators(payload: IndicatorsData): Observable<void> {
        return this.http.put<void>(`/dashboard-indicators`, payload);
    }
}

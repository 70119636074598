import { Component, ComponentRef, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { isEnabled } from 'src/environments/environment.prod';
import { GroupWithOverlaysTreeNode } from '../../model/overlay/group/group-with-overlays-tree-node';
import { OverlaysService } from '../../data-access-layer/global-overlays/overlays.service';
import { DatapointsPageStateService } from '../../dataset/datapoints/datapoints-page-state.service';
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../../data-access-layer/account/account.service';
import { NotifService } from '../../core/notification/notif.service';
import { SidePanelComponent } from '../../core/side-panel/side-panel.component';
import { Functionalities } from '../../../environments/app-functionalities';
import { SidePanelService } from '../../shared/services/side-panel.service';
import { DataPointsServiceState } from '../../shared/services/datapoints-service-state';
import { SidePanels } from '../../shared/services/side-panel.helper';
import { RoutingUtils } from 'src/app/core/utils/routing-utils';

const IS_PART_OF = Functionalities.OVERLAYS;

type CreateGroupPanelType = {
    groupNodes: GroupWithOverlaysTreeNode[]
}

type UpdateGroupPanelType = {
    map: {};
    selectedNode: { group: { parentId: number; name: string; tag?: string; id: number; } };
    groupNodes: GroupWithOverlaysTreeNode[]
}

/**
 * This component stores the overlays with their rendering configurations.
 */
@Component({
    selector: 'map-edit-overlay-tree',
    templateUrl: './edit-overlay-tree.component.html',
    styleUrls: ['./edit-overlay-tree.component.scss']
})
export class EditOverlayTreeComponent implements OnInit, OnDestroy {

    isEnabled = isEnabled(IS_PART_OF);
    createGroupPanel: ComponentRef<SidePanelComponent>;
    editGroupPanel: ComponentRef<SidePanelComponent>;


    groupNodes: GroupWithOverlaysTreeNode[] = [];
    map: {} = {};
    selectedNode: { group: { parentId: number; name: string; tag?: string; id: number; } };
    filteredGroups: any = [];

    constructor(
        public readonly dialog: MatDialog,
        public readonly notifService: NotifService,
        private readonly sidePanelService: SidePanelService,
        private readonly viewContainerRef: ViewContainerRef,
        private readonly datapointsServiceState: DataPointsServiceState,
        private readonly datapointsPageStateService: DatapointsPageStateService,
        private readonly accountService: AccountService,
        private readonly route: ActivatedRoute,
        private readonly overlayService: OverlaysService,
        private readonly router: Router
    ) {
    }

    ngOnInit(): void {
        this.init();
    }

    recursiveOperation(groupNode: GroupWithOverlaysTreeNode, operation: (node: GroupWithOverlaysTreeNode) => void) {
        operation(groupNode);
        groupNode.children.forEach(child => this.recursiveOperation(child, operation));
    }

    showCreateGroupPanel(): void {
        if (this.createGroupPanel) {
            this.createGroupPanel.instance.showPanel();
        } else {
            this.sidePanelService.setRootViewContainerRef(this.viewContainerRef);
            this.createGroupPanel = this.sidePanelService.open<CreateGroupPanelType>(SidePanels.CREATE_GROUP_OVERLAY,
                {
                    width: 400,
                    id: "CreateOverlayGroups",
                    panelTitle: "Create Overlay Groups",
                },
                {
                    groupNodes: this.groupNodes
                });

            this.datapointsServiceState.onCreateOverlayGroupSuccess$.subscribe(() => {
                this.init();
                if (this.createGroupPanel) this.createGroupPanel.instance.hidePanel();
            })
        }
    }

    private init() {
        this.selectNode(null);

        this.overlayService.getAllOverlayGroups()
            // .pipe(map(nodes => {
            //     let mappedNodes = nodes.map((node) => {
            //         let {parentID, name, id} = node;
            //         return {group: {parentID, name, id}};
            //     });
            //     return this.listToTree(mappedNodes);
            // }))
            .subscribe(res => {
                this.groupNodes = res;
                this.createMap();
            });
    }

    expandAll(): void {
        this.selectNode(null);
        this.groupNodes.forEach(rootGroup => {
            this.map[rootGroup.group.id].group.isOpen = true;
            this.recursiveOperation(rootGroup, group => {
                this.map[group.group.id].group.isOpen = true;
            });
        });
    }

    collapseAll(): void {
        this.selectNode(null);

        this.groupNodes.forEach(rootGroup => {
            this.map[rootGroup.group.id].group.isOpen = false;
            this.recursiveOperation(rootGroup, group => {
                this.map[group.group.id].group.isOpen = false;
            });
        });
    }

    createMap() {
        this.groupNodes.forEach(rootGroup => {
            if (!this.map[rootGroup.group.id]) {
                this.map[rootGroup.group.id] = rootGroup;
            }
            this.recursiveOperation(rootGroup, group => {
                if (!this.map[group.group.id]) {
                    this.map[group.group.id] = group;
                }
            });
        });
    }

    openNode(groupNode: any) {
        this.map[groupNode.group.id].group.isOpen = !this.map[groupNode.group.id].group.isOpen;
    }

    isGroupOpen(groupNode: any) {
        try {
            return this.map[groupNode.group.id].group.isOpen;

        } catch (err) {
            return false;
        }
    }

    selectNode(groupNode: any) {
        this.selectedNode = groupNode;
        if (groupNode) {
            this.sidePanelService.setRootViewContainerRef(this.viewContainerRef);
            if (this.editGroupPanel) {
                this.editGroupPanel.instance.hidePanel();
            }
            this.editGroupPanel = this.sidePanelService.open<UpdateGroupPanelType>(SidePanels.EDIT_GROUP_OVERLAY,
                {
                    width: 400,
                    id: "EditOverlayGroups",
                    panelTitle: "Edit Overlay Groups",
                },
                {
                    groupNodes: this.prepareGroupNodes(this.groupNodes),
                    selectedNode: this.selectedNode,
                    map: this.map
                });
            this.datapointsServiceState.onEditOverlayGroupSuccess$.subscribe(() => {
                this.init();
                if (this.editGroupPanel) this.editGroupPanel.instance.hidePanel();
            });
            this.datapointsServiceState.onDeleteOverlayGroupSuccess$.subscribe(() => {
                this.init();
                if (this.editGroupPanel) this.editGroupPanel.instance.hidePanel();
            });
        } else {
            if (this.editGroupPanel) this.editGroupPanel.instance.hidePanel();
        }
    }

    listToTree(list) {
        // tslint:disable-next-line:one-variable-per-declaration
        let localMap = {}, node, roots = [], i;
        for (i = 0; i < list.length; i += 1) {
            localMap[list[i].group.id] = i; // initialize the map
            list[i].children = []; // initialize the children
        }
        for (i = 0; i < list.length; i += 1) {
            node = list[i];
            if (node.group.parentID !== null) {
                // if you have dangling branches check that map[node.parentID] exists
                list[localMap[node.group.parentID]].children.push(node);
            } else {
                roots.push(node);
            }
        }
        return roots;
    }

    navigateBackToPrivateOverlays() {
        this.router.navigateByUrl(RoutingUtils.getGlobalOverlaysRoute());
    }

    ngOnDestroy() {
        if (this.editGroupPanel) {
            this.editGroupPanel.instance.closePanel();
            this.editGroupPanel = null;
        }

        if (this.createGroupPanel) {
            this.createGroupPanel.instance.closePanel();
            this.createGroupPanel = null;
        }
    }

    prepareGroupNodes(overlays) {
        overlays.forEach((element, key) => {
            if (element.group.name !== null && element.group.name.trim() !== "")  {
                this.filteredGroups.push({group: element.group});
            }
            if (element.children.length > 0) {
                this.recursiveGroupNodes(element);
            }
         });
         return this.filteredGroups;
     }

    recursiveGroupNodes(element) {
        if (element.children.length) {
            let groupIds = [];
            element.children.forEach((sub_element, key) => {
            if (sub_element.children.length <= 0 && sub_element.overlays.length <= 0) {
                groupIds.push(sub_element.group.id);
            } else if (sub_element.children.length > 0) {
                if (sub_element.group.name !== null && sub_element.group.name.trim() !== "")  {
                    this.filteredGroups.push({group: sub_element.group});
                }
                element.children = element.children.filter(params => !groupIds.includes(params.group.id))
                this.recursiveGroupNodes(sub_element);
                return;
            }
            });
        }
    }
}

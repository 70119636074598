<div class="field-container mat-elevation-z2" [class.new-field]="!localField.id">
	<div class="field-toolbar">
		<mat-icon aria-hidden="false" (click)="expanded = !expanded">remove</mat-icon>
		<mat-icon aria-hidden="false"
		          (click)="deleteField(localField)"
		          [style.display]="localField.isLocked ? 'none' : 'inline'">clear
		</mat-icon>
	</div>
	<form [formGroup]="fieldForm">
		<mat-form-field>
			<input matInput placeholder="Name" formControlName="name" required autocomplete="off"/>
			<mat-error *ngIf="fieldForm.controls.name.hasError('required')">
				Label is required
			</mat-error>
		</mat-form-field>
		<div class="field-body" [style.display]="expanded ? 'block' : 'none'">
			<div class="field-row">
				<mat-form-field>
					<input matInput placeholder="Description" formControlName="description" autocomplete="off"/>
				</mat-form-field>

				<mat-form-field>
					<mat-select placeholder="Type" formControlName="type">
						<mat-option [value]="DatasetFieldSpecificType.SHORT_TEXT_FIELD">Short text</mat-option>
						<mat-option [value]="DatasetFieldSpecificType.LONG_TEXT_FIELD">Text area</mat-option>
						<mat-option [value]="DatasetFieldSpecificType.TELEPHONE_TEXT_FIELD">Telephone</mat-option>
						<mat-option [value]="DatasetFieldSpecificType.EMAIL_TEXT_FIELD">Email</mat-option>
						<mat-option [value]="DatasetFieldSpecificType.URL_TEXT_FIELD">Url</mat-option>
						<mat-option [value]="DatasetFieldSpecificType.NUMBER_FIELD">Number</mat-option>
						<mat-option [value]="DatasetFieldSpecificType.DATE_FIELD">Date</mat-option>
						<mat-option [value]="DatasetFieldSpecificType.RADIO_TEXT_FIELD">Radio</mat-option>
						<mat-option [value]="DatasetFieldSpecificType.CHECKBOX_TEXT_FIELD">Checkbox</mat-option>
						<mat-option [value]="DatasetFieldSpecificType.SELECT_TEXT_FIELD">Select</mat-option>
						<mat-option [value]="DatasetFieldSpecificType.LINKS_FIELD">Link(s)</mat-option>
						<mat-option [value]="DatasetFieldSpecificType.MEMBERS_FIELD">Member(s)</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<mat-checkbox formControlName="isSummary">Summary</mat-checkbox>
			<mat-checkbox formControlName="isHighCardinality" *ngIf="localField.baseType === DatasetFieldType.TEXT">High cardinality</mat-checkbox>
			<mat-checkbox formControlName="isRequired">Required</mat-checkbox>
			<mat-checkbox formControlName="isArray">List</mat-checkbox>
			<mat-checkbox *ngIf="allowFloatingFields && fieldForm.controls.type.value === DatasetFieldSpecificType.NUMBER_FIELD"
			              formControlName="isFloating">Floating</mat-checkbox>
			<mat-form-field *ngIf="allowFloatingFields && fieldForm.controls.isFloating.value && fieldForm.controls.type.value === DatasetFieldSpecificType.NUMBER_FIELD"
			                class="floating-option-select">
				<mat-select formControlName="floatingOption" placeholder="Floating Option">
					<mat-option [value]="DatasetFieldFloatingOption.AVG">Average</mat-option>
					<mat-option [value]="DatasetFieldFloatingOption.SUM">Sum</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
	</form>
</div>

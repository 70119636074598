<div *ngIf="loading" class="loader">
    <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
</div>
<div class="header-bar">
    <div class="header-left">
        <div class="header-logo">
            <img src="../../../assets/images/{{logo}}" alt="logo">
        </div>
        <ng-content></ng-content>
    </div>

    <div class="header-right">
        <div class="account-title">
            {{datapointsPageStateService.activeAccount?.name}}
        </div>
        <div class="icon-outer-wrap" matTooltip="Support Center">
                <button class="icon-inner-wrap" (click)="support()" style="cursor: pointer;">
                    <i class="fal fa-question-square" style="color: #ffffff; font-size: 19px;"></i>
                </button>
        </div>
        <div class="icon-outer-wrap">
                <button class="icon-inner-wrap" [matMenuTriggerFor]="menuSet" style="cursor: pointer;">
                    <!-- Setting-cog-ICon -->
                    <svg width="24" height="24" viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg">
                        <g stroke="#ffffff" stroke-width="1.8" fill="none" fill-rule="evenodd" stroke-linecap="round"
                            stroke-linejoin="round">
                            <path
                                d="m6.021 16.974 1.291.202c.953.149 1.633 1.021 1.563 2.004l-.095 1.333a.951.951 0 0 0 .521.923l.955.47a.912.912 0 0 0 1.031-.158l.96-.906a1.817 1.817 0 0 1 2.506 0l.959.906a.91.91 0 0 0 1.031.158l.957-.471a.947.947 0 0 0 .52-.92l-.096-1.335c-.07-.983.61-1.855 1.563-2.004l1.29-.202a.936.936 0 0 0 .764-.727l.236-1.055a.956.956 0 0 0-.38-.993l-1.077-.755a1.916 1.916 0 0 1-.558-2.5l.651-1.157a.966.966 0 0 0-.08-1.063l-.66-.847a.915.915 0 0 0-.996-.316l-1.248.391a1.837 1.837 0 0 1-2.26-1.112l-.478-1.24a.927.927 0 0 0-.864-.6l-1.058.003a.928.928 0 0 0-.861.604l-.466 1.226a1.836 1.836 0 0 1-2.264 1.12l-1.3-.408a.915.915 0 0 0-.999.317l-.655.848a.965.965 0 0 0-.073 1.068l.666 1.16c.49.856.25 1.954-.55 2.514l-1.064.746a.957.957 0 0 0-.38.993l.236 1.055c.084.38.387.669.762.728h0z" />
                            <path d="M15.268 15.268a2.5 2.5 0 1 1-3.536-3.537 2.5 2.5 0 0 1 3.536 3.537z" />
                        </g>
                    </svg>
                </button>
                <mat-menu #menuSet="matMenu" class="nested-menu" backdropClass="mat-menu-header" style="margin: 3px;">
                    <hr *ngIf="!userStateService.principal.provider" class="menu-line-separator"/>
                    <div *ngIf="!userStateService.principal.provider" mat-menu-item routerLink="/private/account" style="margin-top: 8px">
                        <span matTooltip="View All Accounts" matTooltipPosition="left">View All Accounts</span>
                    </div>
                    <hr *ngIf="userStateService.principal.provider" class="menu-line-separator"/>
                    <div *ngIf="userStateService.principal.provider" mat-menu-item routerLink="private/overlays/api-meter" style="margin-top: 8px" (click)="navigateToAPIMeter()" >
                        <span matTooltip="API usage" matTooltipPosition="left">API usage</span>
                    </div>
                    <hr *ngIf="userStateService.isSuperadmin && (overlayComponentEnabled || thematicMapFeatureEnabled)" class="menu-line-separator"/>
                    <div mat-menu-item routerLink="/private/overlays/global-overlays" style="margin-top: 8px" *ngIf="userStateService.isSuperadmin && (overlayComponentEnabled || thematicMapFeatureEnabled)">
                        <span matTooltip="View Overlays" matTooltipPosition="left">View Overlays</span>
                    </div>
                    <hr />
    
                    <div *ngFor="let account of accounts" mat-menu-item>
                        <div class="mat-menu-account-item">
                            <span (click)="navigateToDatapoints(account)" style="flex-grow: 1" class="content-ellipsis"
                                 [matTooltip]="account.name" matTooltipPosition="left">{{ account.name }}</span>
                            <span *ngIf="userStateService.isSuperadmin || userStateService.isAccountAdmin(account.id)"
                                (click)="navigateToSettings(account)" class="margin-left-12">
                                <!-- Setting ICon -->
                                <svg width="18" height="18" viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg">
                                    <g stroke="#08080d" stroke-width="1.5" fill="none" fill-rule="evenodd"
                                        stroke-linecap="round" stroke-linejoin="round" class="icon-actions">
                                        <path
                                            d="m6.021 16.974 1.291.202c.953.149 1.633 1.021 1.563 2.004l-.095 1.333a.951.951 0 0 0 .521.923l.955.47a.912.912 0 0 0 1.031-.158l.96-.906a1.817 1.817 0 0 1 2.506 0l.959.906a.91.91 0 0 0 1.031.158l.957-.471a.947.947 0 0 0 .52-.92l-.096-1.335c-.07-.983.61-1.855 1.563-2.004l1.29-.202a.936.936 0 0 0 .764-.727l.236-1.055a.956.956 0 0 0-.38-.993l-1.077-.755a1.916 1.916 0 0 1-.558-2.5l.651-1.157a.966.966 0 0 0-.08-1.063l-.66-.847a.915.915 0 0 0-.996-.316l-1.248.391a1.837 1.837 0 0 1-2.26-1.112l-.478-1.24a.927.927 0 0 0-.864-.6l-1.058.003a.928.928 0 0 0-.861.604l-.466 1.226a1.836 1.836 0 0 1-2.264 1.12l-1.3-.408a.915.915 0 0 0-.999.317l-.655.848a.965.965 0 0 0-.073 1.068l.666 1.16c.49.856.25 1.954-.55 2.514l-1.064.746a.957.957 0 0 0-.38.993l.236 1.055c.084.38.387.669.762.728h0z" />
                                        <path d="M15.268 15.268a2.5 2.5 0 1 1-3.536-3.537 2.5 2.5 0 0 1 3.536 3.537z" />
                                    </g>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <hr *ngIf="userStateService.isSuperadmin" class="menu-line-separator"/>
                    <button *ngIf="userStateService.isSuperadmin" mat-menu-item routerLink="/private/integrated-clients">
                        <span matTooltip="Integrated Clients" matTooltipPosition="left">Integrated Clients</span>
                    </button>
                    <hr *ngIf="userStateService.isSuperadmin" class="menu-line-separator"/>
                    <button *ngIf="userStateService.isSuperadmin" mat-menu-item routerLink="/private/geographical-regions">
                        <span matTooltip="Geographical Regions" matTooltipPosition="left">Geographical Regions</span>
                    </button>
                </mat-menu>
        </div>
        <div class="icon-outer-wrap">
            <button class="icon-inner-wrap" [matMenuTriggerFor]="appMenu" style="cursor: pointer;">
                <!-- Menu-App-Icon -->
                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <g fill="none" fill-rule="evenodd">
                        <path d="M-.005-.005h24.01v24.01H-.005z" />
                        <g stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5">
                            <path d="M6.25 12h.5M6.25 17.334h.5M6.25 6.666h.5" />
                        </g>
                        <g stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5">
                            <path d="M12.25 12h.5M12.25 17.334h.5M12.25 6.666h.5" />
                        </g>
                        <g stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5">
                            <path d="M18.25 12h.5M18.25 17.334h.5M18.25 6.666h.5" />
                        </g>
                    </g>
                </svg>
            </button>
            <mat-menu #appMenu="matMenu" class="header-mat-panel">
                <div class="app-icon-outer-wrap" style="padding-bottom: 0.525rem;">
                    <div class="app-icon-inner-wrap">
                        <img [src]="iconMapping.collexter" (click)="openUrl(appUrls.collexter)" class="app-icon-wrap"
                            alt="app icon" style="cursor: pointer;">
                    </div>
                    <div class="app-icon-inner-wrap">
                        <img [src]="iconMapping.kainobi" (click)="openUrl(appUrls.kainobi)" class="app-icon-wrap"
                            alt="app icon" style="cursor: pointer;">
                    </div>
                </div>
                <div class="app-icon-outer-wrap" style="padding-bottom: 0.525rem;">
                    <div class="app-icon-inner-wrap">
                        <img [src]="iconMapping.policybase" (click)="openUrl(appUrls.policybase)" class="app-icon-wrap"
                            alt="app icon" style="cursor: pointer;">
                    </div>
                    <div class="app-icon-inner-wrap">
                        <img [src]="iconMapping.schemaytic" (click)="openUrl(appUrls.schemaytic)" class="app-icon-wrap"
                            alt="app icon" style="cursor: pointer;">
                    </div>
                </div>
            </mat-menu>
        </div>
        <div class="icon-outer-wrap">
            <div class="avtar-wrapper" [matMenuTriggerFor]="menu">
                <div class="tooltip" style="cursor: pointer;">
                    <img src="../../../assets/images/avtar.png" alt="user icon" class="avtar">
                    <!-- <div>
                        <span class="tooltiptext secondary-tooltiptext secondary-tooltip-bottom-left">
                            <i class="fal fa-question-circle" style="margin-right: 8px;"></i>
                            {{datapointsPageStateService.activeAccount?.name}}
                            <div class="secondary-path-bottom-left"></div>
                        </span>
                    </div> -->
                </div>

                <span class="badge"></span>
            </div>
            <mat-menu #menu="matMenu" class="nested-menu" backdropClass="mat-menu-header">
                <hr class="menu-line-separator"/>
                <button mat-menu-item routerLink="/private/account/user-settings">
                    <span>User Settings</span>
                </button>
                <hr class="menu-line-separator"/>
                <button (click)="logout()" mat-menu-item>
                    <span>Logout</span>
                </button>
            </mat-menu>
        </div>
    </div>
</div>
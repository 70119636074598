export enum TensorflightKeys {
    replacementCost = 'Estimated replacement cost',
    replacementCostPerArea = 'Estimated replacement cost per ft²/m²',
    replacementCostCurrency = 'Replacement cost currency',
    footprintArea = 'Footprint area',
    constructionType = 'Construction type',
    buildingOccupancyType = 'Building occupancy type',
    facadeMaterial = 'Facade material',
    floorArea = 'Gross floor area',
    nrOfStories = 'Number of stories',
    height = 'Height',
    imageryCaptureDate = 'Imagery capture date',
    yearBuilt = 'Year built',
    confidenceScore = 'Confidence scores',
    roofGeometry = 'Roof geometry',
    roofPitch = 'Roof pitch',
    roofMaterial = 'Roof material',
    roofCondition = 'Roof condition',
    roofConditionScore = 'Roof condition score',
    treeOverhang = 'Number of overhanging trees',
    solarPanelsArea = 'Area of solar panels on the roof [ft²/m²]',
    solarPanelsPercentage = 'Solar panels',
    facadeCondition = 'Facade condition',
    facadeConditionScore = 'Facade condition score',
    coastDistance = 'Coast distance',
    latitude = 'Latitude',
    longitude = 'Longitude',
    message = 'Message',
    status= 'Status'
}

export enum TensorflightStatus {
    SUCCESS = "Success",
    IN_PROGRESS = "In-Progress"
}

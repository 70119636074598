import { DownloadService } from "src/app/data-access-layer/download/download.service";
import { NotifService } from "../notification/notif.service";
import { DownloadDatapointRequest } from "src/app/model/download/download-datapoint-request";
import { Constants } from "src/app/constants";
import { DownloadFileType } from "src/app/model/download/download-file-type";
import { DownloadReportRequest } from "src/app/model/download/download-report-request";
import { DownloadReportItem } from "src/app/model/download/item/download-report-item";
import { ReportDisplayType } from "src/app/model/analytics/report-display-type";
import { DownloadItemReportType } from "src/app/model/download/item/download-item-report-type";
import { Dataset } from "src/app/model/dataset/dataset";
import { DialogModel } from "src/app/model/dialog/dialog-model";
import { MatDialog } from "@angular/material/dialog";
import { DialogComponent } from "src/app/shared/dialog/dialog.component";
import { take } from "rxjs/operators";
import { AttachmentUtils } from "../utils/attachment-utils";

export class DownloadReport {

    constructor(
        public readonly downloadService: DownloadService,
        public readonly notifService: NotifService,
        public readonly dialog: MatDialog
    ) {

    }

    public process(isDownloadSingleReport :boolean = true, reportElementIndex: number, activeDataset: Dataset, selectedGroupIds: number[], analyticData: any[]) {
        let downloadItems: DownloadReportItem[] = [];
        if (isDownloadSingleReport) {
            const component = analyticData[reportElementIndex];
            let reportDownloadRequest: DownloadReportItem =
                this.getReportDownloadRequest(component);

            if (reportDownloadRequest) {
                reportDownloadRequest.title = component.reportName;
                downloadItems.push(reportDownloadRequest);
            }
            let downloadRequest: DownloadDatapointRequest = this.getDownloadDatapointRequest(
                { items: downloadItems},
                activeDataset,
                selectedGroupIds,
                DownloadFileType.XLSX
            )

            let reportHasTwoDimensionPieChart = false;
            if(component.reportSubType === ReportDisplayType.PIE_CHART && component.isTwoDimensionReport){
                reportHasTwoDimensionPieChart = true;
            }

           this.downloadReport(activeDataset, downloadRequest, reportHasTwoDimensionPieChart)
        }
    }

    downloadReport(activeDataset: Dataset, downloadRequest: DownloadDatapointRequest, reportHasTwoDimensionPieChart: boolean) {
        if (reportHasTwoDimensionPieChart) {
            const dialogRef = this.dialog.open(DialogComponent, {
                data: new DialogModel(
                    "Confirm Download",
                    `Your pie chart has multiple criteria, but the spreadsheet download will only display one. We recommend downloading the bar charts instead.`,
                    "Cancel",
                    "Download"
                ),
            });
            dialogRef
                .afterClosed()
                .pipe(take(1))
                .subscribe((dialogResult) => {
                    if (dialogResult) {
                        this.downloadReportFile(downloadRequest, activeDataset);
                    }
                });
        } else {
            this.downloadReportFile(downloadRequest, activeDataset);
        }

    }

    private downloadReportFile(downloadRequest: DownloadDatapointRequest, activeDataset: Dataset) {
        this.downloadService
            .downloadDatapoints(
                activeDataset.id,
                downloadRequest,
                "Analytics.xlsx"
            )
            .subscribe(
                (response) => {
                    AttachmentUtils.downloadFileWithName(
                        response,
                        "Analytics.xlsx"
                    );
                },
                (error) =>
                    this.notifService.error(
                        "Something went wrong during download"
                    )
            );
    }

    getDownloadDatapointRequest(reportRequest: DownloadReportRequest, activeDataset: Dataset, selectedGroupIds: number[], downloadFileType: DownloadFileType): DownloadDatapointRequest {
        return {
            reportRequest: reportRequest,
            filter: this.getActiveFilter(activeDataset.id, selectedGroupIds),
            timezone: Constants.DEFAULT_TIMEZONE,
            dateFormat: Constants.DEFAULT_DATE_FORMAT,
            outputFileType: downloadFileType,
        };
    }

    getActiveFilter(datasetId: string, selectedGroupIds: number[]) {
        return {
            datasetID: datasetId,
            fields: [],
            groups: selectedGroupIds,
            links: [],
        };
    }

    getReportDownloadRequest(report): DownloadReportItem {
        if (report.reportSubType === ReportDisplayType.TABLE) {
            return report.tableDownloadRequest;
        } else if (
            report.reportSubType === ReportDisplayType.BAR_CHART ||
            report.reportSubType === ReportDisplayType.PIE_CHART
        ) {
            if (report.chartDownloadRequest) {
                if (report.reportSubType === ReportDisplayType.BAR_CHART) {
                    report.chartDownloadRequest.type =
                        DownloadItemReportType.AGGREGATE_BAR_CHART;
                } else if (report.reportSubType === ReportDisplayType.PIE_CHART) {
                    report.chartDownloadRequest.type =
                        DownloadItemReportType.AGGREGATE_PIE_CHART;
                }
            }
            return report.chartDownloadRequest;
        }
    }
}

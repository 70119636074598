<div class="public-content">
    <div class="public-text">
        <img src="../../../assets/images/logo-icon-dark.svg" style="width: 300px; height: auto;" alt="logo">
    </div>
    <div class="public-form">
        <div *ngIf="isSubmitted" style="color: white;">
            <div>If you are a registered user, you will receive an email shortly with instructions to reset your password.</div>
            <div class="go-to-login-btn" [routerLink]="['/public/login']">GO TO LOGIN</div>
        </div>
        <form [formGroup]="forgotPasswordForm" *ngIf="!isSubmitted">
            <div class="link-button" [routerLink]="['/public/login']">Login instead?</div>
            <mat-form-field color="#fff">
                <mat-label>Enter your registered email address</mat-label>
                <input matInput autocomplete="off" required formControlName="email">
                <mat-error *ngIf="forgotPasswordForm.controls.email.hasError('required')">
                    Email is required
                </mat-error>
            </mat-form-field>
            
            <div class="forgot-description mT-4" [routerLink]="['/public/login']">You will receive an email to confirm the reset of the password</div>
            <button mat-stroked-button color="primary" [disabled]="forgotPasswordForm.invalid" (click)="onSubmit()">Submit</button>
        </form>
    </div>
</div>

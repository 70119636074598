<div class="dialog">
    <div class="dialog-title">
        {{ title }}
    </div>
    <div class="dialog-content">
        <div class="confirm-content">
            <div class="confirm-icon">
                <mat-icon fontIcon="fa-exclamation-triangle" fontSet="fas"></mat-icon>
            </div>
            <div class="confirm-message">
                {{ message }}
            </div>
        </div>
        <div class="confirm-actions">
            <button (click)="onConfirm()" class="small" mat-flat-button color="accent">{{confirmButton}}</button>
        </div>
    </div>
</div>

<div class="page-root">
	<div class="breadcrumbs">
		<span>Home</span>
		<span>Integrated Clients</span>
	</div>
	<div class="page">
		<div class="page-container grey-background">
			<div class="table-actions">
				<div class="toolbar-title">
					Integrated Clients
				</div>
				<div class="toolbar-container">
					<ng-container>
						<button (click)="openCreateIntegratedClientPanel()" mat-flat-button color="accent">
							 <!-- Add User-Icon -->
							 <svg style="margin-right: 4px;" width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
								<g fill="none" fill-rule="evenodd" class="icon-actions">
									<path d="M18 20v-.75A4.25 4.25 0 0 0 13.75 15h-7.5A4.25 4.25 0 0 0 2 19.25V20" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
									<circle stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" cx="10" cy="7" r="4"/>
									<path stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M19 9v4M21 11h-4"/>
									<path d="M0 0h24v24H0z"/>
								</g>
							</svg>
							<span>create integrated client</span>
						</button>
					</ng-container>
				</div>
			</div>

			<div class="table-container">
				<div class="table-content">
					<table mat-table [dataSource]="clients">
						<!-- Platform Column -->
						<ng-container matColumnDef="platform">
							<th *matHeaderCellDef mat-header-cell> Platform</th>
							<td *matCellDef="let row" mat-cell> {{row.platform}} </td>
						</ng-container>

						<!-- Client Column -->
						<ng-container matColumnDef="platformClient">
							<th *matHeaderCellDef mat-header-cell> Client</th>
							<td *matCellDef="let row" mat-cell> {{row.platformClient}}</td>
						</ng-container>


						<!-- Account key Column -->
						<ng-container matColumnDef="accountKey">
							<th *matHeaderCellDef mat-header-cell> Account key</th>
							<td *matCellDef="let row" mat-cell>{{row.accountKey}}</td>
						</ng-container>

						<!-- Password Column -->
						<ng-container matColumnDef="accountPassword">
							<th *matHeaderCellDef mat-header-cell> Password</th>
							<td *matCellDef="let row" mat-cell>{{row.accountPassword}}</td>
						</ng-container>

						<!-- Password Column -->
						<ng-container matColumnDef="account">
							<th *matHeaderCellDef mat-header-cell> Account</th>
							<td *matCellDef="let row" mat-cell>{{row.accountName}}</td>
						</ng-container>

						<!-- Actions Column -->
						<ng-container matColumnDef="actions">
							<th *matHeaderCellDef mat-header-cell> Actions</th>
							<td *matCellDef="let row" [style.color]="row.color" mat-cell class="table-cell-icons">
								<ng-container>
                                    <span (click)="openEditIntegrationClientPanel(row)">
                                        <!-- Pencil-Icon -->
                                    <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <g stroke="#08080d" stroke-width="1.5" fill="none" fill-rule="evenodd"
                                            stroke-linecap="round" stroke-linejoin="round" class="icon-actions">
                                            <path
                                                d="m8.972 12.2 4.76-4.76a1.5 1.5 0 0 1 2.122 0l.707.706a1.5 1.5 0 0 1 0 2.122l-4.761 4.76a2.5 2.5 0 0 1-1.414.708l-1.815.259a.5.5 0 0 1-.566-.566l.26-1.815a2.5 2.5 0 0 1 .707-1.414zM15.209 11.62 12.38 8.791" />
                                            <rect transform="rotate(180 12 12)" x="3" y="3" width="18" height="18"
                                                rx="5" />
                                        </g>
                                    </svg>
                                    </span>
								</ng-container>
								<ng-container>
                                    <span (click)="deleteIntegratedClient(row)">
                                       <!-- Trash Icon -->
                                    <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <g fill="none" fill-rule="evenodd">
                                            <path
                                                d="M18.5 6.5V19a2 2 0 0 1-2 2h-9a2 2 0 0 1-2-2V6.5M9 17h6M9 13h6M8.5 6.5v-2A1.5 1.5 0 0 1 10 3h4a1.5 1.5 0 0 1 1.5 1.5v2M20 6.5H4"
                                                stroke="#08080d" stroke-width="1.5" stroke-linecap="round"
                                                stroke-linejoin="round" class="icon-actions" />
                                            <path d="M0 0h24v24H0z" />
                                        </g>
                                    </svg>
                                    </span>
								</ng-container>
							</td>
						</ng-container>

						<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
						<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
					</table>
				</div>
			</div>
		</div>
		<app-side-panel [width]="400" #editIntegratedClientPanel panelTitle="Edit Client" [panelIcon]="'icon-pencil'">
			<map-edit-integrated-client #editIntegratedClientComponent (updateSuccess)="closeUpdateIntegrationClientPanel()"></map-edit-integrated-client>
		</app-side-panel>
		<app-side-panel [width]="400" #createIntegratedClientPanel panelTitle="Create Client" [panelIcon]="'icon-user-plus'">
			<map-create-integrated-client [accounts]="accounts" (createSuccess)="closeCreateIntegrationClientPanel()"></map-create-integrated-client>
		</app-side-panel>
	</div>
</div>

<div *ngIf="overlay && isEnabled" class="filters-container filters">
    <div class="flex filters">
    <div class="filter-count">
        <div class="filter-locations-label full-width">
            <span>Layers filters ({{filters.length}})</span>
            <div [matMenuTriggerFor]="addFilterMenu" class="flex cursor-pointer">
                <mat-icon fontIcon="fa-plus" fontSet="fal" style="margin-left: 10px;"></mat-icon>
                <mat-menu #addFilterMenu="matMenu" class="nested-menu nested-menu-leaf">
                    <div (click)="$event.stopPropagation()" class="nested-menu-search">
                        <input placeholder="Search..." [(ngModel)]="search" (keydown)="$event.stopPropagation()" autocomplete="off">
                    </div>
                    <ng-container *ngFor="let field of overlay.fields | callback: filterFieldSearch">
                        <div *ngIf="!field.isGenerated && !field.tags?.includes('ID')"
                             (click)="$event.stopPropagation()" mat-menu-item>
                            <mat-checkbox [(ngModel)]="field.selected"
                                          (ngModelChange)="onFilterMenuFieldClick(field.selected, overlay, field)">
                                <span>{{field.name}}</span>
                            </mat-checkbox>
                        </div>
                    </ng-container>
                </mat-menu>
                <div class="overlay-name" [matTooltip]="overlay.name">{{overlay.name}}</div>
            </div>
        </div>
    </div>


    <div *ngFor="let filterItem of filters; let i = index; trackBy: trackByIndexFunction" class="filter-item">
        <div [matTooltip]="filterItem.displayedTitle" [matMenuTriggerFor]="filterMenu" class="filter-item-content" [tabIndex] = 0>
            <div class="filter-title">
                {{filterItem.datasetField.name}}
            </div>
            <div class="filter-value">
                {{filterItem.displayedSearchValue || '-'}}
            </div>
            <div class="filter-close" (click)="$event.stopPropagation(); removeFilterBarItem(filterItem.id)">
                <mat-icon fontIcon="fa-times" fontSet="far"></mat-icon>
            </div>
        </div>

        <mat-menu xPosition="before" yPosition="below" #filterMenu="matMenu" class="nested-menu nested-menu-leaf">
            <div (click)="$event.stopPropagation()" [ngSwitch]="filterItem.datasetField.baseType">
                <div *ngSwitchCase="DatasetFieldType.DATE_TIME">
                    <div class="dateMenuContent">

                        <mat-form-field class="date-form-field">
                            <mat-label>Choose a starting date</mat-label>
                            <input [(ngModel)]="filterItem.minDateValue" [matDatepicker]="startAt"
                                   [max]="filterItem.statistics.maxDateValue" [min]="filterItem.statistics.minDateValue"
                                   matInput autocomplete="off">
                            <mat-datepicker-toggle [for]="startAt" matSuffix></mat-datepicker-toggle>
                            <mat-datepicker #startAt [startAt]="filterItem.statistics.minValue"></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field class="date-form-field">
                            <mat-label>Choose an ending date</mat-label>
                            <input [(ngModel)]="filterItem.maxDateValue" [matDatepicker]="endAt"
                                   [max]="filterItem.statistics.maxDateValue" [min]="filterItem.statistics.minDateValue"
                                   matInput autocomplete="off">
                            <mat-datepicker-toggle [for]="endAt" matSuffix></mat-datepicker-toggle>
                            <mat-datepicker #endAt></mat-datepicker>
                        </mat-form-field>
                        <button (click)="applyFilters(); filterMenu.closed.emit()" mat-flat-button color="accent"
                                class="apply-filter-button date-apply-button small">Apply
                        </button>
                    </div>
                </div>

                <div class="number-field-content" (click)="$event.stopPropagation()"
                     *ngSwitchCase="DatasetFieldType.NUMBER">
                    <mat-form-field *ngIf="filterItem.id !== 'distance'" class="full-width">
                        <mat-label>Min
                            <span *ngIf="filterItem.id==='distance'">
								<span *ngIf="filterItem.distanceUnit === DistanceUnit.KM">(km)</span>
								<span *ngIf="filterItem.distanceUnit === DistanceUnit.MILES">(mi)</span>
							</span>
                        </mat-label>
                        <input [(ngModel)]="filterItem.minNumberValue"
                               [max]="filterItem.statistics.maxValue" [min]="filterItem.statistics.minValue" matInput
                               placeholder="Min Value" type="number" autocomplete="off" matTooltip="Statistical Minimum: {{filterItem.statistics.minValue || '-' | commaFormat}}" matTooltipPosition="right">
                    </mat-form-field>
                    <mat-form-field class="full-width">
                        <mat-label>Max
                            <span *ngIf="filterItem.id==='distance'">
								<span *ngIf="filterItem.distanceUnit === DistanceUnit.KM">(km)</span>
								<span *ngIf="filterItem.distanceUnit === DistanceUnit.MILES">(mi)</span>
							</span>
                        </mat-label>
                        <input [(ngModel)]="filterItem.maxNumberValue"
                               [max]="filterItem.statistics.maxValue" [min]="filterItem.statistics.minValue" matInput
                               placeholder="Max Value" type="number" autocomplete="off"  matTooltip="Statistical Maximum: {{filterItem.statistics.maxValue || '-' | commaFormat}}" matTooltipPosition="right">
                    </mat-form-field>
                    <button class="apply-filter-button small" (click)="applyFilters(filterItem); filterMenu.closed.emit()"
                            mat-flat-button color="accent">Apply
                    </button>
                </div>

                <div  (click)="$event.stopPropagation()"
                     *ngSwitchCase="DatasetFieldType.TEXT" class="filter-bar-item-text-field ng-switch-short-text-field"
                      [class.highCardinality]="filterItem.datasetField.isHighCardinality">

                    <!-- text field low cardinality-->
                    <ng-container *ngIf="!filterItem.datasetField.isHighCardinality else newDeb">
                        <div (click)="$event.stopPropagation()" class="nested-menu-search overlay-filter-search">
                                <input matInput [(ngModel)]="search" placeholder="Search..." autocomplete="off">
                        </div>
                        <span *ngIf="filterItem.statistics.values.length === 0 " class="no-values">
                            No values available...
                        </span>
                        <ng-container
                            *ngFor="let value of filterItem.statistics.values | callback: filterValueSearch">
                            <div (click)="$event.stopPropagation()" mat-menu-item>
                                <mat-checkbox [(ngModel)]="filterItem.statisticValues[value]"
                                              (ngModelChange)="applyFilters()">
                                    <span>{{value}}</span>
                                </mat-checkbox>

                            </div>
                        </ng-container>
                    </ng-container>
                    <!-- text field low cardinality-->


                    <!-- text field high cardinality-->
                    <ng-template #newDeb>
                        <mat-form-field class="full-width">
                            <mat-label>Enter value</mat-label>
                            <input [(ngModel)]="filterItem.searchValue" matInput placeholder="Search contains..." autocomplete="off">
                        </mat-form-field>
                        <button class="apply-filter-button small" (click)="applyFilters(filterItem); filterMenu.closed.emit()"
                                mat-flat-button color="accent">Apply
                        </button>
                    </ng-template>
                    <!-- text field high cardinality-->
                </div>

                <span (click)="$event.stopPropagation()" *ngSwitchDefault>
                     <mat-form-field class="full-width">
                         <mat-label>Search a text value</mat-label>
                         <input matInput placeholder="Search contains..." autocomplete="off">
                      </mat-form-field>
                     <button>default</button>
                </span>
            </div>
        </mat-menu>

    </div>
    </div>
    <button style="display: block; margin-left: auto;" class="remove-filter-bar-button" (click)="removeFilterBar()"
            mat-icon-button>
        <span class="material-icons">clear</span>
    </button>
</div>


export const customMapStyles = [
    {
      "featureType": "water",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#66d1e6"
        },
        {
          "weight": 1
        }
      ]
    }
]

<div class="form-container">
    <form [formGroup]="updateGroupForm">
        <mat-form-field>
            <mat-label>Group Name</mat-label>
            <input formControlName="name" matInput placeholder="Group Name" autocomplete="off"/>
            <mat-error *ngIf="updateGroupForm.controls.name.hasError('required') ">
                Group name is required
            </mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="showParentGroup()">
            <mat-label>Parent Group</mat-label>
            <mat-select [disabled]="group.parentId === null" formControlName="parentId">
                <mat-option *ngFor="let group of this.parentsGroupsList" [value]="group.id">
                    {{ group.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </form>
    <button class="full-width" (click)="updateGroup()" [disabled]="updateGroupForm.invalid" color="accent"
            mat-flat-button>
        Update
    </button>
</div>

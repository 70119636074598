<ng-container *ngIf="dataset && renderingOptions">
	<div class="colorization-box">
        <ng-container [ngSwitch]="renderingOptions.visualizationOptions.type">
            <mpt-basic-config-box *ngSwitchCase="VisualizationType.DEFAULT" (submitChanges)="onApplySettings($event)"
                                  (Reset)="reset()" [dataset]="dataset" [renderingOptions]="renderingOptions"
                                  (viewModeChanged)="visualizationTypeChanged($event)">
            </mpt-basic-config-box>
            <mpt-cluster-config-box *ngSwitchCase="VisualizationType.CLUSTER" (submitChanges)="onApplySettings($event)"
                                    (Reset)="reset()" [dataset]="dataset" [renderingOptions]="renderingOptions"
                                    (viewModeChanged)="visualizationTypeChanged($event)">
            </mpt-cluster-config-box>
            <mpt-thematic-config-box *ngSwitchCase="VisualizationType.THEMATIC_MAP" (submitChanges)="onApplySettings($event)"
                                     (Reset)="reset()" [dataset]="dataset" [renderingOptions]="renderingOptions"
                                     (viewModeChanged)="visualizationTypeChanged($event)">
            </mpt-thematic-config-box>
        </ng-container>
	</div>

</ng-container>

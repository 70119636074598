<ng-template #uploadModal>
    <div class="page upload">
        <div class="dialog">
            <div class="dialog-title">Upload data</div>
            <div class="dialog-content">
                <div class="stepper">
                    <div class="step" [class.visited]="currentStepIndex >= 0" [class.focused]="currentStepIndex === 0">
                        <div class="step-container">
                            <div class="step-icon">
                                <!-- upload-icon -->
                                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <g fill="none" fill-rule="evenodd">
                                        <path d="M0 0h24v24H0z" />
                                        <path
                                            d="M16 19h2a4 4 0 0 0 4-4h0a4 4 0 0 0-4-4h0a6 6 0 0 0-6-6h0a5.998 5.998 0 0 0-5.676 4.068A4.985 4.985 0 0 0 2 14h0a5 5 0 0 0 5 5h1"
                                            stroke="#b5b9c1" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                        <path stroke="#b5b9c1" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" d="m14 16-2-2-2 2M12 19v-5" />
                                    </g>
                                </svg>
                            </div>
                            <div class="step-label">
                                Upload file
                            </div>
                        </div>
                    </div>
                    <div class="step" [class.visited]="currentStepIndex >= 1" [class.focused]="currentStepIndex === 1">
                        <div class="step-container">
                            <div class="step-icon">
                                <!-- mouse-pointer-icon -->
                                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <g fill="none" fill-rule="evenodd">
                                        <path d="M0 0h24v24H0z" />
                                        <path stroke="#b5b9c1" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="m15 4-3-2-3 2M12 22h0c-2.946 0-5.357-2.411-5.357-5.357v-4.286C6.643 9.411 9.054 7 12 7h0c2.946 0 5.357 2.411 5.357 5.357v4.286C17.357 19.589 14.946 22 12 22zM12 11.286v2.143" />
                                    </g>
                                </svg>
                            </div>
                            <div class="step-label">
                                Select data
                            </div>
                        </div>
                    </div>
                    <div class="step" [class.visited]="currentStepIndex >= 2" [class.focused]="currentStepIndex === 2">
                        <div class="step-container">
                            <div class="step-icon">
                                <!-- columns-icon -->
                                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <g fill="none" fill-rule="evenodd">
                                        <path stroke="#b5b9c1" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" d="M3 11h18" />
                                        <rect stroke="#b5b9c1" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" x="3" y="3" width="18" height="18" rx="5" />
                                        <path stroke="#b5b9c1" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" d="M12 11v10M15 7.5H9" />
                                        <path d="M0 24V0h24v24z" />
                                    </g>
                                </svg>
                            </div>
                            <div class="step-label">
                                Map columns
                            </div>
                        </div>
                    </div>
                    <div class="step last" [class.visited]="currentStepIndex === 3"
                        [class.focused]="currentStepIndex === 3">
                        <div class="step-container">
                            <div class="step-icon">
                                <!-- check-icon -->
                                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <g transform="translate(-.005 -.005)" fill="none" fill-rule="evenodd">
                                        <circle stroke="#b5b9c1" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" cx="12.005" cy="12.005" r="9.004" />
                                        <path stroke="#b5b9c1" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="m8.448 12.344 2.167 2.168-.014-.014 4.891-4.891" />
                                        <path d="M0 0h24.01v24.01H0z" />
                                    </g>
                                </svg>
                            </div>
                            <div class="step-label">
                                Done
                            </div>
                        </div>
                    </div>
                </div>

                <div class="step-content" [class.prepare-scroll]="currentStepIndex === 2">
                    <div class="spinner-container" *ngIf="isLoading">
                        <mat-spinner class="spinner" diameter="40" color="accent"></mat-spinner>
                    </div>
                    <ng-container *ngIf="currentStepIndex === 0">
                        <div class="upload-container">
                            <div class="upload-image">
                                <!-- upload-icon -->
                                <svg width="80" height="80" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <g fill="none" fill-rule="evenodd">
                                        <path d="M0 0h24v24H0z" />
                                        <path
                                            d="M16 19h2a4 4 0 0 0 4-4h0a4 4 0 0 0-4-4h0a6 6 0 0 0-6-6h0a5.998 5.998 0 0 0-5.676 4.068A4.985 4.985 0 0 0 2 14h0a5 5 0 0 0 5 5h1"
                                            stroke="#b5b9c1" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                        <path stroke="#b5b9c1" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" d="m14 16-2-2-2 2M12 19v-5" />
                                    </g>
                                </svg>
                            </div>
                            <input style="display:none;" type="file" id="file-input" multiple=true
                                (change)="onFilesSelected($event)" [accept]="getAcceptedFileTypesForDataset()">
                            <label class="upload-label" for="file-input">Attach Files</label>
                            <div class="file-names-container">
                                <div *ngFor="let item of files; let i=index">
                                    {{ item.name }} </div>
                            </div>
                        </div>
                        <div class="step-controls">
                            <button mat-flat-button color="primary" class="cancel-btn" (click)="resetUploadProcess()">
                                Cancel
                            </button>
                            <button mat-flat-button color="accent" (click)="submitFiles()" class="next-btn"
                                [disabled]="isLoading || !files.length">
                                Next
                            </button>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="currentStepIndex === 1">
                        <div class="form-container">
                            <div class="input-grid" *ngIf="uploadSheetOptions">
                                <mat-form-field>
                                    <mat-label>Header index</mat-label>
                                    <input matInput placeholder="Type here"
                                        [(ngModel)]="uploadSheetOptions.headerIndex" />
                                </mat-form-field>

                                <mat-form-field>
                                    <mat-label>Footer index</mat-label>
                                    <input matInput placeholder="Type here"
                                        [(ngModel)]="uploadSheetOptions.footerIndex" />
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="step-controls" *ngIf="uploadSheetOptions">
                            <button mat-flat-button color="primary" (click)="resetUploadProcess()">
                                Cancel
                            </button>
                            <button mat-flat-button color="accent" (click)="proceedToFieldMappings()"
                                [disabled]="Number.isNaN(uploadSheetOptions.headerIndex) || Number.isNaN(uploadSheetOptions.footerIndex)">
                                Next
                            </button>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="currentStepIndex === 2">
                        <div class="form-container">
                            <div *ngIf="uploadInProgress && uploadColumns?.length" class="input-grid">
                                <mat-form-field *ngFor="let mapping of uploadSheetOptions.mappings">
                                    <mat-label *ngIf="mapping.type === TableUploadColumnMappingType.LONGITUDE">
                                        Longitude
                                    </mat-label>
                                    <mat-label *ngIf="mapping.type === TableUploadColumnMappingType.LATITUDE">Latitude
                                    </mat-label>
                                    <mat-label
                                        *ngIf="mapping.type !== TableUploadColumnMappingType.LONGITUDE && mapping.type !== TableUploadColumnMappingType.LATITUDE">
                                        {{ datasetFieldsById[mapping.fieldID].name }}
                                    </mat-label>
                                    <mat-select [(value)]="mapping.columnID">
                                        <mat-option value="none">None</mat-option>
                                        <mat-option *ngFor="let column of uploadColumns" [value]="column.id">
                                            {{column.value}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="step-controls">
                            <button mat-flat-button color="primary" (click)="resetUploadProcess()">
                                Cancel
                            </button>
                            <button color="accent" mat-flat-button (click)="submitUploadSheetOptions()"
                                [disabled]="isLoading">
                                Next
                            </button>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="currentStepIndex === 3">
                        <div class="upload-container">
                            <ng-container *ngIf="!isLoading">
                                <div class="success-icon">
                                    <mat-icon color="primary" fontIcon="fa-check-circle" fontSet="far"></mat-icon>
                                </div>
                                <span class="success-label">Success</span>
                            </ng-container>

                        </div>
                        <div class="step-controls">
                            <button mat-flat-button color="primary" (click)="resetUploadProcess()" *ngIf="isLoading">
                                Cancel
                            </button>
                        </div>
                    </ng-container>
                </div>

            </div>
        </div>
    </div>

</ng-template>
<div class="report-box">
    <div>
        <div class="report-header">
            <div style="display: flex; margin-top: 10px;">
                <div *ngIf="rankingDataSource.data[0]?.children.length === 0 || rankingDataSource.data.length === 0">
                    <span class="report-icon header-icon" matTooltip="Ranking field">
                        <!-- amount-up-icon-->
                        <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <g fill="none" fill-rule="evenodd">
                                <path stroke="#002d57" class="chart-icons" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M18.003 2.996v17.007M2.996 20.003H12M4.997 16.002H12M6.998 12H12M8.398 7.998H12M15.501 5.497l2.502-2.5 2.5 2.5" />
                                <path d="M-.005-.005h24.01v24.01H-.005z" />
                            </g>
                        </svg>
                    </span>
                </div>
                <div *ngIf="rankingDataSource.data[0]?.children.length > 0">
                    <div [matMenuTriggerFor]="valueMenu" style="cursor: pointer;" (mouseleave)="collapseAll()">
                        <span class="report-icon header-icon" matTooltip="Ranking field">
                            <!-- amount-up-icon-->
                            <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <g fill="none" fill-rule="evenodd">
                                    <path stroke="#002d57" class="chart-icons" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M18.003 2.996v17.007M2.996 20.003H12M4.997 16.002H12M6.998 12H12M8.398 7.998H12M15.501 5.497l2.502-2.5 2.5 2.5" />
                                    <path d="M-.005-.005h24.01v24.01H-.005z" />
                                </g>
                            </svg>
                        </span>
                    </div>
                    <mat-menu #valueMenu="matMenu" class="nested-menu analytics">
                        <mat-tree [dataSource]="rankingDataSource" [treeControl]="rankingTreeControl"
                            class="sub-menu-options-child">
                            <!-- This is the tree node template for leaf nodes -->
                            <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle class="sub-menu-form-options-tree">

                                <li class="mat-tree-node sub-menu-checkbox-options" [matTooltip]="getDisplayName(node)" matTooltipPosition="right"
                                    *ngIf="node.params?.overlay?.geometryType !== DatasetGeometryType.POINT && node.params?.showCheckBox">
                                    <button mat-icon-button disabled></button>
                                    <mat-checkbox class="checklist-leaf-node" [checked]="node.selected"
                                        [disabled]="selectedRankingField !== undefined && selectedRankingField?.id !== node.params?.field.id"
                                        [(ngModel)]="node.selected"
                                        (ngModelChange)="onRankingFieldSelection(node.selected, node.params?.field)">
                                        {{getDisplayName(node)}}</mat-checkbox>
                                </li>
                            </mat-tree-node>
                            <!-- This is the tree node template for expandable nodes -->
                            <mat-nested-tree-node *matTreeNodeDef="let node; when: rankingTreeStrcuture.hasChild"
                                class="sub-menu-options-parent">
                                <li>
                                    <hr *ngIf="rankingDataSource.data.length > 1" class="menu-line-separator" />
                                    <div class="mat-tree-node" [matTooltip]="node.name" matTooltipPosition="right" style="justify-content: space-between !important;">
                                        <span class="filter-inner-parent">{{node.name}}</span>
                                        <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
                                            <mat-icon class="mat-icon-rtl-mirror" style="position: relative; bottom: 2px;">
                                                {{rankingTreeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                            </mat-icon>
                                        </button>
                                    </div>
                                    <ul [class.tree-invisible]="!rankingTreeControl.isExpanded(node)">
                                        <ng-container matTreeNodeOutlet></ng-container>
                                    </ul>
                                </li>
                            </mat-nested-tree-node>
                        </mat-tree>
                    </mat-menu>
                </div>
                <div *ngIf="columnsToDisplayDataSource.data[0]?.children.length === 0 || columnsToDisplayDataSource.data.length === 0">
                    <span class="report-icon header-icon" matTooltip="Columns to display">
                        <!-- tasks-icon -->
                        <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <g fill="none" fill-rule="evenodd">
                                <path class="chart-icons" stroke="#002d57" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M7.998 3.997h12.005M2.996 3.997h1.001M2.996 7.998h1.001M2.996 12h1.001M7.998 7.998h12.005M7.998 12h2.001M17.002 21.004A5.002 5.002 0 0 1 12 16.002 5.08 5.08 0 0 1 17.002 11a5.002 5.002 0 0 1 0 10.004" />
                                <path class="chart-icons" stroke="#002d57" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" d="m18.665 15.167-2.083 2.085-1.25-1.25" />
                                <path d="M-.005-.005h24.01v24.01H-.005z" />
                            </g>
                        </svg>
                    </span>
                </div>
                <div *ngIf="columnsToDisplayDataSource.data[0]?.children.length > 1">
                    <div [matMenuTriggerFor]="columnsMenu" style="cursor: pointer;" (mouseleave)="collapseAll()">
                        <span class="report-icon header-icon" matTooltip="Columns to display">
                            <!-- tasks-icon -->
                            <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <g fill="none" fill-rule="evenodd">
                                    <path class="chart-icons" stroke="#002d57" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M7.998 3.997h12.005M2.996 3.997h1.001M2.996 7.998h1.001M2.996 12h1.001M7.998 7.998h12.005M7.998 12h2.001M17.002 21.004A5.002 5.002 0 0 1 12 16.002 5.08 5.08 0 0 1 17.002 11a5.002 5.002 0 0 1 0 10.004" />
                                    <path class="chart-icons" stroke="#002d57" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" d="m18.665 15.167-2.083 2.085-1.25-1.25" />
                                    <path d="M-.005-.005h24.01v24.01H-.005z" />
                                </g>
                            </svg>
                        </span>
                    </div>
                    <mat-menu #columnsMenu="matMenu" class="nested-menu analytics">
                        <mat-tree [dataSource]="columnsToDisplayDataSource" [treeControl]="columnsToDisplayTreeControl"
                            class="sub-menu-options-child">
                            <!-- This is the tree node template for leaf nodes -->
                            <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle class="sub-menu-form-options-tree">

                                <li class="mat-tree-node sub-menu-checkbox-options" [matTooltip]="getDisplayName(node)" matTooltipPosition="right"
                                    *ngIf="node.params?.overlay?.geometryType !== DatasetGeometryType.POINT && node.params?.showCheckBox">
                                    <button mat-icon-button disabled></button>
                                    <mat-checkbox class="checklist-leaf-node" [checked]="node.selected"
                                        [disabled]="node.params?.field.id === selectedRankingField?.id"
                                        [(ngModel)]="node.selected"
                                        (ngModelChange)="onFieldsMenuClick(node.selected, node.params?.field)">
                                        {{getDisplayName(node)}}</mat-checkbox>
                                </li>
                            </mat-tree-node>
                            <!-- This is the tree node template for expandable nodes -->
                            <mat-nested-tree-node *matTreeNodeDef="let node; when: columnsToDisplayTreeStrcuture.hasChild"
                                class="sub-menu-options-parent">
                                <li>
                                    <hr  *ngIf="columnsToDisplayDataSource.data.length > 1" class="menu-line-separator" />
                                    <div class="mat-tree-node" [matTooltip]="node.name" matTooltipPosition="right" style="justify-content: space-between !important;">
                                        <span class="filter-inner-parent">{{node.name}}</span>
                                        <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
                                            <mat-icon class="mat-icon-rtl-mirror">
                                                {{columnsToDisplayTreeControl.isExpanded(node) ? 'expand_more' :
                                                'chevron_right'}}
                                            </mat-icon>
                                        </button>
                                    </div>
                                    <ul [class.tree-invisible]="!columnsToDisplayTreeControl.isExpanded(node)">
                                        <ng-container matTreeNodeOutlet></ng-container>
                                    </ul>
                                </li>
                            </mat-nested-tree-node>
                        </mat-tree>
                    </mat-menu>
                </div>
                <div>
                    <span class="report-icon header-icon stacks-icon" style="cursor: pointer"
                        [matMenuTriggerFor]="groupMenu" *ngIf="isComparisonModeActivated">
                        <svg xmlns="http://www.w3.org/2000/svg" height="15px" viewBox="0 -960 960 960" width="15px"
                            fill="#002d57">
                            <path
                                d="M480-400 40-640l440-240 440 240-440 240Zm0 160L63-467l84-46 333 182 333-182 84 46-417 227Zm0 160L63-307l84-46 333 182 333-182 84 46L480-80Zm0-411 273-149-273-149-273 149 273 149Zm0-149Z" />
                        </svg>
                    </span>
                </div>
                <input class="ranking-input" type="number" placeholder="# of items" matInput [(ngModel)]="noOfItems"
                        autocomplete="off" />
                <mat-menu #groupMenu="matMenu" (closed)="generateReport()" class="analytics-mat-tree-container">
                    <div><hr class="menu-line-separator" />
                        <span class="group-compare-label">
                            <span>Compare</span>
                            <mat-icon (click)="$event.stopPropagation(); isCompareDropdownOpen = false" *ngIf="isCompareDropdownOpen">keyboard_arrow_down</mat-icon>
                            <mat-icon (click)="$event.stopPropagation(); isCompareDropdownOpen = true" *ngIf="!isCompareDropdownOpen">chevron_right</mat-icon>
                        </span>
                        <ng-container *ngIf="isCompareDropdownOpen">
                            <ul [ngClass]="ulClassName">
                                <ng-container *ngTemplateOutlet="recursiveList; context: { items: _compareGroupsStrcuture, level: 0, section: 'compare' }"></ng-container>
                            </ul>
                        </ng-container>
                        <hr class="menu-line-separator" />
                        <span class="group-compare-label">
                            <span>With</span> 
                            <mat-icon (click)="$event.stopPropagation(); isCompareWithDropdownOpen = false" *ngIf="isCompareWithDropdownOpen">keyboard_arrow_down</mat-icon>
                            <mat-icon (click)="$event.stopPropagation(); isCompareWithDropdownOpen = true" *ngIf="!isCompareWithDropdownOpen">chevron_right</mat-icon>
                        </span>

                        <ng-container *ngIf="isCompareWithDropdownOpen">
                          <ul [ngClass]="ulClassName">
                            <ng-container *ngTemplateOutlet="recursiveList; context: { items: _withGroupsStrcuture, level: 0, section: 'with' }"></ng-container>
                          </ul>
                        </ng-container>
                          
                          <ng-template #recursiveList let-items="items" let-level="level" let-section="section">
                            <li *ngFor="let item of items" [style.margin-left.px]="level * 10">
                              <div class="parent-group-container">
                                <div class="toggle-icon" [ngClass]="{'invisble': !item?.children?.length}" (click)="$event.stopPropagation();toggleItemExpansion(item)">
                                  <mat-icon class="small-icon">{{ item.expanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}</mat-icon>
                                </div>
                                <mat-checkbox
                                  [(ngModel)]="item.selected"
                                  (change)="toggleItem($event, item, section)"
                                  [disabled]="item.disabled"
                                  (click)="$event.stopPropagation()"
                                  (keyup)="$event.stopPropagation()"
                                  matTooltip="{{ item.name }}" matTooltipPosition="right">
                                  {{ item.name }}
                                </mat-checkbox>
                              </div>
                              <ng-container *ngIf="item.children && item.expanded">
                                <ng-container *ngTemplateOutlet="recursiveList; context: { items: item.children, level: level + 1, section: section }"></ng-container>
                              </ng-container>
                            </li>
                          </ng-template>
                        <!-- <div>
                        <span class="group-compare-label">
                            <span>Compare</span>
                            <mat-icon (click)="$event.stopPropagation(); isCompareDropdownOpen = false" *ngIf="isCompareDropdownOpen">keyboard_arrow_down</mat-icon>
                            <mat-icon (click)="$event.stopPropagation(); isCompareDropdownOpen = true" *ngIf="!isCompareDropdownOpen">chevron_right</mat-icon>
                        </span>
                        <ng-container *ngIf="isCompareDropdownOpen">
                            <div class="groups-menu-options-container">
                                <ng-container *ngFor="let group of _compareGroups">
                                    <mat-checkbox
                                        class="checklist-leaf-node"
                                        [value]="group.id"
                                        [checked]="group.isChecked"
                                        [(ngModel)]="group.isChecked"
                                        (change)="onCheckboxChange($event, 'compare')"
                                        [disabled]="group.disabled"
                                        (click)="$event.stopPropagation()"
                                        (keyup)="$event.stopPropagation()">
                                        <span class="group-checkbox-label">{{group?.name}}</span>
                                    </mat-checkbox>
                                </ng-container>
                            </div>
                        </ng-container>
                        </div> -->
                        <hr class="menu-line-separator" />
                        <!-- <div>
                        <span class="group-compare-label">
                            <span>With</span> 
                            <mat-icon (click)="$event.stopPropagation(); isCompareWithDropdownOpen = false" *ngIf="isCompareWithDropdownOpen">keyboard_arrow_down</mat-icon>
                            <mat-icon (click)="$event.stopPropagation(); isCompareWithDropdownOpen = true" *ngIf="!isCompareWithDropdownOpen">chevron_right</mat-icon>
                        </span>
                        <ng-container *ngIf="isCompareWithDropdownOpen">
                            <ng-container *ngFor="let group of _withGroups">
                                <mat-checkbox
                                    class="checklist-leaf-node"
                                    [value]="group.id"
                                    [checked]="group.isChecked"
                                    [(ngModel)]="group.isChecked"
                                    (change)="onCheckboxChange($event, 'with')"
                                    [disabled]="group.disabled"
                                    (click)="$event.stopPropagation()"
                                    (keyup)="$event.stopPropagation()"
                                >
                                <span class="group-checkbox-label">{{group?.name}}</span>
                                </mat-checkbox>
                            </ng-container>
                        </ng-container>
                        </div> -->
                    </div>
                </mat-menu>         
                <div *ngIf="showCheckButton()"
                    class="report-submit-btn" (click)="generateReport()">
                    <i class="far fa-check"></i>
                </div>
            </div>
            <div class="report-type-icons-container">
                <span (click)="closed.emit()" class="report-icon active">
                    <!-- cross-icon -->
                    <svg width="11" height="11" viewBox="0 0 9 9" xmlns="http://www.w3.org/2000/svg">
                        <g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"
                            stroke="#002d57" stroke-width="1.5" class="chart-icons">
                            <path d="m1 1 3.333 3.333L1 7.667" />
                            <path d="M7.333 1 4 4.333l3.333 3.334" />
                        </g>
                    </svg>
                </span>
                <span *ngIf="isLocationTypeApplication()" [matMenuTriggerFor]="actionsMenu" class="report-icon active">
                    <!-- dot-menu-icon -->
					 <svg *ngIf="fromAnalytics" width="13" height="13" viewBox="0 0 9 9" xmlns="http://www.w3.org/2000/svg">
                        <g fill="#002d57" fill-rule="evenodd">
                            <circle cx="4.5" cy="1" r="1" />
                            <circle cx="4.5" cy="4" r="1" />
                            <circle cx="4.5" cy="7" r="1" />
                        </g>
                    </svg>
                </span>
                <mat-menu #actionsMenu="matMenu" class="nested-menu header-menu" backdropClass="mat-menu-header">
                    <hr *ngIf="dashboardWorkspaceItems.length" class="menu-line-separator" />
                    <div mat-menu-item *ngIf="dashboardWorkspaceItems.length"
                        (click)="saveWorkspaceItemToDashboard.emit()">
                        <span>
                            <!-- save-icon -->
                            <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <g transform="translate(-.005 -.005)" fill="none" fill-rule="evenodd">
                                    <path
                                        d="M20.008 11.005V7.564c0-.502-.199-.983-.553-1.337l-2.672-2.672a1.89 1.89 0 0 0-1.337-.554H5.002a2 2 0 0 0-2 2.001v13.006a2 2 0 0 0 2 2h6.003"
                                        stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path
                                        d="M15 3.001v4.127a.876.876 0 0 1-.876.875H8.872a.876.876 0 0 1-.875-.875V3.001M7.003 20.008v-5.676a1.315 1.315 0 0 1 1.215-1.327h3.787"
                                        stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <circle stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" cx="17.507" cy="17.507" r="4.502" />
                                    <path stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" d="m18.907 16.808-1.75 1.752-1.05-1.052" />
                                    <path d="M0 0h24.01v24.01H0z" />
                                </g>
                            </svg>
                        </span>
                        {{getSaveButtonLabel()}}
                    </div>
                    <hr *ngIf="dashboardWorkspaceItems.length > 0" class="menu-line-separator" />
                    <div mat-menu-item (click)="saveAsWorkspaceItemToDashboard.emit()">
                        <span>
                            <!-- save-icon -->
                            <svg width="16" height="16" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <g transform="translate(-.005 -.005)" fill="none" fill-rule="evenodd">
                                    <path
                                        d="M20.008 11.005V7.564c0-.502-.199-.983-.553-1.337l-2.672-2.672a1.89 1.89 0 0 0-1.337-.554H5.002a2 2 0 0 0-2 2.001v13.006a2 2 0 0 0 2 2h6.003"
                                        stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path
                                        d="M15 3.001v4.127a.876.876 0 0 1-.876.875H8.872a.876.876 0 0 1-.875-.875V3.001M7.003 20.008v-5.676a1.315 1.315 0 0 1 1.215-1.327h3.787"
                                        stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <circle stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" cx="17.507" cy="17.507" r="4.502" />
                                    <path stroke="#0044f1" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" d="m18.907 16.808-1.75 1.752-1.05-1.052" />
                                    <path d="M0 0h24.01v24.01H0z" />
                                </g>
                            </svg>
                        </span>
                        {{getSaveAsButtonLabel()}}
                    </div>
                </mat-menu>
            </div>
        </div>

        <div *ngIf="!dataIsReady" class="report-instructions">
            Select from above the fields for ranking.
        </div>

        <div *ngIf="dataIsReady" class="report-content">
            <div class="report-table" *ngIf="reportSubType===ReportDisplayType.TABLE">
                <table mat-table matSort matSortDisableClear (matSortChange)="sortData($event, true)"
                    [dataSource]="reportData" [matSortActive]="sort?.active" [matSortDirection]="sort?.direction"
                    class="report-table">

                    <ng-container [matColumnDef]="RANKING_COLUMN_ID">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>Ranking</th>
                        <td mat-cell *matCellDef="let element"> {{element.ranking | commaFormat}}</td>
                        <td mat-footer-cell *matFooterCellDef>{{sumsByFieldId.size > 0 ? 'Total' : '' | commaFormat}}
                        </td>
                    </ng-container>

                    <ng-container *ngFor="let dynamicColumn of dynamicColumns | keyvalue; let i = index"
                        matColumnDef="{{dynamicColumn.key}}">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef [matTooltip]="getFormattedName(dynamicColumn.value, false)" matTooltipPosition="above">{{getFormattedName(dynamicColumn.value, true) | commaFormat}}</th>
                        <td mat-cell *matCellDef="let element "
                            [matTooltip]="element.dynamicFieldValuesByIds.get(dynamicColumn.key) | commaFormat">
                            {{element.dynamicFieldValuesByIds.get(dynamicColumn.key) | commaFormat}}</td>
                        <td mat-footer-cell *matFooterCellDef>{{sumsByFieldId.get(dynamicColumn.key) | commaFormat}}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
                    <tr mat-footer-row *matFooterRowDef="columnsToDisplay"></tr>
                </table>
            </div>
        </div>
    </div>
    <div class="report-title-container">
        <input class="report-bottom-title" placeholder="Ranking" [(ngModel)]="reportName">
    </div>
</div>
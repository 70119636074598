import { Component, OnInit, ViewEncapsulation, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Dataset } from 'src/app/model/dataset/dataset';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'map-projection-selector',
    templateUrl: './projection-selector.component.html',
    styleUrls: ['./projection-selector.component.scss']
})
export class ProjectionSelectorComponent implements OnInit, OnChanges {
    @Input() dataset: Dataset = null;
    @Input() currentValue = [];
    @Output() valueChanged: EventEmitter<any> = new EventEmitter();

    toppings = new UntypedFormControl();
    fields: any[] = [];
    filteredFields = this.fields;

    constructor() {
    }

    ngOnInit() {
    }

    ngOnChanges() {
        this.setValues(this.dataset.fields);
        // this.onValueChange()
    }

    onKey($event) {
        let searchString = $event.target.value;
        this.filteredFields = this.fields.filter(field => field.name.toLowerCase().includes(searchString.toLowerCase()));
    }

    onValueChange() {
        this.valueChanged.emit(this.currentValue);
    }

    setValues(fields) {
        if (this.fields.length >= 1) {
            return;
        }
        this.fields = fields;
        this.filteredFields = fields;
        this.currentValue = fields.map(field => field.id);
        // this.onValueChange()
    }
}

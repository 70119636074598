<div class="page">
    <div class="dialog" style="width: 100%;">
        <div class="dialog-title">Download</div>
        <div class="dialog-content">
            <form *ngIf="downloadDatapointsForm" [formGroup]="downloadDatapointsForm">
                <div class="main-options">
                    <mat-form-field>
                        <mat-label>File name</mat-label>
                        <input formControlName="fileName" matInput>
                    </mat-form-field>

                    <div class="advanced-actions-handle" (click)="advancedOptions = !advancedOptions">
                        <div *ngIf="!advancedOptions">
                            Show advanced options
                            <mat-icon fontIcon="fa-chevron-down" fontSet="fas"></mat-icon>
                        </div>
                        <div *ngIf="advancedOptions">
                            Hide advanced options
                            <mat-icon fontIcon="fa-chevron-up" fontSet="fas"></mat-icon>
                        </div>
                    </div>

                    <div class="advanced-actions" *ngIf="advancedOptions">
                        <mat-radio-group formControlName="filter">
                            <mat-label>Select a filter option</mat-label>
                            <mat-radio-button [value]="'CURRENT_FILTER'">
                                Current filter
                            </mat-radio-button>
                            <mat-radio-button [value]="'NO_FILTER'">
                                No filter
                            </mat-radio-button>
                        </mat-radio-group>

                        <mat-radio-group formControlName="projection">
                            <mat-label>Select a projection option</mat-label>
                            <mat-radio-button [value]="'CURRENT_FIELDS'">
                                Current fields
                            </mat-radio-button>
                            <mat-radio-button [value]="'ALL_FIELDS'">
                                All fields
                            </mat-radio-button>
                        </mat-radio-group>

                        <mat-radio-group formControlName="sort">
                            <mat-label>Select a sort option</mat-label>
                            <mat-radio-button [value]="'CURRENT_SORT'">
                                Current sort
                            </mat-radio-button>
                            <mat-radio-button [value]="'NO_SORT'">
                                No sort
                            </mat-radio-button>
                        </mat-radio-group>

                        <mat-radio-group formControlName="fileType">
                            <mat-label>Select a file type</mat-label>
                            <mat-radio-button [value]="'CSV'">
                                CSV
                            </mat-radio-button>
                            <mat-radio-button [value]="'XLS'">
                                XLS
                            </mat-radio-button>
                            <mat-radio-button [value]="'XLSX'">
                                XLSX
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <ng-container *ngIf="climateData && climateData.length">
                        <div class="advanced-actions-handle climate-data-handle" (click)="isClimateData = !isClimateData">
                            <div *ngIf="!isClimateData">
                                Show Additional data to download
                                <mat-icon fontIcon="fa-chevron-down" fontSet="fas"></mat-icon>
                            </div>
                            <div *ngIf="isClimateData">
                                Hide Additional data to download
                                <mat-icon fontIcon="fa-chevron-up" fontSet="fas"></mat-icon>
                            </div>
                        </div>
                        <div class="climate-structure-container" *ngIf="isClimateData">
                            <app-multi-level-dropdown [items]="dropdownItems" [lastItemDisable]="false"
                                (selectedItems)="onSelectedItemsChanged($event)" [ulClass]="ulClassName"></app-multi-level-dropdown>
                        </div>
                    </ng-container>
                </div>

                <div class="main-actions" [class.explanded]="advancedOptions">
                    <button (click)="submitDownloadRequest()" mat-flat-button color="accent" cdkFocusInitial style="margin-top: 0px !important;min-width: 80px;margin-right: 4px;">
                        Download
                    </button>
                    <button (click)="onNoClick()" mat-flat-button color="primary" style="margin-top: 0px !important;min-width: 80px;margin-left: 4px;">Close</button>
                </div>
            </form>
        </div>
    </div>
</div>

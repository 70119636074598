<div style="display: flex; justify-content: flex-end; align-items: center; padding: 20px 24px;">
    <button mat-stroked-button
        color="primary"
        class="lg"
        style="background-color: #ffffff !important; min-width: 90px !important; color:#002d57 !important"
        [routerLink]="['/public/login']"
    >                        
        <mat-icon fontIcon="fa-arrow-left" fontSet="fas" style="margin-right: 8px; position: relative; bottom: 1px;"></mat-icon>
        <span style="font-size:0.75rem; font-weight: 600;">Back</span>
    </button>
</div>
<div class="public-content" style="height: 60vh !important;">
    <div class="public-text">
        <img src="../../../assets/images/logo-icon-dark.svg" style="width: 450px; height: auto;" alt="logo">
    </div>
    <div class="public-form">
        <div style="color: white;">
            <div style="font-size: xxx-large;">
                Coming Soon
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="isEnabled && account && dataset">
	<div class="panel-container">
		<div class="panel-header">
			<button (click)="addNotification()" mat-stroked-button class="notif-btn">
				<mat-icon fontIcon="fa-plus" fontSet="far"></mat-icon>
				<span style="padding-left: 6px;">Add Notification</span>
			</button>
		</div>
		<div class="reports-container">
			<div *ngFor="let notif of notifications; let i = index">
				<div class="notification-box">
					<div class="notification-container">
						<map-event-notification style="width: 100%;" [eligibleOverlays]="eligibleOverlays"
							[eventNotif]="notif" [currencies]="currencies" [dataset]="dataset" [groupsIds]="groupsIds"
							(deleteEventNotif)="deleteEventNotif($event)"></map-event-notification>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-container>
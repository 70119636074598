<div class="dialog">
    <div class="dialog-title">
        {{ title }}
    </div>
    <div class="dialog-content">
        <div class="indicators-dialog-table-container">
            <form class="table-form" #tableForm="ngForm">
                <mat-table *ngIf="dataSource.data.length" [dataSource]="dataSource">
                    <!-- Id Column -->
                    <ng-container matColumnDef="id">
                        <mat-header-cell *matHeaderCellDef> Id</mat-header-cell>
                        <mat-cell *matCellDef="let field; let i = index;">
                            <span> {{field.id}}</span>
                        </mat-cell>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef> Name</mat-header-cell>
                        <mat-cell *matCellDef="let field; let i = index;">
                            <p>{{ field.name }}</p>
                        </mat-cell>
                    </ng-container>


                    <!-- Indicator Column -->
                    <ng-container matColumnDef="isIndicators">
                        <mat-header-cell *matHeaderCellDef>Indicator</mat-header-cell>
                        <mat-cell *matCellDef="let field; let i = index;">
                            <mat-checkbox
                                    [(ngModel)]="field.isIndicators"
                                    (ngModelChange)="setIndicatorOption(field)"
                                    [name]="'isIndicator'+i"
                            ></mat-checkbox>
                        </mat-cell>
                    </ng-container>

                    <!-- Indicator Options Column -->
                    <ng-container matColumnDef="indicatorOptions">
                        <mat-header-cell *matHeaderCellDef>Indicator Options</mat-header-cell>
                        <mat-cell *matCellDef="let field; let i = index;">
                            <ng-container *ngIf="field.isIndicators else none">
                                <mat-select [(value)]="field.indicatorsOption" placeholder="Option">
                                    <mat-option *ngFor="let option of indicatorOptions" [value]="option">
                                        {{option}}
                                    </mat-option>
                                </mat-select>
                            </ng-container>
                            <ng-template #none>
                                -
                            </ng-template>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;" [cdkDragData]="row" cdkDrag
                                cdkDragLockAxis="y">
                    </mat-row>
                </mat-table>
            </form>
        </div>
        <div class="confirm-actions">
            <button (click)="onConfirm()" class="small" mat-flat-button color="accent">{{ confirmButton }}</button>
            <button (click)="onDismiss()" class="small" mat-flat-button color="primary">{{ cancelButton }}</button>
        </div>
    </div>
</div>

<div class="colorization-scroll">
    <div class="colorization-grid">
        <div class="colorization-cell">
            <div class="colorization-label">VIEW MODE</div>
            <mat-form-field [floatLabel]="'never'">
                <mat-select [(ngModel)]="renderingOptions.visualizationOptions.type"
                            (ngModelChange)="viewModeChanged.emit(renderingOptions.visualizationOptions.type)"
                            placeholder="Type">
                    <mat-option *ngFor="let option of getVisualizationTypes" value="{{option}}">
                        {{option | titlecase}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="colorization-cell">
            <div class="colorization-label">Grouping</div>
            <mat-form-field [floatLabel]="'never'">
                <mat-select [(value)]="renderingOptions.converterOptions.grouping" placeholder="Grouping">
                    <mat-option *ngFor="let grouping of groupings" [value]="grouping">
                        {{grouping | titlecase}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="colorization-cell">
            <div class="colorization-label">Colorize By</div>
            <mat-form-field [floatLabel]="'never'">
                <mat-select [(value)]="renderingOptions.converterOptions.accountApplicationFieldId" placeholder="Field">
                    <ng-container *ngFor="let field of mainDataset.fields">
                        <mat-option *ngIf="!field.isGenerated && !field.tags?.includes('ID')"
                                    [disabled]="renderingOptions.datasetStylingOptions.type === ColorScaleType.INTERVAL && field.baseType !== DatasetFieldType.NUMBER"
                                    [value]="field.id">
                            {{field.name}}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="colorization-cell">
            <div class="colorization-label">Scale Type</div>
            <mat-form-field [floatLabel]="'never'">
                <mat-select [(value)]="renderingOptions.datasetStylingOptions.type" placeholder="Type"
                            [disabled]="true">
                    <mat-option [value]="ColorScaleType.INTERVAL">{{ColorScaleType.INTERVAL  | titlecase}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="colorization-cell">
            <div class="colorization-label">Opacity</div>
            <mat-slider class="example-margin" min="0" max="1" step="0.1" value="1" color=primary
                        [(ngModel)]="renderingOptions.datasetStylingOptions.backgroundOpacity">
            </mat-slider>
        </div>
        <div class="colorization-cell">
        </div>
    </div>

    <div class="colorization-etc">
        <div class="colorization-cell" style="display: flex;align-items: center;">
            <div class="colorization-label">Color</div>
            <div style="margin-left: 10px;" 
                 class="color-square">
                 <div class="color-square">
                    <input class="color-picker-input"
                           cpColorMode="true"
                           [style.background]="defaultColor"
                           (colorPicker)="defaultColor"
                           [ngStyle]="{'background': defaultColor}"
                           (colorPickerChange)="onConstantColorChanged($event)"
                           style="bottom: 2px !important;"
                    >
                </div>
            </div>
        </div>


        <mpt-color-intervals (Update)="onApplySettings()"
                             [intervals]="renderingOptions.datasetStylingOptions.intervalOptions"></mpt-color-intervals>
    </div>
</div>
<div class="colorization-hint" *ngIf="dataset.isSelected">Your changes will appear on the map</div>
<div class="colorization-hint" *ngIf="!dataset.isSelected">The overlay is not active on the map</div>
<div class="colorization-config-box-controls">
    <button (click)="reset()" *ngIf="dataset.isSelected" mat-button mat-flat-button color="primary">
        Reset
    </button>
    <button (click)="onApplySettings()" *ngIf="dataset.isSelected" mat-button mat-flat-button color="accent">
        Apply
    </button>
</div>

import {environment} from '../../../environments/environment';
import {MapViewType} from '../../model/map/map-view-type';
import {MapSettings} from '../../model/map/map-settings';
import {DrawingOptions} from '../../model/map/drawing/drawing-options';
import {MapState} from '../../model/map/map-state';
import {MapStateActionEnum} from '../../model/map/map-state-action.enum';
import {MapInteractionMode} from '../../dataset/datapoints/map-interaction-mode';
import {MapInteractionStatus} from '../../dataset/datapoints/map-interaction-status';

export const testStateMap = new Map<string, any>([
    ['portofolio', {
        url: (x, y, z) => `https://c.app2.maptycs.com/api/account/3/locations/application-overlay/43/` +
            `dataset/36/tile/${x}/${y}/${z}?token=${environment.authToken}&settings=1-43-3000063`,
        mapTypeIndex: 0,
        display: true
    }],
    // ['fire', {
    //     url: (x, y, z) => `https://a.app2.maptycs.com/api/account/4/global-overlay/5000006/dataset/ibm_forecast_wildfire/tile/` +
    //         `${x}/${y}/${z}?token=${environment.authToken}&settings=1-5000006-8000016`,
    //     mapTypeIndex: 1,
    //     display: true
    // }],
    // ['other', {
    //     url: (x, y, z) => `https://c.app2.maptycs.com/api/account/4/global-overlay/30/dataset/41/tile/${x}/${y}/${z}?` +
    //         `token=${environment.authToken}&settings=1-30-8000016`,
    //     mapTypeIndex: 2,
    //     display: true
    // }],
]);

export const testStateMap2 = new Map<string, any>([
    ['portofolio', {
        url: (x, y, z) => `https://c.app2.maptycs.com/api/account/4/locations/application-overlay/4/` +
            `dataset/10/tile/${x}/${y}/${z}?token=${environment.authToken}&settings=1-4-8000016`,
        mapTypeIndex: 0,
        display: true
    }],
    ['fire', {
        url: (x, y, z) => `https://a.app2.maptycs.com/api/account/4/global-overlay/5000006/dataset/ibm_forecast_wildfire/tile/` +
            `${x}/${y}/${z}?token=${environment.authToken}&settings=1-5000006-8000016`,
        mapTypeIndex: 1,
        display: false
    }],
    ['other', {
        url: (x, y, z) => `https://c.app2.maptycs.com/api/account/4/global-overlay/30/dataset/41/tile/${x}/${y}/${z}?` +
            `token=${environment.authToken}&settings=1-30-8000016`,
        mapTypeIndex: 2,
        display: false
    }],
]);

export const testStateMap3 = new Map<string, any>([
    ['portofolio', {
        url: (x, y, z) => `https://c.app2.maptycs.com/api/account/4/locations/application-overlay/4/` +
            `dataset/10/tile/${x}/${y}/${z}?token=${environment.authToken}&settings=1-4-8000016`,
        mapTypeIndex: 0,
        display: false
    }],
    ['fire', {
        url: (x, y, z) => `https://a.app2.maptycs.com/api/account/4/global-overlay/5000006/dataset/ibm_forecast_wildfire/tile/` +
            `${x}/${y}/${z}?token=${environment.authToken}&settings=1-5000006-8000016`,
        mapTypeIndex: 1,
        display: true
    }],
    ['other', {
        url: (x, y, z) => `https://c.app2.maptycs.com/api/account/4/global-overlay/30/dataset/41/tile/${x}/${y}/${z}?` +
            `token=${environment.authToken}&settings=1-30-8000016`,
        mapTypeIndex: 2,
        display: false
    }],
]);

// TODO clean past constants if not needed
export const defaultMapSettings: MapSettings = {
    zoom: 3,
    minZoom: 3,
    maxZoom: 21,
    latitude: 24,
    longitude: 0,
    drawingControl: true,
    mapType: MapViewType.ROADMAP,
    disableDefaultUI: true,
    streetViewControl: true,
    tilt: 45,
    restriction: {
        latLngBounds: {
            east: 180,
            north: 85,
            south: -85,
            west: -180,
        },
        strictBounds: true
    }
};

export const defaultTileSize: { width: number, height: number } = {
    width: 256,
    height: 256
};

export const defaultMapStatus: MapInteractionStatus = {
    mode: MapInteractionMode.DEFAULT,
    externalDatasetId: null,
    externalDatasetName: null
};

export const mapActionStoreDemoData = new Map<string, any>([
    ['portofolio', {
        url: (x, y, z) =>
            `https://c.app2.maptycs.com/api/account/2/locations/application-overlay/15/dataset/4/tile/${x}/${y}/${z}?token=${environment.authToken}&settings=1-15-4000012`,
        mapTypeIndex: 0,
        display: true
    }]
]);

export const defaultMapState: MapState = {
    datasetID: null,
    map: null,
    action: MapStateActionEnum.LOAD,
    options: {
        drawingMode: null
    }
};

export const shapeSelectColor = '#0073c4';
export const shapeMeasurementColor = '#ff8000';

export const shapeOptions: any = {
    draggable: true,
    editable: true,
    fillColor: shapeSelectColor,
    strokeColor: shapeSelectColor,
    geodesic: true,
    strokeWeight: 0.5
};

export const drawingOptions: DrawingOptions = {
    strokePosition: 0,
    circle: {
        ...shapeOptions
    },
    rectangle: {
        ...shapeOptions
    },
    polygon: {
        ...shapeOptions,
        geodesic: true
    },
    polyline: {
        ...shapeOptions,
        strokeColor: '#0062b8',
        strokeWeight: 5
    }
};

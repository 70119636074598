import {
    ElementRef,
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { DatapointsPageStateService } from "../../datapoints-page-state.service";
import { Dataset } from "../../../../model/dataset/dataset";
import { DatapointsAggregateService } from "../../../../data-access-layer/datapoints/datapoints-aggregate.service";
import { DatasetGeometryType } from "../../../../model/dataset/dataset-geometry-type";
import { DatasetField } from "../../../../model/dataset/field/dataset-field";
import { DatasetFieldType } from "../../../../model/dataset/dataset-field-type";
import { DatasetUtils } from "../../../../core/utils/dataset-utils";
import { ReportDisplayType } from "../../../../model/analytics/report-display-type";
import { DatapointFilter } from "../../../../model/datapoint/filter/datapoint-filter";
import { DatapointProjection } from "../../../../model/datapoint/projection/datapoint-projection";
import { ReportRequest } from "../../../../model/datapoint/report/report-request";
import { AggregateGroupRequest } from "../../../../model/datapoint/report/aggregate-group-request";
import { ReportResultResponse } from "../../../../model/datapoint/report/report-result-response";
import { ReportResultGroupResponse } from "../../../../model/datapoint/report/report-result-group-response";
import { Sort } from "@angular/material/sort";
import { ReportRow } from "../../../../model/datapoint/report/count/report-row";
import { ChartInfo } from "../../../../model/datapoint/report/chart-info";
import { ColorUtils } from "../../../../core/utils/color-utils";
import { ChartDataSets, ChartLegendLabelItem } from "chart.js";
import { DatapointsFilterService } from "../../datapoints-filter.service";
import { Subscription } from "rxjs";
import { DatasetFieldSpecificType } from "../../../../model/dataset/dataset-field-specific.type";
import {
    TableColumn,
    TableColumnType,
    TableColumnAlignment,
} from "../../../../model/upload/table/table-column";
import { TableRow } from "../../../../model/upload/table/table-row";
import { TableCell } from "../../../../model/upload/table/table-cell";
import { DownloadReportItem } from "../../../../model/download/item/download-report-item";
import { DownloadReportTableRequest } from "../../../../model/download/download-report-table-request";
import { ReportComponent } from "../report.component";
import {
    DownloadReportChartRequest,
    ValueKey,
} from "../../../../model/download/download-report-chart-request";
import { DownloadReportChartValueRequest } from "../../../../model/download/download-report-chart-value-request";
import { DownloadItemReportType } from "../../../../model/download/item/download-item-report-type";
import { DatapointAggregateFieldType } from "../../../../model/datapoint/report/datapoint-aggregate-field-type";
import { ActivatedRoute } from "@angular/router";
import {
    GroupWithOverlaysTreeNode,
} from "../../../../model/overlay/group/group-with-overlays-tree-node";
import * as _ from "agile";
import { isUndefined } from "src/app/core/utils/util-master";
import { WorkspaceItem } from "src/app/model/workspace/workspace-item";
import { ReportItem } from "src/app/model/analytics/report-item";
import { WorkspaceItemDialogComponent } from "src/app/dataset/workspace-item/projection/workspace-item-dialog.component";
import { MaptycsApplication } from "src/app/model/account/maptycs-application";
import { StringUtils } from "src/app/core/utils/string-utils";
import { ReportType } from "src/app/model/analytics/report-type";
import { ChartDisplayType } from "src/app/model/analytics/chart-display-type";
import { MatMenu } from "@angular/material/menu";
import { TreeStructureUtils } from "src/app/core/utils/tree-structure-utils";
import { Datapoints } from "../../datapoints";
import { AnayticsConstants } from "src/app/constants";
import { RandomUtils } from "src/app/core/utils/random-utils";
import { AnalyticsUtils } from "src/app/core/analytics/analytics-utils";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ObjectUtils } from "src/app/core/utils/object-utils";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { TreeNode, TreeStructure } from "src/app/model/menu/tree-structure";
@Component({
    selector: "map-count-report",
    templateUrl: "./count-report.component.html",
    styleUrls: ["./count-report.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class CountReportComponent
    implements AfterViewInit, OnInit, OnDestroy, ReportComponent
{
    @ViewChild("workspaceItemDialog", { static: true })
    workspaceIemDialog: WorkspaceItemDialogComponent;
    MaptycsApplication = MaptycsApplication;

    private readonly subscriptions: Subscription = new Subscription();
    private LIMIT: number = 247;
    _nriFields: any;
    _climateOverlays: any;

    @Input() dataset: Dataset;
    @Input() uuid: string;
    @Output() closed = new EventEmitter();
    @Output() saveWorkspaceItemToDashboard = new EventEmitter();
    @Output() saveAsWorkspaceItemToDashboard = new EventEmitter();
    @Output() saveSorting = new EventEmitter();
    @Input() set nriFields(nriFields: any) {
        this._nriFields = JSON.parse(JSON.stringify(nriFields));
    }
    @Input() dashboardWorkspaceItems: WorkspaceItem[];
    @Input() isDashboardCall: boolean;
    @Input() fromAnalytics: boolean = true;
    get climateOverlays(): any {
        return this.climateOverlays;
    }
    get AnalyticsUtils() {
        return AnalyticsUtils;
    }
    get RandomUtils() {
        return RandomUtils;
    }
    @Input() set climateOverlays(climateOverlays: any) {
        this._climateOverlays = JSON.parse(JSON.stringify(climateOverlays));
    }
    @Input() isComparisonModeActivated: boolean;
    @Input() set groups(groups: any) {
        this._groups = JSON.parse(JSON.stringify(groups));
        this._compareGroups = ObjectUtils.clone(this._groups);
        this._withGroups = ObjectUtils.clone(this._groups);
    }
    ulClassName = 'compareGroups';
    @Input() set groupsStrcuture(groupsStrcuture: TreeStructure[]) {
        this._groupsStrcuture = groupsStrcuture;
        this._compareGroupsStrcuture = ObjectUtils.clone(this._groupsStrcuture);
        this._withGroupsStrcuture = ObjectUtils.clone(this._groupsStrcuture);;
    }
    selectedFieldsByDataset: Map<string, DatasetField[]> = new Map();
    selectedFieldsCount = 0;
    reportName: string;
    datasetsToChooseFrom: Dataset[];
    displayType: ReportDisplayType = ReportDisplayType.TABLE;
    reportType: string = ReportType.COUNT;
    reportSubType: ReportDisplayType = ReportDisplayType.TABLE;
    chartDisplayType: ChartDisplayType = ChartDisplayType.AGGREGATE;
    dataIsReady = false;
    datasetforCountReport: GroupWithOverlaysTreeNode[];
    filterFieldSearchString = "";
    filterFieldSearchFilter: (field: DatasetField) => boolean;
    datapointFilter: DatapointFilter;
    datapointProjection: DatapointProjection;
    tessadataFields: {
        externalFields: any[];
        nriFields: any[];
        tensorflightFields: any[];
        munichreFields: any[];
    };
    tessadataGroupedFields: any[];

    /** TABLE  */
    dynamicColumns: Map<string, string>; // key of the map is a string composed of datasetId_fieldId to ensure uniqueness, value is field value
    columnsToDisplay: string[];
    totalCount: number;
    reportData: ReportRow[];
    downloadReportData: ReportRow[];
    parentOverlay: any;

    /** CHART  */
    chartDatasets: any[];
    chartLabels: string[];
    chartColors: any[];
    chartOptions: any = {
        responsive: true,
        legend: {
            labels: {
                generateLabels: (chart) => {
                    let legendItems: ChartLegendLabelItem[] = [];

                    chart.data.datasets.forEach((dataset) => {
                        legendItems.push({
                            text: dataset.label,
                            fillStyle: dataset.backgroundColor[0], // because all values os the secondary dataset have the same color
                        });
                    });
                    if (legendItems.length <= 10) {
                        // not to overload the page
                        return legendItems;
                    } else {
                        return [];
                    }
                },
            },
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    const value =
                        data.datasets[tooltipItem.datasetIndex].data[
                            tooltipItem.index
                        ];
                    const name = data.labels[tooltipItem.index];
                    const roundedUpValue = value;
                        // typeof value === "number" ? value.toFixed(2) : value;
                    let generatedTooltip = `${name}:${StringUtils.numberWithCommas(
                        roundedUpValue
                    )}`;

                    if (this.isComparisonModeActivated) {
                        generatedTooltip = `${
                            data.datasets[tooltipItem.datasetIndex].label
                        } - ${name}:${StringUtils.numberWithCommas(
                            roundedUpValue
                        )}`;
                    }

                    return generatedTooltip;
                },
            },
        },
    };

    reportItems: ReportItem[] = [];

    readonly COUNT_COLUMN_ID = "count";
    readonly PERCENTAGE_COLUMN_ID = "percentage";
    readonly TOTAL_COLUMN_ID = "total";
    readonly BLANK_COLUMN_ID = "blank";

    private static compare(a: any, b: any, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    sort: Sort = {
        active: "",
        direction: "",
    };
    showDropdownMenu: boolean = false;
    showTessadataMenus: boolean = false;
    @ViewChild("locationsFieldsMenu") locationsMenu!: MatMenu;
    toggleDropdownMenu() {
        this.showDropdownMenu = !this.showDropdownMenu;
    }

    openDropdownOnHover() {
        this.showDropdownMenu = true;
    }

    public toggleTessadataMenu(key?: string) {
        // Logic to update the showTessadataMenus object based on the key
        if (key) {
            this.showTessadataMenus[key] = !this.showTessadataMenus[key];
        }
    }
    filterTreeStrcuture = new TreeStructureUtils();
    treeControl = this.filterTreeStrcuture.getTreeControl();
    dataSource = this.filterTreeStrcuture.getDataSource();
    datapointObject = new Datapoints();

    // Comparison declarations
    treeControlGroup = this.filterTreeStrcuture.getTreeControl();
    _groups = [];
    _compareGroups = [];
    _withGroups = [];
    _groupsStrcuture: any[] = [];
    _compareGroupsStrcuture: any[] = [];
    _withGroupsStrcuture: any[] = [];
    totalCountPrimaryGroup: number;
    totalCountSecondaryGroup: number;
    get AnayticsConstants() {
        return AnayticsConstants;
    }
    columnsToDisplayForComparison: string[];
    activeGroups = [];

    readonly PRIMARY_GROUP_COUNT_COLUMN_ID = "primaryGroupCount";
    readonly SECONDARY_GROUP_COUNT_COLUMN_ID = "secondaryGroupCount";
    readonly COMPARE_AMOUNT_COLUMN_ID = "compareAmount";
    readonly COMPARE_COLUMN_ID = "comparePercentage";
    groupForm: FormGroup;

    isCompareDropdownOpen: boolean = true;
    isCompareWithDropdownOpen: boolean = true;

    constructor(
        private readonly datapointsPageStateService: DatapointsPageStateService,
        private readonly datapointsFilterService: DatapointsFilterService,
        private readonly aggregateService: DatapointsAggregateService,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly route: ActivatedRoute,
        private readonly formBuilder: FormBuilder
    ) {
        this.filterFieldSearchFilter = (field: DatasetField) => {
            return field.name
                .toLowerCase()
                .includes(this.filterFieldSearchString.toLowerCase());
        };
        this.groupForm = this.formBuilder.group({
            compareWith: [''],
            selectionType: ['']
          });
    }

    ngOnInit() {
        this.resetNRISelectedFlag();
        let accountId = +this.route.snapshot.paramMap.get("accountId");
        this.dataset = JSON.parse(JSON.stringify(this.dataset));

        this.dataset.fields = this.sortFields(this.dataset.fields);
        if (
            [MaptycsApplication.CLAIMS, MaptycsApplication.POLICIES].includes(
                this.dataset.application
            )
        ) {
            this._climateOverlays = this.datapointObject.filterAndDelete(
                this._climateOverlays,
                { isTextTypeCheck: true }
            );
        }
        if (this._climateOverlays.length) {
            this.datasetforCountReport = this._climateOverlays;
            this.datasetforCountReport = this.setFieldsSelected(
                this.datasetforCountReport
            );
        }

        this.datapointFilter = this.datapointsFilterService.getActiveFilter();
        this.subscriptions.add(
            this.datapointsFilterService
                .onFilterChange()
                .subscribe((newFilter) => {
                    this.datapointFilter = newFilter;
                    if (this.dataIsReady) {
                        this.generateReportData();
                    }
                })
        );

        let datasetsToGroupBy = this.datapointsPageStateService
            .getLinkedAccountDatasets()
            .concat(
                this.datapointsPageStateService
                    .getLinkedAccountOverlays()
                    .filter(
                        (overlay) =>
                            overlay.geometryType === DatasetGeometryType.COMPLEX
                    )
            );

        if (
            !datasetsToGroupBy.find((dataset) => dataset.id === this.dataset.id)
        ) {
            datasetsToGroupBy.splice(0, 0, this.dataset);
        }

        this.tessadataFields = JSON.parse(
            JSON.stringify(
                this.datapointsPageStateService.getActiveTessadataFields()
            )
        );
        this.tessadataFields.externalFields.forEach(
            (field) => (field.selected = false)
        );
        if (
            this.tessadataFields.externalFields &&
            this.tessadataFields.externalFields.length > 0
        ) {
            let externalDatasets = this.sortFields(
                this.tessadataFields.externalFields
            );
            this.tessadataGroupedFields = externalDatasets.reduce(
                (groups, item) => ({
                    ...groups,
                    [item.tags[0]]: [...(groups[item.tags[0]] || []), item],
                }),
                []
            );
        }
        this.tessadataFields.tensorflightFields.forEach(
            (field) => (field.selected = false)
        );
        if (
            this.tessadataFields.tensorflightFields &&
            this.tessadataFields.tensorflightFields.length > 0
        ) {
            let tensorflightDatasets = this.sortFields(
                this.tessadataFields.tensorflightFields
            );
            let tensorflightGroupFields = tensorflightDatasets.reduce(
                (groups, item) => ({
                    ...groups,
                    [item.tags[0]]: [...(groups[item.tags[0]] || []), item],
                }),
                []
            );
            this.tessadataGroupedFields =
                !isUndefined(this.tessadataGroupedFields) &&
                Object.keys(this.tessadataGroupedFields).length
                    ? {
                          ...this.tessadataGroupedFields,
                          ...tensorflightGroupFields,
                      }
                    : tensorflightGroupFields;
        }
        this.tessadataFields.munichreFields.forEach(
            (field) => (field.selected = false)
        );
        if (
            this.tessadataFields.munichreFields &&
            this.tessadataFields.munichreFields.length > 0
        ) {
            let munichreDatasets = this.sortFields(
                this.tessadataFields.munichreFields
            );
            let munichreGroupFields = munichreDatasets.reduce(
                (groups, item) => ({
                    ...groups,
                    [item.tags[0]]: [
                        ...(groups[item.tags[0]] || [])
                            .map(group => group[item.tags[1]] ? {
                                ...group, [item.tags[1]]:
                                    [...group[item.tags[1]], item]
                            } : group
                            ),
                        ...(groups[item.tags[0]]?.some(group => group[item.tags[1]]) ? [] : [{ [item.tags[1]]: [item] }])
                    ]
                }),
                []
            );
            this.tessadataGroupedFields =
                !isUndefined(this.tessadataGroupedFields) &&
                Object.keys(this.tessadataGroupedFields).length
                    ? { ...this.tessadataGroupedFields, ...munichreGroupFields }
                    : munichreGroupFields;
        }
        this.datasetsToChooseFrom = datasetsToGroupBy;
        let tessadataFieldsByDataset = {};
        tessadataFieldsByDataset[this.dataset.id] = { nriFields: [] };
        tessadataFieldsByDataset[this.dataset.id].nriFields = this._nriFields;
        if (this.datasetforCountReport !== undefined) {
            this.dataSource.data = [
                ...this.datapointObject.prepareDataset(
                    [this.dataset],
                    this.dataset
                ),
                ...this.datapointObject.prepareTesadata(
                    this.tessadataGroupedFields,
                    this.dataset
                ),
                ...this.datapointObject.prepareNRIFields(
                    [this.dataset],
                    tessadataFieldsByDataset,
                    true,
                    this.dataset
                ),
                ...this.datapointObject.filterAndDelete(
                    [
                        ...this.datapointObject.prepareClimateData(
                            this.datasetforCountReport,
                            false,
                            false,
                            false
                        ),
                    ],
                    {
                        isTextTypeCheck: true,
                        geometryTypes: [DatasetGeometryType.COMPLEX],
                    }
                ),
            ];
            this.dataSource.data = this.datapointObject.filterAndDelete(
                this.dataSource.data,
                { isTextTypeCheck: true }
            );
        } else {
            this.dataSource.data = [
                ...this.datapointObject.prepareDataset(
                    [this.dataset],
                    this.dataset
                ),
            ];
        }
        this.collapseAll();
    }

    @ViewChild('reportBox') reportBox: ElementRef;
    resizeObserver: ResizeObserver;

    isExpanded: boolean = false;

    
  ngAfterViewInit(): void {
    const reportBoxElement = this.reportBox.nativeElement;

    this.resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        const width = entry.contentRect.width;
        if (width >= 800) {
          this.isExpanded = true;
        } else {
          this.isExpanded = false;
        }

        this.changeDetector.detectChanges();
      }
    });

    this.resizeObserver.observe(reportBoxElement);
  }


    recursiveOperation(
        groupNode: GroupWithOverlaysTreeNode,
        operation: (node: GroupWithOverlaysTreeNode) => void
    ) {
        operation(groupNode);
        groupNode.children.forEach((child) => {
            // Sort the Overlay Of children of each Parent
            if (child.overlays) {
                child.overlays = this.sortFields(child.overlays);
            }
            this.recursiveOperation(child, operation);
        });
    }

    onFieldsMenuClick(
        fieldSelected: boolean,
        dataset: Dataset,
        field: DatasetField,
        overlay?: Dataset
    ) {
        const validDataset = overlay || dataset;
        let datasetFields = this.selectedFieldsByDataset.get(validDataset.id);
        if (!datasetFields) {
            datasetFields = [];
        }
        if (fieldSelected) {
            datasetFields.push(field);
            this.selectedFieldsCount++;
        } else {
            datasetFields = datasetFields.filter((f) => f.id !== field.id);
            this.selectedFieldsCount--;
        }
        if (datasetFields.length === 0) {
            this.selectedFieldsByDataset.delete(validDataset.id);
        } else {
            this.selectedFieldsByDataset.set(validDataset.id, datasetFields);
        }
    }

    generateReport() {
        if (this.selectedFieldsByDataset.size > 0) {
            this.generateReportData();
        } else {
            this.dataIsReady = false;
        }
    }

    generateReportData() {
        let reportRequest = this.createReportRequest();
        this.populateTableColumnsList();
        this.prepareProjection();
        const datapointFilter = this.generateDatapointFilter();

        if (!this.isComparisonModeActivated) {
            this.generateAnalytic(reportRequest, datapointFilter);
        } else if (
            datapointFilter.groups.length ===
            AnayticsConstants.MAX_GROUP_SELECT_LIMIT
        ) {
            this.generateComparisonAnalytic(reportRequest, datapointFilter);
        }
    }

    generateDatapointFilter() {
        this.datapointFilter = this.datapointsFilterService.getActiveFilter();
        const currentFilter = isUndefined(this.datapointFilter)
            ? { datasetID: this.dataset.id }
            : this.datapointFilter;

        if (this.isComparisonModeActivated) {
            currentFilter.groups = this.getSelectedGroups().map((group) => {
                return group.id;
            });
        }
        currentFilter.groups = [...new Set(currentFilter.groups)];
        return currentFilter;
    }

    generateAnalytic(reportRequest, datapointFilter) {
        this.subscriptions.add(
            this.aggregateService
                .getDatapointsReport(
                    this.dataset.id,
                    datapointFilter,
                    reportRequest,
                    this.datapointProjection
                )
                .subscribe((success) => {
                    this.computeTotalCount(success.groupResults);
                    this.convertDataToTableFormat(success.groupResults);
                    this.populateTableColumnsList();
                    if (this.sort && !isUndefined(this.sort)) {
                        this.sortData(this.sort);
                    }
                    this.populateChartData();

                    this.dataIsReady = true;
                    this.changeDetector.detectChanges();
                })
        );
    }

    generateComparisonAnalytic(reportRequest, datapointFilter) {
        this.subscriptions.add(
            this.aggregateService
                .getComparisonAnalyticsData(
                    this.dataset.id,
                    datapointFilter,
                    reportRequest,
                    this.datapointProjection
                )
                .subscribe((success) => {
                    this.activeGroups = this.getSelectedGroups();
                    this.computeTotalCountsForComparison(success.groupResults);
                    this.convertDataToTableFormatForComparison(
                        success.groupResults
                    );
                    this.populateTableColumnsListForComparison();
                    if (this.sort) {
                        this.sortData(this.sort);
                    }
                    this.populateChartData();

                    this.dataIsReady = true;
                    this.changeDetector.detectChanges();
                })
        );
    }

    private createReportRequest(): ReportRequest {
        let groups: AggregateGroupRequest[] = [];
        this.selectedFieldsByDataset.forEach((fields, datasetId) => {
            fields.forEach((field) => {
                groups.push({
                    datasetID: datasetId,
                    fieldID: field.id,
                });
            });
        });
        return {
            datasetID: this.dataset.id,
            aggregateFieldCodes: [{ aggregateFieldCode: "1", id: "count" }],
            groups: groups,
            aggregateFieldType: DatapointAggregateFieldType.FIELD,
        };
    }

    private populateTableColumnsList() {
        this.dynamicColumns = new Map();
        this.columnsToDisplay = [];

        this.selectedFieldsByDataset.forEach((fields, datasetId) => {
            fields.forEach((field) => {
                let key = DatasetUtils.createUniqueIdentifierForDatasetField(
                    datasetId,
                    field.id
                );
                this.dynamicColumns.set(key, field.name);
                this.columnsToDisplay.push(key);
            });
        });
        
        this.columnsToDisplay.push(this.PERCENTAGE_COLUMN_ID);
        this.columnsToDisplay.push(this.COUNT_COLUMN_ID);
    }

    private computeTotalCount(groupResults: ReportResultResponse[]) {
        this.totalCount = 0;
        groupResults.forEach((groupResult) => {
            this.totalCount += groupResult.values[0].count;
        });
    }

    get DatasetFieldType() {
        return DatasetFieldType;
    }

    get DatasetFieldSpecificType() {
        return DatasetFieldSpecificType;
    }

    get ReportDisplayType() {
        return ReportDisplayType;
    }

    setDisplayType(reportSubType: ReportDisplayType) {
        if (this.selectedFieldsByDataset.size > 0) {
            this.reportSubType = reportSubType;
        }

        this.chartOptions.scales =
            reportSubType === ReportDisplayType.BAR_CHART
                ? {
                      yAxes: [
                          {
                              ticks: {
                                  callback: (value, index, values) => {
                                      return StringUtils.numberWithCommas(
                                          value
                                      );
                                  },
                              },
                          },
                      ],
                  }
                : null;

        this.chartOptions.tooltips = {
            callbacks: {
                label: (tooltipItem, data) => {
                    const value =
                        data.datasets[tooltipItem.datasetIndex].data[
                            tooltipItem.index
                        ];
                    const name = data.labels[tooltipItem.index];
                    const roundedUpValue = value;
                        // typeof value === "number" ? value.toFixed(2) : value;
                    let generatedTooltip = `${name}:${StringUtils.numberWithCommas(
                        roundedUpValue
                    )}`;

                    if (this.isComparisonModeActivated) {
                        generatedTooltip = `${
                            data.datasets[tooltipItem.datasetIndex].label
                        } - ${name}:${StringUtils.numberWithCommas(
                            roundedUpValue
                        )}`;
                    }

                    return generatedTooltip;
                },
            },
        };

        this.populateChartData();
    }

    getSelectedFieldsByDataset() {
        return this.selectedFieldsByDataset;
    }

    getSort() {
        return this.sort;
    }

    sortData(sort: Sort, isSortCall: boolean = false) {
        const isAsc = sort.direction === "asc";
        const fieldId = sort.active;
        if (isUndefined(this.sort)) {
            this.sort = {
                active: "",
                direction: "",
            };
        }
        this.sort.active = fieldId;
        this.sort.direction = sort.direction;
        let sortedData = this.reportData.sort((a, b) => {
            switch (fieldId) {
                case this.COUNT_COLUMN_ID:
                    return CountReportComponent.compare(
                        a.count,
                        b.count,
                        isAsc
                    );
                case this.PRIMARY_GROUP_COUNT_COLUMN_ID:
                    return CountReportComponent.compare(
                        a.primaryGroupCount,
                        b.primaryGroupCount,
                        isAsc
                    );
                case this.SECONDARY_GROUP_COUNT_COLUMN_ID:
                    return CountReportComponent.compare(
                        a.secondaryGroupCount,
                        b.secondaryGroupCount,
                        isAsc
                    );
                case this.PERCENTAGE_COLUMN_ID:
                    return CountReportComponent.compare(
                        a.percentage,
                        b.percentage,
                        isAsc
                    );
                case this.COMPARE_AMOUNT_COLUMN_ID:
                    return CountReportComponent.compare(
                        a.comparedGroupAmount,
                        b.comparedGroupAmount,
                        isAsc
                    );    
                case this.COMPARE_COLUMN_ID:
                    return CountReportComponent.compare(
                        a.comparedGroupPercentage,
                        b.comparedGroupPercentage,
                        isAsc
                    );
                default: {
                    let aValue = a.dynamicFieldValuesByIds.get(fieldId);
                    let bValue = b.dynamicFieldValuesByIds.get(fieldId);
                    return CountReportComponent.compare(aValue, bValue, isAsc);
                }
            }
        });
        this.reportData = [...sortedData];
        this.downloadReportData = this.reportData;
        if (isSortCall) {
            this.saveSorting.emit();
        }
    }

    private convertDataToTableFormat(groupResults: ReportResultResponse[]) {
        this.reportData = [];
        this.downloadReportData = [];
        groupResults.forEach((groupResult) => {
            let percentage: number =
                this.totalCount > 0
                    ? (groupResult.values[0].count / this.totalCount) * 100
                    : 0;
            let tableEntry: ReportRow = {
                count: groupResult.values[0].count,
                dynamicFieldValuesByIds: this.getDynamicFieldValuesByIds(
                    groupResult.buckets
                ),
                percentage: Math.round(percentage * 100) / 100,
            };
            this.downloadReportData.push(tableEntry);
        });
        this.reportData = this.downloadReportData.slice(0, this.LIMIT);
    }

    getDynamicFieldValuesByIds(
        groupResultBuckets: ReportResultGroupResponse[]
    ): Map<string, string> {
        let tableEntries: Map<string, string> = new Map<string, string>();
        groupResultBuckets.forEach((groupResultBucket) => {
            let value = groupResultBucket.value || "N/A";
            tableEntries.set(
                DatasetUtils.createUniqueIdentifierForDatasetField(
                    groupResultBucket.datasetID,
                    groupResultBucket.fieldID
                ),
                value
            );
        });
        return tableEntries;
    }

    /**
     * We need a structure like: Map<String, chartDataset[]>
     * The key if the main field value
     * The value is the chartDataset list for each of the other field's values (one chart dataset per second field value).
     * Each chart dataset array will contain the count values for the pair (mainField, secondField)
     */
    private populateChartData() {
        if (this.selectedFieldsCount > 2) {
            return; // cannot create chart with more than 2 group by fields
        }
        if (this.reportData.length === 0) {
            return;
        }
        this.initializeChartData();

        let chartColors: ChartInfo[] = [];
        let mainDatasetFieldValues = []; // chart labels
        let chartDatasets: ChartDataSets[] = [];

        if (this.selectedFieldsCount <= 1) {
            if (!this.isComparisonModeActivated) {
                let chartDataset = {
                    data: [],
                };
                let chartColor = {
                    backgroundColor: [],
                };
                chartDatasets.push(chartDataset);
                chartColors.push(chartColor);

                this.reportData.forEach((entry) => {
                    mainDatasetFieldValues.push(
                        entry.dynamicFieldValuesByIds.values().next().value
                    );
                    chartDataset.data.push(entry.count);
                    chartColor.backgroundColor.push(
                        ColorUtils.generateRandomHexColor()
                    );
                });
            } else {
                const primaryGroupCounts = [];
                const secondaryGroupCounts = [];
                const primaryGroupColor = ColorUtils.generateRandomHexColor();
                const secondaryGroupColor = ColorUtils.generateRandomHexColor();

                this.reportData.forEach((entry, index) => {
                    primaryGroupCounts.push(entry.primaryGroupCount);
                    secondaryGroupCounts.push(entry.secondaryGroupCount);
                    mainDatasetFieldValues.push(
                        entry.dynamicFieldValuesByIds.values().next().value
                    );
                });

                chartDatasets = [
                    {
                        label: this.getGroupLargeName(
                            AnayticsConstants.PRIMARY_GROUP_INDEX
                        ),
                        data: primaryGroupCounts,
                        backgroundColor: primaryGroupColor,
                    },
                    {
                        label: this.getGroupLargeName(
                            AnayticsConstants.SECONDARY_GROUP_INDEX
                        ),
                        data: secondaryGroupCounts,
                        backgroundColor: secondaryGroupColor,
                    },
                ];
            }
        } else {
            let mainField = this.reportData[0].dynamicFieldValuesByIds
                .keys()
                .next().value; // we will use this as the main field in the chart
            this.reportData.forEach((row) => {
                let mainFieldValue = row.dynamicFieldValuesByIds.get(mainField);
                if (!mainDatasetFieldValues.includes(mainFieldValue)) {
                    mainDatasetFieldValues.push(mainFieldValue);
                }
            });

            this.reportData.forEach((row) => {
                let mainDatasetFieldValue =
                    row.dynamicFieldValuesByIds.get(mainField);
                let indexInDatasetArray = mainDatasetFieldValues.indexOf(
                    mainDatasetFieldValue
                ); // because the labels indexes must match the values indexes
                row.dynamicFieldValuesByIds.forEach((value, datasetFieldId) => {
                    if (datasetFieldId !== mainField) {
                        let chartDataset = chartDatasets.find(
                            (cd) => cd.label === value
                        );
                        let backgroundColor = chartColors.find(
                            (bg) => bg.label === value
                        );
                        if (!chartDataset) {
                            chartDataset = {
                                data: new Array(
                                    mainDatasetFieldValues.length
                                ).fill(0),
                                label: value,
                            };
                            chartDatasets.push(chartDataset);
                        }
                        if (!backgroundColor) {
                            backgroundColor = {
                                label: value,
                                backgroundColor: new Array(
                                    mainDatasetFieldValues.length
                                ).fill(ColorUtils.generateRandomHexColor()),
                            };
                            chartColors.push(backgroundColor);
                        }
                        chartDataset.data[indexInDatasetArray] = row.count;
                    }
                });
            });
        }

        this.chartDatasets = chartDatasets;
        this.chartLabels = mainDatasetFieldValues;
        this.chartColors = chartColors;
    }

    private initializeChartData() {
        this.chartDatasets = [];
        this.chartLabels = [];
        this.chartColors = [];
    }

    private prepareProjection() {
        if (!this.datapointProjection) {
            this.datapointProjection = {
                datasetID: this.dataset.id,
                fields: [],
                links: [],
            };
        }
        this.selectedFieldsByDataset.forEach((fields, datasetId) => {
            if (datasetId === this.dataset.id) {
                this.datapointProjection.fields = fields.map(
                    (field) => field.id
                );
            } else {
                let linkProjection: DatapointProjection = {
                    datasetID: datasetId,
                    fields: fields.map((field) => field.id),
                };
                this.datapointProjection.links.push(linkProjection);
            }
        });
    }

    getTableReportHeader(): TableColumn[] {
        let columns: TableColumn[] = [];
        this.selectedFieldsByDataset.forEach((fields, datasetId) => {
            fields.forEach((field) => {
                columns.push({
                    id: DatasetUtils.createUniqueIdentifierForDatasetField(
                        datasetId,
                        field.id
                    ),
                    name: field.name,
                    type: TableColumnType.TEXT, // even of  type is number, we use TEXT to cover the 'N/A' value as well
                    horizontalAlignment: TableColumnAlignment.LEFT,
                });
            });
        });
        columns.push({
            id: this.PERCENTAGE_COLUMN_ID,
            name: "Total %",
            type: TableColumnType.TEXT,
            horizontalAlignment: TableColumnAlignment.LEFT,
        });
        columns.push({
            id: this.COUNT_COLUMN_ID,
            name: "Count",
            type: TableColumnType.INTEGER,
            horizontalAlignment: TableColumnAlignment.RIGHT,
        });
        return columns;
    }

    getTableReportFooter(): TableRow {
        let cells: TableCell[] = [];
        cells.push({ id: this.TOTAL_COLUMN_ID, value: "Total" });
        this.dynamicColumns.forEach((key, value) =>
            cells.push({ id: this.BLANK_COLUMN_ID, value: "" })
        );
        cells.splice(cells.length - 1, 1); // we need to add only N-1 empty spaces
        cells.push({ id: this.PERCENTAGE_COLUMN_ID, value: "100%" });
        cells.push({ id: this.COUNT_COLUMN_ID, value: this.totalCount });
        return { cells: cells };
    }

    getTableReportRows(): TableRow[] {
        let rows: TableRow[] = [];
        this.downloadReportData.map((row) => {
            let columns: TableCell[] = [];
            this.dynamicColumns.forEach((value, key) => {
                columns.push({
                    id: key,
                    value: row.dynamicFieldValuesByIds.get(key),
                });
            });
            columns.push({
                id: this.PERCENTAGE_COLUMN_ID,
                value: row.percentage.toString() + "%",
            });
            columns.push({ id: this.COUNT_COLUMN_ID, value: row.count });
            rows.push({ cells: columns });
        });
        return rows;
    }

    getTableReportDownloadRequest(): DownloadReportItem {
        if (this.dataIsReady) {
            let reportHeader = this.getTableReportHeader();
            let reportRows = this.getTableReportRows();
            let reportFooter = this.getTableReportFooter();
            let title = this.reportName || "Count";
            return new DownloadReportTableRequest(
                title,
                reportHeader,
                reportRows,
                reportFooter
            );
        } else {
            return null;
        }
    }

    getDisplayType(): ReportDisplayType {
        return this.reportSubType;
    }

    getChartReportDownloadRequest(): DownloadReportItem {
        let breakdownFieldsNames: string[] = [];
        this.selectedFieldsByDataset.forEach((fields, datasetId) => {
            fields.forEach((field) => {
                breakdownFieldsNames.push(field.name);
            });
        });
        let request: DownloadReportChartRequest = {
            title: this.reportName || "Count",
            valueKey: ValueKey.COUNT,
            type: undefined,
            columns: {
                value: "Count",
                categories: breakdownFieldsNames,
            },
            values: [],
        };

        this.chartDatasets.forEach((dataset, datasetIndex) => {
            dataset.data.forEach((value, valueIndex) => {
                let chartValueRequest: DownloadReportChartValueRequest = {
                    categories: [this.chartLabels[valueIndex]],
                    colors: [
                        this.chartColors[datasetIndex]?.backgroundColor[
                            valueIndex
                        ],
                    ],
                    count: value,
                    value: value,
                };
                if (dataset.label) {
                    chartValueRequest.categories.push(dataset.label);
                }
                request.values.push(chartValueRequest);
            });
        });

        if (this.reportSubType === ReportDisplayType.PIE_CHART) {
            request.type = DownloadItemReportType.AGGREGATE_PIE_CHART;
        } else if (this.reportSubType === ReportDisplayType.BAR_CHART) {
            request.type = DownloadItemReportType.AGGREGATE_BAR_CHART;
        }
        return request;
    }

    getReportDownloadRequest(): DownloadReportItem {
        if (this.getDisplayType() === ReportDisplayType.TABLE) {
            return !this.isComparisonModeActivated
                ? this.getTableReportDownloadRequest()
                : this.getTableReportDownloadRequestForComparison();
        } else if (
            this.getDisplayType() === ReportDisplayType.BAR_CHART ||
            this.getDisplayType() === ReportDisplayType.PIE_CHART
        ) {
            return !this.isComparisonModeActivated
                ? this.getChartReportDownloadRequest()
                : this.getChartReportDownloadRequestForComparison();
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    }

    isTwoDimensionReport(): boolean {
        let selectedFieldNo = 0;
        this.selectedFieldsByDataset.forEach(
            (fields, datasetId) => (selectedFieldNo += fields.length)
        );
        return selectedFieldNo === 2;
    }

    setSelectedFieldsByDataset(
        selectedFieldsByDataset: Map<string, DatasetField[]>
    ) {
        // the following is necessary for checking the selected field in the breakdown dropdown
        selectedFieldsByDataset.forEach((fields, datasetId) => {
            this.selectedFieldsCount += fields.length;
            let selectedFields: DatasetField[] = [];
            let selectedDataset = this.datasetsToChooseFrom.find(
                (d) => d.id === datasetId
            );
            if (selectedDataset) {
                selectedDataset.fields.forEach((selectionField) => {
                    let fieldIsSelected = fields.find(
                        (f) => f.id === selectionField.id
                    );
                    if (fieldIsSelected) {
                        selectionField.selected = true;
                        this.datapointObject.markSelected(
                            this.dataSource.data,
                            selectionField.id
                        );
                        selectedFields.push(selectionField);
                    }
                });
                this.selectedFieldsByDataset.set(
                    selectedDataset.id,
                    selectedFields
                );
            }
        });
    }
    get DatasetGeometryType() {
        return DatasetGeometryType;
    }

    sortFields(fields) {
        fields.sort((item1, item2) => {
            if (item1 && item1.name && item2 && item2.name) {
                return item1.name
                    .trim()
                    .toLowerCase()
                    .localeCompare(item2.name.trim().toLowerCase());
            } else {
                return item1.datasetLabel
                    .toLowerCase()
                    .localeCompare(item2.datasetLabel.toLowerCase());
            }
        });
        return fields;
    }

    resetNRISelectedFlag() {
        this._nriFields.forEach((outerElement) => {
            outerElement.child.forEach((element) => {
                element.child.selected = false;
            });
        });
    }

    dynamicFilterMenuEmitter($event) {
        this.onFieldsMenuClick($event.event, $event.dataset, $event.field);
    }

    prepareFilterAcccountOverlays(overlays) {
        overlays.forEach((element, key) => {
            this.parentOverlay = element;
            if (element.children.length <= 0 && element.overlays.length <= 0) {
                overlays.splice(key, 1);
            } else if (element.children.length > 0) {
                this.recursiveFilterAccountOverlay(element);
            }
            if (element.children.length <= 0 && element.overlays.length <= 0) {
                overlays.splice(key, 1);
            }
        });
        return overlays;
    }

    recursiveFilterAccountOverlay(element) {
        if (element.children.length) {
            let groupIds = [];
            element.children.forEach((sub_element, key) => {
                if (
                    sub_element.children.length <= 0 &&
                    sub_element.overlays.length <= 0
                ) {
                    groupIds.push(sub_element.group.id);
                } else if (sub_element.children.length > 0) {
                    element.children = element.children.filter(
                        (params) => !groupIds.includes(params.group.id)
                    );
                    this.recursiveFilterAccountOverlay(sub_element);
                    return;
                }
            });

            if (groupIds.length) {
                element.children = element.children.filter(
                    (params) => !groupIds.includes(params.group.id)
                );
                this.recursiveFilterAccountOverlay(this.parentOverlay);
            }
        }
    }

    getSaveButtonLabel() {
        return this.isDashboardCall ? "Save" : "Save to Dashboard";
    }

    getSaveAsButtonLabel() {
        return this.isDashboardCall ? "Save as" : "Save as Dashboard";
    }

    isTensorFlight(key: string) {
        return key.toUpperCase() === "TENSORFLIGHT";
    }

    setFieldsSelected(datasetforCountReport): GroupWithOverlaysTreeNode[] {
        for (const [key, value] of this.selectedFieldsByDataset) {
            this.datasetforCountReport.forEach((dataset) => {
                if (dataset && dataset.overlays && dataset.overlays.length) {
                    let selectedOverlay = this.findSelectedOverlay(
                        dataset.overlays,
                        key
                    );

                    if (!selectedOverlay) {
                        selectedOverlay = this.findSelectedOverlayFromChildren(
                            dataset.children,
                            key
                        );
                    }

                    if (selectedOverlay && selectedOverlay.fields) {
                        for (const field of value) {
                            let matchingField = selectedOverlay.fields.find(
                                (overlayField) => overlayField.id === field.id
                            );
                            if (matchingField) {
                                this.datapointObject.markSelected(
                                    this.dataSource.data,
                                    matchingField.id
                                );
                                matchingField.selected = true;
                            }
                        }
                    }
                }
            });
        }
        return datasetforCountReport;
    }

    findSelectedOverlayFromChildren(
        children: GroupWithOverlaysTreeNode[],
        key: string
    ): any {
        for (const element of children || []) {
            const foundOverlay = this.findSelectedOverlay(
                element.overlays,
                key
            );

            if (foundOverlay) {
                return foundOverlay;
            }

            if (element.children && element.children.length > 0) {
                const overlayFromChildren =
                    this.findSelectedOverlayFromChildren(element.children, key);
                if (overlayFromChildren) {
                    return overlayFromChildren;
                }
            }
        }

        return null;
    }

    findSelectedOverlay(overlays, key) {
        const selectedOverlay = overlays.find((overlay) => overlay.id === key);

        return selectedOverlay;
    }

    getDisplayName(node) {
        return this.datapointObject.getDisplayName(node);
    }

    collapseAll(): void {
        this.treeControl.collapseAll();
    }

    isLocationTypeApplication() {
        return this.dataset.application === MaptycsApplication.LOCATIONS;
    }

    // Comparison code

    getSelectedGroups() {
        return [...AnalyticsUtils.getSelectedNodes(this._compareGroupsStrcuture), ...AnalyticsUtils.getSelectedNodes(this._withGroupsStrcuture)];
    }

    getActiveGroups() {
        return this.activeGroups;
    }

    collapseAllGroup(): void {
        this.treeControlGroup.collapseAll();
    }

    fieldSelectLimitCount() {
        return this.isComparisonModeActivated
            ? AnayticsConstants.MAX_FIELD_SELECT_LIMIT_COMPARISON
            : AnayticsConstants.MAX_FIELD_SELECT_LIMIT;
    }

    isGenerateReportPossible() {
        if (this.isComparisonModeActivated) {
            return (
                [...new Set(this.getSelectedGroups())].length ===
                    AnayticsConstants.MAX_GROUP_SELECT_LIMIT &&
                this.selectedFieldsByDataset.size
            );
        } else {
            return this.selectedFieldsByDataset.size;
        }
    }

    private calculatePercentage(count: number, totalCount: number): number {
        return totalCount > 0 ? (count / totalCount) * 100 : 0;
    }

    private populateTableColumnsListForComparison() {
        this.dynamicColumns = new Map();
        const dynamicColumnsAsArray = [];

        this.selectedFieldsByDataset.forEach((fields, datasetId) => {
            fields.forEach((field) => {
                let key = DatasetUtils.createUniqueIdentifierForDatasetField(
                    datasetId,
                    field.id
                );
                this.dynamicColumns.set(key, field.name);
                dynamicColumnsAsArray.push(key);
            });
        });

        this.columnsToDisplayForComparison = [
            ...dynamicColumnsAsArray,
            this.PRIMARY_GROUP_COUNT_COLUMN_ID,
            this.SECONDARY_GROUP_COUNT_COLUMN_ID,
            this.COMPARE_AMOUNT_COLUMN_ID,
            this.COMPARE_COLUMN_ID,
        ];
    }

    private convertDataToTableFormatForComparison(
        groupResults: ReportResultResponse[]
    ) {
        this.reportData = [];
        this.downloadReportData = [];
        groupResults.forEach((result) => {
            const percentage: number = this.calculatePercentage(
                this.findGroupCount(
                    AnayticsConstants.PRIMARY_GROUP_INDEX,
                    result
                ) +
                    this.findGroupCount(
                        AnayticsConstants.SECONDARY_GROUP_INDEX,
                        result
                    ),
                this.totalCount
            );
            const primaryGroupPercentage: number = this.calculatePercentage(
                this.findGroupCount(
                    AnayticsConstants.PRIMARY_GROUP_INDEX,
                    result
                ),
                this.totalCountPrimaryGroup
            );
            const secondaryGroupPercentage: number = this.calculatePercentage(
                this.findGroupCount(
                    AnayticsConstants.SECONDARY_GROUP_INDEX,
                    result
                ),
                this.totalCountSecondaryGroup
            );
            const comparedGroupPercentage: number =
                AnalyticsUtils.calculateVariancePercentage(
                    this.findGroupCount(
                        AnayticsConstants.PRIMARY_GROUP_INDEX,
                        result
                    ),
                    this.findGroupCount(
                        AnayticsConstants.SECONDARY_GROUP_INDEX,
                        result
                    )
                );

            const tableEntry: ReportRow = {
                primaryGroupCount: this.findGroupCount(
                    AnayticsConstants.PRIMARY_GROUP_INDEX,
                    result
                ),
                secondaryGroupCount: this.findGroupCount(
                    AnayticsConstants.SECONDARY_GROUP_INDEX,
                    result
                ),
                count:
                    this.findGroupCount(
                        AnayticsConstants.PRIMARY_GROUP_INDEX,
                        result
                    ) +
                    this.findGroupCount(
                        AnayticsConstants.SECONDARY_GROUP_INDEX,
                        result
                    ),
                dynamicFieldValuesByIds: this.getDynamicFieldValuesByIds(
                    result.buckets
                ),
                percentage: RandomUtils.roundUp(percentage),
                primaryGroupPercentage: RandomUtils.roundUp(
                    primaryGroupPercentage
                ),
                secondaryGroupPercentage: RandomUtils.roundUp(
                    secondaryGroupPercentage
                ),
                comparedGroupAmount:
                this.findGroupCount(
                    AnayticsConstants.PRIMARY_GROUP_INDEX,
                    result
                ) -
                this.findGroupCount(
                    AnayticsConstants.SECONDARY_GROUP_INDEX,
                    result
                ),
                comparedGroupPercentage: RandomUtils.roundUp(
                    comparedGroupPercentage
                ),
            };
            this.downloadReportData.push(tableEntry);
        });
        this.reportData = this.downloadReportData.slice(0, this.LIMIT);
    }

    private computeTotalCountsForComparison(
        groupResults: ReportResultResponse[]
    ) {
        this.totalCount = 0;
        groupResults.forEach((result) => {
            this.totalCount +=
                this.findGroupCount(
                    AnayticsConstants.PRIMARY_GROUP_INDEX,
                    result
                ) +
                this.findGroupCount(
                    AnayticsConstants.SECONDARY_GROUP_INDEX,
                    result
                );
        });

        this.totalCountPrimaryGroup = 0;
        groupResults.forEach((result) => {
            this.totalCountPrimaryGroup += this.findGroupCount(
                AnayticsConstants.PRIMARY_GROUP_INDEX,
                result
            );
        });

        this.totalCountSecondaryGroup = 0;
        groupResults.forEach((result) => {
            this.totalCountSecondaryGroup += this.findGroupCount(
                AnayticsConstants.SECONDARY_GROUP_INDEX,
                result
            );
        });
    }

    getGroupName(groupIndex, isLengthChecked: boolean = true) {
        return AnalyticsUtils.getGroupName(groupIndex, this.getActiveGroups(), isLengthChecked);
    }

    getGroupLargeName(groupIndex, isLengthChecked: boolean = true) {
        return AnalyticsUtils.getGroupLargeName(groupIndex, this.getActiveGroups(), isLengthChecked);
    }

    findGroupCount(groupIndex, result) {
        const groupId = this.getActiveGroups()[groupIndex].id;
        const bucketIndex = result.buckets.findIndex(
            (bucket) => groupId === bucket.groupID
        );

        if (bucketIndex !== -1) {
            return result.values[bucketIndex].count;
        }

        return 0;
    }

    isLegendsEnabled() {
        return this.selectedFieldsCount > 1;
    }

    // Download comparison
    getTableReportFooterForComparison(): TableRow {
        let cells: TableCell[] = [];
        cells.push({ id: this.TOTAL_COLUMN_ID, value: "Total" });
        this.dynamicColumns.forEach((key, value) =>
            cells.push({ id: this.BLANK_COLUMN_ID, value: "" })
        );
        cells.splice(cells.length - 1, 1);
        cells.push({
            id: this.PRIMARY_GROUP_COUNT_COLUMN_ID,
            value: this.totalCountPrimaryGroup,
        });
        cells.push({
            id: this.SECONDARY_GROUP_COUNT_COLUMN_ID,
            value: this.totalCountSecondaryGroup,
        });
        cells.push({ id: this.COMPARE_AMOUNT_COLUMN_ID, value: RandomUtils.roundUp((this.totalCountPrimaryGroup - this.totalCountSecondaryGroup)) });
        cells.push({ id: this.COMPARE_COLUMN_ID, value: RandomUtils.roundUp(AnalyticsUtils.calculateVariancePercentage(this.totalCountPrimaryGroup, this.totalCountSecondaryGroup)) + "%" });
        return { cells: cells };
    }

    getTableReportRowsForComparison(): TableRow[] {
        let rows: TableRow[] = [];
        this.downloadReportData.map((row) => {
            let columns: TableCell[] = [];
            this.dynamicColumns.forEach((value, key) => {
                columns.push({
                    id: key,
                    value: row.dynamicFieldValuesByIds.get(key),
                });
            });

            columns.push({
                id: this.PRIMARY_GROUP_COUNT_COLUMN_ID,
                value: row.primaryGroupCount,
            });
            columns.push({
                id: this.SECONDARY_GROUP_COUNT_COLUMN_ID,
                value: row.secondaryGroupCount,
            });
            columns.push({ id: this.COUNT_COLUMN_ID, value: row.count });
            columns.push({
                id: this.COMPARE_AMOUNT_COLUMN_ID,
                value: row.comparedGroupAmount.toString(),
            });
            columns.push({
                id: this.COMPARE_COLUMN_ID,
                value: row.comparedGroupPercentage.toString() + "%",
            });
            rows.push({ cells: columns });
        });
        return rows;
    }

    getTableReportHeaderForComparison(): TableColumn[] {
        let columns: TableColumn[] = [];
        this.selectedFieldsByDataset.forEach((fields, datasetId) => {
            fields.forEach((field) => {
                columns.push({
                    id: DatasetUtils.createUniqueIdentifierForDatasetField(
                        datasetId,
                        field.id
                    ),
                    name: field.name,
                    type: TableColumnType.TEXT,
                    horizontalAlignment: TableColumnAlignment.LEFT,
                });
            });
        });

        columns.push({
            id: this.PRIMARY_GROUP_COUNT_COLUMN_ID,
            name: `${this.getGroupName(
                AnayticsConstants.PRIMARY_GROUP_INDEX,
                false
            )} Count`,
            type: TableColumnType.INTEGER,
            horizontalAlignment: TableColumnAlignment.RIGHT,
        });

        columns.push({
            id: this.SECONDARY_GROUP_COUNT_COLUMN_ID,
            name: `${this.getGroupName(
                AnayticsConstants.SECONDARY_GROUP_INDEX,
                false
            )} Count`,
            type: TableColumnType.INTEGER,
            horizontalAlignment: TableColumnAlignment.RIGHT,
        });

        columns.push({
            id: this.COMPARE_AMOUNT_COLUMN_ID,
            name: `${this.getGroupName(
                AnayticsConstants.PRIMARY_GROUP_INDEX,
                false
            )} vs ${this.getGroupName(
                AnayticsConstants.SECONDARY_GROUP_INDEX,
                false
            )}`,
            type: TableColumnType.TEXT,
            horizontalAlignment: TableColumnAlignment.LEFT,
        });

        columns.push({
            id: this.COMPARE_COLUMN_ID,
            name: `${this.getGroupName(
                AnayticsConstants.PRIMARY_GROUP_INDEX, false
            )} vs ${this.getGroupName(
                AnayticsConstants.SECONDARY_GROUP_INDEX, false
            )} (%)`,
            type: TableColumnType.TEXT,
            horizontalAlignment: TableColumnAlignment.LEFT,
        });

        return columns;
    }

    getTableReportDownloadRequestForComparison() {
        if (this.dataIsReady) {
            let reportHeader = this.getTableReportHeaderForComparison();
            let reportRows = this.getTableReportRowsForComparison();
            let reportFooter = this.getTableReportFooterForComparison();
            let title = this.reportName || "Count";
            return new DownloadReportTableRequest(
                title,
                reportHeader,
                reportRows,
                reportFooter
            );
        } else {
            return null;
        }
    }

    getChartReportDownloadRequestForComparison(): DownloadReportItem {
        let breakdownFieldsNames: string[] = [];
        this.selectedFieldsByDataset.forEach((fields, datasetId) => {
            fields.forEach((field) => {
                breakdownFieldsNames.push(field.name);
            });
        });

        let request: DownloadReportChartRequest = {
            title: this.reportName || "Count",
            valueKey: ValueKey.COUNT,
            type: DownloadItemReportType.AGGREGATE_BAR_CHART,
            columns: {
                value: "Count",
                categories: [...breakdownFieldsNames, "Count"],
            },
            values: [],
        };

        this.chartDatasets.forEach((dataset, datasetIndex) => {
            dataset.data.forEach((value, valueIndex) => {
                let chartValueRequest: DownloadReportChartValueRequest = {
                    categories: [this.chartLabels[valueIndex]],
                    colors: [dataset.backgroundColor],
                    count: value,
                    value: value,
                };
                if (dataset.label) {
                    chartValueRequest.categories.push(dataset.label);
                }
                request.values.push(chartValueRequest);
            });
        });

        return request;
    }

    onCheckboxChange(event: MatCheckboxChange, type: string) {
        const result = AnalyticsUtils.groupSelectionEvent(event, type, this._compareGroups, this._withGroups);
        this._compareGroups = result.compareGroups;
        this._withGroups = result.withGroups;
    }

    toggleItemExpansion(item: TreeNode) {
        item.expanded = !item.expanded;
    }

    toggleItem(event: MatCheckboxChange, item: TreeNode, section: 'compare' | 'with') {
        if(event.checked && section == 'compare') {
            this.compareGroupCheckEvent(this._withGroupsStrcuture, item.id, 'with');
            this.compareGroupCheckEvent(this._compareGroupsStrcuture, item.id, 'compare');
        } else if(event.checked && section == 'with') {
            this.withGroupCheckEvent(this._compareGroupsStrcuture, item.id, 'compare');
            this.withGroupCheckEvent(this._withGroupsStrcuture, item.id, 'with');
        } else if(!event.checked && section == 'compare') {
            let selectedItem = AnalyticsUtils.getSelectedNodes(this._withGroupsStrcuture);
            const withItem = selectedItem.length > 0 ? selectedItem[0] : undefined;
            this.compareGroupUnCheckEvent(this._withGroupsStrcuture, item.id, 'with', withItem);
            this.compareGroupUnCheckEvent(this._compareGroupsStrcuture, item.id, 'compare', withItem);
        } else if(!event.checked && section == 'with') {
            let selectedItem = AnalyticsUtils.getSelectedNodes(this._compareGroupsStrcuture);
            const compareItem = selectedItem.length > 0 ? selectedItem[0] : undefined;
            this.withGroupUnCheckEvent(this._compareGroupsStrcuture, item.id, 'compare', compareItem);
            this.withGroupUnCheckEvent(this._withGroupsStrcuture, item.id, 'with', compareItem);
        }  
    }

    compareGroupCheckEvent(nodes: TreeNode[], id: number, section: string) {
        for (const node of nodes) {
            if ((section === 'compare' && node.id !== id) || (section === 'with' && node.id == id)) {
                node.disabled = true;
            } 
            if (node.children) {
                this.compareGroupCheckEvent(node.children, id, section);
            }
        }
    }

    withGroupCheckEvent(nodes: TreeNode[], id: number, section: string) {
        for (const node of nodes) {
            if ((section === 'compare' && node.id === id) || (section === 'with' && node.id !== id)) {
                node.disabled = true;
            } 
            if (node.children) {
                this.withGroupCheckEvent(node.children, id, section);
            }
        }
    }

    compareGroupUnCheckEvent(nodes: TreeNode[], id: number, section: string, withItem: any) {
        for (const node of nodes) {
            if ((section === 'with' && node.id == id) && withItem == undefined) {
                node.disabled = false;
            } else if (section === 'compare' && (withItem !== undefined && (node.id !== withItem.id && node.id !== id) ||  withItem == undefined)) {
                node.disabled = false;
            } else if (section === 'compare' && withItem == undefined) {
                node.disabled = false;
            }
            if (node.children) {
                this.compareGroupUnCheckEvent(node.children, id, section, withItem);
            }
        }
    }

    withGroupUnCheckEvent(nodes: TreeNode[], id: number, section: string, compareItem: any) {
        for (const node of nodes) {
            if ((section === 'compare' && node.id == id) && compareItem == undefined) {
                node.disabled = false;
            } else if (section === 'with' && (compareItem !== undefined && (node.id !== compareItem.id && node.id !== id) ||  compareItem == undefined)) {
                node.disabled = false;
            } else if (section === 'with' && compareItem == undefined) {
                node.disabled = false;
            }
            if (node.children) {
                this.withGroupUnCheckEvent(node.children, id, section, compareItem);
            }
        }
    }
}

 <agm-info-window  #infoWindow [disableAutoPan]="false" [latitude]="infoWindowCoordinates?.lat" [longitude]="infoWindowCoordinates?.lng" [isOpen]="haveData">
    <ng-container *ngIf="overlaysWithMatchedPoints.length > 1 || foundDatapointsByDatasetId[overlaysWithMatchedPoints[0]?.id]?.length > 1">
        <ng-container *ngFor="let overlay of activeOverlays">
            <div *ngIf="foundDatapointsByDatasetId[overlay.id]?.length">
                <div class="overlay-header-name">{{getOverlayName(overlay)}}</div>
                <ul>
                    <li class="list-item" (click)="openDetailsByDatapoint(overlay.id, i)" *ngFor="let datapoint of foundDatapointsByDatasetId[overlay.id]; let i = index">
                            <span class="info-preview name"> {{getOverlayName(fieldsByIdsAndDataset.get(overlay.id).get(datapoint.fields[0].id))}}</span>&nbsp;&nbsp;&nbsp;
                            <span class="info-preview value" style="text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;">{{datapoint.fields[0].textValue || datapoint.fields[0].numberValue || (datapoint.fields[0].datetimeValue | maptycsDate : true)}}</span>
                    </li>
                </ul>
            </div>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="overlaysWithMatchedPoints.length === 1 && foundDatapointsByDatasetId[overlaysWithMatchedPoints[0].id].length === 1">
        <div class="title">
            Summary
        </div>
        <ul>
            <ng-container *ngFor="let field of foundDatapointsByDatasetId[overlaysWithMatchedPoints[0].id][0].fields">
                <ng-container *ngIf="field && (field.textValue || field.numberValue  || field.datetimeValue)">
                    <li class="list-item">
                            <span class="info-preview name">{{formatedKeyName(field)}}</span>

                            <span class="info-preview value" [innerHTML]="this.domSanitizer.bypassSecurityTrustHtml(field?.textValue || field?.numberValue  || (field.datetimeValue | maptycsDate : true))"  matTooltipPosition="after" style="text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;">
                                {{(field.numberValue | number) || (field.datetimeValue | maptycsDate : true)}}
                            </span>
                    </li>
                </ng-container>
            </ng-container>
        </ul>
    </ng-container>
    <button *ngIf="foundDatapoints === 1 && !isShowViewAlertsButton()"  style="width: auto;" mat-raised-button (click)="openDetailedView()">View Profile</button>
    <button *ngIf="foundDatapoints > 1 && isShowViewAlertsButton()"  style="width: auto;" mat-raised-button (click)="openDetailedView(true)">View Alerts</button>
    <div *ngIf="foundDatapoints === 1 && isShowViewAlertsButton()" class="d-flex" style="display: flex; margin: 5px 0 1px 0;">
        <button style="width: auto;" mat-raised-button (click)="openDetailedView()">View Profile</button>
        <button style="width: auto;" mat-raised-button (click)="openDetailedView(true)">View Alerts</button>
    </div>

</agm-info-window>

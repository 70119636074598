import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Dataset } from "src/app/model/dataset/dataset";

@Injectable({
    providedIn: "root",
})
export class ApiMeteringService {
    constructor(private readonly http: HttpClient) {}

    getAPIMeteringUsageCount(filterObject: FilterObject): Observable<number> {
        return this.http.post<number>(
            `/api-metering/usage/count`,
            filterObject
        );
    }

    getTotalCount(filterObject: FilterObject): Observable<number> {
        return this.http.post<number>(
            `/api-metering/total`,
            filterObject
        );
    }

    getPaginatedAPIMeteringUsage(filterObject): Observable<Dataset[]> {
        console.log(filterObject);
        return this.http.post<Dataset[]>(
            `/api-metering/get-usage`,
            filterObject
        );
    }
    getUsersForDropdown(accountId: number): Observable<APIMeteringDropdownItem[]> {
        return this.http.get<Dataset[]>(`/api-metering/get/username/${accountId}`);
    }

    getServicesForDropdown(): Observable<APIMeteringDropdownItem[]> {
        return this.http.get<Dataset[]>(`/api-metering/get/service`);
    }

    downloadAPIMeteringData(
        filterObject: FilterObject,
        fileName: string
    ): Observable<any> {
        return this.http.post(`/api-metering/download`, filterObject, {
            params: { fileName },
            responseType: "blob",
        });
    }
}

export enum SortingOrder {
    ASC = "ASCENDANT",
    DESC = "DESCENDANT",
}

export interface GlobalOverlayFilter {
    skip: number;
    limit: number;
    sortBy: string;
    filterString: string;
    sortOrder: SortingOrder;
}

interface Filter {
    client: number[];
    user_id: string[];
    service: string[];
    start_date: number[];
    end_date: number[];
}
export interface FilterObject {
    skip: number;
    limit: number;
    sortBy: string | null;
    sortOrder: any;
    filter: Filter;
}

export interface APIMeteringDropdownItem {
    id: string;
    name: string;
}

<div class="form-container">
    <form [formGroup]="createAccountForm">
        <mat-form-field>
            <mat-label>Name</mat-label>
            <input matInput formControlName="name" name="account-name" autocomplete="off" />
            <mat-error *ngIf="createAccountForm.controls.name.hasError('used') ">
                Account with this name already exists
            </mat-error>
            <mat-error *ngIf="createAccountForm.controls.name.hasError('required') ">
                Account name is required
            </mat-error>
            <mat-error
                *ngIf="createAccountForm.controls['name'].touched && createAccountForm.controls['name'].hasError('maxlength')">
                You exceed the maximum number of characters.
            </mat-error>
            <mat-error
                *ngIf="createAccountForm.controls['name'].touched && createAccountForm.controls['name'].hasError('pattern')">
                You cannot use special characters. Space is allowed only between words.
            </mat-error>
        </mat-form-field>
        <h4 class="create-title">Select modules</h4>
        <div class="list" formGroupName="checkboxes">

            <table style="width:100%">
                <tr>
                    <th>Dataset Name</th>
                    <th>Links</th>
                </tr>
                <tr *ngFor="let dataset of datasets; let i = index">
                    <td>
                        <mat-checkbox #datasetCheckbox [formControlName]="datasets[i].text"
                            (change)="toggleLinks($event, datasets[i])" style="text-transform: capitalize">
                            {{ datasets[i].text }}
                        </mat-checkbox>

                    </td>
                    <td>
                        <div class="links">
                            <span *ngFor="let linkedDataset of linkedDatasets(dataset)"
                                style="display:inline-flex; width: 50%;">
                                <mat-checkbox
                                    [disabled]="!datasetCheckbox.checked || !this.createAccountForm.value.checkboxes[linkedDataset.key]"
                                    [ngModelOptions]="{standalone: true}"
                                    [(ngModel)]="datasetLinkMap[(linkedDataset.bitValue+ dataset.bitValue)]"
                                    style="text-transform: capitalize">
                                    {{ linkedDataset.text }}
                                </mat-checkbox>
                            </span>
                        </div>
                    </td>
                </tr>
            </table>
            <mat-error *ngIf="!createAccountForm.get('atLeastOneDatasetSelected').value">
                At least 1 dataset should be selected
            </mat-error>
        </div>
    </form>
    <button mat-flat-button color="accent" [disabled]="createAccountForm.invalid" (click)="submitAccount()">Save
    </button>
</div>
import {Component, Input, OnInit, ChangeDetectorRef} from '@angular/core';
import {isEnabled} from '../../../environments/environment';
import {EventNotificationSettings} from '../../model/notifications/event-notification-settings';
import {AuthService} from '../../auth/auth.service';
import {EventNotificationService} from '../../data-access-layer/notification/event-notification.service';
import {Account} from '../../model/account/account';
import {AccountMember} from '../../model/member/account-member';
import {Dataset} from '../../model/dataset/dataset';
import {Constants, DistanceUnit} from '../../constants';
import {DatapointsPageStateService} from '../datapoints/datapoints-page-state.service';
import {DatasetGeometryType} from '../../model/dataset/dataset-geometry-type';
import {Functionalities} from '../../../environments/app-functionalities';

const IS_PART_OF = Functionalities.DATAPOINTS;

@Component({
    selector: 'map-event-notifications',
    templateUrl: './event-notifications.component.html',
    styleUrls: ['./event-notifications.component.scss']
})
export class EventNotificationsComponent implements OnInit {

    @Input() account: Account;
    @Input() dataset: Dataset;
    @Input() groupsIds: number[];

    isEnabled = isEnabled(IS_PART_OF);
    notifications: EventNotificationSettings[] = [];
    eligibleOverlays: Dataset[] = [];
    currencies: string[];

    private accountMember: AccountMember;


    constructor(private readonly eventNotifService: EventNotificationService,
                private readonly datapointsPageStateService: DatapointsPageStateService,
                private readonly authService: AuthService,private readonly changeDetector: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.eligibleOverlays = this.datapointsPageStateService.getLinkedAccountOverlays().filter(overlay => overlay.isRefreshable && overlay.geometryType === DatasetGeometryType.POINT);
        let principal = this.authService.getPrincipal();
        if (!principal.isSuperadmin) {
            this.accountMember = principal.membership.find(member => member.accountID === this.account.id);
            this.eventNotifService.getNotificationsForMember(this.accountMember.id).subscribe(
                notifications => {
                    this.notifications = notifications;
                    this.changeDetector.detectChanges();
                }
            );
        }
        this.eventNotifService.getCurrencies().subscribe(currencies => this.currencies = currencies);
    }

    addNotification() {
        this.notifications.push({
            datasetId: this.dataset.id,
            locationBreakdownFieldId: '',
            memberId: this.accountMember.id,
            notificationName: '',
            overlayId: '',
            currency: Constants.DEFAULT_CURRENCY,
            radius: Constants.DEFAULT_RADIUS,
            distanceUnit: DistanceUnit.KM,
            emails: []
        });
    }

    deleteEventNotif(notif: EventNotificationSettings) {
        let index = this.notifications.indexOf(notif);
        this.notifications.splice(index, 1);
    }

}

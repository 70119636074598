<div class="grid-container" cdkDropListGroup>
    <div cdkDropList
        [cdkDropListEnterPredicate]="dropListEnterPredicate"
        (cdkDropListDropped)="dropListDropped()">

    </div>
    <div cdkDropList
        *ngFor="let item of localFields"
        [cdkDropListEnterPredicate]="dropListEnterPredicate"
        (cdkDropListDropped)="dropListDropped()">
        <!--        <div cdkDrag class="example-box" (cdkDragMoved)="dragMoved($event);">{{item}}</div>-->
        <map-field (delete)="removeField($event)" cdkDrag (cdkDragMoved)="dragMoved($event)" [field]="item" [allowFloatingFields]="allowFloatingFields"></map-field>
    </div>
</div>

import { Injectable } from "@angular/core";
import { EChartsOption } from "echarts";
import { Observable, Subject } from "rxjs";
import {
    ClimateChart,
    ChartConfig,
    YAxis,
} from "../../../model/climate/climate-chart";
import { MAPTYCS_CHART_COLOR } from "./custom-theme";

@Injectable()
export class MaxTemperatureService {
    private readonly locationChartSubject: Subject<any>;
    private readonly addressChartSubject: Subject<any>;

    constructor() {
        this.locationChartSubject = new Subject<any>();
        this.addressChartSubject = new Subject<any>();
    }

    emitLocationChartEvent(chartInstance: any): void {
        this.locationChartSubject.next(chartInstance);
    }

    getLocationChartSubject(): Observable<any> {
        return this.locationChartSubject.asObservable();
    }

    emitAddressChartEvent(chartInstance: any): void {
        this.addressChartSubject.next(chartInstance);
    }

    getAddressChartSubject(): Observable<any> {
        return this.addressChartSubject.asObservable();
    }

    options: EChartsOption = {
        height: "80%",
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: "cross",
                label: {
                    backgroundColor: "#6a7985",
                },
            },
        },
        legend: {},
        toolbox: {
            show: true,
            orient: "vertical",
            feature: {
                show: true,
                dataView: {
                    readOnly: false,
                    title: "View raw data",
                    lang: ["Raw data view", "Close", "Refresh"],
                    buttonColor: MAPTYCS_CHART_COLOR,
                },
                magicType: {
                    type: ["line", "bar"],
                    title: {
                        line: "Switch to Line Chart",
                        bar: "Switch to Bar Chart",
                    },
                },
                restore: { title: "Restore" },
                saveAsImage: { title: "Save as an image" },
            },
        },
        dataZoom: {
            show: false,
            start: 0,
            end: 100,
        },
        grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
        },
        xAxis: [{ type: "category" }],
        yAxis: [
            {
                type: "value",
                scale: true,
                name: "Temperature",
                position: "left",
                alignTicks: true,
                axisLine: {
                    show: true,
                },
                axisLabel: {
                    formatter: "{value} °C",
                },
                boundaryGap: [0.2, 0.2],
            },
        ],
        series: [],
        dataset: {},
    };

    updateMaxTempChart(echartsInstance: any, climateChart: any) {
        const chartConfig: ChartConfig = climateChart.chartConfig;
        const yAxis: YAxis = chartConfig.yAxis;
        const seriesData = [];
        const source = [];

        source.push(climateChart.xAxis);

        for (const [key, value] of Object.entries(climateChart.series)) {
            seriesData.push({
                type: chartConfig.type,
                seriesLayoutBy: "row",
                smooth: true,
            });
            source.push(value);
        }

        echartsInstance.setOption({
            xAxis: [{ type: "category" }],
            yAxis: [
                {
                    type: "value",
                    scale: true,
                    name: "Temperature",
                    min: yAxis.min,
                    max: yAxis.max,
                    interval: yAxis.interval,
                    position: "left",
                    alignTicks: true,
                    axisLine: {
                        show: true,
                    },
                    axisLabel: {
                        formatter: "{value} " + yAxis.unit,
                    },
                    boundaryGap: [0.2, 0.2],
                },
            ],
            dataset: {
                source: source,
            },
            series: seriesData,
        });
    }
}
